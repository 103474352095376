import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createProduction = async (form) => {
  try {
    const response = await httpClient.post(`/Production/Job/Create`, form);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const changeProductionJobStatus = async (form) => {
  try {
    const response = await httpClient.post(
      `/Production/Job/ChangeStatus`,
      form,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const editProduction = async (form) => {
  try {
    const response = await httpClient.post(`/Production/Job/Edit`, form);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getProductionDetails = async (
  customerLocationId,
  customerFacilityId,
  jobId,
) => {
  try {
    const response = await httpClient.get(
      `/Production/Job/${customerLocationId}/${customerFacilityId}/${jobId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getProductionKitSkuByWarehouseCustomerId = async (
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `/Production/Job/create/data/Kitsku/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCreatedProductions = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/Production/Job/List/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getAllDropdown = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/Production/Job/Create/Data/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getKitdetails = async (itemId) => {
  try {
    const response = await httpClient.get(
      `/Production/Job/kitdetail/${itemId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getCreatedProduction = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/Production/Job/Create/Data/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPickTicketLineItemListForNewPickTicket = async (jobId) => {
  try {
    const response = await httpClient.get(
      `/production/pickticket/lineitem/list/new/${jobId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const postPickTicketLineItemListForNewPickTicket = async (form) => {
  try {
    const response = await httpClient.post(
      `/production/pickticket/create`,
      form,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPickTicketDetail = async (pickTicketId) => {
  try {
    const response = await httpClient.get(
      `/production/pickticket/detail/${pickTicketId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const ProductionDeletePickTicket = async (pickTicketModel) => {
  try {
    const response = await httpClient.post(
      `/production/pickticket/delete`,
      pickTicketModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const ProductionEditPickTicket = async (pickTicketModel) => {
  try {
    const response = await httpClient.post(
      `/production/pickticket/edit`,
      pickTicketModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const ProductionReportCreate = async (productionModel) => {
  try {
    const response = await httpClient.post(
      `/production/productionreport/create`,
      productionModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const ProductionReportEdit = async (productionModel) => {
  try {
    const response = await httpClient.post(
      `/production/productionreport/edit`,
      productionModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetProductionReportById = async (jobProductionReportId) => {
  try {
    const response = await httpClient.get(
      `/production/productionreport/${jobProductionReportId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const ProductionReportDelete = async (productionModel) => {
  try {
    const response = await httpClient.post(
      `/production/productionreport/delete`,
      productionModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetCreateLicensePlateKnownData = async (jobId) => {
  try {
    const response = await httpClient.get(
      `/production/licenseplate/known/create/data/${jobId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const CreateLicensePlateKnown = async (productionLicensePlateModel) => {
  try {
    const response = await httpClient.post(
      `production/licenseplate/known/create`,
      productionLicensePlateModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetEditLicensePlateKnownData = async (licensePlateId) => {
  try {
    const response = await httpClient.get(
      `/production/licenseplate/known/edit/data/${licensePlateId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const EditLicensePlateKnown = async (productionLicensePlateModel) => {
  try {
    const response = await httpClient.post(
      `production/licenseplate/known/edit`,
      productionLicensePlateModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// for Put Away Drop drown
export const GetLocationPutAwayLicensePlateKnownData = async (
  customerLocationId,
  customerFacilityId,
  licensePlateId,
) => {
  try {
    const response = await httpClient.get(
      `/production/licenseplate/known/putaway/data/${customerLocationId}/${customerFacilityId}/${licensePlateId} `,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const CreatePutAwayLicensePlateKnown = async (
  productionLicensePlateModel,
) => {
  try {
    const response = await httpClient.post(
      `production/licenseplate/known/putaway`,
      productionLicensePlateModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const DeleteLicensePlateKnown = async (productionLicensePlateModel) => {
  try {
    const response = await httpClient.post(
      `/production/licenseplate/known/delete`,
      productionLicensePlateModel,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
