import React, { useContext, useEffect, useState } from 'react';

import Button from 'components/button';
import Input from 'components/input';
import { ModalFooterEnd } from 'components/modal/styles';
import { ModalBox, ModalContent } from 'components/styles';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { ItemUnitOfMeasureType } from 'pages/receiving/receipts/subpage';
import { UOMType, useSalesOrderContext } from 'pages/sales/salesorder/context';
import { getItemUnitOfMeasureWithPalletDDL } from 'services/api/item/item.api';
import { getSkuItemDetail } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Grid as MUIGrid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ModalReturnQuantityLineItem = () => {
  const theme = useTheme();
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);

  const {
    isReturnQuantityLineItemModalOpen,
    setIsReturnQuantityLineItemModalOpen,
  } = useContext(GlobalContext);

  const {
    uomValue,
    optionsUOM,
    orderItemRows,
    salesOrderForm,
    returnOrderItemRows,
    returnQuantityLineItem,
    setUomValue,
    setOptionsUOM,
    setReturnOrderItemRows,
    setReturnQuantityLineItem,
  } = useSalesOrderContext();

  const [quantityError, setQuantityError] = useState<any>({});

  const onLoadGetItemUnitOfMeasureDDLOnChange = async (itemId) => {
    try {
      return await getItemUnitOfMeasureWithPalletDDL(itemId);
    } catch (err) {
      return err;
    }
  };

  const validateOnlick = () => {
    const tempValue = orderItemRows.find(
      (o) => o.lineId === returnQuantityLineItem.lineId,
    );

    const temp: any = {};
    if (returnQuantityLineItem?.uomType === UOMType.Primary) {
      if (
        returnQuantityLineItem.qty === null ||
        returnQuantityLineItem.qty === ''
      ) {
        temp.totalCases = 'This field is required';
      } else if (returnQuantityLineItem.qty < 1) {
        temp.totalCases = 'Value must be greater than 0';
      } else if (returnQuantityLineItem.qty > tempValue.qty) {
        temp.totalCases = 'Value must not be greater than original order';
      }
    } else {
      if (
        returnQuantityLineItem.totalCases === null ||
        returnQuantityLineItem.totalCases === '' ||
        returnQuantityLineItem.totalCases === undefined
      ) {
        temp.totalCases = 'This field is required';
      }
      if (returnQuantityLineItem.totalCases < 1) {
        temp.totalCases = 'Value must be greater than 0';
      }
      if (
        Number(
          returnQuantityLineItem.totalCases *
            returnQuantityLineItem.otherUOMCount,
        ) > Number(tempValue.qty)
      ) {
        temp.totalCases = 'Value must not be greater than original order';
      }
    }

    setQuantityError({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleUpdateReturnRows = (event) => {
    event.preventDefault();

    if (!validateOnlick()) {
      return;
    }

    const main = [...returnOrderItemRows];
    const datas = [...returnOrderItemRows];

    const getData = datas.find(
      (o) => o.lineId === returnQuantityLineItem.lineId,
    );

    if (getData === undefined) {
      setReturnOrderItemRows([...returnOrderItemRows, returnQuantityLineItem]);
    } else {
      const setData = main.findIndex((o) => o.lineId === getData.lineId);
      datas.splice(setData, 1);
      setReturnOrderItemRows([...datas, returnQuantityLineItem]);
    }

    setIsReturnQuantityLineItemModalOpen(false);
  };

  useEffect(() => {
    if (isReturnQuantityLineItemModalOpen) {
      getSkuItemDetail(
        currentUser.Claim_CustomerId,
        currentLocationAndFacility.customerFacilityId,
        returnQuantityLineItem.itemId,
      )
        .then(() => {
          onLoadGetItemUnitOfMeasureDDLOnChange(
            returnQuantityLineItem.itemId,
          ).then((x) => {
            setOptionsUOM([]);
            x.map((uom) =>
              setOptionsUOM((prev) => [
                ...prev,
                {
                  value: uom.value,
                  label: uom.text,
                  uomAbbr: uom.uomAbbr,
                  isPrimaryUOM: uom.isPrimaryUOM,
                  qty: uom.qty,
                  primaryUOM: uom.primaryUOM,
                  primaryUOMStr: uom.primaryUOMStr,
                  primaryUOMAbbr: uom.primaryUOMAbbr,
                  uomType: uom.uomType,
                },
              ]),
            );
          });
        })
        // eslint-disable-next-line no-console
        .catch((e) => console.log(e));
    }
  }, [isReturnQuantityLineItemModalOpen]);

  useEffect(() => {
    if (returnQuantityLineItem.uomType === UOMType.Primary) {
      // set primaryUom Id to 0 at the backend

      const resultUom = optionsUOM.find((u) => u.value === 0);
      if (resultUom !== undefined) {
        setUomValue((prev) => ({
          ...prev,
          value: resultUom.value,
          label: resultUom.label,
          uomAbbr: resultUom.uomAbbr,
          isPrimaryUOM: resultUom.isPrimaryUOM,
          qty: resultUom.qty,
          primaryUOM: resultUom.primaryUOM,
          primaryUOMStr: resultUom.primaryUOMStr,
          primaryUOMAbbr: resultUom.primaryUOMAbbr,
          uomType: resultUom.uomType,
        }));
      }
      // other uom
    } else if (returnQuantityLineItem.uomType === UOMType.Other) {
      const resultUom = optionsUOM.find(
        (u) => u.value === returnQuantityLineItem.itemUnitOfMeasureId,
      );
      if (resultUom !== undefined) {
        setUomValue((prev) => ({
          ...prev,
          value: resultUom.value,
          label: resultUom.label,
          uomAbbr: resultUom.uomAbbr,
          isPrimaryUOM: resultUom.isPrimaryUOM,
          qty: resultUom.qty,
          primaryUOM: resultUom.primaryUOM,
          primaryUOMStr: resultUom.primaryUOMStr,
          primaryUOMAbbr: resultUom.primaryUOMAbbr,
          uomType: resultUom.uomType,
        }));
      }
    }
    // pallet
    else if (returnQuantityLineItem.uomType === UOMType.Pallet) {
      const resultUom = optionsUOM.find((u) => u.uomType === UOMType.Pallet);
      if (resultUom !== undefined) {
        setUomValue((prev) => ({
          ...prev,
          value: resultUom.value,
          label: resultUom.label,
          uomAbbr: resultUom.uomAbbr,
          isPrimaryUOM: resultUom.isPrimaryUOM,
          qty: resultUom.qty,
          primaryUOM: resultUom.primaryUOM,
          primaryUOMStr: resultUom.primaryUOMStr,
          primaryUOMAbbr: resultUom.primaryUOMAbbr,
          uomType: resultUom.uomType,
        }));
      }
    }
  }, [optionsUOM]);

  return (
    <Modal open={isReturnQuantityLineItemModalOpen}>
      <ModalBox>
        <ModalContent>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Return Items
            </Typography>
            <TextField
              required
              type="number"
              label="Qty"
              size="small"
              value={
                returnQuantityLineItem?.uomType === UOMType.Primary
                  ? returnQuantityLineItem?.qty
                  : returnQuantityLineItem?.totalCases || ''
              }
              onChange={(e) => {
                if (returnQuantityLineItem?.uomType === UOMType.Primary) {
                  setReturnQuantityLineItem((prev) => ({
                    ...prev,
                    qty: e.target.value,
                  }));
                } else {
                  setReturnQuantityLineItem((prev) => ({
                    ...prev,
                    totalCases: e.target.value,
                  }));
                }
              }}
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
                marginBottom: '16px',
              }}
              {...(quantityError.qty && {
                error: true,
                helperText: quantityError.qty,
              })}
              {...(quantityError.totalCases && {
                error: true,
                helperText: quantityError.totalCases,
              })}
            />
            <Autocomplete
              disableClearable
              onChange={(event: any, newValue: ItemUnitOfMeasureType) => {
                if (newValue !== null) {
                  setUomValue(newValue);
                  if (newValue.isPrimaryUOM) {
                    setReturnQuantityLineItem((prevState) => ({
                      ...prevState,
                      primaryUOM: newValue.value,
                      primaryUOMAbbr: newValue.uomAbbr,
                      itemUnitOfMeasureId: null,
                      otherUOMStr: '',
                      otherUOMAbbr: '',
                      otherUOMCount: null,
                      uomType: newValue.uomType,
                      sellPrice: '',
                    }));
                  } else {
                    setReturnQuantityLineItem((prevState) => ({
                      ...prevState,
                      primaryUOM: newValue.primaryUOM,
                      primaryUOMAbbr: newValue.primaryUOMAbbr,
                      itemUnitOfMeasureId: newValue.value,
                      otherUOMStr: newValue.label,
                      otherUOMAbbr: newValue.uomAbbr,
                      otherUOMCount: newValue.qty,
                      uomType: newValue.uomType,
                      sellPrice: '',
                    }));
                  }
                } else {
                  setUomValue(null);
                  setReturnQuantityLineItem((prevState) => ({
                    ...prevState,
                    primaryUOM: 0,
                    primaryUOMAbbr: '',
                    itemUnitOfMeasureId: null,
                    otherUOMStr: '',
                    otherUOMAbbr: '',
                    otherUOMCount: null,
                    uomType: 0,
                    quantity: '',
                    totalCases: null,
                    sellPrice: '',
                  }));
                }
                setQuantityError({});
              }}
              id="controllable-states"
              options={optionsUOM}
              getOptionLabel={(option: ItemUnitOfMeasureType) => option.label}
              value={uomValue}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option: ItemUnitOfMeasureType) => (
                <li
                  {...props}
                  key={option?.value}
                  data-uomabbr={option?.uomAbbr}
                  data-isprimaryuom={option?.isPrimaryUOM}
                  data-qty={option?.qty}
                  data-primaryuom={option?.primaryUOM}
                  data-primaryuomstr={option?.primaryUOMStr}
                  data-primaryuomabbr={option?.primaryUOMAbbr}
                  data-uomtype={option?.uomType}
                >
                  {option?.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  // {...(orderLineFormErrors.uomError && {
                  //   error: true,
                  //   helperText: orderLineFormErrors.uomError,
                  // })}
                  required
                  id="outlined-required"
                  label="UOM"
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
            {uomValue?.isPrimaryUOM === false && (
              <MUIGrid
                container
                spacing={6}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                  paddingTop: '4%',
                }}
              >
                <MUIGrid item sm={12}>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="Primary Units per Package"
                    value={uomValue.qty}
                    size="small"
                    disabled
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
              </MUIGrid>
            )}
          </Box>
          <ModalFooterEnd>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center',
                },
                gap: '8px',
              }}
            >
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="text"
                size="medium"
                onClick={() => {
                  setUomValue(null);
                  setIsReturnQuantityLineItemModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ minHeight: '48px', maxWidth: '91px' }}
                variant="contained"
                size="medium"
                onClick={(e) => handleUpdateReturnRows(e)}
              >
                Save
              </Button>
            </Box>
          </ModalFooterEnd>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
};
