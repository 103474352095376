/* eslint-disable no-unused-vars */
export enum ItemInventoryUOMEnum {
  Each = 1,
  Carton = 2,
  InnerCarton = 3,
  Pallet = 4,
  Drum = 5,
  Bag = 6,
  Pound = 7,
  Kilogram = 8,
  Gram = 9,
  Ounce = 10,
  Yard = 11,
  Foot = 12,
  Inch = 13,
  Millimiter = 14,
  Liter = 15,
  SquareFeet = 16,
  SquareInch = 17,
}
