import React, { useContext, useState } from 'react';

import Button from 'components/button';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import { useSalesOrderDataTableViewContext } from 'pages/sales/context-provider';
import { orderMultipleUpdateStatus } from 'services/api/salesorders/salesorders-new.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { Autocomplete, Box, Modal, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface IFlagStatusType {
  value: number;
  label: string;
}

export const optionStatusTypes = [
  { value: '1', label: 'New' },
  { value: '2', label: 'Open' },
  { value: '4', label: 'Shipment' },
  { value: '6', label: 'Partial' },
  { value: '11', label: 'Ready for Pickup' },
  { value: '5', label: 'Complete' },
  { value: '7', label: 'Cancelled' },
];

export default React.memo(() => {
  const theme = useTheme();

  const { isUpdateStatusOnOrderModalOpen, setIsUpdateStatusOnOrderModalOpen } =
    useContext(GlobalContext);

  const {
    selectedRowsOnCheckbox,
    initialStateSearchParams,
    searchParams,
    setSearchParams,
    OnClickUserFilterSearchApply,
  } = useSalesOrderDataTableViewContext();

  const initialFormFStatus = {
    value: 1,
    label: 'New',
  };

  const [salesOrderFlagStatus, setSalesOrderFlagStatus] =
    useState<IFlagStatusType>(initialFormFStatus);

  const updateStatusMultipleOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const payload = {
      newStatus: salesOrderFlagStatus.value,
      OrderToUpdateStatuses: selectedRowsOnCheckbox.map((c: any) => ({
        OrderId: c.orderId,
        StatusId: c.statusValue,
        OrderNo: c.orderNo,
      })),
    };

    try {
      const result = await orderMultipleUpdateStatus(payload);
      if (result.data.length > 0) {
        result.data.forEach((element) => {
          snackActions.info(element.resultMessage);
        });
      }

      setIsUpdateStatusOnOrderModalOpen(false);
      OnClickUserFilterSearchApply(e, true);
      setSearchParams({
        ...initialStateSearchParams,
        status: searchParams.status,
      });
    } catch (err) {
      snackActions.error(err);
    }
  };

  return (
    <Modal open={isUpdateStatusOnOrderModalOpen}>
      <ModalBox>
        <ModalContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography
              sx={{ marginBottom: '16px' }}
              variant="h6"
              fontWeight="bold"
            >
              Update Status
            </Typography>
            <Autocomplete
              disableClearable
              aria-required
              onChange={(event: any, newValue: any) => {
                if (newValue !== null) {
                  setSalesOrderFlagStatus((e) => ({
                    ...e,
                    value: newValue.value,
                    label: newValue.label,
                  }));
                } else {
                  setSalesOrderFlagStatus(null);
                }
              }}
              id="controllable-states"
              options={optionStatusTypes}
              getOptionLabel={(option: any) => option.label}
              value={salesOrderFlagStatus}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined-required"
                  label="Status"
                  size="small"
                  sx={{ width: '100%', marginBottom: '16px' }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
              },
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
              },
              gap: '8px',
            }}
          >
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="text"
              size="large"
              onClick={() => setIsUpdateStatusOnOrderModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ minHeight: '48px', maxWidth: '91px' }}
              variant="contained"
              size="large"
              onClick={(e) => updateStatusMultipleOrderOnClick(e)}
            >
              Update
            </Button>
          </Box>
        </ModalContent>
      </ModalBox>
    </Modal>
  );
});
