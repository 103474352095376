import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import CustomGridToolbar from 'components/gridtoolbar';
import Header from 'components/header';
import Input from 'components/input/Input';
import moment from 'moment';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import DeviceModal from 'pages/shared/deviceregistermodal';
import { MainContainer, ContentContainer } from 'pages/styles';
import { getCustomerDevicesByLocationId } from 'services/api/devices/devices.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { ContainerFlat, DatagridStandard } from 'styles';

import { Search } from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColumns, GridActionsCellItem } from '@mui/x-data-grid';

function DeviceManagement() {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    loading,
    updateLoading,
    updateData,
    onOpenDeviceModal,
    onOpenConfirmDeleteDialog,
  } = useContext(GlobalContext);
  const [currentView, setCurrentView] = useState(0);
  const [devices, setDevices] = useState([]);
  const [editDevice, setEditDevice] = useState(false);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const [dataSelected, setDataSelected] = useState({
    customerId: currentUser.Claim_CustomerId,
  });
  const [searchParams, setSearchParams] = useState({
    searchDeviceId: '',
    searchCustomerLocationId: '',
    searchSerialNo: '',
    searchDateAdded: '',
    searchStatus: '',
    searchToken: '',
  });
  const [dataSelectedToDelete, setDataSelectedToDelete] = useState({});

  const filter = () => {
    const customerDeviceResult = devices.filter((d) =>
      d.customerDeviceId.toString().includes(searchParams.searchDeviceId),
    );
    const customerLocResult = customerDeviceResult.filter((d) =>
      d.customerLocationId
        .toString()
        .includes(searchParams.searchCustomerLocationId),
    );
    const dateResult = customerLocResult.filter((d) =>
      moment(d.addedDateTime)
        .format('MM/DD/YY')
        .includes(searchParams.searchDateAdded),
    );
    const serialNoResult = dateResult.filter((d) =>
      d.serialNumber.toString().includes(searchParams.searchSerialNo),
    );
    const result = serialNoResult.filter((d) =>
      d.isActive.toString().includes(searchParams.searchStatus),
    );

    setFilteredDevices(result);
  };

  function getStatus(params) {
    let status = '';
    if (!params.row.isActive) {
      status = 'Inactive';
    } else {
      status = 'Active';
    }
    return status;
  }

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const onLoadDeviceAndUserData = async () => {
    try {
      const customerDevicesFromApi = await getCustomerDevicesByLocationId(
        currentLocationAndFacility.customerLocationId,
      );
      setDevices(customerDevicesFromApi);
      setFilteredDevices(customerDevicesFromApi);

      return customerDevicesFromApi;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    updateLoading(true);
    setDevices([]);
    setFilteredDevices([]);
    onLoadDeviceAndUserData();
  }, [loading, updateData]);

  type DeviceRows = typeof devices[number];

  const handleEditDeviceClick = (rowData) => {
    setDataSelected(rowData);
    setEditDevice(true);
    onOpenDeviceModal();
  };

  const handleResetDataSelected = () => {
    setDataSelected({
      customerId: currentUser.Claim_CustomerId,
    });
    setEditDevice(false);
  };

  const handleDeleteDevice = (rowData) => {
    setDataSelectedToDelete(rowData);
    onOpenConfirmDeleteDialog();
  };

  const deviceColumns: GridColumns<DeviceRows> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit Device"
          onClick={() => handleEditDeviceClick(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleDeleteDevice(params.row)}
          showInMenu
        />,
      ],
    },

    {
      field: 'serialNumber',
      width: 200,
      headerName: 'Device Name',
    },
    {
      field: 'deviceToken',
      width: 200,
      headerName: 'Token',
    },
    {
      field: 'addedDateTime',
      width: 200,
      headerName: 'Date Registered',
      renderCell: (params) => (
        <Typography>
          {moment(params.row.addedDateTime).format('MM/DD/YY')}
        </Typography>
      ),
    },
    {
      field: 'isActive',
      width: 200,
      headerName: 'Date Registered',
      renderCell: (params) => <Typography>{getStatus(params)}</Typography>,
    },
  ];

  return (
    <MainContainer>
      <Header />
      {editDevice ? (
        <DeviceModal
          edit
          customer={dataSelected}
          callBack={handleResetDataSelected}
        />
      ) : (
        <DeviceModal customer={dataSelected} />
      )}
      <ConfirmationDialog
        customerDeviceData={dataSelectedToDelete}
        deleteCustomerDevice
        callBack={onLoadDeviceAndUserData}
      />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            marginBottom: '16px',
          }}
        >
          <Card
            sx={{ display: 'flex', flexDirection: 'column', minWidth: '192px' }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main], cursor: 'pointer' }}
              variant="h3"
              onClick={() => setCurrentView(0)}
            >
              {devices.length}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Active Devices
            </Typography>
          </Card>
        </Box>
        <ContainerFlat>
          {currentView === 0 && (
            <DatagridStandard
              autoHeight
              rows={filteredDevices}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              columns={deviceColumns}
              pageSize={15}
              hideFooterSelectedRowCount
              rowsPerPageOptions={[15]}
              getRowId={(row) => row.customerDeviceId}
              onSelectionModelChange={(customerId) => {
                const selectedId = customerId[0];
                const selectedRowData = devices.filter(
                  (c) => c.customerId === selectedId,
                );
                setDataSelected(selectedRowData[0]);
              }}
              components={{ Toolbar: CustomGridToolbar }}
            />
          )}
        </ContainerFlat>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(DeviceManagement);
