import React, { useContext } from 'react';

import Button from 'components/button';
import { ModalFooterEnd } from 'components/modal/styles';
import { ModalBox, ModalContent } from 'components/styles';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { ModalReturnQuantityLineItem } from 'pages/sales/return-line-item-modal/return-quantity-modal';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import {
  Modal,
  Box,
  Typography,
  Grid as MUIGrid,
  Divider,
  Button as ButtonBlue,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';

import { UOMType, useSalesOrderContext } from '../salesorder/context';

export const ModalReturnLineItem = () => {
  const theme = useTheme();

  const {
    isReturnLineItemModalOpen,
    setIsReturnLineItemModalOpen,
    isReturnQuantityLineItemModalOpen,
    setIsReturnQuantityLineItemModalOpen,
  } = useContext(GlobalContext);

  const {
    orderItemRows,
    returnOrderItemRows,
    salesOrderForm,
    setReturnQuantityLineItem,
    setReturnOrderItemRows,
  } = useSalesOrderContext();

  const handleRemoveReturnRows = (rows) => {
    const datas = [...returnOrderItemRows];

    const getData = datas.findIndex((o) => o.lineId === rows.lineId);
    datas.splice(getData, 1);
    setReturnOrderItemRows(datas);
  };

  const lineItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      minWidth: 100,
      flex: 0.5,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <ButtonBlue
          variant="contained"
          size="small"
          sx={{
            display: salesOrderForm?.isReturn === true ? 'block ' : 'none',
            padding: '1px',
            textTransform: 'capitalize',
          }}
          onClick={() => {
            setReturnQuantityLineItem(params.row);
            setIsReturnQuantityLineItemModalOpen(true);
          }}
        >
          Add To Return
        </ButtonBlue>,
      ],
    },
    {
      field: 'sku',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      minWidth: 110,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty Ordered</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.uomType === UOMType.Primary &&
            `${params.row.qty.toLocaleString()}`}

          {params?.row?.uomType === UOMType.Other &&
            `${params.row.totalCases.toLocaleString()} ${
              params?.row?.otherUOMAbbr
            } (${params.row.totalCases * params.row.otherUOMCount} ${
              params.row.primaryUOMAbbr
            })`}

          {params?.row?.uomType === UOMType.Pallet &&
            `${params.row.totalCases.toLocaleString()} pallet (${
              params.row.totalCases * params.row.otherUOMCount
            } ${params.row.primaryUOMAbbr})`}
        </Typography>
      ),
    },
    {
      field: 'qtyShipped',
      minWidth: 110,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty Shipped</Typography>
        </Box>
      ),
    },
    {
      field: 'statusStr',
      width: 130,
      hide: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Status</Typography>
        </Box>
      ),
    },
    {
      field: 'sellPrice',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Sell Price</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.sellPrice !== undefined || params?.row?.sellPrice
            ? `$${Number(params.row.sellPrice).toFixed(2)}`
            : ''}
        </Typography>
      ),
    },
  ];
  const returnLineItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      minWidth: 100,
      flex: 0.5,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <Button
          variant="contained"
          size="small"
          sx={{
            padding: '0px !important',
            borderRadius: '3px !important',
            textTransform: 'capitalize !important',
          }}
          onClick={() => {
            handleRemoveReturnRows(params.row);
          }}
        >
          Remove
        </Button>,
      ],
    },
    {
      field: 'sku',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      minWidth: 110,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty Ordered</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.uomType === UOMType.Primary &&
            `${params.row.qty.toLocaleString()}`}

          {params?.row?.uomType === UOMType.Other &&
            `${params.row.totalCases.toLocaleString()} ${
              params?.row?.otherUOMAbbr
            } (${decimalValidationHelper(
              (params.row.totalCases * params.row.otherUOMCount).toString(),
            )} ${params.row.primaryUOMAbbr})`}

          {params?.row?.uomType === UOMType.Pallet &&
            `${params.row.totalCases.toLocaleString()} pallet (${decimalValidationHelper(
              (params.row.totalCases * params.row.otherUOMCount).toString(),
            )} ${params.row.primaryUOMAbbr})`}
        </Typography>
      ),
    },
    {
      field: 'statusStr',
      width: 130,
      hide: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Status</Typography>
        </Box>
      ),
    },
    {
      field: 'onHand',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">On Hand</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.onHand?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'available',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Available</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.available?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'sellPrice',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Sell Price</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.sellPrice !== undefined || params?.row?.sellPrice
            ? `$${Number(params.row.sellPrice).toFixed(2)}`
            : ''}
        </Typography>
      ),
    },
  ];
  return (
    <>
      {isReturnQuantityLineItemModalOpen && <ModalReturnQuantityLineItem />}
      <Modal open={isReturnLineItemModalOpen}>
        <ModalBox sx={{ maxWidth: '1000px !important' }}>
          <ModalContent>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <MUIGrid item md={12}>
                  <Typography variant="h6" fontWeight="bold">
                    Original Order Line Items
                  </Typography>
                  <DatagridStandard
                    autoHeight
                    headerHeight={80}
                    rows={orderItemRows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    hideFooter
                    columns={lineItemsColumns}
                    rowsPerPageOptions={[25]}
                    pageSize={25}
                    hideFooterSelectedRowCount
                    hideFooterPagination
                    getRowId={(row) => row.lineId}
                  />
                </MUIGrid>
              </MUIGrid>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <MUIGrid item md={12}>
                  <Typography variant="h6" fontWeight="bold">
                    Return Line Items
                  </Typography>
                  <DatagridStandard
                    autoHeight
                    headerHeight={80}
                    rows={returnOrderItemRows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    hideFooter
                    columns={returnLineItemsColumns}
                    rowsPerPageOptions={[25]}
                    pageSize={25}
                    hideFooterSelectedRowCount
                    hideFooterPagination
                    getRowId={(row) => row.lineId}
                  />
                </MUIGrid>
              </MUIGrid>
            </Box>
            <ModalFooterEnd>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  [theme.breakpoints.up('sm')]: {
                    justifyContent: 'flex-end',
                  },
                  [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                  },
                  gap: '8px',
                }}
              >
                <Button
                  sx={{ minHeight: '48px', maxWidth: '91px' }}
                  variant="text"
                  size="medium"
                  onClick={() => setIsReturnLineItemModalOpen(false)}
                >
                  Close
                </Button>
              </Box>
            </ModalFooterEnd>
          </ModalContent>
        </ModalBox>
      </Modal>
    </>
  );
};
