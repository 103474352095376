/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useNavigate } from 'react-router';

import {
  IOrderChecklistObj,
  IPickTicketCarrierFormObj,
  IPickTicketFormObj,
  IPickTicketPackageFormObj,
} from 'common/interfaces';
import { DropdownListItem } from 'common/models';
import { PickTickLineItemManualItem } from 'common/models/picktickets/packandship';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import httpClient from 'infra/httpClient/index.js';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { PrinterSettingsState } from 'pages/settings/tabviews/PrinterSettings';
import { getCartonCodes } from 'services/api/cartoncodes/cartoncodes.api';
import { createLabel } from 'services/api/integrations/shipment/shipment.api';
import {
  getAndScanPickTicket,
  getAndScanPickTicketV2,
  getTheScannedPickTicketDetails,
  getTheScannedPickTicketDetailsV2,
  getThePickTicketForApprovedOrApprovedAndPackDetails,
  getThePickTicketCarrierDetails,
  getThePickTicketShipToBillToDetails,
  updateThePickTicketCarrierDetails,
  updateThePickTicketShipToBillToDetails,
  updatePackageToShipAndComplete,
  printShippingLabelByPickTicketId,
  updatePackagePackageLineItemQty,
  createPackageProperty,
  approveOrApproveAndPack,
  approveOrApproveAndPackV2,
  getPackAndShipCompletedList,
  CompleteOrdersAfterPickApproval,
  createPackAndShipPackageLineItemScan,
  updateCartonCodeFromPackage,
  createPackage,
  updateWeightPound,
  createLicensePlate,
  deletePackageOnPackAndShip,
  updatePackageToComplete,
  GetScanPackAndShipPickTicketLineItem,
  createPackAndShipPackageLineitemSerialNo,
  verifyAddressForPackAndShip,
  updateOrderStatusToReadyForPickup,
  createPalletize,
} from 'services/api/packandship/packandship.api';
import {
  getOrderBillingsLookup,
  getCountriesLookup,
  checkOrderLabelProvided,
} from 'services/api/salesorders/salesorders-new.api';
import { getCustomerOutboundSection } from 'services/api/uoms/uoms.api';
import { getBillingChargesDetailByModule } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { UpdateCartonCodeFunctionModel } from '../models';

type AutocompleteBase = {
  label?: string;
  value?: number;
  dimensions?: string;
  isExternalPackage?: boolean;
  acceptsVoidFill?: boolean;
};
type OrderBillingType = AutocompleteBase;
type CountryType = AutocompleteBase;
type CartonCodeDDLType = AutocompleteBase;

type ContactDetailType = {
  orderContactDetailId?: number;
  contactDetailType?: number;
  codeId?: string;
  recipientName?: string;
  companyName?: string;
  phone?: string;
  email?: string;
  address1?: string;
  address2?: string;
  country?: number;
  stateProvinceRegion?: string;
  cityTownship?: string;
  zipPostalCode?: string;
  retailerId?: string;
  departmentNumber?: string;
};
type QueryStringExtendType = {
  pickId: string;
  processType: string;
};
type QueryStringType = {
  form: string;
  id: number;
} & QueryStringExtendType;
type PackAndShipProviderProps = {
  children: React.ReactNode;
};
type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};
enum FormQueryStringStatus {
  View = 'view',
  New = 'new',
  Edit = 'edit',
}
interface IPackAndShip {
  findPickTicketForm?: IFindPickTicketForm;
  customerValue: AutocompleteBase;
  facilityValue: AutocompleteBase;
  pickTicketFormObj: IPickTicketFormObj;
  processType: IProcessTypeObj;
  queryStringItemPackAndShip: QueryStringType;
  optionOrderBillings: OrderBillingType[];
  optionCountries: CountryType[];
  orderBillValue: OrderBillingType;
  shipToCountryValue: CountryType;
  billToCountryValue: CountryType;
  carrierForm: IPickTicketCarrierFormObj;
  tabValue: string;
  packageIdValue: number;
  shipToBillToForm: IPickTicketShipToBillToFormObj;
  shipmentRateId?: string;
  allowBarcodeScanner: boolean;
  setAllowBarcodeScanner: React.Dispatch<React.SetStateAction<boolean>>;
  loadThePickTicketCarrierDetails: () => void;
  loadThePickTicketShipToBillToDetails: () => void;
  verifyAddress: () => void;

  setTabValue?: React.Dispatch<React.SetStateAction<string>>;

  setCarrierForm: React.Dispatch<
    React.SetStateAction<IPickTicketCarrierFormObj>
  >;

  setPackageIdValue: React.Dispatch<React.SetStateAction<number>>;
  setShipToBillToForm: React.Dispatch<
    React.SetStateAction<IPickTicketShipToBillToFormObj>
  >;
  updateThePickTicketCarrierDetailsOnClick: (e: React.SyntheticEvent) => void;
  updatePackageLineItemQtyOnClick: (
    e: React.SyntheticEvent,
    packageLineItemId: number,
    qty: number,
    arithmetic: string,
  ) => void;
  updatePackageToShipAndCompleteOnClick: (e: React.SyntheticEvent) => void;
  readyForPickupOnClick: (e: React.SyntheticEvent) => void;
  setShipmentRateId?: React.Dispatch<React.SetStateAction<string>>;
  updateThePickTicketShipToBillToDetailsOnClick: (
    e: React.SyntheticEvent,
  ) => void;
  setOrderBillValue: React.Dispatch<React.SetStateAction<OrderBillingType>>;
  setShipToCountryValue: React.Dispatch<React.SetStateAction<CountryType>>;

  setBillToCountryValue: React.Dispatch<React.SetStateAction<CountryType>>;
  setProcessType: React.Dispatch<React.SetStateAction<IProcessTypeObj>>;
  setFindPickTicketForm?: React.Dispatch<
    React.SetStateAction<IFindPickTicketForm>
  >;
  findPickTicketFormObj?: IFindPickTicketFormObj;
  setFindPickTicketFormObj?: React.Dispatch<
    React.SetStateAction<IFindPickTicketFormObj>
  >;
  setQueryStringItemPackAndShip?: React.Dispatch<
    React.SetStateAction<QueryStringType>
  >;
  approveOrApproveAndPackOnClick: (
    e: React.SyntheticEvent,
    type: string,
  ) => void;
  approveOrApproveAndPackOnClickV2: (
    e: React.SyntheticEvent,
    type: number,
  ) => void;
  justCompletePackAndShip: () => void;
  completePackAndShipAndCreateShippmentLabel: () => void;

  findPickTicketFormOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  carrierFormOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  shipToBillToFormAutoCompleteOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: AutocompleteBase,
    custom?: any,
  ) => void;

  packageOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    packageId: number,
  ) => void;

  cartonCodeOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    packageId: number,
    cartonCode: any,
  ) => void;

  packageOnBlur: (
    e: React.ChangeEvent<HTMLInputElement>,
    packageId: number,
  ) => void;
  shipToBillToFormOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  viewLoadData: (isWeightAutoCompute?: boolean) => Promise<void>;
  setDataSelectedPackageLineItem: any;
  dataSelectedPackageLineItem: any;
  onLoadPackAndShipCompletedList: any;
  billingAdhocChargesData: any;
  handleChangeBillingChargeSection: any;
  handleRemoveBillingChargeSection: any;
  errorBillingAdhocItemValues: any;
  cartonCodesOptions: any[];
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setPickTicketFormObj: React.Dispatch<
    React.SetStateAction<IPickTicketFormObj>
  >;
  ScanTheItemCodeFunction: (barcodeValue: string) => Promise<void>;
  optionPackageCartonCodes: AutocompleteBase[];
  scanTheItemCodeField: React.MutableRefObject<any>;
  focusIsOnBarcode: boolean;
  setFocusIsOnBarcode: React.Dispatch<React.SetStateAction<boolean>>;
  updateCartonCodeFunction: (
    packageIdParam?: string,
    cartonCodeIdParam?: string,
    isCreateNewPackage?: boolean,
    isExternalPackage?: boolean,
    isCustomPackageParam?: boolean,
    itemAsVoidFillId?: number,
  ) => Promise<void>;
  updateWeightPoundFunction: (
    packageIdParam?: string,
    weightPoundParam?: string,
  ) => Promise<void>;
  createPalletizeFunction: (data: any) => Promise<void>;
  createPackageFunction: () => Promise<void>;
  setIsShowBarcodeError: React.Dispatch<React.SetStateAction<boolean>>;
  isShowBarcodeError: boolean;
  setMessageShowBarcodeError: React.Dispatch<React.SetStateAction<string>>;
  messageShowBarcodeError: string;
  setBarcode: React.Dispatch<React.SetStateAction<string>>;
  barcode: string;
  setPrintOfLPItem: React.Dispatch<any>;
  printOfLPItem: any;
  setLastPackageWeightLbs: React.Dispatch<React.SetStateAction<string>>;
  lastPackageWeightLbs: string;
  buttonSinglePrintRef: React.MutableRefObject<any>;
  isPulseNumber: {
    isPulse: boolean;
    value: string;
  };
  setLastPackageWeightLbsFormError: React.Dispatch<any>;
  lastPackageWeightLbsFormError: any;
  deletePackageFunction: (packageIdParam?: string) => Promise<void>;
  setDataSelectedPackage: React.Dispatch<
    React.SetStateAction<{
      packageId: any;
    }>
  >;
  dataSelectedPackage: {
    packageId: any;
  };
  setIsOpenDeleteConfirmModal: React.Dispatch<
    React.SetStateAction<{
      package: boolean;
      packageLineItem: boolean;
    }>
  >;
  isOpenDeleteConfirmModal: {
    package: boolean;
    packageLineItem: boolean;
  };
  updatePackAndShipToShipEasyPostFunction: () => Promise<void>;
  setIsGeneratePrintLabel: React.Dispatch<React.SetStateAction<boolean>>;
  disablePressEnterToConfirmButton: boolean;
  setDisablePressEnterToConfirmButton: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isGeneratePrintLabel: boolean;
  dataUrlLabels: any;
  GetScanPackAndShipPickTicketLineItemfunction: (
    barcodeValue?: string,
  ) => Promise<void>;
  setSerialNumberBarcode: React.Dispatch<React.SetStateAction<string>>;
  serialNumberBarcode: string;
  serialNumbers: string[];
  setSerialNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  setIsShowSerialNumberBarcodeError: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isShowSerialNumberBarcodeError: boolean;
  setMessageShowSerialNumberBarcodeError: React.Dispatch<
    React.SetStateAction<string>
  >;
  messageShowSerialNumberBarcodeError: string;
  createPackAndShipPackageLineitemSerialNoFunction: (
    pickTicketLineItemIdValue?: string,
    serialNumbersValue?: string[],
  ) => Promise<void>;
  setSerialNumberData: React.Dispatch<any>;
  serialNumberData: any;
  WeightsValidation: () => boolean;
  setIsLoadingToComplete: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingToComplete: boolean;

  // checklist
  isChecklistComplete: boolean;
  setIsChecklistComplete: React.Dispatch<React.SetStateAction<boolean>>;
  checklists: IOrderChecklistObj[];
  setChecklists: React.Dispatch<React.SetStateAction<IOrderChecklistObj[]>>;

  isNewPackageForManualItem: boolean;
  setIsNewPackageForManualItem: React.Dispatch<React.SetStateAction<boolean>>;

  // void fill
  isVoidFillModal: boolean;
  setIsVoidFillModal: React.Dispatch<React.SetStateAction<boolean>>;
  shipVoidFillDDL: DropdownListItem[];
  setShipVoidFillDDL: React.Dispatch<React.SetStateAction<DropdownListItem[]>>;
  updateCartonCodeFunctionModel: UpdateCartonCodeFunctionModel;
  setUpdateCartonCodeFunctionModel: React.Dispatch<
    React.SetStateAction<UpdateCartonCodeFunctionModel>
  >;
  setAllowPackageNo: React.Dispatch<React.SetStateAction<boolean>>;
  allowPackageNo: boolean;
  setPackAndShipListRows: React.Dispatch<React.SetStateAction<any[]>>;
  packAndShipListRows: any[];
  setFilteredPackAndShipListRows: React.Dispatch<React.SetStateAction<any[]>>;
  filteredPackAndShipListRows: any[];
  checkShipAndCompleIsDisable: () => boolean;
}

// context -----------------------------------------------------------------------
interface IFindPickTicketForm {
  pickTicketId?: string;
}
interface IFindPickTicketFormObj {
  pickTicketId: number;
  pickId: string;
  status: number;
  skUs: number;
  locations: number;
  orderStatus: number;
  orderIsOnHold: boolean;
  isHold: boolean;
  isCancelled: boolean;
  shipmentRateId: string;
}

interface IProcessTypeObj {
  isApprove: boolean;
  isPack: boolean;
}

interface IPickTicketShipToBillToFormObj {
  orderId: number;
  shipTo: ContactDetailType;
  billTo: ContactDetailType;
  useShipToDetailsForBillTo: boolean;
}

export {
  type OrderBillingType,
  type IPickTicketShipToBillToFormObj,
  type CountryType,
};

const PackAndShipContext = createContext<IPackAndShip>({} as IPackAndShip);
export const usePackAndShipContext = () => useContext(PackAndShipContext);

export const PackAndShipContextProvider = ({
  children,
}: PackAndShipProviderProps) => {
  const navigate = useNavigate();
  const query = useQuery();

  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
    pickId: query.get('pickId'),
    processType: query.get('processType'),
  };

  // #region All useContext
  const {
    updateLoading,
    setRatesOptionData,
    isEditPalletizeMode,
    setLabelToPrintDataUrl,
    setIsPalletizeModalOpen,
    setIsSerialNumberScanOpen,
    setIsNextPackageModalOpen,
    setIsLabelToPrintModalOpen,
    setIsChangePackageModalOpen,
    setCompleteOrderManuallyModal,
    setIsOrderReadyForPickupModal,
    setIsRatesToCompleteModalOpen,
    setIsAutoCreateLabelErrorModal,
    onOpenShipAndCompleteModalModal,
    setIsAutoCreateLabelErrorMessage,
    onCloseShipAndCompleteModalModal,
    setIsPackAndShipCompleteModalOpen,
  } = useContext(GlobalContext);

  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  // #endregion

  // #region All useStates
  const [queryStringItemPackAndShip, setQueryStringItemPackAndShip] = useState(
    initialStateOfQueryParams,
  );
  const [findPickTicketForm, setFindPickTicketForm] =
    useState<IFindPickTicketForm>(undefined);
  const [findPickTicketFormObj, setFindPickTicketFormObj] =
    useState<IFindPickTicketFormObj>(undefined);
  const [pickTicketFormObj, setPickTicketFormObj] =
    useState<IPickTicketFormObj>(undefined);
  const [customerValue, setCustomerValue] = useState<AutocompleteBase>({
    id: -1,
    label: '',
  } as AutocompleteBase);
  const [facilityValue, setFacilityValue] = useState<AutocompleteBase>({
    id: -1,
    label: '',
  } as AutocompleteBase);
  const [processType, setProcessType] = useState<IProcessTypeObj>(undefined);
  const [optionOrderBillings, setOptionOrderBillings] = useState<
    OrderBillingType[] | null | []
  >([]);
  const [optionCountries, setOptionCountries] = useState<
    CountryType[] | null | []
  >([]);
  const [cartonCodesOptions, setCartonCodesOptions] = useState<[]>([]);
  const [orderBillValue, setOrderBillValue] = useState<OrderBillingType>(null);

  const [carrierForm, setCarrierForm] =
    useState<IPickTicketCarrierFormObj>(undefined);
  const [shipToBillToForm, setShipToBillToForm] =
    useState<IPickTicketShipToBillToFormObj>(undefined);

  const [tabValue, setTabValue] = React.useState<string>('1');
  const [shipToCountryValue, setShipToCountryValue] =
    useState<CountryType>(null);
  const [billToCountryValue, setBillToCountryValue] =
    useState<CountryType>(null);
  const [packageIdValue, setPackageIdValue] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>(null);
  // #endregion

  const [allowBarcodeScanner, setAllowBarcodeScanner] = useState(false);
  const [allowPackageNo, setAllowPackageNo] = useState(false);

  // pack and ship list page ---------------------------------------
  const [packAndShipListRows, setPackAndShipListRows] = useState([]);
  const [filteredPackAndShipListRows, setFilteredPackAndShipListRows] =
    useState([]);

  // --------------------------------------------------------------
  const pageViewer: PageViewerType = {
    isNew: () => queryStringItemPackAndShip.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringItemPackAndShip.form === FormQueryStringStatus.View &&
      queryStringItemPackAndShip.id > 0,
    isEdit: () =>
      queryStringItemPackAndShip.form === FormQueryStringStatus.Edit &&
      queryStringItemPackAndShip.id > 0,
    isViewOrEdit: () =>
      (queryStringItemPackAndShip.form === FormQueryStringStatus.View ||
        queryStringItemPackAndShip.form === FormQueryStringStatus.Edit) &&
      queryStringItemPackAndShip.id > 0,
  };
  const [dataSelectedPackageLineItem, setDataSelectedPackageLineItem] =
    useState({});
  const [dataSelectedPackage, setDataSelectedPackage] = useState({
    packageId: null,
  });

  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState({
    package: false,
    packageLineItem: false,
  });

  const [optionPackageCartonCodes, setOptionPackageCartonCodes] = useState<
    CartonCodeDDLType[]
  >([]);

  const [focusIsOnBarcode, setFocusIsOnBarcode] = useState(false);
  const [isShowBarcodeError, setIsShowBarcodeError] = useState(false);
  const [messageShowBarcodeError, setMessageShowBarcodeError] = useState('');
  const [isShowSerialNumberBarcodeError, setIsShowSerialNumberBarcodeError] =
    useState(false);
  const [
    messageShowSerialNumberBarcodeError,
    setMessageShowSerialNumberBarcodeError,
  ] = useState('');

  const scanTheItemCodeField = React.useRef(null);

  const [barcode, setBarcode] = useState('');
  const [serialNumberBarcode, setSerialNumberBarcode] = useState('');
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [printOfLPItem, setPrintOfLPItem] = useState<any>('');
  const [lastPackageWeightLbs, setLastPackageWeightLbs] = useState('');

  const initialFormStateWeightsError: any = {
    weightPound: '',
  };

  const initialFormStatePackagesFormError: any = {
    packages: [initialFormStateWeightsError],
  };

  const [lastPackageWeightLbsFormError, setLastPackageWeightLbsFormError] =
    useState<any>([initialFormStatePackagesFormError]);

  const [isPulseNumber, setIsPulseNumber] = useState({
    isPulse: false,
    value: '',
  });
  const [dataUrlLabels, setDataUrlLabels] = useState<any>();
  const [isGeneratePrintLabel, setIsGeneratePrintLabel] = useState(false);
  const [
    disablePressEnterToConfirmButton,
    setDisablePressEnterToConfirmButton,
  ] = useState(false);
  const [isLoadingToComplete, setIsLoadingToComplete] = useState(false);

  // checklist
  const [isChecklistComplete, setIsChecklistComplete] = useState(true);
  const [checklists, setChecklists] = useState<IOrderChecklistObj[]>([]);
  const [initChecklists, setInitChecklists] = useState<boolean>(false);

  const [isNewPackageForManualItem, setIsNewPackageForManualItem] =
    useState(false);

  // void fill
  const [isVoidFillModal, setIsVoidFillModal] = useState(false);
  const [shipVoidFillDDL, setShipVoidFillDDL] = useState<DropdownListItem[]>(
    [],
  );
  const [updateCartonCodeFunctionModel, setUpdateCartonCodeFunctionModel] =
    useState(null);

  // serial number data
  const [serialNumberData, setSerialNumberData] = useState<any>();

  // PRINT LP -----------------
  const buttonSinglePrintRef = useRef(null);

  // Billing Charge Section - Adhoc -------------------------------------------------------------------------
  const initialStateOfBillingChargeSection = [
    {
      warehouseCustomerBillingChargeSectionId: '',
      warehouseCustomerAdhocChargeId: '',
      chargeName: '',
      rate: '',
      qty: '',
      total: '',
      notes: '',
    },
  ];

  const [billingAdhocChargesData, setBillingAdhocChargesData] = useState([]);
  const [errorBillingAdhocItemValues, setErrorBillingAdhocItemValues] =
    useState(initialStateOfBillingChargeSection);

  // -------------------------------------------------------------------------
  const loadBillingChargesDetailByModule = async (warehouseCustomerId) => {
    try {
      const result = await getBillingChargesDetailByModule(
        warehouseCustomerId,
        'Adhoc',
        'Packing',
      );

      return result;
    } catch (err) {
      return err;
    }
  };

  const handleChangeBillingChargeSection = (e, index) => {
    const clone = [...billingAdhocChargesData];

    const obj = clone[index];
    if (e.target.name === 'rate') {
      if (e.target.value < 0) {
        e.target.value = 0;
      }
      const validateDecimal = decimalValidationHelper(e.target.value);

      obj.rate = validateDecimal.trim();
    }
    if (e.target.name === 'qty') {
      if (e.target.value < 0) {
        e.target.value = 0;
      }
      const validateDecimal = decimalValidationHelper(e.target.value);

      obj.qty = validateDecimal.trim();
    }

    if (e.target.name === 'notes') {
      obj.notes = e.target.value;
    }

    if (
      (obj.rate !== null || obj.rate !== '') &&
      (obj.qty !== null || obj.qty !== '')
    ) {
      const checkRate = obj.rate.includes('$')
        ? obj.rate.replace('$', '')
        : obj.rate;

      obj.total = `$${(Number(checkRate) * Number(obj.qty)).toFixed(2)}`;
    } else {
      obj.total = '0';
    }

    clone[index] = obj;
    setBillingAdhocChargesData([...clone]);
  };

  const validateBillingCharge = (selectedItemId = null) => {
    const temp: any = {};

    let tempBillingAdhocChargesData = billingAdhocChargesData;

    if (selectedItemId != null) {
      tempBillingAdhocChargesData = tempBillingAdhocChargesData.filter(
        (x) => x.warehouseCustomerAdhocChargeId === selectedItemId,
      );
    }
    temp.billingAdhocChargesData = [];
    tempBillingAdhocChargesData.forEach((element, index) => {
      const TempData = {
        rate: element.rate ? '' : 'This field is required',
        qty: element.qty ? '' : 'This field is required',
      };

      if (!element.required) {
        TempData.rate = '';
        TempData.qty = '';
      }

      temp.billingAdhocChargesData = [
        ...temp.billingAdhocChargesData,
        TempData,
      ];
    });

    setErrorBillingAdhocItemValues({
      ...temp.billingAdhocChargesData,
    });

    return temp.billingAdhocChargesData.every((e) =>
      Object.values(e).every((x) => x === ''),
    );
  };

  const handleRemoveBillingChargeSection = (warehouseCustomerAdhocChargeId) => {
    if (!validateBillingCharge(warehouseCustomerAdhocChargeId)) {
      return;
    }

    const filteredArray = billingAdhocChargesData.filter(
      (v) =>
        v.warehouseCustomerAdhocChargeId !== warehouseCustomerAdhocChargeId ||
        v.required,
    );

    setBillingAdhocChargesData(filteredArray);
  };

  const checkShipAndCompleIsDisable = () => {
    if (
      pickTicketFormObj?.isComplete ||
      !pickTicketFormObj?.isAllLineItemPack ||
      !pickTicketFormObj?.carrierName ||
      !isChecklistComplete
    ) {
      return true;
    }

    return false;
  };

  // #region All Method for Find Pick Ticket
  const onLoadPackAndShipCompletedList = async (customerFacilityId: number) => {
    try {
      const response = await getPackAndShipCompletedList(customerFacilityId);

      return response;
    } catch (err) {
      return err;
    }
  };

  const onLoadScanPickTicketLookupV2 = async (
    customerLocationId: number,
    customerFacilityId: number,
    searchText: string,
  ) => {
    try {
      const response = await getAndScanPickTicketV2(
        customerLocationId,
        customerFacilityId,
        searchText,
      );

      return response;
    } catch (err) {
      return err;
    }
  };

  const findPickTicketFormOnChange = (e: any): void => {
    e.preventDefault();
    // const { name, value } = e.target;
    // setFindPickTicketForm((prev) => ({
    //   ...prev,
    //   [name]: value,
    // }));

    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm?.length > 0) {
        onLoadScanPickTicketLookupV2(
          currentLocationAndFacility.customerLocationId,
          currentLocationAndFacility.customerFacilityId,
          searchTerm,
        )
          .then((x: any) => {
            if (x === `Pick ticket not found.`) {
              snackActions.error('Pick ticket not found.');
              setFindPickTicketFormObj((prev) => ({
                ...prev,
                pickTicketId: -1,
              }));
            } else if (x === `That order appears to be complete.`) {
              snackActions.error(x);
              setFindPickTicketFormObj((prev) => ({
                ...prev,
                pickTicketId: -1,
              }));
            } else if (
              x ===
              `Pick ticket ${findPickTicketForm?.pickTicketId} is not complete.`
            ) {
              snackActions.error(x);
              setFindPickTicketFormObj((prev) => ({
                ...prev,
                pickTicketId: -1,
              }));
            } else if (x === 'That order appears to be cancelled.') {
              setFindPickTicketFormObj((prev) => ({
                ...prev,
                pickTicketId: -1,
                isCancelled: true,
                isHold: false,
              }));
            } else if (x === 'That order appears to be On Hold.') {
              setFindPickTicketFormObj((prev) => ({
                ...prev,
                pickTicketId: -1,
                isHold: true,
                isCancelled: false,
              }));
            } else {
              setFindPickTicketFormObj((prev) => ({
                ...prev,
                pickTicketId: x?.data?.pickTicketId,
                pickId: x?.data?.pickId,
                status: x?.data?.status,
                skUs: x?.data?.skUs,
                locations: x?.data?.locations,
                orderStatus: x?.data?.orderStatus,
                orderIsOnHold: x?.data?.orderIsOnHold,
              }));
            }
          })
          .catch((err) => snackActions.error(err));
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  // #endregion

  // #region All Method for Pick Ticket Details
  const onLoadTheScannedPickTicketDetailsLookupV2 = async (
    pickTicketId: number,
    isWeightAutoCompute?: boolean,
  ) => {
    try {
      const response = await getTheScannedPickTicketDetailsV2(
        pickTicketId,
        isWeightAutoCompute,
      );

      return response;
    } catch (err) {
      return err;
    }
  };

  const onLoadThePickTicketForApprovedOrApprovedAndPackDetailsLookup = async (
    pickTicketId: number,
  ) => {
    try {
      const response =
        await getThePickTicketForApprovedOrApprovedAndPackDetails(pickTicketId);

      return response;
    } catch (err) {
      return err;
    }
  };

  const WeightsValidation = () => {
    const temp: any = {};

    let results = [];
    let result = [];
    const datas = [...pickTicketFormObj.packages];
    datas.forEach((value) => {
      // eslint-disable-next-line no-param-reassign
      value.weightPound = value.weightPound ? value.weightPound : 0;
      result.push(value.weightPound ? '' : 'This field is required');
      results = [...results, result];
      result = [];
    });
    temp.packages = results;

    setLastPackageWeightLbsFormError({
      ...temp,
    });
    let IsReturnTrue = true;
    temp.packages.forEach((element) => {
      if (!Object.values(element).every((x) => x === '')) IsReturnTrue = false;
    });

    return IsReturnTrue;
  };

  const approveOrApproveAndPackOnClick = async (
    e: React.SyntheticEvent,
    type: string,
  ): Promise<void> => {
    e.preventDefault();
    const PAYLOAD = {
      UserId: pickTicketFormObj?.userId,
      PickTicketId: pickTicketFormObj?.pickTicketId,
      Type: type,
    };

    try {
      const response = await approveOrApproveAndPack(PAYLOAD);
      if (type === 'Approve') {
        setPickTicketFormObj((prev) => ({
          ...prev,
          pickId: queryStringItemPackAndShip.pickId,
          pickTicketId: response.pickTicketId,
          customerWarehouseId: response.customerWarehouseId,
          customerWarehouseName: response.customerWarehouseName,
          facilityId: response.facilityId,
          facilityName: response.facilityName,
          orderNoId: response.orderNoId,
          orderNo: response.orderNo,
          userId: response.userId,
          fullname: response.fullname,
          isApprove: response.isApprove,
          isPack: response.isPack,
          carrierId: response?.carrierId,
          carrierName: response?.carrierName,
          carrierService: response?.carrierService,
          packingInstructions: response?.packingInstructions,
          specialInstructions: response?.specialInstructions,
          lineItems: response.lineItems,
        }));
        setProcessType((prev) => ({
          ...prev,
          isApprove: true,
        }));

        setQueryStringItemPackAndShip((prev) => ({
          ...prev,
          processType: 'Approved',
        }));

        navigate(
          `/pack-and-ship/pick-ticket?form=view&id=${
            queryStringItemPackAndShip.id
          }&pickId=${
            queryStringItemPackAndShip.pickId
          }&processType=${'Approved'}`,
        );
        snackActions.success('Approve successfully.');
      }

      if (type === 'Pack') {
        setPickTicketFormObj((prev) => ({
          ...prev,
          pickId: queryStringItemPackAndShip.pickId,
          pickTicketId: response.pickTicketId,
          customerWarehouseId: response.customerWarehouseId,
          customerWarehouseName: response.customerWarehouseName,
          facilityId: response.facilityId,
          facilityName: response.facilityName,
          orderNoId: response.orderNoId,
          orderNo: response.orderNo,
          userId: response.userId,
          fullname: response.fullname,
          isApprove: response.isApprove,
          isPack: response.isPack,
          carrierId: response?.carrierId,
          carrierName: response?.carrierName,
          carrierService: response?.carrierService,
          packingInstructions: response?.packingInstructions,
          specialInstructions: response?.specialInstructions,
          lineItems: response.lineItems,
        }));

        setProcessType((prev) => ({
          ...prev,
          isPack: true,
        }));

        setQueryStringItemPackAndShip((prev) => ({
          ...prev,
          processType: 'PackAndShip',
        }));

        navigate(
          `/pack-and-ship/pick-ticket?form=view&id=${
            queryStringItemPackAndShip.id
          }&pickId=${
            queryStringItemPackAndShip.pickId
          }&processType=${'PackAndShip'}`,
        );

        snackActions.success('Approve and Pack successfully.');
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const justCompletePackAndShip = () => {
    setPickTicketFormObj((prev) => ({
      ...prev,
      justComplete: true,
    }));

    onOpenShipAndCompleteModalModal();
  };

  const completePackAndShipAndCreateShippmentLabel = () => {
    if (pickTicketFormObj?.isCustomOrOtherCarrier !== true) {
      if (!WeightsValidation()) {
        return;
      }
    }

    const gettempPackage = [...pickTicketFormObj.packages];

    if (
      gettempPackage[0].isNoPackage === false &&
      gettempPackage[0].itemAsPackage.value === undefined &&
      !gettempPackage[0].carrierPredefinedPackage &&
      gettempPackage[0].isCustomPackage !== true
    ) {
      snackActions.error(
        'One or more packages do not have a package type selected. Select a package type and try again.',
      );
      return;
    }

    let labelProvided = false;

    checkOrderLabelProvided(pickTicketFormObj.orderNoId)
      .then((res) => {
        labelProvided = res;
      })
      .finally(() => {
        if (pickTicketFormObj?.isCustomOrOtherCarrier || labelProvided) {
          setPickTicketFormObj((prev) => ({
            ...prev,
            justComplete: true,
          }));
          setAllowBarcodeScanner(false);
          setIsPackAndShipCompleteModalOpen(true);
        } else {
          setPickTicketFormObj((prev) => ({
            ...prev,
            justComplete: false,
          }));
          onOpenShipAndCompleteModalModal();
        }
      });
  };

  const approveOrApproveAndPackOnClickV2 = async (
    e: React.SyntheticEvent,
    type: number,
  ): Promise<void> => {
    e.preventDefault();
    const PAYLOAD = {
      UserId: pickTicketFormObj?.userId,
      PickTicketId: pickTicketFormObj?.pickTicketId,
      ApproveAndPackType: type,
      CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
      CustomerLocationId: currentLocationAndFacility.customerLocationId,
    };

    try {
      const resultOutboundSetting = await getCustomerOutboundSection(
        currentUser.Claim_CustomerId,
        pickTicketFormObj.customerWarehouseId,
      );

      if (resultOutboundSetting.isCompleteOrderAfterPickApproval) {
        setCompleteOrderManuallyModal((prevState) => ({
          ...prevState,
          orderId: pickTicketFormObj.orderNoId,
          warehousecustomerId: pickTicketFormObj.customerWarehouseId ?? 0,
          carrier: pickTicketFormObj.carrierId
            ? {
                value: pickTicketFormObj.carrierId,
                label: pickTicketFormObj.carrierNameApprove,
              }
            : null,
          service: pickTicketFormObj.carrierService
            ? {
                value: pickTicketFormObj.carrierService,
                label: pickTicketFormObj.carrierService,
              }
            : null,
          package: pickTicketFormObj?.carrierPackageId
            ? optionPackageCartonCodes.find(
                (o) => o.value === pickTicketFormObj?.carrierPackageId,
              )
            : null,
          trackingNo: null,
          shippingCost: null,
          isPalletShipment: { value: 1, label: 'No' },
          noOfPallet: null,
          length: null,
          width: null,
          height: null,
          palletWeight: null,
          totalWeight: null,
          carrierError: '',
          serviceError: '',
          trackingNoError: '',
          shippingCostError: '',
        }));
      } else {
        const response = await approveOrApproveAndPackV2(PAYLOAD);

        const item = response;
        if (type === 1) {
          setProcessType((prev) => ({
            ...prev,
            isApprove: true,
          }));

          snackActions.success('Approve successfully.');
        }
        if (type === 2) {
          setProcessType((prev) => ({
            ...prev,
            isPack: true,
          }));

          snackActions.success('Approve And Pack successfully.');
        }

        if (type === 3) {
          setProcessType((prev) => ({
            ...prev,
            isPack: true,
          }));

          snackActions.success('Pack and Ship successfully.');
        }

        setPickTicketFormObj((prev) => ({
          ...prev,
          pickId: queryStringItemPackAndShip.pickId,
          pickTicketId: item.pickTicketId,
          warehouseCustomerId: item.warehouseCustomerId ?? null,
          orderNo: item.orderNo,
          customerWarehouseName: item.customer,
          facilityName: item.facility,
          isApprove: item.approveButtonVisible,
          isPack: item.approveAndPackButtonVisible,
          isPackAndShip: item.packAndShipButtonVisible,
          referenceNo: item.referenceNo,
          approvedBy: item.approvedBy,
          pickedBy: item.pickedBy,
          palletizeButtonEnable: item.palletizeButtonEnable,
          readyForPickupButtonEnable: item.readyForPickupButtonEnable,
          lineItems: item.pickTickLineItems.map((line) => ({
            pickTicketLineItemId: line.pickTicketLineItemId,
            itemId: line.itemId,
            itemSKU: line.sku,
            itemDescription: line.description,
            primaryUOM: line.primaryUOM,
            primaryUOMAbbr: line.primaryUOMAbbr,
            itemUnitOfMeasureId: line.itemUnitOfMeasureId,
            otherUOMStr: line.otherUOMStr,
            otherUOMAbbr: line.otherUOMAbbr,
            otherUOMCount: line.otherUOMCount,
            qty: line.quantity,
            totalCases: line.totalCases,
            packed: line.packed,
            isTrackableBySerialNo: line.isTrackableBySerialNo,
            isTrackableByLot: line.isTrackableByLot,
            isOrderByLPN: line.isOrderByLPN,
            lpn: line.lpn,
          })),
        }));
        setQueryStringItemPackAndShip((prev) => ({
          ...prev,
          id: queryStringItemPackAndShip.id,
          pickId: queryStringItemPackAndShip.pickId,
        }));
      }
    } catch (err) {
      snackActions.error(err);
    }
  };
  // #endregion

  // #region All Method for Carrier
  const onLoadOrderBillingsLookup = async () => {
    try {
      const countriesLookupFromApi = await getOrderBillingsLookup();
      return countriesLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadThePickTicketCarrierDetailsLookup = async (orderId: number) => {
    try {
      const response = await getThePickTicketCarrierDetails(orderId);

      return response;
    } catch (err) {
      return err;
    }
  };

  const loadThePickTicketCarrierDetails = async () => {
    await onLoadThePickTicketCarrierDetailsLookup(
      pickTicketFormObj?.orderNoId,
    ).then((z: any) => {
      const { data, status } = z;
      const item: IPickTicketCarrierFormObj = data;

      switch (status) {
        case 200:
          setCarrierForm((prev) => ({
            ...prev,
            orderId: item?.orderId,
            carrier: item?.carrier,
            warehouseCustomerShippingAccountId:
              item?.warehouseCustomerShippingAccountId,
            accountZipPostalCode: item?.accountZipPostalCode,
            deliveryType: item?.deliveryType,
            trackingNo: item?.trackingNo,
            billingType: item?.billingType,
            scacCode: item?.scacCode,
            warehouseNotes: item?.warehouseNotes,
            carrierNotes: item?.carrierNotes,
          }));

          if (optionOrderBillings !== null) {
            const billing = (el: OrderBillingType) =>
              el.value === item?.billingType;

            const vmBilling = optionOrderBillings.find(billing);
            if (vmBilling !== undefined) {
              setOrderBillValue(vmBilling);
            }
          }

          break;

        default:
          break;
      }
    });
  };

  const updateThePickTicketCarrierDetailsOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();
    const PAYLOAD = {
      orderId: carrierForm.orderId,
      carrier: carrierForm.carrier,
      customerId: currentUser.Claim_CustomerId,
      warehouseCustomerId: pickTicketFormObj.customerWarehouseId,
      warehouseCustomerShippingAccountId:
        carrierForm.warehouseCustomerShippingAccountId,
      accountZipPostalCode: carrierForm.accountZipPostalCode,
      deliveryType: carrierForm.deliveryType,
      trackingNo: carrierForm.trackingNo,
      billingType: carrierForm.billingType,
      scacCode: carrierForm.scacCode,
      warehouseNotes: carrierForm.warehouseNotes,
      carrierNotes: carrierForm.carrierNotes,
    };

    try {
      const response = await updateThePickTicketCarrierDetails(PAYLOAD);
      setCarrierForm(response);
      snackActions.success('Successfully updated carrier.');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const carrierFormOnChange = (e: any): void => {
    e.preventDefault();
    const { name, value } = e.target;
    setCarrierForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // #endregion

  // #region All Method for Ship To and Bill To

  const onLoadCountriesLookup = async () => {
    try {
      const countriesLookupFromApi = await getCountriesLookup();
      return countriesLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadThePickTicketShipToBillToDetailsLookup = async (
    orderId: number,
  ) => {
    try {
      const response = await getThePickTicketShipToBillToDetails(orderId);

      return response;
    } catch (err) {
      return err;
    }
  };

  const setStateValue = (
    name: string,
    prev: IPickTicketShipToBillToFormObj,
    dataset: any,
    value: any,
  ) => {
    if (name === 'shipTo') return { ...prev.shipTo, [dataset.state]: value };
    if (name === 'billTo') return { ...prev.billTo, [dataset.state]: value };

    return prev;
  };

  const shipToBillToFormOnChange = (e: any): void => {
    e.preventDefault();
    const { name, value, dataset } = e.target;
    setShipToBillToForm((prev) => ({
      ...prev,
      [name]:
        dataset.state !== undefined
          ? setStateValue(name, prev, dataset, value)
          : value,
    }));
  };

  const shipToBillToFormAutoCompleteOnChange = (
    e: any,
    item: AutocompleteBase,
    custom: any = null,
  ): void => {
    e.preventDefault();

    const { dataset } = e.target;
    if (!custom) {
      setShipToBillToForm((prev) => ({
        ...prev,
        [dataset.name]:
          dataset.state !== undefined
            ? setStateValue(dataset.name, prev, dataset, item.value)
            : item.value,
      }));
    } else {
      setShipToBillToForm((prev) => ({
        ...prev,
        [custom.name]:
          custom.value !== undefined
            ? setStateValue(
                custom.name,
                prev,
                custom.data.state,
                custom.data.value,
              )
            : custom.value,
      }));
    }
  };

  const verifyAddress = async () => {
    try {
      const response = await verifyAddressForPackAndShip(
        pickTicketFormObj?.pickTicketId,
      );
      snackActions.success('Address is valid.');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const readyForPickupOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    try {
      const PAYLOAD = {
        PickTicketId: pickTicketFormObj?.pickTicketId,
      };

      const response = await updateOrderStatusToReadyForPickup(PAYLOAD);

      const item = response;

      setPickTicketFormObj((prevState) => ({
        ...prevState,
        readyForPickupButtonEnable: item.readyForPickupButtonEnable,
      }));

      setIsOrderReadyForPickupModal(false);

      snackActions.success('Order status updated successfully.');
    } catch (err) {
      snackActions.error(err);
    }
  };

  const loadThePickTicketShipToBillToDetails = async () => {
    await onLoadThePickTicketShipToBillToDetailsLookup(
      pickTicketFormObj?.orderNoId,
    ).then((z: any) => {
      const { data, status } = z;
      const item: IPickTicketShipToBillToFormObj = data;

      switch (status) {
        case 200:
          setShipToBillToForm(item);

          if (optionCountries !== null) {
            const shipToCountry = (el: CountryType) =>
              el.value === item?.shipTo.country;
            const billToCountry = (el: CountryType) =>
              el.value === item?.billTo.country;

            const vmShipToCountry = optionCountries.find(shipToCountry);
            const vmBillToCountry = optionCountries.find(billToCountry);

            if (
              vmShipToCountry !== undefined &&
              vmBillToCountry !== undefined
            ) {
              setShipToCountryValue(vmShipToCountry);
              setBillToCountryValue(vmBillToCountry);
            }
          }
          break;

        default:
          break;
      }
    });
  };

  const updateThePickTicketShipToBillToDetailsOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const PAYLOAD = {
      orderId: shipToBillToForm.orderId,
      customerId: Number(currentUser.Claim_CustomerId),
      warehouseCustomerId: pickTicketFormObj.customerWarehouseId,
      shipTo: shipToBillToForm.shipTo,
      billTo: shipToBillToForm.billTo,
      useShipToDetailsForBillTo: shipToBillToForm.useShipToDetailsForBillTo,
    };

    try {
      const response = await updateThePickTicketShipToBillToDetails(PAYLOAD);
      setCarrierForm(response);
      snackActions.success('Successfully updated contact details.');
    } catch (err) {
      snackActions.error(err);
    }
  };
  // #endregion

  // #region All Method Package
  const packageOnChange = (e: any, packageId: number): void => {
    e.preventDefault();
    const { name, value } = e.target;

    setPickTicketFormObj((prev) => ({
      ...prev,
      packages: prev.packages.map((obj) => {
        if (obj.packageId === packageId) {
          return {
            ...obj,
            packageId,
            [name]: value,
          };
        }
        return {
          ...obj,
        };
      }),
    }));
  };

  const cartonCodeOnChange = (
    e: any,
    packageId: number,
    cartonCode: any,
  ): void => {
    e.preventDefault();
    setPickTicketFormObj((prev) => ({
      ...prev,
      packages: prev.packages.map((obj) => {
        if (obj.packageId === packageId) {
          return {
            ...obj,
            widthInch: cartonCode.width,
            lengthInch: cartonCode.length,
            heightInch: cartonCode.height,
            selectedCarton: cartonCode.value,
          };
        }
        return {
          ...obj,
        };
      }),
    }));
  };

  const packageOnBlur = async (e: any, packageId: number): Promise<void> => {
    e.preventDefault();

    const itemPackage: IPickTicketPackageFormObj =
      pickTicketFormObj.packages.find((el) => el.packageId === packageId);

    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      packageId: packageId !== -1 ? packageId : null,
      pickTicketId: queryStringItemPackAndShip.id,
      itemAsPackageId: itemPackage.itemAsPackage
        ? itemPackage.itemAsPackage.value
        : null,
      heightInch: itemPackage?.heightInch,
      widthInch: itemPackage?.widthInch,
      lengthInch: itemPackage?.lengthInch,
      weightPound: itemPackage?.weightPound,
    };

    try {
      const response = await createPackageProperty(PAYLOAD);
      const item = response;
      setPickTicketFormObj((prev) => ({
        ...prev,
        pickId: queryStringItemPackAndShip.pickId,
        pickTicketId: item.pickTicketId,
        warehouseCustomerId: item.warehouseCustomerId ?? null,
        orderNo: item.orderNo,
        customerWarehouseName: item.customer,
        facilityName: item.facility,
        isApprove: item.approveButtonVisible,
        isPack: item.approveAndPackButtonVisible,
        isPackAndShip: item.packAndShipButtonVisible,
        isShipAndComplete: item.isShipAndComplete,
        isApproveAndPack: item.isApproveAndPack,
        referenceNo: item.referenceNo,
        lineItems: item.pickTickLineItems.map((line) => ({
          pickTicketLineItemId: line.pickTicketLineItemId,
          itemId: line.itemId,
          itemSKU: line.sku,
          itemDescription: line.description,
          primaryUOM: line.primaryUOM,
          primaryUOMAbbr: line.primaryUOMAbbr,
          itemUnitOfMeasureId: line.itemUnitOfMeasureId,
          otherUOMStr: line.otherUOMStr,
          otherUOMAbbr: line.otherUOMAbbr,
          otherUOMCount: line.otherUOMCount,
          qty: line.quantity,
          totalCases: line.totalCases,
          packed: line.packed,
          isTrackableBySerialNo: line.isTrackableBySerialNo,
          isTrackableByLot: line.isTrackableByLot,
          isOrderByLPN: line.isOrderByLPN,
          lpn: line.lpn,
        })),
        packages: item.packages.map((p) => ({
          packageId: p.packageId,
          packageName: p.packageName,
          isNoPackage: p.isNoPackage,
          cartonCode: {
            value: p.cartonCode,
            label: p.cartonCodeStr,
          },
          heightInch: p.heightInch,
          widthInch: p.widthInch,
          lengthInch: p.lengthInch,
          weightPound: p.weightPound,
          labelUrl: p.labelUrl,
          palletizedButtonVisible: p.palletizedButtonVisible,
          packageIsPalletized: p.packageIsPalletized,
          serialNoColumnIsHidden: p.serialNoColumnIsHidden,
          lotColumnIsHidden: p.lotColumnIsHidden,
          packageLineItems: p.packageLineItems,
        })),
        orderNotes: item.orderNotes.map((x) => ({
          orderNoteId: x.orderNoteId,
          note: x.note,
        })),
        orderChecklists: item.orderChecklists.map((x: IOrderChecklistObj) => ({
          orderChecklistId: x.orderChecklistId,
          name: x.name,
          isChecked: x.isChecked,
        })),
      }));

      if (initChecklists === false) {
        setChecklists(() =>
          item.orderChecklists.map((x: IOrderChecklistObj) => x),
        );
        setInitChecklists(true);
      }
    } catch (err) {
      snackActions.error(err);
    }

    // createPackageProperty
  };

  const [shipmentRateId, setShipmentRateId] = useState<string>('');

  const updatePackageToShipAndCompleteOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    if (billingAdhocChargesData.length > 0) {
      if (!validateBillingCharge()) {
        snackActions.error(`Please complete rate and quantity fields`);
        onCloseShipAndCompleteModalModal();
        return;
      }
    }

    const stateFromLocalStorage = localStorage?.getItem('printerSettingsState');

    const printerSettingsState = JSON.parse(
      stateFromLocalStorage,
    ) as PrinterSettingsState;

    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      pickTicketId: queryStringItemPackAndShip.id,
      justComplete: pickTicketFormObj?.justComplete ?? false,
      warehouseCustomerId: pickTicketFormObj.customerWarehouseId,
      packAndShipBillingChargeSectionAdhocs: billingAdhocChargesData,
      printerId: printerSettingsState?.printerId ?? null, // PrintNode
      proNumberFromCarrier: pickTicketFormObj?.proNumberFromCarrier,
      notesFromCarrier: pickTicketFormObj?.notesFromCarrier,
      shippingCost: pickTicketFormObj?.shippingCost,
    };

    try {
      updateLoading(true);

      await updatePackageToShipAndComplete(PAYLOAD)
        .then((response) => {
          const { status } = response;

          snackActions.success('Successfully shipped and completed.');

          if (status === 200) {
            snackActions.success('Successfully shipped and completed.');

            if (
              PAYLOAD?.printerId === null &&
              PAYLOAD?.justComplete === false
            ) {
              setLabelToPrintDataUrl(
                `${httpClient.defaults.baseURL}/PickTicket/shippingLabels/pickTicket/print/${queryStringItemPackAndShip.id}`,
              );
              setIsLabelToPrintModalOpen(true);
            } else {
              navigate(`/pack-and-ship/find-pick-ticket`);
            }
            updateLoading(false);
          }
        })
        .catch((error) => {
          if (error?.includes('Rates Option:')) {
            setRatesOptionData(JSON.parse(error.replace('Rates Option: ', '')));
            setIsRatesToCompleteModalOpen(true);
          } else {
            setIsAutoCreateLabelErrorMessage(error);
            setIsAutoCreateLabelErrorModal(true);
          }
          onCloseShipAndCompleteModalModal();
        });
    } catch (err) {
      snackActions.error(
        'There was an error while processing your request. Please try again!',
      );
      updateLoading(false);
    }
  };

  // #endregion
  useEffect(() => {
    const optOrderBillings = onLoadOrderBillingsLookup();
    const optCountries = onLoadCountriesLookup();
    const cartonOptions = getCartonCodes();
    optOrderBillings.then((opt) => {
      setOptionOrderBillings(
        opt.map((c: any) => ({
          label: c.name,
          value: c.id,
        })),
      );
    });

    optCountries
      .then((opt) => {
        setOptionCountries(
          opt.map((c: any) => ({
            label: c.name,
            value: c.countryId,
          })),
        );
      })
      .catch();

    cartonOptions
      .then((opt) => {
        setCartonCodesOptions(
          opt.map((c: any) => ({
            label: c.description,
            value: c.description,
            ...c,
          })),
        );
      })
      .catch();
  }, [queryStringItemPackAndShip]);

  const viewLoadData = async (isWeightAutoCompute?: boolean) => {
    onLoadTheScannedPickTicketDetailsLookupV2(
      queryStringItemPackAndShip.id,
      isWeightAutoCompute,
    )
      .then((item: any) => {
        setCustomerValue({
          id: item.customerWarehouseId,
          label: item.customerWarehouseName,
        } as AutocompleteBase);

        setFacilityValue({
          id: item.facilityId,
          label: item.facilityName,
        } as AutocompleteBase);

        setPickTicketFormObj((prev) => ({
          ...prev,
          pickId: queryStringItemPackAndShip.pickId,
          warehouseCustomerId: item.warehouseCustomerId ?? null,
          pickTicketId: item.pickTicketId,
          orderNo: item.orderNo,
          orderDate: item?.orderDate,
          customerWarehouseName: item.customer,
          facilityName: item.facility,
          isApprove: item.approveButtonVisible,
          isPack: item.approveAndPackButtonVisible,
          isPackAndShip: item.packAndShipButtonVisible,
          isShipAndComplete: item.isShipAndComplete,
          isApproveAndPack: item.isApproveAndPack,
          isAllLineItemPack: item.isAllLineItemPack,
          isShip: item.isShip,
          isComplete: item.isComplete,
          referenceNo: item.referenceNo,
          carrierId: item?.carrierId,
          carrierNameApprove: item?.carrierName,
          carrierName: item?.carrier,
          carrierService: item?.carrierService,
          carrierPackageId: item?.carrierPackageId,
          isCustomOrOtherCarrier: item?.isCustomOrOtherCarrier,
          approvedBy: item?.approvedBy,
          pickedBy: item?.pickedBy,
          palletizeButtonEnable: item.palletizeButtonEnable,
          packingInstructions: item?.packingInstructions,
          specialInstructions: item?.specialInstructions,
          readyForPickupButtonEnable: item.readyForPickupButtonEnable,
          isAddressInvalid: item.isAddressInvalid,
          deliveryMethod: item.deliveryMethod,
          isShowWarningForPartialOrder: item.isShowWarningForPartialOrder,
          lineItems: item?.pickTickLineItems?.map((line) => ({
            pickTicketLineItemId: line.pickTicketLineItemId,
            itemId: line.itemId,
            itemSKU: line.sku,
            itemDescription: line.description,
            lotNos: line.lotNos,
            uomType: line.uomType,
            primaryUOM: line.primaryUOM,
            primaryUOMAbbr: line.primaryUOMAbbr,
            itemUnitOfMeasureId: line.itemUnitOfMeasureId,
            otherUOMStr: line.otherUOMStr,
            otherUOMAbbr: line.otherUOMAbbr,
            otherUOMCount: line.otherUOMCount,
            qty: line.quantity,
            totalCases: line.totalCases,
            packed: line.packed,
            isTrackableBySerialNo: line.isTrackableBySerialNo,
            isTrackableByLot: line.isTrackableByLot,
            isOrderByLPN: line.isOrderByLPN,
            lpn: line.lpn,
          })),
          packages: item?.packages?.map((p) => ({
            packageId: p.packageId,
            licensePlateId: p.licensePlateId,
            licensePlateNo: p.licensePlateNo,
            lpPrintButtonVisible: p.lpPrintButtonVisible,
            packageName: p.packageName,
            isNoPackage: p.isNoPackage,
            itemAsPackage: {
              value: p.itemAsPackageId,
              label: p.itemAsPackageName,
            },
            itemAsVoidFill: {
              value: p.itemAsVoidFillId,
              text: p.itemAsVoidFillName,
            } as DropdownListItem,
            isExternalPackage: p.isExternalPackage,
            carrierPredefinedPackage: p.carrierPredefinedPackage,
            heightInch: p.heightInch,
            widthInch: p.widthInch,
            lengthInch: p.lengthInch,
            weightPound: p.weightPound,
            isCustomPackage: p.isCustomPackage,
            labelUrl: p.labelUrl,
            palletizedButtonVisible: p.palletizedButtonVisible,
            packageIsPalletized: p.packageIsPalletized,
            serialNoColumnIsHidden: p.serialNoColumnIsHidden,
            lotColumnIsHidden: p.lotColumnIsHidden,
            packageLineItems: p.packageLineItems,
            packageLicensePlate: p.packageLicensePlate,
            outboundPalletId: p.outboundPalletId,
            itemAsPalletName: p.outboundPalletName,
          })),
          orderNotes: item?.orderNotes?.map((x) => ({
            orderNoteId: x.orderNoteId,
            note: x.note,
          })),
          manualItems: item?.manualItems as PickTickLineItemManualItem[],
          orderChecklists: item.orderChecklists.map(
            (x: IOrderChecklistObj) => ({
              orderChecklistId: x.orderChecklistId,
              name: x.name,
              isChecked: x.isChecked,
            }),
          ),
        }));

        if (initChecklists === false) {
          setChecklists(() =>
            item.orderChecklists.map((x: IOrderChecklistObj) => x),
          );
          setInitChecklists(true);
        }

        setDataUrlLabels(item?.labels);

        const getLastWeight = item?.packages?.length;
        const reveselist = item?.packages[getLastWeight - 1];

        setLastPackageWeightLbs(reveselist?.weightPound);

        const getMainCharge = loadBillingChargesDetailByModule(
          item?.warehouseCustomerId ?? 0,
        );
        getMainCharge.then((res) => {
          const billingItems = [];

          res?.adhocCharges?.map((v: any) => {
            const getCharge = item?.packAndShipBillingChargeSectionAdhocs.find(
              (x: any) =>
                x.warehouseCustomerAdhocChargeId ===
                v.warehouseCustomerAdhocChargeId,
            );
            if (getCharge) {
              const setCharge = {
                warehouseCustomerBillingChargeSectionId:
                  getCharge?.warehouseCustomerBillingChargeSectionId,
                warehouseCustomerAdhocChargeId:
                  getCharge?.warehouseCustomerAdhocChargeId,
                chargeName: getCharge?.chargeName,
                rate: getCharge?.rate,
                qty: getCharge?.qty,
                required: v?.required,
                total: getCharge?.total,
                notes: getCharge?.notes,
              };
              billingItems.push(setCharge);
              return setCharge;
            }
            const setCharge = {
              warehouseCustomerAdhocChargeId: v.warehouseCustomerAdhocChargeId,
              chargeName: v.chargeName,
              rate: v.rate,
              qty: null,
              required: v.required,
            };
            billingItems.push(setCharge);
            return setCharge;
          });
          setBillingAdhocChargesData(billingItems);
        });

        setProcessType((prev) => ({
          ...prev,
          isApprove: !item.approveButtonVisible,
          isPack: !item.approveAndPackButtonVisible,
        }));

        setOptionPackageCartonCodes(
          item?.cartonCodeDDL?.map((p) => ({
            value: p.value,
            label: p.text,
            dimensions: p.dimensions,
            isExternalPackage: p.isExternalPackage,
            acceptsVoidFill: p.acceptsVoidFill,
          })),
        );

        if (item.isAllLineItemPack && item.isShipAndComplete !== true) {
          if (!item.carrier) {
            return;
          }

          if (item?.isCustomOrOtherCarrier !== true) {
            if (!WeightsValidation()) {
              return;
            }
          }

          if (item?.isComplete) {
            return;
          }
        }

        setLastPackageWeightLbsFormError([initialFormStatePackagesFormError]);
      })
      .catch();

    setIsOpenDeleteConfirmModal({ package: false, packageLineItem: false }); // set the confirm delete modal to all false
  };

  const updatePackageLineItemQtyOnClick = async (
    e: React.SyntheticEvent,
    packageLineItemId: number,
    qty: number,
    arithmetic: string,
  ): Promise<void> => {
    e.preventDefault();
    let quantity = qty;
    if (arithmetic === 'subtraction') {
      quantity -= 1;
    }
    if (arithmetic === 'add') {
      quantity += 1;
    }
    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      packageLineItemId,
      qty: quantity,
    };

    try {
      await updatePackagePackageLineItemQty(PAYLOAD);

      viewLoadData(true);
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (pageViewer.isView()) {
      viewLoadData();

      onLoadThePickTicketForApprovedOrApprovedAndPackDetailsLookup(
        queryStringItemPackAndShip.id,
      ).then((x: any) => {
        const { data, status } = x;
        const item: IPickTicketFormObj = data;
        switch (status) {
          case 200:
            setCustomerValue({
              id: item.customerWarehouseId,
              label: item.customerWarehouseName,
            } as AutocompleteBase);

            setFacilityValue({
              id: item.facilityId,
              label: item.facilityName,
            } as AutocompleteBase);
            setPickTicketFormObj((prev) => ({
              ...prev,
              orderNoId: item.orderNoId,
              customerWarehouseId: item.customerWarehouseId,
              customerWarehouseName: item.customerWarehouseName,
              facilityId: item.facilityId,
              facilityName: item.facilityName,
              userId: item.userId,
              carrierId: item?.carrierId,
              carrierName: item?.carrierName,
              carrierService: item?.carrierService,
            }));

            break;

          default:
            break;
        }
      });
    }
  }, [queryStringItemPackAndShip]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setIsShowBarcodeError(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [isShowBarcodeError]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setIsPulseNumber((e) => ({
        ...e,
        isPulse: false,
        value: '',
      }));
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [isPulseNumber]);

  const ScanTheItemCodeFunction = async (
    barcodeValue?: string,
  ): Promise<void> => {
    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      pickTicketId: queryStringItemPackAndShip.id,
      barcode: barcodeValue,
    };
    if (pickTicketFormObj.isApproveAndPack) {
      try {
        const response = await createPackAndShipPackageLineItemScan(PAYLOAD);
        const { status } = response;
        if (status === 200) {
          viewLoadData(true);
          setIsPulseNumber((e) => ({
            ...e,
            isPulse: true,
            value: barcodeValue,
          }));
        }
      } catch (err) {
        setMessageShowBarcodeError(err.toString());
        setIsShowBarcodeError(true);
      }
    }
  };

  const updatePackageToCompleteFunction = async (): Promise<void> => {
    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      pickTicketId: queryStringItemPackAndShip.id,
      justComplete: pickTicketFormObj.justComplete ?? false,
      warehouseCustomerId: pickTicketFormObj.customerWarehouseId,
      packAndShipBillingChargeSectionAdhocs: billingAdhocChargesData,
    };

    try {
      setIsLoadingToComplete(true);

      const response = await updatePackageToComplete(PAYLOAD);
      const { status, data } = response;
      if (status === 200) {
        navigate('/pack-and-ship/find-pick-ticket');
      }
    } catch (err) {
      setIsLoadingToComplete(false);

      snackActions.error(err);
    }
  };

  const createPackageFunction = async (): Promise<void> => {
    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      pickTicketId: queryStringItemPackAndShip.id,
      width: pickTicketFormObj?.widthCustomPackage,
      height: pickTicketFormObj?.heightCustomPackage,
      length: pickTicketFormObj?.lengthCustomPackage,
    };

    try {
      if (pickTicketFormObj.isAllLineItemPack) {
        if (pickTicketFormObj.isShip) {
          updatePackageToCompleteFunction();
        } else {
          const stateFromLocalStorage = localStorage?.getItem(
            'printerSettingsState',
          );

          const printerSettingsState = JSON.parse(
            stateFromLocalStorage,
          ) as PrinterSettingsState;

          if (
            printerSettingsState !== null &&
            printerSettingsState.computerId !== null &&
            printerSettingsState.printerId !== null
          ) {
            setDisablePressEnterToConfirmButton(true);
            setIsGeneratePrintLabel(true);
            viewLoadData(); // to show modal again

            const EASYPOST_PAYLOAD = {
              pickTicketId: queryStringItemPackAndShip.id,
              printerId: printerSettingsState.printerId, // PrintNode
            };

            try {
              const response = await createLabel(EASYPOST_PAYLOAD);
              const { status, data } = response;
              if (status === 200) {
                updatePackageToCompleteFunction();
                setDisablePressEnterToConfirmButton(false);
              }
            } catch (err) {
              setIsGeneratePrintLabel(false);
              setDisablePressEnterToConfirmButton(false);

              snackActions.error(err);
            }
          }
        }
      } else {
        const response = await createPackage(PAYLOAD);
        const { status, data } = response;
        if (status === 200) {
          viewLoadData();
          snackActions.success('New package has been created');
          setIsChangePackageModalOpen(false);
          setIsNextPackageModalOpen(false);
        }
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const updateCartonCodeFunction = async (
    packageIdParam?: string,
    cartonCodeIdParam?: string,
    isCreateNewPackage?: boolean,
    isExternalPackage?: boolean,
    isCustomPackageParam?: boolean,
    itemAsVoidFillId?: number,
  ): Promise<void> => {
    const PAYLOAD = {
      packageId: packageIdParam,
      itemAsPackageId: cartonCodeIdParam,
      isExternalPackage: isExternalPackage || false,
      isCustomPackage: isCustomPackageParam,
      itemAsVoidFillId,
      Height: pickTicketFormObj?.widthCustomPackage,
      Width: pickTicketFormObj?.heightCustomPackage,
      Length: pickTicketFormObj?.lengthCustomPackage,
    };

    try {
      const response = await updateCartonCodeFromPackage(PAYLOAD);
      const { status, data } = response;
      if (status === 200) {
        if (isCreateNewPackage) {
          createPackageFunction();
        } else {
          viewLoadData();
        }
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const updateWeightPoundFunction = async (
    packageIdParam?: string,
    weightPoundParam?: string,
  ): Promise<void> => {
    const PAYLOAD = {
      packageId: packageIdParam,
      weightPound: weightPoundParam,
    };

    try {
      const response = await updateWeightPound(PAYLOAD);
      const { status, data } = response;
      if (status === 200) {
        viewLoadData();
        setLastPackageWeightLbsFormError('');
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const createPalletizeFunction = async (data: any): Promise<void> => {
    const PAYLOAD = {
      pickTicketId: queryStringItemPackAndShip.id,
      outboundPalletId: data?.outboundPalletId,
      itemAsPalletId: data.palletPackagingType?.value,
      heightInch: data.heightInch,
      widthInch: data.widthInch,
      lengthInch: data.lengthInch,
      weightPound: data.weightPound,
      isEditPalletize: isEditPalletizeMode,
    };

    try {
      createPalletize(PAYLOAD)
        .then(() => {
          viewLoadData();
          setIsPalletizeModalOpen(false);
          snackActions.success('Palletize successfully saved');
        })
        .catch((err) => {
          snackActions.error(err);
        });
    } catch (err) {
      snackActions.error(err);
    }
  };

  const deletePackageFunction = async (
    packageIdParam?: string,
  ): Promise<void> => {
    const PAYLOAD = {
      packageId: packageIdParam,
    };

    try {
      const response = await deletePackageOnPackAndShip(PAYLOAD);
      const { status, data } = response;
      if (status === 200) {
        viewLoadData();
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const updatePackAndShipToShipEasyPostFunction = async (): Promise<void> => {
    const PAYLOAD = {
      pickTicketId: queryStringItemPackAndShip.id,
    };

    try {
      setIsGeneratePrintLabel(true);

      await createLabel(PAYLOAD);
      viewLoadData();
    } catch (err) {
      setIsGeneratePrintLabel(false);
      snackActions.error(err);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setIsGeneratePrintLabel(false);
    }, 7000);

    return () => {
      clearTimeout(timeId);
    };
  }, [isGeneratePrintLabel]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setIsLoadingToComplete(false);
    }, 7000);

    return () => {
      clearTimeout(timeId);
    };
  }, [isLoadingToComplete]);

  const GetScanPackAndShipPickTicketLineItemfunction = async (
    barcodeValue?: string,
  ): Promise<void> => {
    try {
      const response = await GetScanPackAndShipPickTicketLineItem(
        queryStringItemPackAndShip.id,
        barcodeValue,
      );

      const { status, data } = response;

      if (status === 200) {
        const check = data.some((c) => c.isSerialNo === true);

        if (check) {
          const filtered = data.filter((obj) => obj.isSerialNo === true);
          setSerialNumberData(filtered[0]);
          setIsSerialNumberScanOpen(true);
        } else {
          ScanTheItemCodeFunction(barcodeValue);
        }
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  const createPackAndShipPackageLineitemSerialNoFunction = async (
    pickTicketLineItemIdValue?: string,
    serialNumbersValue?: string[],
  ): Promise<void> => {
    const PAYLOAD = {
      customerLocationId: currentLocationAndFacility.customerLocationId,
      customerFacilityId: currentLocationAndFacility.customerFacilityId,
      pickTicketLineItemId: pickTicketLineItemIdValue,
      serialNumbers: serialNumbersValue,
    };
    try {
      const response = await createPackAndShipPackageLineitemSerialNo(PAYLOAD);
      const { status } = response;
      if (status === 200) {
        viewLoadData();
        setIsSerialNumberScanOpen(false);
      }
    } catch (err) {
      setMessageShowSerialNumberBarcodeError(err.toString());
      setIsShowSerialNumberBarcodeError(true);
    }
  };

  const value: IPackAndShip = useMemo(
    () => ({
      optionOrderBillings,
      optionCountries,
      customerValue,
      facilityValue,
      orderBillValue,
      shipToCountryValue,
      billToCountryValue,
      pickTicketFormObj,
      findPickTicketForm,
      findPickTicketFormObj,
      processType,
      queryStringItemPackAndShip,
      carrierForm,
      shipToBillToForm,
      tabValue,
      packageIdValue,
      shipmentRateId,
      dataSelectedPackageLineItem,
      billingAdhocChargesData,
      errorBillingAdhocItemValues,
      cartonCodesOptions,
      optionPackageCartonCodes,
      scanTheItemCodeField,
      searchTerm,
      focusIsOnBarcode,
      isShowBarcodeError,
      messageShowBarcodeError,
      barcode,
      printOfLPItem,
      lastPackageWeightLbs,
      buttonSinglePrintRef,
      isPulseNumber,
      lastPackageWeightLbsFormError,
      dataSelectedPackage,
      isOpenDeleteConfirmModal,
      isGeneratePrintLabel,
      dataUrlLabels,
      serialNumberBarcode,
      serialNumbers,
      isShowSerialNumberBarcodeError,
      messageShowSerialNumberBarcodeError,
      serialNumberData,
      isLoadingToComplete,
      disablePressEnterToConfirmButton,
      allowBarcodeScanner,
      setIsLoadingToComplete,
      setSerialNumberData,
      setMessageShowSerialNumberBarcodeError,
      setIsShowSerialNumberBarcodeError,
      setSerialNumberBarcode,
      setSerialNumbers,
      setIsGeneratePrintLabel,
      setIsOpenDeleteConfirmModal,
      setDataSelectedPackage,
      setLastPackageWeightLbsFormError,
      setLastPackageWeightLbs,
      setPrintOfLPItem,
      setBarcode,
      setMessageShowBarcodeError,
      setIsShowBarcodeError,
      setFocusIsOnBarcode,
      setPickTicketFormObj,
      findPickTicketFormOnChange,
      setFindPickTicketForm,
      setFindPickTicketFormObj,
      setQueryStringItemPackAndShip,
      setProcessType,
      setOrderBillValue,
      carrierFormOnChange,
      setCarrierForm,
      loadThePickTicketCarrierDetails,
      approveOrApproveAndPackOnClick,
      approveOrApproveAndPackOnClickV2,
      updateThePickTicketCarrierDetailsOnClick,
      setTabValue,
      setShipToBillToForm,
      loadThePickTicketShipToBillToDetails,
      verifyAddress,
      shipToBillToFormOnChange,
      updateThePickTicketShipToBillToDetailsOnClick,
      updatePackageToShipAndCompleteOnClick,
      readyForPickupOnClick,
      updatePackageLineItemQtyOnClick,
      shipToBillToFormAutoCompleteOnChange,
      setShipToCountryValue,
      setBillToCountryValue,
      setPackageIdValue,
      packageOnChange,
      cartonCodeOnChange,
      packageOnBlur,
      setShipmentRateId,
      justCompletePackAndShip,
      completePackAndShipAndCreateShippmentLabel,
      viewLoadData,
      setDataSelectedPackageLineItem,
      handleChangeBillingChargeSection,
      handleRemoveBillingChargeSection,
      onLoadPackAndShipCompletedList,
      setSearchTerm,
      ScanTheItemCodeFunction,
      updateCartonCodeFunction,
      createPackageFunction,
      updateWeightPoundFunction,
      createPalletizeFunction,
      deletePackageFunction,
      updatePackAndShipToShipEasyPostFunction,
      GetScanPackAndShipPickTicketLineItemfunction,
      createPackAndShipPackageLineitemSerialNoFunction,
      WeightsValidation,
      setDisablePressEnterToConfirmButton,
      setAllowBarcodeScanner,
      isChecklistComplete,
      setIsChecklistComplete,
      checklists,
      setChecklists,
      isNewPackageForManualItem,
      setIsNewPackageForManualItem,
      isVoidFillModal,
      setIsVoidFillModal,
      shipVoidFillDDL,
      setShipVoidFillDDL,
      updateCartonCodeFunctionModel,
      setUpdateCartonCodeFunctionModel,
      allowPackageNo,
      setAllowPackageNo,
      packAndShipListRows,
      setPackAndShipListRows,
      filteredPackAndShipListRows,
      setFilteredPackAndShipListRows,
      checkShipAndCompleIsDisable,
    }),
    [
      packAndShipListRows,
      filteredPackAndShipListRows,
      allowPackageNo,
      optionOrderBillings,
      optionCountries,
      processType,
      customerValue,
      facilityValue,
      orderBillValue,
      shipToCountryValue,
      billToCountryValue,
      pickTicketFormObj,
      findPickTicketForm,
      findPickTicketFormObj,
      queryStringItemPackAndShip,
      carrierForm,
      tabValue,
      shipToBillToForm,
      packageIdValue,
      shipmentRateId,
      dataSelectedPackageLineItem,
      billingAdhocChargesData,
      errorBillingAdhocItemValues,
      optionPackageCartonCodes,
      scanTheItemCodeField,
      focusIsOnBarcode,
      isShowBarcodeError,
      messageShowBarcodeError,
      barcode,
      printOfLPItem,
      lastPackageWeightLbs,
      buttonSinglePrintRef,
      isPulseNumber,
      lastPackageWeightLbsFormError,
      dataSelectedPackage,
      isOpenDeleteConfirmModal,
      isGeneratePrintLabel,
      dataUrlLabels,
      serialNumberBarcode,
      serialNumbers,
      isShowSerialNumberBarcodeError,
      messageShowSerialNumberBarcodeError,
      serialNumberData,
      isLoadingToComplete,
      disablePressEnterToConfirmButton,
      allowBarcodeScanner,
      isChecklistComplete,
      checklists,
      isNewPackageForManualItem,
      isVoidFillModal,
      setIsVoidFillModal,
      shipVoidFillDDL,
      setShipVoidFillDDL,
      updateCartonCodeFunctionModel,
      setUpdateCartonCodeFunctionModel,
    ],
  );
  return (
    <PackAndShipContext.Provider value={value}>
      {children}
    </PackAndShipContext.Provider>
  );
};
