/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  Reducer,
} from 'react';

import { Action } from 'common/models';
import { ModelParameter } from 'common/models/ModelParameter';

import {
  InvoiceDetailFormErrorModel,
  InvoiceDetailFormModel,
  InvoiceLineItemErrorModel,
  InvoiceLineItemModel,
} from '../models';

function ComputeTotalQuantity(lineItems: InvoiceLineItemModel[]): number {
  return lineItems.length;
}

function ComputeSubTotal(lineItems: InvoiceLineItemModel[]): number {
  let subtotal = 0;
  lineItems.forEach((x) => {
    // subtotal += x.quantity * (x.amount ? x.amount : 1);
    subtotal += x.amount ?? 0;
  });

  return subtotal;
}

function ComputeTotal(subtotal?: number, adjustmentAmount?: number): number {
  let total = 0;
  total = (subtotal ?? 0) + (adjustmentAmount ?? 0);

  return total;
}

function MutateLineItems(currentState: State, action: any): State {
  const lineItems: InvoiceLineItemModel[] = [...currentState.vm.form.lineItems];
  const lineItemToEdit = action.payload as ModelParameter<
    InvoiceLineItemModel,
    string,
    any
  >;
  const lineItem = {
    ...lineItemToEdit.model,
    [lineItemToEdit.key]: lineItemToEdit.value,
  } as InvoiceLineItemModel;

  let index = null;
  if (lineItemToEdit.model.rowId !== undefined) {
    index = lineItems.findIndex((x) => x.rowId === lineItemToEdit.model.rowId);
  }
  // exist in db
  else {
    index = lineItems.findIndex(
      (x) => x.invoiceDetailId === lineItemToEdit.model.invoiceDetailId,
    );
  }

  lineItems[index] = { ...lineItem };

  const subtotal = ComputeSubTotal(lineItems);

  return {
    ...currentState,
    vm: {
      ...currentState.vm,
      form: {
        ...currentState.vm.form,
        lineItems,
        subTotal: subtotal,
        total: ComputeTotal(subtotal, currentState.vm.form.adjustmentAmount),
      },
    },
  } as State;
}

const INITIAL_STATE_INVOICELINEITEM_ERROR: InvoiceLineItemErrorModel = {
  description: '',
  quantity: '',
  amount: '',
};

const INITIAL_STATE_INVOICEFORM_ERROR: InvoiceDetailFormErrorModel = {
  invoiceNo: '',
  invoiceDate: '',
};

type ViewModel = {
  form?: InvoiceDetailFormModel;
  formError?: InvoiceDetailFormErrorModel;
  formIsValid: boolean;
};

// init all fields to null, so we don't get undefined value
export const INITIAL_STATE_VM_FORM_LINEITEM: InvoiceLineItemModel = {
  rowId: null,
  invoiceDetailId: null,
  description: null,
  quantity: null,
  amount: null,
  error: INITIAL_STATE_INVOICELINEITEM_ERROR,
};

// init all fields to null, so we don't get undefined value
export const INITIAL_STATE_VM_FORM: InvoiceDetailFormModel = {
  invoiceId: null,
  warehouseCustomerId: null,
  orderId: null,
  invoiceType: null,
  invoiceNo: null,
  invoiceDate: null,
  dueDate: null,
  totalQuantity: null,
  subTotal: null,
  adjustmentAmount: null,
  total: null,
  comment: null,
  lineItems: [],
};

const INITIAL_STATE_VM: ViewModel = {
  form: INITIAL_STATE_VM_FORM,
  formError: INITIAL_STATE_INVOICEFORM_ERROR,
  formIsValid: false,
};

type State = {
  isBusy: boolean;
  vm: ViewModel;
};

const INITIAL_STATE: State = {
  isBusy: false,
  vm: INITIAL_STATE_VM,
};

enum ACTION_TYPES {
  SET_VM = 'SET_VM',
  VALIDATE_FORM = 'VALIDATE_FORM',
  SET_FORMISVALID_TO_FALSE = 'SET_FORMISVALID_TO_FALSE',
  SET_STATE_ISBUSY = 'SET_STATE_ISBUSY',

  // FORM
  SET_FORM_INVOICENO = 'SET_FORM_INVOICENO',
  SET_FORM_INVOICEDATE = 'SET_FORM_INVOICEDATE',
  SET_FORM_ADJUSTMENTAMOUNT = 'SET_FORM_ADJUSTMENTAMOUNT',
  SET_FORM_COMMENT = 'SET_FORM_COMMENT',

  // LINE ITEM
  ADD_FORM_LINEITEM = 'ADD_FORM_LINEITEM',
  REMOVE_FORM_LINEITEM = 'REMOVE_FORM_LINEITEM',
  SET_FORM_LINEITEM_DESCRIPTION = 'SET_FORM_LINEITEM_DESCRIPTION',
  SET_FORM_LINEITEM_QUANTITY = 'SET_FORM_LINEITEM_QUANTITY',
  SET_FORM_LINEITEM_AMOUNT = 'SET_FORM_LINEITEM_AMOUNT',
}

function reducer(state: State, action: Action<ACTION_TYPES>) {
  const { type } = action;

  switch (type) {
    case ACTION_TYPES.SET_VM: {
      const vm = { ...INITIAL_STATE_VM };
      vm.form = action.payload;

      return {
        ...state,
        vm,
      } as State;
    }
    case ACTION_TYPES.VALIDATE_FORM: {
      const formError = { ...INITIAL_STATE_INVOICEFORM_ERROR };

      // FORM
      if (
        state.vm.form?.invoiceNo === null ||
        state.vm.form?.invoiceNo === ''
      ) {
        formError.invoiceNo = 'This field is required';
      }

      if (state.vm.form?.invoiceDate === null) {
        formError.invoiceDate = 'This field is required';
      }

      // LINE ITEM
      let lineItemsIsValid = true;
      const lineItems: InvoiceLineItemModel[] = [...state.vm.form.lineItems];
      state.vm.form.lineItems.forEach((x) => {
        const lineItemError = { ...INITIAL_STATE_INVOICELINEITEM_ERROR };
        if (x.description === null || x.description === '') {
          lineItemError.description = 'This field is required';

          if (lineItemsIsValid) {
            lineItemsIsValid = false;
          }
        }

        // if (x.quantity === null) {
        //   lineItemError.quantity = 'This field is required';

        //   if (lineItemsIsValid) {
        //     lineItemsIsValid = false;
        //   }
        // }

        if (x.amount === null) {
          lineItemError.amount = 'This field is required';

          if (lineItemsIsValid) {
            lineItemsIsValid = false;
          }
        }

        let index = null;
        if (x.rowId !== undefined) {
          index = lineItems.findIndex((z) => z.rowId === x.rowId);
        }
        // exist in db
        else {
          index = lineItems.findIndex(
            (z) => z.invoiceDetailId === x.invoiceDetailId,
          );
        }

        lineItems[index] = { ...x, error: lineItemError };
      });

      const formIsValid = Object.values(formError).every((x) => x === '');

      return {
        ...state,
        vm: {
          ...state.vm,
          form: {
            ...state.vm.form,
            lineItems,
          },
          formError,
          formIsValid: formIsValid && lineItemsIsValid,
        },
      } as State;
    }
    case ACTION_TYPES.SET_FORMISVALID_TO_FALSE: {
      return {
        ...state,
        vm: {
          ...state.vm,
          formIsValid: false,
        },
      } as State;
    }
    case ACTION_TYPES.SET_STATE_ISBUSY: {
      return {
        ...state,
        isBusy: action.payload,
      } as State;
    }

    // FORM
    case ACTION_TYPES.SET_FORM_INVOICENO: {
      return {
        ...state,
        vm: {
          ...state.vm,
          form: {
            ...state.vm.form,
            invoiceNo: action.payload,
          },
        },
      } as State;
    }
    case ACTION_TYPES.SET_FORM_INVOICEDATE: {
      return {
        ...state,
        vm: {
          ...state.vm,
          form: {
            ...state.vm.form,
            invoiceDate: action.payload,
          },
        },
      } as State;
    }
    case ACTION_TYPES.SET_FORM_ADJUSTMENTAMOUNT: {
      const subtotal = ComputeSubTotal(state.vm.form?.lineItems);

      return {
        ...state,
        vm: {
          ...state.vm,
          form: {
            ...state.vm.form,
            adjustmentAmount: action.payload,
            subTotal: subtotal,
            total: ComputeTotal(subtotal, action.payload),
          },
        },
      } as State;
    }
    case ACTION_TYPES.SET_FORM_COMMENT: {
      return {
        ...state,
        vm: {
          ...state.vm,
          form: {
            ...state.vm.form,
            comment: action.payload,
          },
        },
      } as State;
    }

    // LINE ITEM
    case ACTION_TYPES.ADD_FORM_LINEITEM: {
      const lineItemToAdd = {
        ...INITIAL_STATE_VM_FORM_LINEITEM,
      };

      lineItemToAdd.rowId = Math.random();

      const lineItems: InvoiceLineItemModel[] = [
        ...state.vm.form.lineItems,
        { ...lineItemToAdd },
      ];

      const totalQuantity = ComputeTotalQuantity(lineItems);

      return {
        ...state,
        vm: {
          ...state.vm,
          form: {
            ...state.vm.form,
            lineItems,
            totalQuantity,
          },
        },
      } as State;
    }
    case ACTION_TYPES.REMOVE_FORM_LINEITEM: {
      let lineItems: InvoiceLineItemModel[] = [...state.vm.form.lineItems];

      const lineItemToRemove = action.payload as InvoiceLineItemModel;

      if (lineItemToRemove.isRequired) {
        const lineItemError = { ...INITIAL_STATE_INVOICELINEITEM_ERROR };
        lineItemError.description = 'This line is required';

        lineItems.filter((x) => x.rowId === lineItemToRemove.rowId)[0].error =
          lineItemError;
      } else if (lineItemToRemove.rowId !== undefined) {
        lineItems = [
          ...lineItems.filter((x) => x.rowId !== lineItemToRemove.rowId),
        ];
      }
      // exist in db
      else {
        lineItems = [
          ...lineItems.filter(
            (x) => x.invoiceDetailId !== lineItemToRemove.invoiceDetailId,
          ),
        ];
      }

      const totalQuantity = ComputeTotalQuantity(lineItems);
      const subtotal = ComputeSubTotal(lineItems);

      return {
        ...state,
        vm: {
          ...state.vm,
          form: {
            ...state.vm.form,
            lineItems,
            totalQuantity,
            subTotal: subtotal,
            total: ComputeTotal(subtotal, state.vm.form.adjustmentAmount),
          },
        },
      } as State;
    }
    case ACTION_TYPES.SET_FORM_LINEITEM_DESCRIPTION: {
      return MutateLineItems(state, action);
    }
    case ACTION_TYPES.SET_FORM_LINEITEM_QUANTITY: {
      return MutateLineItems(state, action);
    }
    case ACTION_TYPES.SET_FORM_LINEITEM_AMOUNT: {
      return MutateLineItems(state, action);
    }
    default:
      return state;
  }
}

// MEMO STATE
interface IInvoiceDetailForm {
  state: State;
  setForm(model?: InvoiceDetailFormModel);
  validateForm(): boolean;
  setFormIsValidToFalse();
  setIsBusy(isBusy: boolean);

  // FORM
  setFormInvoiceNo(invoiceNo?: string);
  setFormInvoiceDate(invoiceDate?: Date);
  setFormAdjustmentAmount(adjustmentAmount?: number);
  setFormComment(comment?: string);

  // LINE ITEM
  addLineItem();
  removeLineItem(model: InvoiceLineItemModel);
  setLineItemDescription(
    model: ModelParameter<InvoiceLineItemModel, string, string>,
  );
  setLineItemQuantity(
    model: ModelParameter<InvoiceLineItemModel, string, number>,
  );
  setLineItemAmount(
    model: ModelParameter<InvoiceLineItemModel, string, number>,
  );
}

type InvoiceDetailFormProviderProps = {
  children: React.ReactNode;
};
export const InvoiceDetailFormContext = createContext<IInvoiceDetailForm>(
  {} as IInvoiceDetailForm,
);

export const useInvoiceDetailFormContext = () =>
  useContext(InvoiceDetailFormContext);

export const InvoiceDetailFormContextProvider = ({
  children,
}: InvoiceDetailFormProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<State, Action<ACTION_TYPES>>>(
    reducer,
    INITIAL_STATE,
  );

  function setForm(model?: InvoiceDetailFormModel) {
    dispatch({
      type: ACTION_TYPES.SET_VM,
      payload: model,
    });
  }

  function validateForm(): boolean {
    dispatch({
      type: ACTION_TYPES.VALIDATE_FORM,
      payload: null,
    });

    return true;
  }

  function setIsBusy(isBusy: boolean) {
    dispatch({
      type: ACTION_TYPES.SET_STATE_ISBUSY,
      payload: isBusy,
    });
  }

  function setFormIsValidToFalse() {
    dispatch({
      type: ACTION_TYPES.SET_FORMISVALID_TO_FALSE,
      payload: null,
    });
  }

  function setFormInvoiceNo(invoiceNo?: string) {
    dispatch({
      type: ACTION_TYPES.SET_FORM_INVOICENO,
      payload: invoiceNo,
    });
  }

  function setFormInvoiceDate(invoiceDate?: Date) {
    dispatch({
      type: ACTION_TYPES.SET_FORM_INVOICEDATE,
      payload: invoiceDate,
    });
  }

  function setFormAdjustmentAmount(adjustmentAmount?: number) {
    dispatch({
      type: ACTION_TYPES.SET_FORM_ADJUSTMENTAMOUNT,
      payload: adjustmentAmount,
    });
  }

  function setFormComment(comment?: string) {
    dispatch({
      type: ACTION_TYPES.SET_FORM_COMMENT,
      payload: comment,
    });
  }

  function addLineItem() {
    dispatch({
      type: ACTION_TYPES.ADD_FORM_LINEITEM,
      payload: null,
    });
  }

  function removeLineItem(model: InvoiceLineItemModel) {
    dispatch({
      type: ACTION_TYPES.REMOVE_FORM_LINEITEM,
      payload: model,
    });
  }

  function setLineItemDescription(
    model: ModelParameter<InvoiceLineItemModel, string, string>,
  ) {
    dispatch({
      type: ACTION_TYPES.SET_FORM_LINEITEM_DESCRIPTION,
      payload: model,
    });
  }

  function setLineItemQuantity(
    model: ModelParameter<InvoiceLineItemModel, string, number>,
  ) {
    dispatch({
      type: ACTION_TYPES.SET_FORM_LINEITEM_QUANTITY,
      payload: model,
    });
  }

  function setLineItemAmount(
    model: ModelParameter<InvoiceLineItemModel, string, number>,
  ) {
    dispatch({
      type: ACTION_TYPES.SET_FORM_LINEITEM_AMOUNT,
      payload: model,
    });
  }

  const value: IInvoiceDetailForm = useMemo(
    () => ({
      state,
      setForm,
      validateForm,
      setIsBusy,
      setFormIsValidToFalse,

      // FORM
      setFormInvoiceNo,
      setFormInvoiceDate,
      setFormAdjustmentAmount,
      setFormComment,

      // LINE ITEM
      addLineItem,
      removeLineItem,
      setLineItemDescription,
      setLineItemQuantity,
      setLineItemAmount,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return (
    <InvoiceDetailFormContext.Provider value={value}>
      {children}
    </InvoiceDetailFormContext.Provider>
  );
};
