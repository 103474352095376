import React, { useContext, useState, useEffect } from 'react';

import { GlobalContext } from 'store/contexts/GlobalContext';

import { Button, Dialog, Divider, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { color } from '@mui/system';

export interface IinvoiceDetails {
  invoiceId?: number;
  invoiceDate?: string;
  companyName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: number;
  date?: number;
  invoiceNo?: string;
  customerId?: string;
  dueDate?: string;
  billTo?: {
    recipientName?: string;
    companyName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    phone?: string;
  };
  lineItems: IInvoiceLineItem[] | [];
  subtotal?: string;
  adjustmentAmount?: string;
  total?: string;
  comment?: string;
}
interface IInvoiceLineItem {
  name?: string;
  amount: string;
}

interface InvoiceDialogProps {
  data?: IinvoiceDetails;
}

export default React.memo(({ data }: InvoiceDialogProps) => {
  const { isInvoiceDialogOpen, onCloseInvoiceDialogModal } =
    useContext(GlobalContext);

  const handleCloseInvoiceDialogModal = () => {
    onCloseInvoiceDialogModal();
  };

  return (
    <Dialog
      sx={{ height: '100% !important' }}
      maxWidth="md"
      fullWidth
      open={isInvoiceDialogOpen}
      onClose={() => handleCloseInvoiceDialogModal()}
    >
      <DialogContent>
        <div
          id="invoiceDocument"
          className="printDialog"
          style={{ margin: '0px', padding: '0px', width: '100%' }}
        >
          <table width="100%" style={{ padding: '15px' }}>
            <tr>
              <td width="50%">
                <p
                  style={{
                    fontSize: '40px',
                    color: 'rgb(8 20 165)',
                  }}
                >
                  <b>INVOICE</b>
                </p>
              </td>
              <td align="right">
                <table width="70%">
                  <tr>
                    <td>
                      <Typography style={{ color: 'GrayText' }}>
                        Invoice no.:
                      </Typography>
                    </td>
                    <td align="right">
                      <p>{data?.invoiceNo} </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography style={{ color: 'GrayText' }}>
                        Invoice date:
                      </Typography>
                    </td>
                    <td align="right">
                      <p>{data?.invoiceDate} </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <br />
                <table width="100%">
                  <tr>
                    <td>
                      <Typography
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                      >
                        {data?.companyName}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {data?.address1} {data?.address2}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {data?.city} {data?.state} {data?.zipCode}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </td>
              <td align="right">
                <br />
                <table width="70%">
                  <tr>
                    <td>
                      <Typography
                        style={{ fontSize: '18px', fontWeight: 'bold' }}
                      >
                        BILL TO
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {data?.billTo.companyName} {data?.billTo.address1}
                        {data?.billTo.address2}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>
                        {data?.billTo.city}
                        {data?.billTo.state}
                        {data?.billTo.zipCode}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <br />
                <br />
                <table width="100%" cellSpacing="0">
                  <thead style={{ fontWeight: 'bold' }}>
                    <tr>
                      <td width="60%">Description</td>
                      <td align="right">Amount</td>
                    </tr>
                  </thead>
                </table>
                <Divider sx={{ mt: 1, mb: 1, borderWidth: '1.8px' }} />
                {data?.lineItems !== undefined && data?.lineItems.length > 0 && (
                  <div>
                    {data?.lineItems.map((row, index) => (
                      <>
                        <table width="100%" cellSpacing="0">
                          <tbody>
                            <tr>
                              <td width="60%">{row.name}</td>
                              <td align="right">{row.amount}</td>
                            </tr>
                          </tbody>
                        </table>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                      </>
                    ))}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td width="50%" />
              <td align="right" valign="top">
                <br />
                <table width="100%">
                  <tr>
                    <td>
                      <b>Subtotal:</b>
                    </td>
                    <td align="right">{data?.subtotal}</td>
                  </tr>
                </table>

                <table width="100%">
                  <tr>
                    <td>
                      <b>Adjustment Amount:</b>
                    </td>
                    <td align="right">{data?.adjustmentAmount}</td>
                  </tr>
                </table>

                <Divider sx={{ mt: 1, mb: 1, borderWidth: '1.8px' }} />
                <table width="100%">
                  <tr>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td align="right">{data?.total}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td width="50%">
                <br />
                <table width="70%">
                  <tr>
                    <td>
                      <b>Other Comments:</b>
                    </td>
                  </tr>
                  <tr>
                    <td>{data?.comment}</td>
                  </tr>
                </table>
                <br />
                <table>
                  <tr>
                    <td>
                      <b style={{ color: '#0052CC' }}>
                        Thank You For Your Business!
                      </b>
                    </td>
                  </tr>
                </table>
              </td>
              <td />
            </tr>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  );
});
