import React, { useContext, useState, ChangeEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { ModalBox, ModalContent } from 'components/styles';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { useInventoryItemDetailsContext } from 'pages/inventory/item/details/index';
import { itemPalletTypeOption } from 'pages/receiving/receipts/context';
import {
  ItemUnitOfMeasureType,
  LotNoType,
} from 'pages/receiving/receipts/subpage';
import {
  adjustCustomerBinQty,
  getAdjustItemQuantity,
} from 'services/api/customerfacilities/customerfacilities.api';
import {
  GetReasonTransferData,
  getItemUnitOfMeasureDDL,
} from 'services/api/item/item.api';
import {
  createLotNo,
  getLotNo,
  getStatusArrival,
} from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import InfoIcon from '@mui/icons-material/Info';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Grid as MUIGrid,
  Button,
  InputAdornment,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import { MUIContainer, MUIContent, isNumberKey } from '../editreceiptmodal';

type AutocompleteBase = {
  label?: string;
  value?: number;
};

interface AdjustQtyBinModalProps {
  locationDetails?: any;
  itemDetails?: any;
}

type ExtendItemUnitOfMeasureType = {
  uomAbbr?: string;
  isPrimaryUOM?: boolean;
  qty?: number;
  primaryUOM?: number;
  primaryUOMStr?: string;
  primaryUOMAbbr?: string;
  uomType?: number;
  textInPerCase?: string;
  primaryTextInPerCase?: string;
  textInTotal?: string;
  primaryLength?: string;
  primaryWidth?: string;
  primaryHeight?: string;
  primaryWeight?: string;
};

type formProps = {
  customerId?: string;
  customerLocationId?: string;
  customerFacilityId?: string;
  itemId?: string;
  binId?: string;
  newQty?: number;
  lotId?: number;
  lotNo?: string;
  lotCreationDate?: string;
  lotExpirationDate?: string;
  status?: string;
  serialNo?: string;
  eachesPerCase?: number;
  totalCases?: number;
  receivedDate: string;
  palletType?: {
    value?: number;
    label?: string;
  };
  binItemTypeStr?: string;
} & ExtendItemUnitOfMeasureType;

export default React.memo(
  ({ locationDetails, itemDetails }: AdjustQtyBinModalProps) => {
    const [loadingPage, setLoadingPage] = useState<boolean>(false);

    const getLocation = useLocation();
    const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
    const {
      handleUpdateData,
      isAdjustModalOpen,
      addLotNoModalData,
      onCloseAdjustModal,
      setAddLotNoModalData,
      setIsAddLotNoModalOpen,
      isAddLotNoUpdateOptions,
      setIsAddLotNoUpdateOptions,
    } = useContext(GlobalContext);
    const { formValues } = useInventoryItemDetailsContext();
    const [optionLotNo, setOptionLotNo] = useState<LotNoType[]>([]);
    const [lotNoValue, setLotNoValue] = useState<LotNoType>(null);
    const [showErrors, setShowErrors] = useState(false);
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState(null);

    const initialState: formProps = {
      customerId: currentUser.Claim_CustomerId,
      customerLocationId: locationDetails.customerLocationId,
      customerFacilityId: locationDetails.customerFacilityId,
      itemId: itemDetails,
      binId: locationDetails.binId,
      newQty: locationDetails.availableQty,
      lotId: locationDetails.lotId,
      lotNo: locationDetails.lotNo,
      lotCreationDate: locationDetails.lotCreationDate,
      lotExpirationDate: locationDetails.expirationDate,
      status: locationDetails.status,
      serialNo: locationDetails.serialNo,
      eachesPerCase: locationDetails?.itemUOMUnitsPerItem,
      totalCases: Math.floor(
        locationDetails.availableQty /
          (locationDetails?.itemUOMUnitsPerItem ?? 0),
      ),
      receivedDate: locationDetails?.receiptDate,
      binItemTypeStr: locationDetails?.binItemTypeStr,
      palletType: locationDetails?.receiptLineItemPalletTypeStr
        ? itemPalletTypeOption.find(
            (o) => o.label === locationDetails?.receiptLineItemPalletTypeStr,
          )
        : null,
    };
    const [form, setForm] = useState(initialState);
    const [optionReasonValue, setOptionReasonValue] = useState(null);
    const [optionReasons, setOptionReasons] = useState([]);

    const [optionItemStatusValue, setOptionItemStatusValue] = useState(null);
    const [optionItemStatuses, setOptionItemStatuses] = useState([]);

    // UOM -----------------------------------------------------------
    const [optionsUOM, setOptionsUOM] = useState<ItemUnitOfMeasureType[]>([]);
    const [uomValue, setUomValue] = useState<ItemUnitOfMeasureType>(null);

    // ----------------------------------------------------------------

    const onForm = (key, text) => {
      setForm(() => ({
        ...form,
        [key]: text,
      }));
    };

    const onLoadReasonsDetails = async () => {
      try {
        const transferReasonsFromApi = await GetReasonTransferData();

        return transferReasonsFromApi;
      } catch (err) {
        return err;
      }
    };

    const onLoadStatusOnArrival = async () => {
      try {
        return await getStatusArrival();
      } catch (err) {
        return err;
      }
    };

    const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
      onForm(key, event.target.value);

    const handleCloseAdjustModal = () => {
      setForm({
        customerId: '',
        customerLocationId: '',
        customerFacilityId: '',
        itemId: '',
        binId: '',
        newQty: null,
        lotId: null,
        lotNo: '',
        lotCreationDate: null,
        lotExpirationDate: null,
        status: '',
        serialNo: '',
        receivedDate: null,
      });
      setFormErrors(null);
      onCloseAdjustModal();
    };

    const validateAdjustInventory = () => {
      const temp: any = {};

      temp.customerId = form.customerId ? '' : 'This field is required';
      temp.customerLocationId = form.customerLocationId
        ? ''
        : 'This field is required';
      temp.customerFacilityId = form.customerFacilityId
        ? ''
        : 'This field is required';
      temp.itemId = form.itemId ? '' : 'This field is required';
      temp.binId = form.binId ? '' : 'This field is required';
      temp.uomValue = uomValue ? '' : 'This field is required';

      // other uom
      if (locationDetails?.itemUnitOfMeasureId) {
        temp.totalCases = form.totalCases ? '' : 'This field is required';

        // if (Number(form.totalCases) <= 0) {
        //   temp.totalCases = 'Must be greater than zero';
        // }

        temp.eachesPerCase = form.eachesPerCase ? '' : 'This field is required';

        if (Number(form.eachesPerCase) <= 0) {
          temp.eachesPerCase = 'Must be greater than zero';
        }
      }
      // primary uom
      else {
        temp.newQty = form.newQty ? '' : 'This field is required';
      }

      if (form.serialNo === undefined) {
        form.serialNo = '';
      }

      if (
        formValues.inventorySettings.isSerialNo &&
        form.serialNo !== '' &&
        form.newQty > 1
      ) {
        temp.newQty =
          'Qty value should be 1. Because this sku is using serial no.';
      }

      if (form.lotNo === undefined || form.lotNo === null) {
        form.lotNo = '';
      }

      if (form.lotNo !== '') {
        if (
          form.lotCreationDate === null ||
          form.lotCreationDate === undefined ||
          form.lotCreationDate === ''
        ) {
          temp.lotNo = 'Lot creation date is required.';
        }
      }

      if (optionItemStatusValue?.value < 5) {
        temp.reasonStatus = optionReasonValue ? '' : 'This field is required';
      } else {
        temp.reasonStatus = '';
      }

      setFormErrors({
        ...temp,
      });

      return Object.values(temp).every((x) => x === '');
    };

    // SAVE ADJUST INVENTORY --------------------------------------------------
    const onAdjustBinQty = async () => {
      if (!validateAdjustInventory()) {
        return;
      }
      setLoadingPage(true);
      try {
        const payload = {
          customerLocationId: form.customerLocationId,
          customerFacilityId: form.customerFacilityId,
          inventoryBinDetailId: locationDetails.inventoryBinDetailId,
          totalCases: form.totalCases,
          eachesPerCase: form.eachesPerCase,
          newQty: form.newQty,
          lotId: form.lotId,
          lotNo: form.lotNo,
          lotCreationDate: form.lotCreationDate
            ? moment(form.lotCreationDate).format('MM/DD/YY')
            : '',
          expirationDate: form.lotExpirationDate
            ? moment(form.lotExpirationDate).format('MM/DD/YY')
            : '',
          reason:
            optionReasonValue && optionReasonValue?.value
              ? optionReasonValue.value
              : '',
          status: optionItemStatusValue.value,
          serialNo: form.serialNo,
          receivedDate: form.receivedDate
            ? moment(form.receivedDate).format('MM/DD/YY')
            : '',
          itemPalletType: form?.palletType?.value,
        };

        await adjustCustomerBinQty(payload);
        snackActions.success(`Successfully adjust the quantity.`);
        setForm(initialState);
        setFormErrors(null);
        setOptionReasonValue(null);
        handleCloseAdjustModal();
        handleUpdateData();
      } catch (err: any) {
        setError(err);
        setFormErrors(null);
        setShowErrors(true);
        snackActions.error(`${err}`);
      }
      setLoadingPage(false);
    };

    const onLoadGetLotNoOnChange = async (itemId) => {
      try {
        return await getLotNo(itemId);
      } catch (err) {
        return err;
      }
    };

    useEffect(() => {
      setForm(initialState);
      setLotNoValue((prevState) => ({
        ...prevState,
        id: initialState.lotId,
        label: initialState.lotNo,
        lotCreationDate: initialState.lotCreationDate,
        lotExpirationDate: initialState.lotExpirationDate,
      }));

      onLoadReasonsDetails()
        .then((x) => {
          const { reasonDDL } = x;
          setOptionReasons(
            reasonDDL?.map((dropdown) => ({
              value: dropdown.value,
              label: dropdown.text,
            })),
          );
        })
        .catch((err) => snackActions.error(err));

      onLoadStatusOnArrival()
        .then((opt) => {
          setOptionItemStatuses(
            opt?.map((dropdown) => ({
              value: dropdown.id,
              label: dropdown.name,
            })),
          );
        })
        .catch((err) => snackActions.error(err));

      onLoadGetLotNoOnChange(itemDetails).then((x) => {
        setOptionLotNo([]);
        x.map((lot) =>
          setOptionLotNo((prev) => [
            ...prev,
            {
              value: lot.lotId,
              label: lot.lotNo,
              lotCreationDate: lot.creationDate,
              lotExpirationDate: lot.expirationDate,
            },
          ]),
        );
      });
    }, [itemDetails, locationDetails]);

    const { status } = form;
    useEffect(() => {
      if (optionItemStatuses.length !== 0) {
        const result = optionItemStatuses.find((o) => o.value === status);
        if (result !== undefined) {
          setOptionItemStatusValue(result);
        }
      }
    }, [optionItemStatuses, status]);

    useEffect(() => {
      async function fetchData() {
        if (isAdjustModalOpen) {
          await getItemUnitOfMeasureDDL(itemDetails).then((x) => {
            setOptionsUOM([]);
            x.map((uom) =>
              setOptionsUOM((prev) => [
                ...prev,
                {
                  value: uom.value,
                  label: uom.text,
                  uomAbbr: uom.uomAbbr,
                  isPrimaryUOM: uom.isPrimaryUOM,
                  qty: uom.qty,
                  primaryUOM: uom.primaryUOM,
                  primaryUOMStr: uom.primaryUOMStr,
                  primaryUOMAbbr: uom.primaryUOMAbbr,
                  uomType: uom.uomType,
                  textInPerCase: uom.textInPerCase,
                  primaryTextInPerCase: uom.primaryTextInPerCase,
                  textInTotal: uom.textInTotal,
                  primaryLength: uom.primaryLength,
                  primaryWidth: uom.primaryWidth,
                  primaryHeight: uom.primaryHeight,
                  primaryWeight: uom.primaryWeight,
                },
              ]),
            );
          });
        }
      }
      fetchData();
    }, [isAdjustModalOpen]);

    useEffect(() => {
      if (optionsUOM.length > 0) {
        const uom = optionsUOM.find((x) =>
          locationDetails?.itemUnitOfMeasureId
            ? x.value === locationDetails.itemUnitOfMeasureId
            : x.isPrimaryUOM,
        );

        if (uom !== undefined) {
          setUomValue((prev) => ({
            ...prev,
            value: uom.value,
            label: uom.label,
            uomAbbr: uom.uomAbbr,
            isPrimaryUOM: uom.isPrimaryUOM,
            qty: uom.qty,
            primaryUOM: uom.primaryUOM,
            primaryUOMAbbr: uom.primaryUOMAbbr,
            textInTotal: uom.textInTotal,
            primaryTextInPerCase: uom.primaryTextInPerCase,
            textInPerCase: uom.textInPerCase,
            primaryLength: uom.primaryLength,
            primaryWidth: uom.primaryWidth,
            primaryHeight: uom.primaryHeight,
            primaryWeight: uom.primaryWeight,
          }));
        }
      }
    }, [optionsUOM]);

    useEffect(() => {
      if (isAddLotNoUpdateOptions) {
        onLoadGetLotNoOnChange(itemDetails).then((x) => {
          setOptionLotNo([]);
          x.map((lot) =>
            setOptionLotNo((prev) => [
              ...prev,
              {
                value: lot.lotId,
                label: lot.lotNo,
                lotCreationDate: lot.creationDate,
                lotExpirationDate: lot.expirationDate,
              },
            ]),
          );
        });

        setLotNoValue((prev) => ({
          ...prev,
          lotId: addLotNoModalData?.value,
          label: addLotNoModalData?.label,
          value: addLotNoModalData?.value,
        }));

        setForm((prevState) => ({
          ...prevState,
          lotId: addLotNoModalData?.value,
          lotNo: addLotNoModalData?.label,
          lotCreationDate: addLotNoModalData?.lotCreationDate,
          lotExpirationDate: addLotNoModalData?.lotExpirationDate,
        }));
      }
    }, [isAddLotNoUpdateOptions]);

    if (!isAdjustModalOpen) return null;

    return (
      <Modal open={isAdjustModalOpen} onClose={() => handleCloseAdjustModal()}>
        <MUIContainer>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Adjust Item Quantity
          </Typography>
          <MUIContent sx={{ padding: '0px 20px !important' }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Change known quantities for item{' '}
              {getLocation.pathname.split('/')[3]} in {locationDetails.bin}.
            </Typography>

            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  // disabled={modalTitle === ModalTitleStatus.AddHold}
                  onChange={(event: any, newValue: ItemUnitOfMeasureType) => {
                    if (newValue !== null) {
                      setUomValue(newValue);
                      if (newValue.isPrimaryUOM) {
                        setForm((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.value,
                          primaryUOMAbbr: newValue.uomAbbr,
                          itemUnitOfMeasureId: null,
                          otherUOMStr: '',
                          otherUOMAbbr: '',
                          otherUOMCount: null,
                          totalCases: null,
                          eachesPerCase: null,
                          qty: null,
                          qtyOfPrimaryOUMPerLP: null,
                          primaryLength: newValue.primaryLength,
                          primaryWidth: newValue.primaryWidth,
                          primaryHeight: newValue.primaryHeight,
                          primaryWeight: newValue.primaryWeight,
                        }));
                      } else {
                        setForm((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.primaryUOM,
                          primaryUOMAbbr: newValue.primaryUOMAbbr,
                          primaryTextInPerCase: newValue.primaryTextInPerCase,
                          itemUnitOfMeasureId: newValue.value,
                          otherUOMStr: newValue.label,
                          otherUOMAbbr: newValue.uomAbbr,
                          otherUOMCount: newValue.qty,
                          eachesPerCase: newValue.qty ? newValue.qty : 0,
                          totalCases: null,
                          qty: null,
                          qtyOfPrimaryOUMPerLP: null,
                          primaryLength: newValue.primaryLength,
                          primaryWidth: newValue.primaryWidth,
                          primaryHeight: newValue.primaryHeight,
                          primaryWeight: newValue.primaryWeight,
                        }));
                      }
                    } else {
                      setUomValue(null);
                      setForm((prevState) => ({
                        ...prevState,
                        primaryUOM: null,
                        primaryUOMAbbr: '',
                        itemUnitOfMeasureId: '',
                        otherUOMStr: '',
                        otherUOMAbbr: '',
                        otherUOMCount: null,
                        totalCases: null,
                        qty: null,
                        qtyOfPrimaryOUMPerLP: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionsUOM}
                  getOptionLabel={(option: ItemUnitOfMeasureType) =>
                    option.label
                  }
                  value={uomValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: ItemUnitOfMeasureType) => (
                    <li
                      {...props}
                      key={option?.value}
                      data-uomabbr={option?.uomAbbr}
                      data-isprimaryuom={option?.isPrimaryUOM}
                      data-qty={option?.qty}
                      data-primaryuom={option?.primaryUOM}
                      data-primaryuomstr={option?.primaryUOMStr}
                      data-primaryuomabbr={option?.primaryUOMAbbr}
                    >
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      required
                      id="outlined-required"
                      label="Units Of Measure"
                      size="small"
                      sx={{ width: '100%' }}
                      {...(formErrors?.uomValue && {
                        error: true,
                        helperText: formErrors?.uomValue,
                      })}
                    />
                  )}
                  disabled
                />
              </MUIGrid>
              {uomValue && !uomValue?.isPrimaryUOM && (
                <>
                  <MUIGrid item xs={2.7}>
                    <TextField
                      disabled={uomValue?.isPrimaryUOM}
                      required={uomValue?.isPrimaryUOM === false}
                      label={`Total ${uomValue?.textInTotal}`}
                      value={form?.totalCases}
                      onChange={(event: any) => {
                        if (isNumberKey(event)) {
                          setForm((prevState) => ({
                            ...prevState,
                            totalCases: event.target.value,
                            newQty:
                              event.target.value *
                              (prevState.eachesPerCase
                                ? prevState.eachesPerCase
                                : 0),
                          }));
                        }
                      }}
                      {...(formErrors?.totalCases && {
                        error: true,
                        helperText: formErrors?.totalCases,
                      })}
                      id="outlined"
                      type="number"
                      size="small"
                      minRows={0}
                      sx={{ width: '100%' }}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <h5>
                                  Total quantity (i.e. qty of boxes, bags,
                                  cases)
                                </h5>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: 'white ',
                                    backgroundColor: 'rgb(46, 105, 255)',
                                  },
                                },
                              }}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={2.7}>
                    <TextField
                      disabled={uomValue?.isPrimaryUOM}
                      required={uomValue?.isPrimaryUOM === false}
                      value={form?.eachesPerCase}
                      label={`${uomValue?.primaryTextInPerCase} Per ${uomValue?.textInPerCase}`}
                      onChange={(event: any) => {
                        if (isNumberKey(event)) {
                          setForm((prevState) => ({
                            ...prevState,
                            eachesPerCase: event.target.value,
                            newQty:
                              event.target.value *
                              (prevState.totalCases ? prevState.totalCases : 0),
                          }));
                        }
                      }}
                      minRows={0}
                      id="outlined"
                      size="small"
                      type="number"
                      sx={{ width: '100%' }}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={<h5>Number of pieces per case</h5>}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    color: 'white ',
                                    backgroundColor: 'rgb(46, 105, 255)',
                                  },
                                },
                              }}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      {...(formErrors?.eachesPerCase && {
                        error: true,
                        helperText: formErrors?.eachesPerCase,
                      })}
                    />
                  </MUIGrid>
                </>
              )}
              {uomValue && (
                <MUIGrid item xs={uomValue?.isPrimaryUOM === false ? 2.6 : 4}>
                  <TextField
                    type="number"
                    minRows={0}
                    disabled={uomValue?.isPrimaryUOM === false}
                    required={uomValue?.isPrimaryUOM}
                    value={form?.newQty}
                    label={`Total Qty (${
                      uomValue?.primaryUOMAbbr
                        ? uomValue?.primaryUOMAbbr
                        : uomValue?.uomAbbr
                    })`}
                    onChange={(event: any) => {
                      setForm((prevState) => ({
                        ...prevState,
                        newQty: event.target.value,
                      }));
                    }}
                    {...(formErrors?.newQty && {
                      error: true,
                      helperText: formErrors?.newQty,
                    })}
                    id="outlined-required"
                    size="small"
                    sx={{ width: '100%' }}
                    InputProps={{
                      autoComplete: 'off',
                    }}
                  />
                </MUIGrid>
              )}
            </MUIGrid>

            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={2.5}>
                <Autocomplete
                  value={lotNoValue}
                  options={optionLotNo}
                  getOptionLabel={(option: LotNoType) => option.label}
                  defaultValue={optionLotNo?.find(
                    (o: LotNoType) => o.label === lotNoValue?.label,
                  )}
                  size="small"
                  sx={{ width: '100%' }}
                  onChange={(event: any, newValue: LotNoType) => {
                    if (newValue !== null) {
                      setLotNoValue(newValue);
                      setForm((prevState) => ({
                        ...prevState,
                        lotId: newValue.value,
                        lotNo: newValue.label,
                        lotCreationDate: newValue?.lotCreationDate,
                        lotExpirationDate: newValue?.lotExpirationDate,
                      }));
                    } else {
                      setLotNoValue(null);
                      setForm((prevState) => ({
                        ...prevState,
                        lotId: null,
                        lotNo: '',
                        lotCreationDate: null,
                        lotExpirationDate: null,
                      }));
                    }
                  }}
                  renderOption={(props, option: LotNoType) => (
                    <li {...props} key={option.value}>
                      <Box display="flex">
                        <Box display="flex" flexDirection="column">
                          <Typography color="text.primary">
                            {option.label}
                          </Typography>
                          <Typography color="text.secondary" variant="body2">
                            {option.lotExpirationDate &&
                              moment(option.lotExpirationDate).format(
                                'MM/DD/YY',
                              )}
                          </Typography>
                        </Box>
                      </Box>
                    </li>
                  )}
                  isOptionEqualToValue={(
                    option: LotNoType,
                    selected: LotNoType,
                  ) => option.value === selected.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      onChange={(e) => {
                        setForm((prevState) => ({
                          ...prevState,
                          lotNo: e.target.value,
                        }));
                      }}
                      id="outlined"
                      label="Lot No."
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item md={1.5} sx={{ paddingLeft: '10px !important' }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setAddLotNoModalData((prevState) => ({
                      ...prevState,
                      value: null,
                      label: '',
                      lotId: null,
                      lotCreationDate: null,
                      lotExpirationDate: null,
                    }));
                    setIsAddLotNoModalOpen(true);
                    setIsAddLotNoUpdateOptions(false);
                  }}
                  sx={{
                    padding: '8px !important',
                    width: '100%',
                    textTransform: 'capitalize',
                  }}
                >
                  + New LOT NO
                </Button>
              </MUIGrid>

              {formValues.inventorySettings.isSerialNo && (
                <MUIGrid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <Input
                      sx={{ width: '100% ' }}
                      type="text"
                      placeholder="Serial No."
                      value={form.serialNo}
                      onChange={(value) => inputHandler('serialNo', value)}
                      // onKeyDown={(e) => {
                      //   if (e.key === 'Enter') {
                      //     onAdjustBinQty();
                      //   }
                      // }}
                    />
                  </Box>
                </MUIGrid>
              )}

              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  onChange={(event: any, newValue: any) => {
                    if (newValue !== null) {
                      setOptionItemStatusValue(newValue);
                    } else {
                      setOptionItemStatusValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={optionItemStatuses.filter((e) => e.value !== 5)}
                  getOptionLabel={(option: any) => option.label}
                  value={optionItemStatusValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option?.id}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      id="outlined-required"
                      label="Status"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Autocomplete
                  onChange={(event: any, newValue: any) => {
                    if (newValue !== null) {
                      setOptionReasonValue(newValue);
                    } else {
                      setOptionReasonValue(null);
                    }
                  }}
                  id="controllable-states"
                  options={optionReasons}
                  getOptionLabel={(option: any) => option.label}
                  value={optionReasonValue || null}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      id="outlined-required"
                      label="Reason"
                      size="small"
                      sx={{ width: '100%' }}
                      required
                      {...(formErrors?.reasonStatus && {
                        error: true,
                        helperText: formErrors?.reasonStatus,
                      })}
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <DatePickerInput
                  sx={{ width: '100%', background: 'white' }}
                  inputFormat="MM/dd/yyyy"
                  value={form?.lotExpirationDate || null}
                  label="Lot Expiration Date"
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid date') {
                      setForm((prevState) => ({
                        ...prevState,
                        lotExpirationDate: e,
                      }));
                    } else {
                      setForm((prevState) => ({
                        ...prevState,
                        lotExpirationDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      error={false}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <DatePickerInput
                  sx={{ width: '100%', background: 'white' }}
                  inputFormat="MM/dd/yyyy"
                  value={form?.receivedDate || null}
                  label="Received Date"
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid date') {
                      setForm((prevState) => ({
                        ...prevState,
                        receivedDate: e,
                      }));
                    } else {
                      setForm((prevState) => ({
                        ...prevState,
                        receivedDate: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      error={false}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>
              {form?.binItemTypeStr === 'Pallet' && (
                <MUIGrid item xs={4}>
                  <Autocomplete
                    onChange={(event: any, newValue: AutocompleteBase) => {
                      if (newValue !== null) {
                        setForm((prevState) => ({
                          ...prevState,
                          palletType: newValue,
                        }));
                      } else {
                        setForm((prevState) => ({
                          ...prevState,
                          palletType: null,
                        }));
                      }
                    }}
                    id="controllable-states"
                    options={itemPalletTypeOption}
                    value={form?.palletType || null}
                    getOptionLabel={(option: AutocompleteBase) => option.label}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        id="outlined"
                        label="Pallet Type"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
              )}
            </MUIGrid>
            <Typography variant="subtitle2" color="#d32f2f">
              {error}
            </Typography>
          </MUIContent>
          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '10px',
                  padding: '0px',
                  justifyContent: 'flex-end',
                }}
              >
                {loadingPage ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ padding: 1 }}
                      onClick={() => handleCloseAdjustModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        marginRight: '10px',
                        backgroundColor: ' rgb(75, 126, 254) !important',
                      }}
                      onClick={() => onAdjustBinQty()}
                    >
                      Update
                    </Button>
                  </>
                )}
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    );
  },
);
