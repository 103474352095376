import React, { useState } from 'react';

import CardTab from 'components/card/card-tab';
import Header from 'components/header';
import { IntegrationsInterfacePage } from 'pages/ordercustomers/warehousecustomer';
import {
  CustomerType,
  NewRulesManagementInterface,
} from 'pages/ordercustomers/warehousecustomer/customer-rules-management';
import { MainContainer } from 'pages/styles';

import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import {
  Typography,
  Box,
  Grid as MUIGrid,
  Button,
  Divider,
} from '@mui/material';

import {
  OrderSourceRuleSettingsContextProvider,
  useOrderSourceRuleSettingsContext,
} from './context-order-source-rule';
import CarriersSection from './tabviews/CarriersSection';
import CartonCodes from './tabviews/CartonCodes';
import CompanyProfile from './tabviews/CompanyProfile';
import InventorySettings from './tabviews/InventorySettings';
import PrinterSettings from './tabviews/PrinterSettings';

function CartonCodesCarrier() {
  return (
    <>
      <CartonCodes />
      <Divider />
      <CarriersSection />
    </>
  );
}

function TabPageSettings() {
  const {
    formErrors,
    formSetting,
    isShowRulesFormSettings,
    orderAIRuleDetailSetting,
    isManageClientDefaultSetting,
    setFormSetting,
    setIsShowRulesFormSettings,
    setOrderAIRuleDetailSetting,
    setIsManageClientDefaultSetting,
    handleSaveUpdateCustomerRulesManagement,
  } = useOrderSourceRuleSettingsContext();

  const [view, setView] = useState('1');
  const handleTabOnChange = (event: React.SyntheticEvent, newValue: string) => {
    setView(newValue);

    if (newValue === '7') {
      setIsShowRulesFormSettings(false);
    }
  };

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={12}>
        <CardTab
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            margin: '21px',
            marginBottom: 1,
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #cdcdcd',
          }}
          tabValue={view}
          handleChange={handleTabOnChange}
          tabView={[
            {
              title: 'Company Details',
              value: '1',
              isDisplay: true,
              children: <CompanyProfile />,
            },
            {
              title: 'Inventory Settings',
              value: '2',
              isDisplay: false,
              children: <InventorySettings />,
            },
            {
              title: 'Packaging, Void Fill & Carriers',
              value: '3',
              isDisplay: true,
              children: CartonCodesCarrier(),
            },
            {
              title: 'Integrations',
              value: '4',
              isDisplay: true,
              children: <IntegrationsInterfacePage isShowQuickBookEhubXero />,
            },
            {
              title: 'Printer Settings',
              value: '5',
              isDisplay: true,
              children: <PrinterSettings />,
            },
            {
              title: 'Rules Management',
              value: '6',
              isDisplay: true,
              children: (
                <NewRulesManagementInterface
                  customerType={CustomerType.Customer}
                  formRules={formSetting}
                  setFormRules={setFormSetting}
                  formErrors={formErrors}
                  IsRulesManagementTab
                />
              ),
            },
            {
              title: (
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      textTransform: 'capitalize !important',
                    }}
                  >
                    Order AI Rules
                  </Typography>
                  <ElectricBoltIcon fontSize="medium" />
                </Box>
              ),
              value: '7',
              isDisplay: true,
              children: (
                <>
                  {(isManageClientDefaultSetting ||
                    isShowRulesFormSettings) && (
                    <Box sx={{ textAlign: 'right' }} p={3} pb={0}>
                      <Button
                        variant="outlined"
                        size="medium"
                        sx={{
                          borderColor: 'rgb(5, 47, 255)',
                          color: 'rgb(5, 47, 255)',
                          marginRight: '10px',
                        }}
                        onClick={() => {
                          setIsShowRulesFormSettings(false);
                          setIsManageClientDefaultSetting(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={handleSaveUpdateCustomerRulesManagement}
                      >
                        Save
                      </Button>
                    </Box>
                  )}
                  {isManageClientDefaultSetting ? (
                    <NewRulesManagementInterface
                      customerType={CustomerType.Customer}
                      formRules={formSetting}
                      setFormRules={setFormSetting}
                      formErrors={formErrors}
                    />
                  ) : (
                    <NewRulesManagementInterface
                      customerType={CustomerType.Customer}
                      formRules={orderAIRuleDetailSetting}
                      setFormRules={setOrderAIRuleDetailSetting}
                      formErrors={formErrors}
                      IsOrderAIRules
                      orderSourceRulesDataRow={
                        formSetting?.orderSourceRulesDataRow
                      }
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </MUIGrid>
    </MUIGrid>
  );
}

function NewSettings() {
  return (
    <MainContainer>
      <Header />
      <OrderSourceRuleSettingsContextProvider>
        <TabPageSettings />
      </OrderSourceRuleSettingsContextProvider>
    </MainContainer>
  );
}

export default React.memo(NewSettings);
