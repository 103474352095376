import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const getPutAwayQueue = async (
  customerLocationId,
  customerFacilityId,
  warehouseCustomerId,
) => {
  try {
    const response = await httpClient.get(
      `/PutAway/Queue/${customerLocationId}/${customerFacilityId}`,
      {
        params: {
          warehouseCustomerId,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createPutAwayBin = async (form) => {
  try {
    const response = await httpClient.post(
      `/PutAway/Bin/CreatePutAwayBin`,
      form,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const createPutAwayPallet = async (form) => {
  try {
    const response = await httpClient.post(
      `/PutAway/Bin/CreatePutAwayBin/Pallet`,
      form,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPutAwayPickingAndTransferCountNotComplete = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `/PutAway/GetPutAwayPickingAndTransferCountNotComplete/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
