import React, { useCallback, useContext, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/button';
import Card from 'components/card';
import DatePickerInput from 'components/datepicker';
import Footer from 'components/footer';
import Header from 'components/header';
import moment from 'moment';
import { SinglePrintLicensePlate } from 'pages/licenseplates/lp-print';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import ProductionLicensePlateModal from 'pages/shared/productionlicenseplatenumbermodal';
import ProductionPickTicketModal from 'pages/shared/productionpickticketmodal';
import ProductionReportModal from 'pages/shared/productionreportmodal';
import { getLicensePlateToPrint } from 'services/api/licensePlates/licensePlates-new.api';
import { getProductionKitSkuByWarehouseCustomerId } from 'services/api/production/production.api';
import { getUserOperators } from 'services/api/user/users.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PrintIcon from '@mui/icons-material/Print';
import {
  Typography,
  TextField,
  Box,
  Grid as MUIGrid,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColumns, DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import { MainContainer, ContentContainer } from '../../styles';
import {
  ProductionContextProvider,
  useProductionContext,
  CustomerType,
  JobLocationType,
  isNumberKey,
  KitSkuType,
  JobStatus,
} from '../context';

const JobDetails = () => {
  const {
    isFormDisable,
    pageViewer,
    productionForm,
    productionFormErrors,
    optionCustomers,
    optionCustomerValue,
    optionJobLocations,
    optionJobLocationValue,
    setOptionCustomerValue,
    setOptionJobLocationValue,
    setProductionForm,
    setOptionKitSkus,
    productionFormOnChange,
    setOptionKitSkuValue,
  } = useProductionContext();

  const getProductionKitSkusByWarehouseCustomer = async (
    warehouseCustomerId,
  ) => {
    try {
      const kitDetailsFromApi = await getProductionKitSkuByWarehouseCustomerId(
        warehouseCustomerId,
      );
      return kitDetailsFromApi;
    } catch (err) {
      return err;
    }
  };

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="h6" fontWeight="bold" mb={2}>
        Job Details
      </Typography>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={2.3}>
          <Autocomplete
            disabled={pageViewer.isViewOrEdit()}
            onChange={(event: any, newValue: CustomerType) => {
              if (newValue !== null) {
                setOptionCustomerValue(newValue);
                setOptionKitSkuValue(null);
                getProductionKitSkusByWarehouseCustomer(newValue?.value).then(
                  (x) => {
                    setOptionKitSkus(
                      x.map((c: any) => ({
                        label: c.sku,
                        value: c.itemId,
                      })),
                    );
                  },
                );
              } else {
                setOptionCustomerValue(null);
              }
            }}
            id="controllable-states"
            options={optionCustomers}
            getOptionLabel={(option: CustomerType) => option.label}
            value={optionCustomerValue}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                disabled={isFormDisable}
                required
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                {...(productionFormErrors.customer && {
                  error: true,
                  helperText: productionFormErrors.customer,
                })}
                autoComplete="off"
                id="outlined-required"
                label="Client"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled
            onChange={productionFormOnChange}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            required
            id="outlined-required"
            label="Job No."
            name="jobNo"
            value={productionForm?.jobNo || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled={isFormDisable}
            onChange={productionFormOnChange}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Job Reference No."
            name="jobReferenceNo"
            value={productionForm?.jobReferenceNo || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled={isFormDisable}
            onChange={productionFormOnChange}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="PO No."
            name="purchaseOrderNo"
            value={productionForm?.purchaseOrderNo || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <Autocomplete
            disabled={isFormDisable}
            onChange={(event: any, newValue: CustomerType) => {
              if (newValue !== null) {
                setOptionJobLocationValue(newValue);
              } else {
                setOptionJobLocationValue(null);
              }
            }}
            id="controllable-states"
            options={optionJobLocations}
            getOptionLabel={(option: JobLocationType) => option.label}
            value={optionJobLocationValue}
            isOptionEqualToValue={(option, selected) =>
              option.value === selected.value
            }
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                required
                id="outlined-required"
                {...(productionFormErrors.jobLocation && {
                  error: true,
                  helperText: productionFormErrors.jobLocation,
                })}
                label="Job Location"
                size="small"
                sx={{ width: '100%' }}
              />
            )}
          />
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled={isFormDisable}
            onChange={(e: any) => {
              if (isNumberKey(e)) productionFormOnChange(e);
            }}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Price Per Kit"
            name="pricePerKit"
            value={productionForm?.pricePerKit || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <DatePickerInput
            disabled={isFormDisable}
            label="Start Date"
            inputFormat="MM/dd/yyyy"
            onChange={(e) => {
              if (e !== null && e !== 'Invalid Date') {
                if (e instanceof Date) {
                  setProductionForm((prevState) => ({
                    ...prevState,
                    startDate: e,
                  }));
                }
              } else {
                setProductionForm((prevState) => ({
                  ...prevState,
                  startDate: null,
                }));
              }
            }}
            value={productionForm?.startDate || ''}
            renderInput={(params) => (
              <TextField {...params} error={false} size="small" />
            )}
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <DatePickerInput
            disabled={isFormDisable}
            label="End Date"
            inputFormat="MM/dd/yyyy"
            onChange={(e) => {
              if (e !== null && e !== 'Invalid Date') {
                if (e instanceof Date) {
                  setProductionForm((prevState) => ({
                    ...prevState,
                    endDate: e,
                  }));
                }
              } else {
                setProductionForm((prevState) => ({
                  ...prevState,
                  endDate: null,
                }));
              }
            }}
            value={productionForm?.endDate || ''}
            renderInput={(params) => (
              <TextField disabled {...params} error={false} size="small" />
            )}
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <DatePickerInput
            disabled={isFormDisable}
            label="Ship Date"
            inputFormat="MM/dd/yyyy"
            onChange={(e) => {
              if (e !== null && e !== 'Invalid Date') {
                if (e instanceof Date) {
                  setProductionForm((prevState) => ({
                    ...prevState,
                    shipDate: e,
                  }));
                }
              } else {
                setProductionForm((prevState) => ({
                  ...prevState,
                  shipDate: null,
                }));
              }
            }}
            value={productionForm?.shipDate || ''}
            renderInput={(params) => (
              <TextField
                required
                id="outlined-required"
                {...params}
                {...(productionFormErrors.shipDate && {
                  error: true,
                  helperText: productionFormErrors.shipDate,
                })}
                error={productionFormErrors.shipDate && true}
                size="small"
              />
            )}
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled={isFormDisable}
            onChange={(e: any) => {
              if (isNumberKey(e)) productionFormOnChange(e);
            }}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Kits per Pick"
            name="kitsPerPick"
            value={productionForm?.kitsPerPick || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled={isFormDisable}
            onChange={(e: any) => {
              if (isNumberKey(e)) productionFormOnChange(e);
            }}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Estimated Kits per Shift"
            name="estimatedKitsPerShift"
            value={productionForm?.estimatedKitsPerShift || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled={isFormDisable}
            onChange={(e: any) => {
              if (isNumberKey(e)) productionFormOnChange(e);
            }}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Estimated Hourly Production"
            name="estimatedHourlyProduction"
            value={productionForm?.estimatedHourlyProduction || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
        <MUIGrid item xs={2.3}>
          <TextField
            disabled={isFormDisable}
            onChange={(e: any) => {
              if (isNumberKey(e)) productionFormOnChange(e);
            }}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            required
            autoComplete="off"
            id="outlined-required"
            label="Total Job Quantity"
            name="totalJobQuantity"
            value={productionForm?.totalJobQuantity || ''}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
      </MUIGrid>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={6.9}>
          <TextField
            disabled={isFormDisable}
            onChange={productionFormOnChange}
            inputProps={{
              autoComplete: autoCompleteTimeStamp().toString(),
            }}
            autoComplete="off"
            id="outlined"
            label="Notes"
            name="notes"
            value={productionForm?.notes || ''}
            multiline
            rows={3}
            size="small"
            sx={{ width: '100%' }}
          />
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const KitDetails = () => {
  const {
    isFormDisable,
    productionFormErrors,
    optionKitSkus,
    optionKitSkuValue,
    kitDetailsLineItem,
    setOptionKitSkuValue,
    optionCustomerValue,
    productionPickTicketItems,
    productionForm,
  } = useProductionContext();

  const productionKitDetailsLineItemColumns: GridColumns = [
    {
      field: 'id',
      width: 100,
      hide: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Id</Typography>
        </Box>
      ),
    },
    {
      field: 'itemId',
      width: 100,
      hide: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Item Id</Typography>
        </Box>
      ),
    },
    {
      field: 'componentSKU',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Component SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'uomStr',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">UoM</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty/Kit</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="h6" fontWeight="bold" mb={2}>
        Kit Details
      </Typography>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <Card
            sx={{
              '&.MuiCard-root': {
                background: 'none',
                boxShadow: 'none',
                padding: '20px 0 0',
              },
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={2.3}>
                <Autocomplete
                  disabled={
                    isFormDisable ||
                    optionCustomerValue === null ||
                    productionForm.kitDetail_KitSKU_EnableForEdit
                  }
                  onChange={(event: any, newValue: KitSkuType) => {
                    if (newValue !== null) {
                      setOptionKitSkuValue(newValue);
                    } else {
                      setOptionKitSkuValue(null);
                    }
                  }}
                  options={optionKitSkus}
                  value={optionKitSkuValue}
                  getOptionLabel={(option: KitSkuType) =>
                    option.label ? option.label : ''
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      required
                      id="outlined-required"
                      {...(productionFormErrors.jobLocation && {
                        error: true,
                        helperText: productionFormErrors.jobLocation,
                      })}
                      autoComplete="off"
                      label="Kit SKU"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
          <Card
            sx={{
              '&.MuiCard-root': {
                background: 'none',
                boxShadow: 'none',
                padding: 0,
              },
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={12}>
                <DataGrid
                  autoHeight
                  headerHeight={120}
                  rows={kitDetailsLineItem}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  disableSelectionOnClick
                  columns={productionKitDetailsLineItemColumns}
                  rowsPerPageOptions={[25]}
                  pageSize={25}
                  getRowId={(row) => row.id}
                  sx={{
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                      display: 'none !important',
                    },
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const PickTickets = () => {
  const {
    productionPickTicketItems,
    getProductionPickTicketOnClick,
    editGetPickTicketLineItemListOnClick,
    setDataSelected,
    setIsPickTicketItemEdit,
    setOptionOperator,
    setOperatorValue,
  } = useProductionContext();
  const {
    onOpenPickTicketSalesOrderModal,
    onOpenPrintFormTemplateModal,
    onOpenConfirmDeleteDialog,
    handleUpdateData,
  } = useContext(GlobalContext);

  const [pickTicketIdPrint, setPickTicketIdPrint] = useState(0);

  const editPickTicketItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: number): Promise<void> => {
      e.preventDefault();
      onOpenPickTicketSalesOrderModal();
      editGetPickTicketLineItemListOnClick(id);
    },
    [],
  );

  const printPickTicketItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: any): Promise<void> => {
      e.preventDefault();
      setPickTicketIdPrint(id);
      onOpenPrintFormTemplateModal();
    },
    [pickTicketIdPrint],
  );

  const handleDeleteItem = (rowData) => {
    setDataSelected(rowData);
    setIsPickTicketItemEdit(true);
    onOpenConfirmDeleteDialog();
  };

  const pickTicketItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hideable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          disabled={!params.row.contextMenu_Edit_Enable}
          onClick={(e) => editPickTicketItemOnClick(e, params.row.pickTicketId)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Print"
          disabled={!params.row.contextMenu_Print_Enable}
          onClick={(e) =>
            printPickTicketItemOnClick(e, params.row.pickTicketId)
          }
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={!params.row.contextMenu_Delete_Enable}
          onClick={() => handleDeleteItem(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'pickId',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Pick ID</Typography>
        </Box>
      ),
    },
    {
      field: 'issued',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Issued</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.issued !== undefined
            ? moment(params?.row?.issued).format('MMM DD YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'statusStr',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Status</Typography>
        </Box>
      ),
    },
    {
      field: 'print',
      width: 70,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Print</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <IconButton
          aria-label="print"
          color="primary"
          onClick={(e) => {
            printPickTicketItemOnClick(e, params.row.pickTicketId);
          }}
        >
          <PrintIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ mr: 2, flexGrow: 1 }}
          variant="h6"
          fontWeight="bold"
          mb={2}
        >
          Pick Tickets
        </Typography>
        <Typography mb={2}>
          <Button
            sx={{ textTransform: 'capitalize !important' }}
            variant="outlined"
            size="large"
            onClick={(e) => {
              // editPickTicketItemOnClick(e, 0);
              onOpenPickTicketSalesOrderModal();
              getProductionPickTicketOnClick();

              getUserOperators()
                .then((opt) => {
                  setOptionOperator(
                    opt.map((c: any) => ({
                      label: c.text,
                      value: c.value,
                    })),
                  );
                })
                .catch((err) => console.log(err));

              setOperatorValue(null);
            }}
          >
            New Pick Ticket
          </Button>
        </Typography>
      </Box>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <Card
            sx={{
              '&.MuiCard-root': {
                background: 'none',
                boxShadow: 'none',
                padding: 0,
              },
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={12}>
                <DataGrid
                  autoHeight
                  headerHeight={120}
                  rows={productionPickTicketItems}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  disableSelectionOnClick
                  columns={pickTicketItemsColumns}
                  rowsPerPageOptions={[25]}
                  pageSize={25}
                  getRowId={(row) => row.pickId}
                  sx={{
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                      display: 'none !important',
                    },
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
      {pickTicketIdPrint !== 0 && (
        <Printformtemplate
          preview={false}
          dynamicId={pickTicketIdPrint}
          formtemplateTypeId={FormTemplateTypeEnum.PickTicket}
        />
      )}
    </Card>
  );
};

const ProductionReport = () => {
  const { onOpenProductionReportModal, onOpenConfirmDeleteDialog } =
    useContext(GlobalContext);
  const {
    productionReport,
    editGetProductionReportOnClick,
    setProductionReportModal,
  } = useProductionContext();

  const handleDeleteProductReport = (rowData) => {
    setProductionReportModal(rowData);
    onOpenConfirmDeleteDialog();
  };

  const productionReportItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          disabled={!params.row.contextMenu_Edit_Enable}
          onClick={() => {
            onOpenProductionReportModal();
            editGetProductionReportOnClick(params.row.jobProductionReportId);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={!params.row.contextMenu_Delete_Enable}
          onClick={() => handleDeleteProductReport(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'supervisor',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Supervisor</Typography>
        </Box>
      ),
    },
    {
      field: 'startDate',
      width: 170,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Start Date</Typography>
        </Box>
      ),
    },
    {
      field: 'completedDate',
      width: 170,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Completed Date</Typography>
        </Box>
      ),
    },
    {
      field: 'qtyCompleted',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty Completed</Typography>
        </Box>
      ),
    },
    {
      field: 'actualHourlyProduction',
      width: 220,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Actual Hourly Production</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box sx={{ display: 'flex', width: '100%', placeContent: 'center' }}>
          <Typography>{params.row.actualHourlyProduction}</Typography>
          {params.row.isUp && <ArrowUpwardIcon sx={{ color: '#13b300' }} />}
          {params.row.isDown && <ArrowDownwardIcon sx={{ color: '#f70a0a' }} />}
        </Box>
      ),
    },
  ];

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="h6" fontWeight="bold" mb={2}>
        Production Report
      </Typography>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <Card
            sx={{
              '&.MuiCard-root': {
                background: 'none',
                boxShadow: 'none',
                padding: 0,
              },
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={12}>
                <DataGrid
                  autoHeight
                  headerHeight={120}
                  rows={productionReport}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  disableSelectionOnClick
                  columns={productionReportItemsColumns}
                  rowsPerPageOptions={[25]}
                  pageSize={25}
                  getRowId={(row) => row.jobProductionReportId}
                  sx={{
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                      display: 'none !important',
                    },
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const LicensePlateNumbers = () => {
  const {
    productionLicensePlateItems,
    queryStringItemProduction,
    OnClickGetCreateLicensePlateKnownData,
    editGetProductionLicensePlateOnClick,
    editGetPutAwayOnClick,
    setProductionLicensePlateModal,
    setIsProductLicensePlateEdit,
  } = useProductionContext();
  const {
    onOpenProductionLicensePlateModal,
    onOpenProductionLicensePlatePutAwayModal,
    onOpenConfirmDeleteDialog,
  } = useContext(GlobalContext);

  const [printOfLPItem, setPrintOfLPItem] = useState<any>('');

  const componentSinglePrintRef = useRef(null);
  const buttonSinglePrintRef = useRef(null);

  // const editPickTicketItemOnClick = useCallback(
  //   async (e: React.SyntheticEvent, id: number): Promise<void> => {
  //     e.preventDefault();
  //     onOpenPickTicketSalesOrderModal();
  //     editGetPickTicketLineItemListOnClick(id);
  //   },
  //   [],
  // );

  // const printPickTicketItemOnClick = useCallback(
  //   async (e: React.SyntheticEvent, id: any): Promise<void> => {
  //     e.preventDefault();
  //     setPickTicketIdPrint(id);
  //     onOpenPrintFormTemplateModal();
  //   },
  //   [pickTicketIdPrint],
  // );

  const handleDeleteProductLicensePlate = (rowData) => {
    setProductionLicensePlateModal(rowData);
    setIsProductLicensePlateEdit(true);
    onOpenConfirmDeleteDialog();
  };

  const handleOnClickPrint = async (e: any, id: any) => {
    e.preventDefault();
    try {
      const data = await getLicensePlateToPrint(id);
      setPrintOfLPItem({ ...data, button: buttonSinglePrintRef.current });
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnClickSinglePrintTrigger = useReactToPrint({
    content: () => componentSinglePrintRef.current,
  });

  const licensePlateNumberItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hideable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          disabled={!params.row.contextMenu_Edit_Enable}
          onClick={(e) => {
            editGetProductionLicensePlateOnClick(params.row.licensePlateId);
            onOpenProductionLicensePlateModal();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Put Away"
          disabled={!params.row.contextMenu_PutAway_Enable}
          onClick={() => {
            editGetPutAwayOnClick(params.row.licensePlateId);
            onOpenProductionLicensePlatePutAwayModal();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={!params.row.contextMenu_Delete_Enable}
          onClick={() => handleDeleteProductLicensePlate(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'lpn',
      width: 180,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">LPN</Typography>
        </Box>
      ),
    },
    {
      field: 'type',
      width: 180,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Type</Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      width: 180,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Status</Typography>
        </Box>
      ),
    },
    {
      field: 'sku',
      width: 180,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
    },
    {
      field: 'location',
      width: 180,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Location</Typography>
        </Box>
      ),
    },
    {
      field: 'print',
      width: 60,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Print</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <IconButton
          aria-label="print"
          color="primary"
          onClick={(e) => handleOnClickPrint(e, params.row.licensePlateId)}
        >
          {params.row?.licensePlateId !== undefined && <PrintIcon />}
        </IconButton>
      ),
    },
  ];

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <div style={{ display: 'none' }}>
        <SinglePrintLicensePlate
          ref={componentSinglePrintRef}
          data={printOfLPItem}
        />
        <button
          type="button"
          ref={buttonSinglePrintRef}
          onClick={handleOnClickSinglePrintTrigger}
        >
          This one is trigger on single print receipt license plate item
        </button>
      </div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ mr: 2, flexGrow: 1 }}
          variant="h6"
          fontWeight="bold"
          mb={2}
        >
          License Plate Numbers
        </Typography>
        <Typography mb={2}>
          <Button
            sx={{ textTransform: 'capitalize !important' }}
            variant="outlined"
            size="large"
            onClick={() => {
              OnClickGetCreateLicensePlateKnownData(
                queryStringItemProduction.id,
              );
              onOpenProductionLicensePlateModal();
            }}
          >
            New LPN
          </Button>
        </Typography>
      </Box>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid item xs={12}>
          <Card
            sx={{
              '&.MuiCard-root': {
                background: 'none',
                boxShadow: 'none',
                padding: 0,
              },
              marginBottom: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <MUIGrid item xs={12}>
                <DataGrid
                  autoHeight
                  headerHeight={120}
                  rows={productionLicensePlateItems}
                  density="compact"
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  disableSelectionOnClick
                  columns={licensePlateNumberItemsColumns}
                  rowsPerPageOptions={[25]}
                  pageSize={25}
                  getRowId={(row) => row.licensePlateId}
                  sx={{
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                      display: 'none !important',
                    },
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </Card>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const ProductionJobContainer = () => {
  const {
    productionJobFlagStatus,
    dataSelected,
    pageViewer,
    productionReport,
    isPickTicketItemEdit,
    isProductLicensePlateEdit,
    productionReportModal,
    productionLicensePlateModal,
    viewLoadDataProduction,
  } = useProductionContext();

  const theme = useTheme();

  return (
    <>
      {isPickTicketItemEdit && (
        <ConfirmationDialog
          pickTicketData={dataSelected}
          deletePickTicket
          callBack={viewLoadDataProduction}
        />
      )}

      <JobDetails />
      <KitDetails />
      {productionJobFlagStatus?.status !== JobStatus.New &&
        !pageViewer.isNew() && (
          <>
            <PickTickets />
            <LicensePlateNumbers />
            {productionReport?.length > 0 && productionReport !== null && (
              <>
                <ProductionReport />

                {!isPickTicketItemEdit && (
                  <ConfirmationDialog
                    productionReportData={productionReportModal}
                    deleteProductionReport
                    callBack={viewLoadDataProduction}
                  />
                )}
              </>
            )}
            {isProductLicensePlateEdit && (
              <ConfirmationDialog
                productionLicensePlateData={productionLicensePlateModal}
                deleteProductionLicensePlate
                callBack={viewLoadDataProduction}
              />
            )}
          </>
        )}
    </>
  );
};

const ProductionJobDetails = () => (
  <MainContainer>
    <ProductionContextProvider>
      <Header />
      <ProductionPickTicketModal />
      <ProductionReportModal />
      <ProductionLicensePlateModal />
      <ContentContainer>
        <ProductionJobContainer />
      </ContentContainer>
    </ProductionContextProvider>
  </MainContainer>
);
export default React.memo(ProductionJobDetails);
