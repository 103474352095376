/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import Card from 'components/card';
import Footer from 'components/footer';
import Header from 'components/header';
import Input from 'components/input/Input';
import { Grid } from 'components/styles';
import moment from 'moment';
import {
  CycleCountContextProvider,
  FormQueryStringStatus,
  useCycleCountContext,
} from 'pages/cyclecount/context';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import Cyclecountmodal from 'pages/shared/cyclecountmodal';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { ContainerFlat, DatagridStandard } from 'styles';

import { Search } from '@mui/icons-material';
import { Box, Typography, Chip, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridToolbar,
} from '@mui/x-data-grid';

import { MainContainer, ContentContainer } from '../styles';

const CycleCountsView = () => {
  const { onOpenConfirmDeleteDialog } = useContext(GlobalContext);
  const {
    cycleCountDataGrid,
    setFilteredCycleCountsGrid,
    filteredCycleCountsGrid,
  } = useCycleCountContext();
  const theme = useTheme();

  const [dataDeleteSelected, setDataDeleteSelected] = useState<any>({});

  const [searchParams, setSearchParams] = useState({
    searchCycleCountName: '',
    searchCreated: '',
    searchStarted: '',
    searchDueDate: '',
    searchAssignedUser: '',
    searchProgress: '',
    searchDiscrepancies: '',
    searchLastActivity: '',
    searchStatus: '',
  });

  const onForm = (key, text) => {
    setSearchParams(() => ({
      ...searchParams,
      [key]: text,
    }));
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    onForm(key, event.target.value);
  };

  const filterCycleCount = () => {
    const cycleCountNameResult = cycleCountDataGrid.filter((r) =>
      r.cycleCountName
        .toString()
        .toLowerCase()
        .includes(searchParams.searchCycleCountName.toLowerCase()),
    );
    const createdResult = cycleCountNameResult.filter((r) =>
      r.created
        .toString()
        .toLowerCase()
        .includes(searchParams.searchCreated.toLowerCase()),
    );
    const startedResult = createdResult.filter((r) =>
      r.started === null || r.started === undefined || r.started === ''
        ? r
        : r.started
            .toLowerCase()
            .includes(searchParams.searchStarted.toLowerCase()),
    );
    const dueDateResult = startedResult.filter((r) =>
      r.dueDate
        .toString()
        .toLowerCase()
        .includes(searchParams.searchDueDate.toLowerCase()),
    );

    const assignedToUserNameResult = dueDateResult.filter((r) =>
      r.assignedToUserName
        .toString()
        .toLowerCase()
        .includes(searchParams.searchAssignedUser.toLowerCase()),
    );

    const statusNameResult = assignedToUserNameResult.filter((r) =>
      r.statusName
        .toString()
        .toLowerCase()
        .includes(searchParams.searchStatus.toLowerCase()),
    );
    const lastActivityResult = statusNameResult.filter((r) =>
      r.lastActivity === null ||
      r.lastActivity === undefined ||
      r.lastActivity === ''
        ? r
        : r.lastActivity
            .toLowerCase()
            .includes(searchParams.searchLastActivity.toLowerCase()),
    );
    if (Object.values(searchParams).every((x) => x === '')) {
      setFilteredCycleCountsGrid(cycleCountDataGrid);
    } else {
      setFilteredCycleCountsGrid(lastActivityResult);
    }
  };

  const onClickHandleCycleCountDelete = (rowData) => {
    setDataDeleteSelected(rowData);
    onOpenConfirmDeleteDialog();
  };

  type CycleCountRows = typeof cycleCountDataGrid[number];

  const cycleCountColumns: GridColumns<CycleCountRows> = [
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Delete"
          onClick={() => onClickHandleCycleCountDelete(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'cycleCountName',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Name',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`/cycle-count/detail?type=${params.row.cycleCountTypeName.toLocaleLowerCase()}&form=${
            FormQueryStringStatus.View
          }&id=${params.row.cycleCountId}`}
          target="_blank"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.cycleCountName}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'created',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Created',
      renderCell: (params) => (
        <Typography>
          {moment(params.row.created).format('MM/DD/yyyy')}
        </Typography>
      ),
    },
    {
      field: 'started',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Started',
      renderCell: (params) => (
        <Typography>
          {params.row.started
            ? moment(params.row.started).format('MM/DD/yyyy')
            : params.row.started}
        </Typography>
      ),
    },
    {
      field: 'dueDate',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Due Date',
      renderCell: (params) => (
        <Typography>
          {moment(params.row.dueDate).format('MM/DD/yyyy')}
        </Typography>
      ),
    },
    {
      field: 'assignedToUserName',
      width: 170,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Assigned User',
    },
    {
      field: 'progress',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Progress',
    },
    {
      field: 'discrepancies',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Discrepancies',
    },
    {
      field: 'statusName',
      width: 140,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Status',
      renderCell: (params) => (
        <Chip
          label={params.row.statusName}
          size="small"
          className={
            params.row.statusName === 'Closed'
              ? 'StatusCycleCountCloseChip'
              : 'StatusCycleCountUncountChip'
          }
        />
      ),
    },
    {
      field: 'lastActivity',
      width: 200,
      disableColumnMenu: true,
      disableReorder: true,
      headerName: 'Last Activity',
      renderCell: (params) => (
        <Typography>
          {params.row.lastActivity
            ? moment(params.row.lastActivity).format('MM/DD/yyyy')
            : ''}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <ConfirmationDialog
        cycleCountData={dataDeleteSelected}
        deleteCycleCount
      />
      <ContainerFlat>
        <DatagridStandard
          autoHeight
          rows={filteredCycleCountsGrid}
          columns={cycleCountColumns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          disableSelectionOnClick
          getRowId={(row) => row.cycleCountId}
          components={{ Toolbar: GridToolbar }}
        />
      </ContainerFlat>
    </>
  );
};

const CycleCountContainer = () => <CycleCountsView />;

const CycleCountDetails = () => (
  <MainContainer>
    <CycleCountContextProvider>
      <Header />
      <Cyclecountmodal />
      <ContentContainer>
        <CycleCountContainer />
      </ContentContainer>
    </CycleCountContextProvider>
  </MainContainer>
);

export default React.memo(CycleCountDetails);
