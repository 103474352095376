import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const createFormTemplate = async (data) => {
  try {
    const response = await httpClient.post(
      `/formTemplate/CreateFormTemplate`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const UpdateFormTemplateAsync = async (data) => {
  try {
    const response = await httpClient.post(
      `/formTemplate/UpdateFormTemplate`,
      data,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const duplicateFormTemplate = async (formTemplateId) => {
  try {
    const response = await httpClient.post(
      `/formTemplate/duplicateFormTemplate/${formTemplateId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getFormTemplateById = async (formTemplateId) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetFormTemplateById/${formTemplateId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getListFormTemplate = async () => {
  try {
    const response = await httpClient.get(`/formTemplate/list`);
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPickTicketDetailsFormTemplate = async (
  pickTicketId,
  preview,
) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetPickTicketDetailsFormTemplate/${pickTicketId}/${preview}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetBulkPrintPickTicketDetailsFormTemplate = async (ids) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetBulkPrintPickTicketDetailsFormTemplate/${ids}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const getPurchaseOrderDetailsFormTemplate = async (purchaseOrderId) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetPurchaseOrderDetailsFormTemplate/${purchaseOrderId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetPackingListDetailsFormTemplate = async (
  packingListId,
  isComingFromOrder,
) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetPackingListDetailsFormTemplate/${packingListId}/${isComingFromOrder}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const GetBillofLadingDetailsFormTemplate = async (orderId) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetBillofLadingDetailsFormTemplate/${orderId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
export const GetLPNFormTemplate = async (id, isUseLicensePlateId) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetLPNFormTemplate/${id}/${isUseLicensePlateId}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetPrintAllLPNFormTemplate = async (ids) => {
  try {
    const response = await httpClient.get(
      `/formTemplate/GetPrintAllLPNFormTemplateAsync/${ids}`,
    );
    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
