import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';

import Card from 'components/card';
import Header from 'components/header';
import { getCreatedProductions } from 'services/api/production/production.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import { Typography, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridToolbar,
} from '@mui/x-data-grid';

import { MainContainer, ContentContainer } from '../styles';

type ProductionDataTableType = {
  jobId: number;
  statusStr: string;
  jobNo: string;
  complete: string;
  kitSKU: string;
  components: string;
  jobQty: string;
  startDate: any;
  endDate: any;
  shipDate: any;
  pricePerKit: number;
  totalPrice: number;
};

type ProductionDataTableViewContextProvider = {
  children: React.ReactNode;
};

interface IProductionDataTableViewType {
  orderId: number;
  orderNo: string;
}

const ProductionDataTableViewContext =
  createContext<IProductionDataTableViewType>(
    {} as IProductionDataTableViewType,
  );

export const useProductionDataTableViewContext = () =>
  useContext(ProductionDataTableViewContext);

const SalesOrderDataTableViewContextProvider = ({
  children,
}: ProductionDataTableViewContextProvider) => {
  const [orderId, setOrderId] = useState<number>(0);
  const [orderNo, setOrderNo] = useState<string>(null);

  const value: IProductionDataTableViewType = useMemo(
    () => ({ orderId, orderNo }),
    [orderId],
  );

  return (
    <ProductionDataTableViewContext.Provider value={value}>
      {children}
    </ProductionDataTableViewContext.Provider>
  );
};

const ProductionDatable = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { currentLocationAndFacility } = useContext(AuthContext);
  const { isLocationAndFacilityModalOpen } = useContext(GlobalContext);

  const initialRows: ProductionDataTableType[] = [];
  type Row = typeof initialRows[number];
  const [rows, setRows] =
    React.useState<ProductionDataTableType[]>(initialRows);

  const onLoadCreatedProductions = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCreatedProductions(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'actions',
        type: 'actions',
        hideable: false,
        width: 34,
        hide: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        getActions: (params) => [
          <GridActionsCellItem
            label="Details"
            onClick={() =>
              navigate(`job-details?form=view&id=${params.row.jobId}`)
            }
            showInMenu
          />,
        ],
      },
      {
        hide: true,
        hideable: false,
        field: 'jobId',
        filterable: false,
        headerName: 'Job Id',
      },
      {
        field: 'statusStr',
        width: 180,
        headerName: 'Status',
      },
      {
        field: 'jobNo',
        width: 180,
        headerName: 'Job No.',
        renderCell: (params) => (
          <Link
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: '#303538',
              textDecoration: 'none',
            }}
            href={`production/job-details?form=view&id=${params.row.jobId}`}
          >
            <Typography
              sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
            >
              {params.row.jobNo}
            </Typography>
          </Link>
        ),
      },
      {
        field: 'complete',
        width: 180,
        headerName: 'Complete',
      },
      {
        field: 'kitSKU',
        width: 180,
        headerName: 'Kit SKU',
      },
      {
        field: 'components',
        width: 180,
        headerName: 'Components',
      },
      {
        field: 'jobQty',
        width: 180,
        headerName: 'Job Qty',
        type: 'string',
      },
      {
        field: 'startDate',
        width: 180,
        headerName: 'Start Date',
        type: 'date',
      },
      {
        field: 'endDate',
        width: 180,
        headerName: 'End Date',
        type: 'date',
      },
      {
        field: 'shipDate',
        width: 180,
        headerName: 'Ship Date',
      },
      {
        field: 'pricePerKit',
        width: 180,
        headerName: 'Price Kit',
      },
      {
        field: 'totalPrice',
        width: 180,
        headerName: 'Total Kit',
      },
    ],
    [],
  );

  useEffect(() => {
    if (!isLocationAndFacilityModalOpen) {
      const createdProductionDatatable = onLoadCreatedProductions();
      createdProductionDatatable.then((x) => {
        setRows(x);
      });
    }
  }, [isLocationAndFacilityModalOpen]);

  return (
    <Card
      sx={{
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {' '}
      <Box
        sx={{
          paddingBottom: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Jobs
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DatagridStandard
          autoHeight
          rows={rows}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'jobId', sort: 'desc' }],
            },
          }}
          rowsPerPageOptions={[5, 15, 100]}
          getRowId={(row) => row.jobId}
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Card>
  );
};

const ProductionView = () => {
  console.log('No impelemntation');

  return (
    <MainContainer>
      <SalesOrderDataTableViewContextProvider>
        <Header />
        <ContentContainer>
          <ProductionDatable />
        </ContentContainer>
      </SalesOrderDataTableViewContextProvider>
    </MainContainer>
  );
};

export default React.memo(ProductionView);
