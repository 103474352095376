/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { OrderSearchStatusesData } from 'common/constant';
import { IOrderStatus } from 'common/interfaces';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { getCountries } from 'services/api/country/country.api';
import { checkCustomerPullOrdersInProgress } from 'services/api/customer/customer.api';
import { triggerAllIntegrations } from 'services/api/integrations/shipStation/shipstation.api';
import {
  deleteSalesOrder,
  getOrdersDatable,
  getOrdersDatableFiltered,
  getOrderBatchList,
  GetOrderTagList,
  getOrdersQuantities,
} from 'services/api/salesorders/salesorders-new.api';
import {
  getUserFilterSearchValue,
  CreateUserFilterSearchValue,
  getUserOperators,
  deleteOrderFilter,
} from 'services/api/user/users.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import useLocalStorage from 'store/localStorage/hooks/useLocalStorage';

export interface AutoCompleteOptionType {
  value?: number;
  label?: string;
}

interface IOrderUserFilterSearch {
  filterSearchParameter?: {
    value: number;
    label: string;
  };
  userFilterSearchValueId?: number;
  setFilterName?: string;
  orderStatuses?: IOrderStatus[] | [];
  orderNo?: string;
  po?: string;
  referenceNo?: string;
  carrier?: string;
  itemName?: string;
  tags?: string;
  customerName?: string;
  customerPointOfContactName?: string;
  shipToName?: string;
  shipToCompany?: string;
  shipToPhone?: string;
  shipToEmail?: string;
  shipToAddress?: string;
  shipToCity?: string;
  shipToState?: string;
  shipToZipcode?: string;
  shipToCountry?: {
    value: number;
    label: string;
  };
  ishold?: boolean;
  isBackOrder?: boolean;
  isInternational?: boolean;
  filterColumnName?: {
    value: string;
    label: string;
  };
  filterSorting?: {
    value: string;
    label: string;
  };
}

type OrderType = {
  orderId?: number;
  customerId?: number;
  warehouseCustomerId?: number;
  orderNo?: string;
  carrier?: string;
  customer: string;
  creationDateTime?: any;
  creationDate?: any;
  creationTime?: any;
  referenceNo?: string;
  shipByDateTime?: any;
  shipCancelDateTime?: any;
  itemCount?: number;
  totalQuantity?: number;
  weightTotal?: number;
  statusValue?: string;
  status?: string;
  po?: string;
  isHold?: boolean;
  isBackOrder?: boolean;
  isPartial?: boolean;
  backOrderDateTime?: string;
  shipStationTags?: any;
  assignedByUserId?: number;
  batchNo?: number;
  firstLoad: boolean;
  invoiceId?: number;
  shipAddress1?: string;
  shipAddress2?: string;
  shipName?: string;
  shipCompany?: string;
  shipCity?: string;
  shipState?: string;
  shipZip?: string;
  shipCountry?: string;
  shipEmail?: string;
  shipPhone?: string;
  shipDate?: any;
  shipDateDay?: string;
  shipDateTime?: string;
  isAddressInvalid?: boolean;
  channel?: string;
};

const SalesOrderDataTableViewContext = createContext(
  {} as {
    apiResponse: any;
    orderId: number;
    orderNo: string;
    setOrderId: React.Dispatch<React.SetStateAction<number>>;
    setOrderNo: React.Dispatch<React.SetStateAction<string>>;
    deleteSalesOrderOnClick: (e: React.SyntheticEvent) => void;
    setOpenFilterDataList: React.Dispatch<React.SetStateAction<boolean>>;
    openFilterDataList: boolean;
    setFilterSearchOptions: React.Dispatch<React.SetStateAction<any[]>>;
    filterSearchOptions: any[];
    countries: any[];
    setAdvanceSearchValue: React.Dispatch<
      React.SetStateAction<IOrderUserFilterSearch>
    >;
    advanceSearchValue: IOrderUserFilterSearch;
    OnClickCreateUserFilterSearch: (e?: any) => Promise<void>;
    initialFormFilterSearchValue: IOrderUserFilterSearch;
    loadCountries: () => Promise<unknown>;
    inputHandler: (key: string, event: ChangeEvent<HTMLInputElement>) => void;
    loadFilterList: () => Promise<void>;
    onLoadFilterSearchValueByOptionValue: (id: number) => Promise<void>;
    setRows: React.Dispatch<React.SetStateAction<OrderType[]>>;
    rows: OrderType[];
    initialRows: OrderType[];
    OnClickUserFilterSearchApply: (
      e?: any,
      forceApply?: boolean,
    ) => Promise<void>;
    onLoadOrdersDatatable: (
      searchParamsParam?: any,
      searchSingleParam?: string,
    ) => Promise<any>;
    setFilteredRows: React.Dispatch<React.SetStateAction<OrderType[]>>;
    filteredRows: OrderType[];
    setSelectedRowsOnCheckbox: React.Dispatch<any>;
    selectedRowsOnCheckbox: any;
    setOrderFilterListLocal: any;
    orderFilterListLocal: any;
    filterSearchList: any;
    onLoadOrdersDatatableFiltered: (modelsearch?: any) => Promise<any>;
    setWarehouseUserValue: React.Dispatch<
      React.SetStateAction<AutoCompleteOptionType>
    >;
    warehouseUserValue: AutoCompleteOptionType;
    optionWarehouseUser: any[];
    setBatchesOnOrderOption: React.Dispatch<React.SetStateAction<any[]>>;
    batchesOnOrderOption: any[];
    tagsOnOrderOption: any[];
    searchParams: any;
    setSearchParams: React.Dispatch<React.SetStateAction<OrderType>>;
    setInitialStateSearchParams: React.Dispatch<
      React.SetStateAction<OrderType>
    >;
    initialStateSearchParams: OrderType;
    orderQuantities: any;
    isFetchingData: boolean;
    setOrderQuantities: React.Dispatch<any>;
    OnClickDeleteFilterSearch: (e?: any) => Promise<void>;
    setIsEnableToRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    isEnableToRefresh: boolean;
    onHandleRefreshOrders: () => Promise<void>;
  },
);

export const useSalesOrderDataTableViewContext = () =>
  useContext(SalesOrderDataTableViewContext);

export const SalesOrderDataTableViewContextProvider = ({ children }: any) => {
  const {
    isWarehouseCustomerAccount,
    currentUser,
    currentLocationAndFacility,
    isSupplierAccount,
  } = useContext(AuthContext);

  const { isLocationAndFacilityModalOpen } = useContext(GlobalContext);

  const location = useLocation();

  const [orderId, setOrderId] = useState<number>(0);
  const [orderNo, setOrderNo] = useState<string>(null);
  const [apiResponse, setApiResponse] = useState<any>(null);

  const initialRows: OrderType[] = [];
  const [rows, setRows] = useState<OrderType[]>(initialRows);
  const [filteredRows, setFilteredRows] = useState<OrderType[]>(initialRows);

  const [selectedRowsOnCheckbox, setSelectedRowsOnCheckbox] =
    useState<any>(null);

  // Assign To seaction -----------------------------------
  // operator
  const [warehouseUserValue, setWarehouseUserValue] =
    useState<AutoCompleteOptionType>({
      value: null,
      label: '',
    });
  const [optionWarehouseUser, setOptionWarehouseUser] = useState([]);
  const [batchesOnOrderOption, setBatchesOnOrderOption] = useState([]);
  const [tagsOnOrderOption, setTagsOnOrderOption] = useState([]);

  // FILTER SECTION ----------------------------------------
  const [openFilterDataList, setOpenFilterDataList] = useState<boolean>(false);
  const [filterSearchOptions, setFilterSearchOptions] = useState([]);
  const [filterSearchList, setFilterSearchList] = useState<any>(null);

  const [orderFilterListLocal, setOrderFilterListLocal] = useLocalStorage(
    'orderFilterListLocal',
    null,
  );

  const [countries, setCountries] = useState([]);
  const [isFetchingData, setisFetchingData] = useState(false);

  // REFRESH ORDER ------------------------------------------

  const [isEnableToRefresh, setIsEnableToRefresh] = useState<boolean>(true);

  const onHandleRefreshOrders = async () => {
    try {
      const response = await triggerAllIntegrations(
        currentUser.Claim_CustomerId,
        currentLocationAndFacility.customerFacilityId,
      );
      const { status, data } = response;
      if (status === 200) {
        snackActions.success(`Orders are downloading...`);
      } else {
        snackActions.error('The synchronization of orders has failed.');
      }
      setisFetchingData(false);
    } catch (error) {
      snackActions.error(error);
    }
  };

  //---------------------------------------------------------

  const loadCountries = async () => {
    try {
      const countriesFromApi = await getCountries('');
      setCountries(countriesFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  const initialFormFilterSearchValue: IOrderUserFilterSearch = {
    filterSearchParameter: null,
    orderStatuses: OrderSearchStatusesData,
    userFilterSearchValueId: 0,
    setFilterName: '',
    orderNo: '',
    po: '',
    referenceNo: '',
    carrier: '',
    itemName: '',
    customerName: '',
    customerPointOfContactName: '',
    shipToName: '',
    shipToCompany: '',
    shipToPhone: '',
    shipToEmail: '',
    shipToAddress: '',
    shipToCity: '',
    shipToState: '',
    shipToZipcode: '',
    shipToCountry: null,
    ishold: false,
    isBackOrder: false,
    isInternational: false,
    filterColumnName: null,
    filterSorting: null,
  };

  const [advanceSearchValue, setAdvanceSearchValue] = useState(
    initialFormFilterSearchValue,
  );

  const [orderQuantities, setOrderQuantities] = useState<any>();

  const getStoredWarehouseValuemain = JSON.parse(
    localStorage.getItem('storeWarehouseCustomer'),
  );

  const [initialStateSearchParams, setInitialStateSearchParams] =
    useState<OrderType>({
      warehouseCustomerId: getStoredWarehouseValuemain
        ? getStoredWarehouseValuemain.id
        : null,
      batchNo: null,
      status: 'open',
      orderNo: '',
      referenceNo: '',
      carrier: '',
      customer: '',
      creationDate: '',
      shipCancelDateTime: '',
      shipByDateTime: '',
      po: '',
      isHold: false,
      firstLoad: true,
      shipAddress1: '',
      shipAddress2: '',
      shipName: '',
      shipCompany: '',
      shipCity: '',
      shipState: '',
      shipZip: '',
      shipCountry: '',
      shipEmail: '',
      shipPhone: '',
      shipDate: '',
      isAddressInvalid: false,
      channel: '',
    });
  const [searchParams, setSearchParams] = useState<OrderType>(
    initialStateSearchParams,
  );

  const onLoadOrdersDatatable = async (
    searchParamsParam?: any,
    searchSingleParam?: string,
  ) => {
    setisFetchingData(true);

    try {
      return await getOrdersDatable(
        currentUser.Claim_CustomerId,
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerLocationId
          : 0,
        !isWarehouseCustomerAccount
          ? currentLocationAndFacility.customerFacilityId
          : 0,
        searchSingleParam || '',
        { DefaultOrderGridFiltersModelData: searchParamsParam },
      );
    } catch (err) {
      return err;
    } finally {
      setisFetchingData(false);
    }
  };

  const onLoadOrdersDatatableFiltered = async (modelsearch?: any) => {
    setisFetchingData(true);

    try {
      return await getOrdersDatableFiltered(modelsearch);
    } catch (err) {
      return err;
    } finally {
      setisFetchingData(false);
    }
  };

  const loadFilterList = async () => {
    const result = await getUserFilterSearchValue('Order');

    setFilterSearchList(result);
    setFilterSearchOptions(
      result.map((v) => ({
        value: v.userFilterSearchValueId,
        label: v.setFilterName,
      })),
    );
  };

  const OnClickUserFilterSearchApply = async (
    e?: any,
    forceApply?: boolean,
  ) => {
    e?.preventDefault();

    const getStoredWarehouseValue = JSON.parse(
      localStorage.getItem('storeWarehouseCustomer'),
    );

    const finalValue = advanceSearchValue.orderStatuses
      .map((item, i) => (item.isChecked === true ? item.value : null))
      .filter((v) => v !== null);
    const payload = {
      CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
      CustomerLocationId: currentLocationAndFacility.customerLocationId,
      CustomerId: currentUser.Claim_CustomerId,
      WarehouseCustomerId: getStoredWarehouseValue
        ? getStoredWarehouseValue.id
        : null,
      UserFilterSearchValueId: advanceSearchValue?.filterSearchParameter
        ? advanceSearchValue.filterSearchParameter.value
        : null,
      SetFilterName: advanceSearchValue.setFilterName,
      UserFilterValueTypeEnum: 'Order',
      UserFilterSearhValueModelData: {
        OrderStatusEnums:
          finalValue.length > 0
            ? finalValue.map((el) => parseInt(el, 10))
            : null,
        OrderNo: advanceSearchValue.orderNo,
        ReferenceNo: advanceSearchValue.referenceNo,
        PoNumber: advanceSearchValue.po,
        ItemName: advanceSearchValue.itemName,
        Carrier: advanceSearchValue.carrier,
        CustomerName: advanceSearchValue.customerName,
        CustomerPointOfContactName:
          advanceSearchValue.customerPointOfContactName,
        ShipName: advanceSearchValue.shipToName,
        ShipCompanyName: advanceSearchValue.shipToCompany,
        ShipPhone: advanceSearchValue.shipToPhone,
        ShipEmail: advanceSearchValue.shipToEmail,
        ShipAddress: advanceSearchValue.shipToAddress,
        ShipCity: advanceSearchValue.shipToCity,
        ShipState: advanceSearchValue.shipToState,
        ShipZip: advanceSearchValue.shipToZipcode,
        ShipCountry: advanceSearchValue.shipToCountry
          ? advanceSearchValue.shipToCountry.value
          : null,
        IsHold: advanceSearchValue.ishold ? advanceSearchValue.ishold : false,
        IsBackOrder: advanceSearchValue.isBackOrder
          ? advanceSearchValue.isBackOrder
          : false,
        IsInternational: advanceSearchValue.isInternational
          ? advanceSearchValue.isInternational
          : false,
        Tags: advanceSearchValue.tags,
        ColumnName: advanceSearchValue.filterColumnName
          ? advanceSearchValue.filterColumnName.value
          : null,
        ColumnNameStr: advanceSearchValue.filterColumnName
          ? advanceSearchValue.filterColumnName.label
          : null,
        Sorting: advanceSearchValue.filterSorting
          ? advanceSearchValue.filterSorting.value
          : null,
        SortingStr: advanceSearchValue.filterSorting
          ? advanceSearchValue.filterSorting.label
          : null,
      },
    };

    try {
      if (
        advanceSearchValue.filterSearchParameter !== null ||
        forceApply === true
      ) {
        await onLoadOrdersDatatableFiltered(payload)
          .then((x) => {
            const orders = x.map((o) => ({
              orderId: o.orderId,
              orderNo: o.orderNo,
              carrier: o?.carrier,
              customer: o?.customer,
              creationDate:
                o?.creationDateTime !== undefined
                  ? moment(o?.creationDateTime).format('MM/DD/YYYY')
                  : undefined,
              creationTime:
                o?.creationDateTime !== undefined
                  ? moment(o?.creationDateTime).format('LT')
                  : undefined,
              creationDateTime: o?.creationDateTime,
              referenceNo: o?.referenceNo,
              shipByDateTime:
                o?.shipByDateTime !== undefined
                  ? moment(o?.shipByDateTime).format('MMM DD YYYY')
                  : undefined,
              shipCancelDateTime:
                o?.shipCancelDateTime !== undefined
                  ? moment(o?.shipCancelDateTime).format('MMM DD YYYY')
                  : undefined,
              itemCount: o.itemCount,
              totalQuantity: o.totalQuantity,
              statusValue: o.statusValue,
              status: o.status,
              po: o.po,
              isHold: o.isHold,
              isBackOrder: o.isBackOrder,
              isPartial: o.isPartial,
              backOrderDateTime: o.backOrderDateTime,
              shipStationTags: o.shipStationTags,
              assignedByUserId: o.assignedByUserId,
              batchNo: o?.batchNo,
              invoiceId: o?.invoiceId,
              weightTotal: o?.weightTotal,
              shipAddress1: o?.shipAddress1,
              shipAddress2: o?.shipAddress2,
              shipName: o?.shipName,
              shipCompany: o?.shipCompany,
              shipCity: o?.shipCity,
              shipState: o?.shipState,
              shipZip: o?.shipZip,
              shipCountry: o?.shipCountry,
              shipEmail: o?.shipEmail,
              shipPhone: o?.shipPhone,
              shipDateDay: o?.shipDate
                ? moment(o?.shipDate).format('MM/DD/YYYY')
                : undefined,
              shipDateTime: o?.shipDate
                ? moment(o?.shipDate).format('LT')
                : undefined,
              isAddressInvalid: o?.isAddressInvalid,
              channel: o?.channel,
            }));
            setRows(orders);
            setFilteredRows(orders);
          })
          .catch((err) => console.log(err));
      } else if (rows.length === 0) {
        onLoadOrdersDatatable(searchParams)
          .then((x) => {
            const orders = [];
            x.forEach((o: OrderType) => {
              orders.push({
                orderId: o.orderId,
                orderNo: o.orderNo,
                customerId: o.customerId,
                warehouseCustomerId: o.warehouseCustomerId,
                carrier: o?.carrier,
                customer: o?.customer,
                creationDate:
                  o?.creationDateTime !== undefined
                    ? moment(o?.creationDateTime).format('MM/DD/YYYY')
                    : undefined,
                creationTime:
                  o?.creationDateTime !== undefined
                    ? moment(o?.creationDateTime).format('LT')
                    : undefined,
                referenceNo: o?.referenceNo,
                shipByDateTime:
                  o?.shipByDateTime !== undefined
                    ? moment(o?.shipByDateTime).format('MMM DD YYYY')
                    : undefined,
                shipCancelDateTime:
                  o?.shipCancelDateTime !== undefined
                    ? moment(o?.shipCancelDateTime).format('MMM DD YYYY')
                    : undefined,
                creationDateTime: o.creationDateTime,
                itemCount: o.itemCount,
                totalQuantity: o.totalQuantity,
                statusValue: o.statusValue,
                status: o.status,
                po: o.po,
                isHold: o.isHold,
                isBackOrder: o.isBackOrder,
                isPartial: o.isPartial,
                shipStationTags: o.shipStationTags,
                assignedByUserId: o.assignedByUserId,
                batchNo: o?.batchNo,
                invoiceId: o?.invoiceId,
                weightTotal: o?.weightTotal,
                shipAddress1: o?.shipAddress1,
                shipAddress2: o?.shipAddress2,
                shipName: o?.shipName,
                shipCompany: o?.shipCompany,
                shipCity: o?.shipCity,
                shipState: o?.shipState,
                shipZip: o?.shipZip,
                shipCountry: o?.shipCountry,
                shipEmail: o?.shipEmail,
                shipPhone: o?.shipPhone,
                shipDateDay: o?.shipDate
                  ? moment(o?.shipDate).format('MM/DD/YYYY')
                  : undefined,
                shipDateTime: o?.shipDate
                  ? moment(o?.shipDate).format('LT')
                  : undefined,
                isAddressInvalid: o?.isAddressInvalid,
                channel: o?.channel,
              });
            });
            setFilteredRows(orders);
            setRows(orders);
          })
          .catch((err) => console.log(err));
      }

      getOrderBatchList(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      )
        .then((opt) => {
          const getOptions = opt.map((c: any) => ({
            batchNo: c.batchNo,
            batchNoCount: c.batchNoCount,
          }));

          const newOption = [
            ...getOptions,
            { batchNo: '+ Create New Batch', batchNoCount: 0 },
          ];

          setBatchesOnOrderOption(newOption);
        })
        .catch((err) => console.log(err));
      GetOrderTagList(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      )
        .then((opt) => {
          const getOptions = opt.map((c: any) => ({
            tagName: c.tagName,
            tagColor: c.tagColor,
            tagCount: c.tagCount,
          }));

          const newOption = [
            ...getOptions,
            { tagName: '+ Add New Tag', tagColor: '#1C9DCC', tagCount: 0 },
            { tagName: '- Remove Tag', tagColor: 'red', tagCount: -1 },
          ];

          setTagsOnOrderOption(newOption);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  const OnClickCreateUserFilterSearch = async (e?: any) => {
    e?.preventDefault();

    const payload = {
      UserFilterSearchValueId: advanceSearchValue.filterSearchParameter
        ? advanceSearchValue.filterSearchParameter.value
        : null,
      SetFilterName: advanceSearchValue.setFilterName,
      UserFilterValueTypeEnum: 'Order',
      UserFilterSearhValueOrderModelData: {
        OrderStatusStatuses: advanceSearchValue.orderStatuses.map((v) => ({
          Value: v.value,
          IsChecked: v.isChecked,
        })),
        OrderNo: advanceSearchValue.orderNo,
        ReferenceNo: advanceSearchValue.referenceNo,
        PoNumber: advanceSearchValue.po,
        ItemName: advanceSearchValue.itemName,
        Carrier: advanceSearchValue.carrier,
        CustomerName: advanceSearchValue.customerName,
        CustomerPointOfContactName:
          advanceSearchValue.customerPointOfContactName,
        ShipName: advanceSearchValue.shipToName,
        ShipCompanyName: advanceSearchValue.shipToCompany,
        ShipPhone: advanceSearchValue.shipToPhone,
        ShipEmail: advanceSearchValue.shipToEmail,
        ShipAddress: advanceSearchValue.shipToAddress,
        ShipCity: advanceSearchValue.shipToCity,
        ShipState: advanceSearchValue.shipToState,
        ShipZip: advanceSearchValue.shipToZipcode,
        ShipCountry: advanceSearchValue.shipToCountry
          ? advanceSearchValue.shipToCountry.value
          : null,
        IsHold: advanceSearchValue.ishold ? advanceSearchValue.ishold : false,
        IsBackOrder: advanceSearchValue.isBackOrder
          ? advanceSearchValue.isBackOrder
          : false,
        IsInternational: advanceSearchValue.isInternational
          ? advanceSearchValue.isInternational
          : false,
        Tags: advanceSearchValue.tags,
        ColumnName: advanceSearchValue.filterColumnName
          ? advanceSearchValue.filterColumnName.value
          : null,
        ColumnNameStr: advanceSearchValue.filterColumnName
          ? advanceSearchValue.filterColumnName.label
          : null,
        Sorting: advanceSearchValue.filterSorting
          ? advanceSearchValue.filterSorting.value
          : null,
        SortingStr: advanceSearchValue.filterSorting
          ? advanceSearchValue.filterSorting.label
          : null,
      },
    };

    try {
      await CreateUserFilterSearchValue(payload);

      loadFilterList();
      OnClickUserFilterSearchApply(e, true);
      setAdvanceSearchValue((prev) => ({
        ...prev,
        filterSearchParameter: {
          value: prev.filterSearchParameter
            ? prev.filterSearchParameter.value
            : null,
          label: prev.setFilterName ? prev.setFilterName : null,
        },
      }));
      snackActions.success(`Filter has been successfully saved`);
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };
  const OnClickDeleteFilterSearch = async (e?: any) => {
    e?.preventDefault();

    const temp: any = {};

    temp.filterSearchParameter = advanceSearchValue.filterSearchParameter
      ? ''
      : 'This field is required';

    if (!Object.values(temp).every((x) => x === '')) {
      snackActions.error('No filter found');
      return;
    }
    try {
      const payload = {
        UserFilterSearchValueId: advanceSearchValue.filterSearchParameter.value,
      };
      await deleteOrderFilter(payload);

      const clearStatuses = advanceSearchValue.orderStatuses.map((v) => ({
        value: v.value,
        isChecked: false,
      }));
      const clearItem = initialFormFilterSearchValue;
      clearItem.orderStatuses = clearStatuses;
      setAdvanceSearchValue(clearItem);
      setOrderFilterListLocal(null);

      snackActions.success(`Filter has been successfully delete`);
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  const onForm = (key: any, text: any) => {
    setAdvanceSearchValue((v) => ({
      ...v,
      [key]: text,
    }));
  };

  const inputHandler = (
    key: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onForm(key, event.target.value);
  };

  const onLoadFilterSearchValueByOptionValue = async (id: number) => {
    const getItem = [...filterSearchList];
    const newValue = getItem.find((r) => r.userFilterSearchValueId === id);
    const statuses =
      newValue.userFilterSearhValueModelData.orderStatusEnums.map((v) => ({
        value: v,
      }));
    const finalValue = OrderSearchStatusesData.map((item, i) => {
      if (
        statuses.some((v2) => item.value.toString() === v2.value.toString())
      ) {
        return { ...item, isChecked: true };
      }
      return item;
    });
    setAdvanceSearchValue((prev) => ({
      ...prev,
      orderStatuses: finalValue,
      userFilterSearchValueId: newValue.userFilterSearchValueId,
      orderNo: newValue.userFilterSearhValueModelData.orderNo,
      po: newValue.userFilterSearhValueModelData.poNumber,
      referenceNo: newValue.userFilterSearhValueModelData.referenceNo,
      carrier: newValue.userFilterSearhValueModelData.carrier,
      itemName: newValue.userFilterSearhValueModelData.itemName,
      customerName: newValue.userFilterSearhValueModelData.customerName,
      customerPointOfContactName:
        newValue.userFilterSearhValueModelData.customerPointOfContactName,
      shipToName: newValue.userFilterSearhValueModelData.shipName,
      shipToCompany: newValue.userFilterSearhValueModelData.shipCompanyName,
      shipToPhone: newValue.userFilterSearhValueModelData.shipPhone,
      shipToEmail: newValue.userFilterSearhValueModelData.shipEmail,
      shipToAddress: newValue.userFilterSearhValueModelData.shipAddress,
      shipToCity: newValue.userFilterSearhValueModelData.shipCity,
      shipToState: newValue.userFilterSearhValueModelData.shipState,
      shipToZipcode: newValue.userFilterSearhValueModelData.shipZip,
      shipToCountry: {
        value: newValue.userFilterSearhValueModelData.shipCountry,
        label: newValue.userFilterSearhValueModelData.shipCountryStr,
      },
      ishold: newValue.userFilterSearhValueModelData.isHold,
      isBackOrder: newValue.userFilterSearhValueModelData.isBackOrder,
      isInternational: newValue.userFilterSearhValueModelData.isInternational,
      tags: newValue.userFilterSearhValueModelData.tags,
      filterColumnName: newValue.userFilterSearhValueModelData.columnName
        ? {
            value: newValue.userFilterSearhValueModelData.columnName,
            label: newValue.userFilterSearhValueModelData.columnNameStr,
          }
        : null,
      filterSorting: newValue.userFilterSearhValueModelData.sorting
        ? {
            value: newValue.userFilterSearhValueModelData.sorting,
            label: newValue.userFilterSearhValueModelData.sortingStr,
          }
        : null,
    }));

    setOrderFilterListLocal(newValue.userFilterSearchValueId);
  };

  const deleteSalesOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const DELETE_PAYLOAD = {
      orderId,
    };
    try {
      const response = await deleteSalesOrder(DELETE_PAYLOAD);
      setApiResponse(response);
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    getUserOperators()
      .then((opt) => {
        const getOptions = opt.map((c: any) => ({
          label: c.text,
          value: c.value,
        }));
        const newOption = [
          ...getOptions,
          { value: 1000, label: 'Unassign' },
          { value: 1001, label: 'Round Robin Selection' },
        ];

        setOptionWarehouseUser(newOption);
      })
      .catch((err) => console.log(err));

    getOrderBatchList(
      !isWarehouseCustomerAccount
        ? currentLocationAndFacility.customerLocationId
        : 0,
      !isWarehouseCustomerAccount
        ? currentLocationAndFacility.customerFacilityId
        : 0,
    )
      .then((opt) => {
        const getOptions = opt.map((c: any) => ({
          batchNo: c.batchNo,
          batchNoCount: c.batchNoCount,
        }));

        const newOption = [
          ...getOptions,
          { batchNo: '+ Create New Batch', batchNoCount: 0 },
        ];

        setBatchesOnOrderOption(newOption);
      })
      .catch((err) => console.log(err));

    GetOrderTagList(
      !isWarehouseCustomerAccount
        ? currentLocationAndFacility.customerLocationId
        : 0,
      !isWarehouseCustomerAccount
        ? currentLocationAndFacility.customerFacilityId
        : 0,
    )
      .then((opt) => {
        const getOptions = opt.map((c: any) => ({
          tagName: c.tagName,
          tagColor: c.tagColor,
          tagCount: c.tagCount,
        }));

        const newOption = [
          ...getOptions,
          { tagName: '+ Add New Tag', tagColor: '#1C9DCC', tagCount: 0 },
          { tagName: '- Remove Tag', tagColor: 'red', tagCount: -1 },
        ];

        setTagsOnOrderOption(newOption);
      })
      .catch((err) => console.log(err));

    const getStoredWarehouseValue = JSON.parse(
      localStorage.getItem('storeWarehouseCustomer'),
    );

    if (location.pathname.includes('/returns')) {
      setSearchParams((prev) => ({
        ...prev,
        status: 'return',
        warehouseCustomerId: getStoredWarehouseValue
          ? getStoredWarehouseValue.id
          : null,
      }));
    } else {
      setSearchParams((prev) => ({
        ...prev,
        status: searchParams?.status ? searchParams?.status : 'open',
        warehouseCustomerId: getStoredWarehouseValue
          ? getStoredWarehouseValue.id
          : null,
      }));
    }
    if (!isLocationAndFacilityModalOpen && rows.length === 0) {
      onLoadOrdersDatatable(searchParams)
        .then((x) => {
          const orders = [];
          x.forEach((o: OrderType) => {
            orders.push({
              orderId: o.orderId,
              orderNo: o.orderNo,
              customerId: o.customerId,
              warehouseCustomerId: o.warehouseCustomerId,
              carrier: o?.carrier,
              customer: o?.customer,
              creationDate:
                o?.creationDateTime !== undefined
                  ? moment(o?.creationDateTime).format('MM/DD/YYYY')
                  : undefined,
              creationTime:
                o?.creationDateTime !== undefined
                  ? moment(o?.creationDateTime).format('LT')
                  : undefined,
              creationDateTime: o?.creationDateTime,
              referenceNo: o?.referenceNo,
              shipByDateTime:
                o?.shipByDateTime !== undefined
                  ? moment(o?.shipByDateTime).format('MMM DD YYYY')
                  : undefined,
              shipCancelDateTime:
                o?.shipCancelDateTime !== undefined
                  ? moment(o?.shipCancelDateTime).format('MMM DD YYYY')
                  : undefined,
              itemCount: o.itemCount,
              totalQuantity: o.totalQuantity,
              statusValue: o.statusValue,
              status: o.status,
              po: o.po,
              isHold: o.isHold,
              isBackOrder: o.isBackOrder,
              isPartial: o.isPartial,
              shipStationTags: o.shipStationTags,
              assignedByUserId: o.assignedByUserId,
              batchNo: o?.batchNo,
              invoiceId: o?.invoiceId,
              shipAddress1: o?.shipAddress1,
              shipAddress2: o?.shipAddress2,
              shipName: o?.shipName,
              shipCompany: o?.shipCompany,
              shipCity: o?.shipCity,
              shipState: o?.shipState,
              shipZip: o?.shipZip,
              shipCountry: o?.shipCountry,
              shipEmail: o?.shipEmail,
              shipPhone: o?.shipPhone,
              shipDateDay: o?.shipDate
                ? moment(o?.shipDate).format('MM/DD/YYYY')
                : undefined,
              shipDateTime: o?.shipDate
                ? moment(o?.shipDate).format('LT')
                : undefined,
              isAddressInvalid: o?.isAddressInvalid,
              channel: o?.channel,
            });
          });
          setFilteredRows(orders);
          setRows(orders);
        })
        .catch((err) => console.log(err));

      if (!isWarehouseCustomerAccount) {
        if (currentLocationAndFacility) {
          getOrdersQuantities(
            currentUser.Claim_CustomerId,
            currentLocationAndFacility.customerLocationId,
            currentLocationAndFacility.customerFacilityId,
            getStoredWarehouseValue ? getStoredWarehouseValue.id : null,
          ).then((opt) => {
            setOrderQuantities(opt);
          });
        }
      } else {
        getOrdersQuantities(currentUser.Claim_CustomerId, 0, 0, null).then(
          (opt) => {
            setOrderQuantities(opt);
          },
        );
      }
    }

    if (!isWarehouseCustomerAccount && !isSupplierAccount) {
      checkCustomerPullOrdersInProgress(currentUser.Claim_CustomerId).then(
        (opt) => {
          setIsEnableToRefresh(opt);
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.Claim_CustomerId, location, isLocationAndFacilityModalOpen]);

  const value = useMemo(
    () => ({
      advanceSearchValue,
      apiResponse,
      filterSearchOptions,
      orderId,
      orderNo,
      openFilterDataList,
      countries,
      initialFormFilterSearchValue,
      rows,
      initialRows,
      filteredRows,
      filterSearchList,
      selectedRowsOnCheckbox,
      setOrderFilterListLocal,
      orderFilterListLocal,
      deleteSalesOrderOnClick,
      inputHandler,
      loadFilterList,
      loadCountries,
      onLoadOrdersDatatable,
      onLoadFilterSearchValueByOptionValue,
      OnClickUserFilterSearchApply,
      OnClickCreateUserFilterSearch,
      onLoadOrdersDatatableFiltered,
      setRows,
      setOrderId,
      setOrderNo,
      setOpenFilterDataList,
      setFilterSearchOptions,
      setFilteredRows,
      setAdvanceSearchValue,
      setSelectedRowsOnCheckbox,
      setWarehouseUserValue,
      warehouseUserValue,
      optionWarehouseUser,
      setBatchesOnOrderOption,
      batchesOnOrderOption,
      tagsOnOrderOption,
      searchParams,
      setSearchParams,
      initialStateSearchParams,
      setInitialStateSearchParams,
      orderQuantities,
      setOrderQuantities,
      isFetchingData,
      OnClickDeleteFilterSearch,
      isEnableToRefresh,
      setIsEnableToRefresh,
      onHandleRefreshOrders,
    }),
    [
      isEnableToRefresh,
      advanceSearchValue,
      apiResponse,
      batchesOnOrderOption,
      countries,
      filteredRows,
      filterSearchList,
      filterSearchOptions,
      orderId,
      openFilterDataList,
      rows,
      selectedRowsOnCheckbox,
      orderFilterListLocal,
      warehouseUserValue,
      optionWarehouseUser,
      tagsOnOrderOption,
      searchParams,
      setSearchParams,
      initialStateSearchParams,
      setInitialStateSearchParams,
      orderQuantities,
      isFetchingData,
    ],
  );

  return (
    <SalesOrderDataTableViewContext.Provider value={value}>
      {children}
    </SalesOrderDataTableViewContext.Provider>
  );
};

export default SalesOrderDataTableViewContextProvider;
