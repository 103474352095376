import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

// Pick Ticket Issue
// GET /api/pickticket/issue/list/{customerLocationId}/{customerFacilityId}
export const getPickTicketIssues = async (
  customerLocationId,
  customerFacilityId,
) => {
  try {
    const response = await httpClient.get(
      `PickTicket/Issue/List/${customerLocationId}/${customerFacilityId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// Pick Ticket Issue
// POST /api/pickticket/issue/resolve
export const updatePickTicketLineItemIssueResolve = async (data) => {
  try {
    const response = await httpClient.post(`PickTicket/Issue/Resolve/`, data);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
