import React from 'react';

import moment from 'moment';

import { Box, Chip, Typography } from '@mui/material';

import ChannelIcon from '../channel-image';
import { useSalesOrderContext } from '../context';

const OrderStatusChip = () => {
  const {
    pageViewer,
    salesOrderForm,
    salesOrderFlagStatus,
    queryStringItemOrders,
  } = useSalesOrderContext();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {pageViewer.isNew() && (
          <Typography
            sx={{ paddingRight: 1 }}
            variant="h6"
            fontWeight="bold"
            textTransform="capitalize"
          >
            New {queryStringItemOrders.isReturn === true && 'Return'} Order
          </Typography>
        )}
        {pageViewer.isViewOrEdit() && (
          <>
            <Typography
              sx={{ paddingRight: 1 }}
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Orders / {salesOrderForm.orderNo}
            </Typography>
            <Chip
              label={salesOrderFlagStatus.statusStr}
              sx={{ marginRight: 1 }}
            />
            {salesOrderFlagStatus.isBackOrder && (
              <Chip
                label={[
                  'Backorder ',
                  moment(salesOrderForm.backOrderDateTime)
                    .format('MMM DD')
                    .toUpperCase(),
                ].join('')}
                color="error"
                sx={{ marginRight: 1 }}
              />
            )}
            {salesOrderFlagStatus.isPartial && (
              <Chip label="Partial" color="error" sx={{ marginRight: 1 }} />
            )}
            {salesOrderFlagStatus.isHold && (
              <Chip label="Hold" color="error" sx={{ marginRight: 1 }} />
            )}
            {salesOrderForm.isAddressInvalid && (
              <Chip
                label="Invalid Address"
                color="error"
                sx={{ marginRight: 1 }}
              />
            )}
            {salesOrderForm?.channel && (
              <ChannelIcon type={salesOrderForm?.channel.toLowerCase()} />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
export default React.memo(OrderStatusChip);
