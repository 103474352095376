import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormLoginLogo } from 'components/forms/style';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import ResetPasswordModal from 'pages/shared/resetpasswordmodal';
import { LoginContainer, LoginBackgroundMesh } from 'pages/styles';
import {
  login as apiLogin,
  getDefaultLocationAndFacility,
} from 'services/api/auth/auth.api';
import {
  GetAccountDetailForActivation,
  updateCustomerPortalAccountActivation,
} from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';

import {
  Box,
  Typography,
  Grid as MUIGrid,
  TextField,
  Button,
} from '@mui/material';

function CustomerPortal() {
  const navigate = useNavigate();
  const query = useQuery();

  const initialStateOfQueryParams = {
    key: query.get('key'),
  };

  const {
    login,
    setFacilityAndLocation,
    isAuth,
    currentUser,
    handleSetAuthorized,
  } = useContext(AuthContext);

  const [queryString] = useState(initialStateOfQueryParams);

  const [formErrors, setFormErrors] = useState({
    key: '',
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    confirmpassword: '',
    email: '',
  });
  const [form, setForm] = useState({
    key: '',
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    confirmpassword: '',
    email: '',
    customerName: '',
    customerLogo: '',
  });

  const validateOnlick = (fieldValues = form) => {
    const temp: any = {};

    if ('username' in fieldValues) {
      temp.username = fieldValues.username ? '' : 'This field is required';
    }
    if ('password' in fieldValues) {
      temp.password = fieldValues.password ? '' : 'This field is required';
    }
    if ('firstname' in fieldValues) {
      temp.firstname = fieldValues.firstname ? '' : 'This field is required';
    }
    if ('lastname' in fieldValues) {
      temp.lastname = fieldValues.lastname ? '' : 'This field is required';
    }
    if ('confirmpassword' in fieldValues) {
      if (
        fieldValues.confirmpassword !== '' ||
        fieldValues.confirmpassword !== null ||
        fieldValues.confirmpassword !== undefined
      ) {
        temp.confirmpassword =
          fieldValues.password === fieldValues.confirmpassword
            ? ''
            : 'Confirm Password and Password do not match.';
      } else {
        temp.confirmpassword = fieldValues.confirmpassword
          ? ''
          : 'This field is required';
      }
    }

    setFormErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const onAccountValidate = async () => {
    if (!validateOnlick()) {
      snackActions.error('Please complete required fields');
      return;
    }

    try {
      const result = await updateCustomerPortalAccountActivation(form);

      const LoginCreditial = {
        username: result.username,
        password: form.password,
      };
      const user = await apiLogin(LoginCreditial);
      login(user.token);

      const locationAndFacility = await getDefaultLocationAndFacility(
        user.userData,
      );
      setFacilityAndLocation(locationAndFacility);
      handleSetAuthorized();

      navigate(`/login`);
    } catch (err: any) {
      snackActions.error(err);
    }
  };

  const viewLoadData = async () => {
    try {
      const response = await GetAccountDetailForActivation(queryString.key);
      setForm(() => ({
        key: response.key,
        username: response.username,
        password: response.password,
        firstname: response.firstname,
        lastname: response.lastname,
        confirmpassword: response.confirmpassword,
        email: response.email,
        customerName: response?.customerName,
        customerLogo: response?.customerLogo,
      }));
      return true;
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    viewLoadData();

    if (isAuth) {
      switch (currentUser.Claim_UserRole) {
        default:
          navigate(`/dashboard`);
      }
    }
  }, [currentUser]);

  return (
    <LoginContainer>
      <ResetPasswordModal />
      <LoginBackgroundMesh>
        <MUIGrid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <MUIGrid
            item
            xs={12}
            md={7}
            sx={{ padding: '8% !important', color: '#f5f1f1' }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2rem', md: '3rem' },
                fontFamily: 'inherit',
              }}
            >
              Welcome to
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '2.5rem', md: '3.6rem' },
                fontWeight: 700,
                fontFamily: 'inherit',
                marginBottom: '10px',
                overflowWrap: 'anywhere',
              }}
            >
              {form?.customerName || 'PackemWMS'} Portal
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1.3rem',
                fontFamily: 'inherit',
                fontWeight: 200,
              }}
            >
              Where you can access inventory, order and
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1.3rem',
                fontFamily: 'inherit',
                fontWeight: 200,
              }}
            >
              warehouse operation data.
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={12} md={5}>
            <Box component="form" className="loginFormContainer">
              {form?.customerLogo ? (
                <input
                  disabled
                  type="image"
                  src={`data:image/jpeg;base64,${form?.customerLogo}`}
                  alt="preview"
                  width={70}
                  height={30}
                  accept="image/*"
                />
              ) : (
                <FormLoginLogo />
              )}

              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'inherit',
                  fontSize: '1.5rem',
                  marginTop: '20px',
                }}
              >
                Client Account Activation
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: '30px',
                  fontFamily: 'inherit',
                  fontSize: '0.9rem',
                  color: '#a3aaaf',
                }}
              >
                Enter your information to create an account
              </Typography>

              <TextField
                autoComplete="off"
                label="Email"
                value={form.email}
                sx={{ width: '100%', mb: 2 }}
                size="small"
                disabled
                {...(formErrors?.email && {
                  error: true,
                  helperText: formErrors.email,
                })}
              />

              <Box sx={{ display: 'flex', gap: '8px' }}>
                <TextField
                  autoComplete="off"
                  label="First Name"
                  value={form.firstname}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  onChange={(value) =>
                    setForm((v) => ({ ...v, firstname: value.target.value }))
                  }
                  {...(formErrors?.firstname && {
                    error: true,
                    helperText: formErrors.firstname,
                  })}
                  required
                />

                <TextField
                  autoComplete="off"
                  label="Last Name"
                  value={form.lastname}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  onChange={(value) =>
                    setForm((v) => ({ ...v, lastname: value.target.value }))
                  }
                  {...(formErrors?.lastname && {
                    error: true,
                    helperText: formErrors.lastname,
                  })}
                  required
                />
              </Box>

              <TextField
                autoComplete="off"
                label="Username"
                value={form.username}
                sx={{ width: '100%', mb: 2 }}
                size="small"
                onChange={(value) =>
                  setForm((v) => ({ ...v, username: value.target.value }))
                }
                {...(formErrors?.username && {
                  error: true,
                  helperText: formErrors.username,
                })}
                required
              />

              <TextField
                autoComplete="off"
                label="Password"
                value={form.password}
                sx={{ width: '100%', mb: 2 }}
                size="small"
                onChange={(value) =>
                  setForm((v) => ({ ...v, password: value.target.value }))
                }
                {...(formErrors?.password && {
                  error: true,
                  helperText: formErrors.password,
                })}
                required
              />

              <TextField
                autoComplete="off"
                label="Confirm Password"
                value={form.confirmpassword}
                sx={{ width: '100%', mb: 2 }}
                size="small"
                onChange={(value) =>
                  setForm((v) => ({
                    ...v,
                    confirmpassword: value.target.value,
                  }))
                }
                {...(formErrors?.confirmpassword && {
                  error: true,
                  helperText: formErrors.confirmpassword,
                })}
                required
              />

              <Button
                variant="contained"
                size="large"
                onClick={() => onAccountValidate()}
                sx={{
                  padding: '15px',
                  backgroundColor: '#176bf4',
                  textTransform: 'Capitalize',
                  fontFamily: 'inherit',
                  width: '100%',
                }}
              >
                Activate Account
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </LoginBackgroundMesh>
    </LoginContainer>
  );
}

export default React.memo(CustomerPortal);
