import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import {
  getPackagingList,
  getVoidFillList,
} from 'services/api/cartoncodes/cartoncodes.api';
import { DatagridStandard } from 'styles';
import { DataGridProStyle } from 'styles/mui/datagrid/premiumpro';

import {
  Typography,
  Grid as MUIGrid,
  Divider,
  Link,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';

export interface CartonCodeModel {
  itemAsPackageId: number;
  description?: string;
  width?: number;
  height?: number;
  length?: number;
}

// IS NOW PACKAGING
function CartonCodes() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [filteredCartons, setFilteredCartons] = useState([]);
  const [filteredVoidFill, setFilteredVoidFill] = useState([]);

  useEffect(() => {
    getPackagingList().then((data) => {
      setFilteredCartons(data);
    });

    getVoidFillList().then((data) => {
      setFilteredVoidFill(data);
    });
  }, []);

  const packagingColumns: GridColDef[] = [
    {
      field: 'descriptionDropDown',
      width: 250,
      flex: 1,
      headerName: 'Description',
      renderCell: (params) => (
        <Typography
          sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
        >
          <Link
            href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
            underline="none"
            target="_blank"
          >
            {params.row.descriptionDropDown}
          </Link>
        </Typography>
      ),
    },
    {
      field: 'width',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Width',
      renderCell: (params) => <Typography>{params.row.width}</Typography>,
    },
    {
      field: 'height',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Height',
      renderCell: (params) => <Typography>{params.row.height}</Typography>,
    },
    {
      field: 'length',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Length',
      renderCell: (params) => <Typography>{params.row.length}</Typography>,
    },
    {
      field: 'client',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Client',
      renderCell: (params) => <Typography>{params.row.client}</Typography>,
    },
    {
      field: 'billRate',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Bill Rate',
      renderCell: (params) => (
        <Typography>
          {params.row.billRate
            ? `$${params.row.billRate.toFixed(2)}`
            : ' $0.00'}
        </Typography>
      ),
    },
    {
      field: 'inventoryTotal',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Inventory',
      renderCell: (params) => (
        <Typography>{params.row.inventoryTotal}</Typography>
      ),
    },
  ];

  const voidFillColumns: GridColDef[] = [
    {
      field: 'description',
      minWidth: 150,
      flex: 1,
      headerName: 'Description',
      renderCell: (params) => (
        <Typography
          sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
        >
          <Link
            href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
            underline="none"
            target="_blank"
          >
            {params.row.description}
          </Link>
        </Typography>
      ),
    },
    {
      field: 'client',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Client',
      renderCell: (params) => <Typography>{params.row.client}</Typography>,
    },
    {
      field: 'billRate',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Bill Rate',
      renderCell: (params) => (
        <Typography>
          {params.row.billRate
            ? `$${params.row.billRate.toFixed(2)}`
            : ' $0.00'}
        </Typography>
      ),
    },
    {
      field: 'inventoryTotal',
      minWidth: 70,
      flex: 0.5,
      headerName: 'Inventory',
      renderCell: (params) => (
        <Typography>{params.row.inventoryTotal}</Typography>
      ),
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 15,
    page: 0,
  });

  const [paginationVoidfillModel, setPaginationVoidfillModel] = React.useState({
    pageSize: 15,
    page: 0,
  });

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <MUIGrid
        container
        spacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: 1, alignItems: 'flex-start' }}
      >
        <MUIGrid item xs={7}>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginBottom: 1, alignItems: 'center' }}
          >
            <MUIGrid item xs={9}>
              <Typography variant="h6" fontWeight="bold">
                Packaging
              </Typography>
              <Typography variant="subtitle1">
                Manage which packaging is available for pack and ship.
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={3} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  navigate('/inventory/item-details?form=new&isPackaging=true')
                }
              >
                Add Packaging
              </Button>
            </MUIGrid>

            <MUIGrid item xs={12}>
              <div style={{ maxHeight: 620, width: '100%' }}>
                <DataGridProStyle
                  autoHeight
                  rows={filteredCartons}
                  density="compact"
                  columns={packagingColumns}
                  hideFooterSelectedRowCount
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  getRowId={(row) => row.itemId}
                  pagination
                  disableColumnMenu
                />
              </div>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        <MUIGrid item xs={5}>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginBottom: 1, alignItems: 'center' }}
          >
            <MUIGrid item xs={8}>
              <Typography variant="h6" fontWeight="bold">
                Void Fill
              </Typography>
              <Typography variant="subtitle1">
                Material used to fill the space inside a package.
              </Typography>
            </MUIGrid>

            <MUIGrid item xs={4} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  navigate('/inventory/item-details?form=new&isVoidFill=true')
                }
              >
                Add Void Fill
              </Button>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <DataGridProStyle
                autoHeight
                rows={filteredVoidFill}
                density="compact"
                columns={voidFillColumns}
                hideFooterSelectedRowCount
                paginationModel={paginationVoidfillModel}
                onPaginationModelChange={setPaginationVoidfillModel}
                getRowId={(row) => row.itemId}
                disableColumnMenu
                pagination
              />
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
}

export default React.memo(CartonCodes);
