/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { DropdownListItem } from 'common/models';
import { snackActions } from 'config/snackbar.js';
import {
  defaultOrderTypes,
  signatureTypes,
} from 'pages/ordercustomers/warehousecustomer';
import { IOrderAIRuleDetail } from 'pages/ordercustomers/warehousecustomer/customer-rules-management';
import {
  getCartonCodeDDL,
  getCartonCodes,
  getPackagingList,
} from 'services/api/cartoncodes/cartoncodes.api';
import { getCountries } from 'services/api/country/country.api';
import {
  getCustomerOrderSourceRulesByCustomerId,
  getCustomerRulesManagementByCustomerId,
  updateCustomerRulesManagement,
} from 'services/api/customer/customer.api';
import { getCarrierAccountByCustomer } from 'services/api/integrations/easypost/easypost.api';
import { getOrderCarrierservices } from 'services/api/integrations/shipment/shipment.api';
import { getSkuLookupWithwarehouseCustomer } from 'services/api/salesorders/salesorders-new.api';
import {
  getCustomerInboundSection,
  getCustomerOutboundSection,
} from 'services/api/uoms/uoms.api';
import { AuthContext } from 'store/contexts/AuthContext';

const OrderSourceRuleSettingsContext = createContext(
  {} as {
    countriesSettings: any[];
    skuMappingOptionSettings: any[];
    carrierTypesOptionSettings: any[];
    setFormSetting: React.Dispatch<any>;
    formSetting: any;
    setOrderAIRuleDetailSetting: React.Dispatch<
      React.SetStateAction<IOrderAIRuleDetail>
    >;
    orderAIRuleDetailSetting: IOrderAIRuleDetail;
    formErrors: any;
    handleAddOrderSourceOnlickSetting: () => Promise<void>;
    isShowRulesFormSettings: boolean;
    setIsShowRulesFormSettings: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditOrderSourceOnlickSetting: (params: any) => void;
    handleSaveUpdateCustomerRulesManagement: () => Promise<void>;
    isRulesFormEditModeSettings: boolean;
    setIsManageClientDefaultSetting: React.Dispatch<
      React.SetStateAction<boolean>
    >;
    isManageClientDefaultSetting: boolean;
    defaultPackgeTypeOptionSetting: any[];
    handleOnDefaultPackageTypeSetting: (inputCarriers?: any[]) => Promise<any>;
    carrierTypeServiceOptionCarrierTypeSetting: any[];
    handleOnCarrierChangeArrayListSetting: (
      inputCarriers?: any[],
    ) => Promise<any>;
    handleOnCarrierChangeSetting: (inputCarrierId?: any) => Promise<any>;
  },
);

export const useOrderSourceRuleSettingsContext = () =>
  useContext(OrderSourceRuleSettingsContext);

export const OrderSourceRuleSettingsContextProvider = ({ children }: any) => {
  const { currentUser } = useContext(AuthContext);

  const [countriesSettings, setCountriesSettings] = useState([]);
  const [skuMappingOptionSettings, setSkuMappingOptionSettings] = useState([]);
  const [carrierTypesOptionSettings, setCarrierTypesOptionSettings] = useState(
    [],
  );

  const [isShowRulesFormSettings, setIsShowRulesFormSettings] = useState(false);
  const [isRulesFormEditModeSettings, setisRulesFormEditModeSettings] =
    useState(false);

  const initialFormState: any = {
    customerRuleManagementId: null,
    signatureOption: '',
    signatureOptionOrderValue: '',
    signatureOptionOrderWeight: '',
    signatureType: null,
    signatureTypeWeight: null,
    carrierSelection: '',
    carrierSelectionOrderValue: '',
    carrierSelectionOrderWeight: '',
    carrierType: null,
    carrierTypeWeight: null,
    printCartonContentLabel: '',
    shippingDocumentSettings: '',
    documentType: null,
    autoholdAllOrders: '',
    internationalOrders: '',
    internationalOrdersDollarValue: '',
    acceptReturns: '',
    maxAllowedReturnDays: '',
    overrideMaximumReturnDays: '',
    acceptReceiveInWhole: '',
    isSendAlertLowInventory: '',
    alertPrimaryEmail: '',
    alertEmailsCC: '',
    isAutoCreatePurchaseOrderLowInventory: '',
    inboundSectionSettingId: null,
    defaultReceivedToLocation: null,
    requireQualityControl: '',
    isConfirmReceiptLineItem: '',
    isAllowMultiplePalletsInOneLocation: '',
    qualityControlOnItems: [],
    outboundSectionSettingId: null,
    requireApprovalBeforePicking: '',
    packAndShipRequirePackingSlip: '',
    completeOrdersAfterPickApproval: '',
    orderSourceRulesDataRow: [],
    isUsingTote: false,
    noOfTotes: null,
  };
  const orderAIRuleDetailForm: IOrderAIRuleDetail = {
    orderSource: '',
    orderSourceDescription: '',
    orderSourceLongDescription: '',
    signatureOptionOrderValue: '',
    signatureType: null,
    signatureOptionOrderWeight: '',
    signatureTypeWeight: null,
    carrierSelectionOrderValue: '',
    carrierSelectionOrderWeight: '',
    insuranceOptionOrderValue: '',
    insuranceOptionOrderWeight: '',
    printCartonContentLabelImage: '',
    shipFromName: '',
    shipFromAddress: '',
    shipFromCity: '',
    shipFromState: '',
    shipFromZip: '',
    shipFromCountry: null,
    returnName: '',
    returnAddress: '',
    returnCity: '',
    returnState: '',
    returnZip: '',
    returnCountry: null,
    documentType: null,
    internationalOrdersDollarValue: '',
    defaultOrderType: null,
    maxAllowedReturnDays: '',
    itemMappingSkus: [],
    shippingAndCarrier: null,
    signatureOptionUseDefault: true,
    carrierSelectionUseDefault: true,
    insuranceOptionUseDefault: true,
    printCartonContentLabelUseDefault: true,
    shippingDocumentSettingsUseDefault: true,
    defaultPackageTypeUseDefault: true,
    ordersUseDefault: true,
    returnOrdersUseDefault: true,
    itemMappingUseDefault: true,
    shippingDocuments: [],
    carrierSelections: [],
    carrierSelectionServices: [],
  };

  const [orderAIRuleDetailSetting, setOrderAIRuleDetailSetting] = useState(
    orderAIRuleDetailForm,
  );
  const [formSetting, setFormSetting] = useState<any>(initialFormState);
  const [formErrors, setFormErrors] = useState<any>();
  const [isManageClientDefaultSetting, setIsManageClientDefaultSetting] =
    useState(false);

  const [defaultPackgeTypeOptionSetting, setDefaultPackgeTypeOptionSetting] =
    useState([]);

  const [
    carrierTypeServiceOptionShippingSetting,
    setCarrierTypeServiceOptionShippingSetting,
  ] = useState([]);
  const [
    carrierTypeServiceOptionCarrierTypeSetting,
    setCarrierTypeServiceOptionCarrierTypeSetting,
  ] = useState([]);

  const loadCountries = async () => {
    try {
      const countriesFromApi = await getCountries('');
      setCountriesSettings(countriesFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onLoadSkuLookupWithwarehouseCustomer = async () => {
    try {
      const skuLookupFromApi = await getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        0,
      );
      setSkuMappingOptionSettings(skuLookupFromApi);
      return true;
    } catch (err) {
      return err;
    }
  };

  const handleOnDefaultPackageTypeSetting = async (inputCarriers?: any[]) => {
    const cartonOptions = await getPackagingList();
    if (inputCarriers && inputCarriers.length > 0) {
      let setArray = [];

      inputCarriers.map(async (e) => {
        const result = await getCartonCodeDDL(e?.value);

        const toCombine = result.map((c: any) => ({
          label: c.text,
          value: c.value,
          dimensions: c.dimensions,
          isExternalPackage: c.isExternalPackage,
        }));
        setArray = [...setArray, ...toCombine];

        const toCombineDefault = cartonOptions.map((c: any) => ({
          value: c.itemId,
          label: c.descriptionDropDown,
          dimensions: c?.dimensions,
        }));
        setDefaultPackgeTypeOptionSetting([...setArray, ...toCombineDefault]);
      });
    } else {
      let setArray = [];
      const toCombineDefault = cartonOptions.map((c: any) => ({
        value: c.itemId,
        label: c.descriptionDropDown,
        dimensions: c?.dimensions,
      }));

      setArray = [...setArray, ...toCombineDefault];

      setDefaultPackgeTypeOptionSetting([...setArray]);
    }
    return null;
  };
  const handleOnCarrierChangeSetting = async (inputCarrierId?: any) => {
    if (inputCarrierId) {
      const result = await getOrderCarrierservices(inputCarrierId);

      const setArray = result.map((c: any) => ({
        value: c.value,
        label: c.text,
        isShippingRate: c.isShippingRate,
        serviceCode: c.serviceCode,
      }));

      setCarrierTypeServiceOptionShippingSetting(setArray);
    } else {
      setCarrierTypeServiceOptionShippingSetting([]);
    }
    return null;
  };

  const onLoadCustomerRulesManagement = async () => {
    try {
      const resultInbound = await getCustomerInboundSection(
        currentUser.Claim_CustomerId,
      );
      setFormSetting((e) => ({
        ...e,
        inboundSectionSettingId: resultInbound?.inboundSectionSettingId,
        defaultReceivedToLocation: resultInbound?.binId
          ? { value: resultInbound.binId, label: resultInbound?.binName }
          : null,
        isConfirmReceiptLineItem: resultInbound?.isConfirmReceiptLineItem,
        isAllowMultiplePalletsInOneLocation:
          resultInbound?.isAllowMultiplePalletsInOneLocation,
        requireQualityControl: resultInbound?.isQualityControl,
        qualityControlOnItems: resultInbound?.itemModels
          ? resultInbound.itemModels.map((v) => ({
              value: v.value,
              label: v.label,
            }))
          : [],
      }));

      const resultOutbound = await getCustomerOutboundSection(
        currentUser.Claim_CustomerId,
      );
      setFormSetting((e) => ({
        ...e,
        outboundSectionSettingId: resultOutbound?.outboundSectionSettingId,
        requireApprovalBeforePicking:
          resultOutbound?.isRequiredApprovalBeforePicking,
        packAndShipRequirePackingSlip: resultOutbound?.isPackAndShip,
        completeOrdersAfterPickApproval:
          resultOutbound?.isCompleteOrderAfterPickApproval,
        isUsingTote: resultOutbound?.isUsingTote,
        noOfTotes: resultOutbound?.noOfTotes,
        shippingIntegration: resultOutbound.shippingIntegration
          ? {
              label: resultOutbound.shippingIntegrationProviderName,
              value: resultOutbound.shippingIntegration,
            }
          : null,
        lockOrderEditsAfter: resultOutbound.lockOrderEditsAfter
          ? ({
              text: resultOutbound.lockOrderEditsAfterStr,
              value: resultOutbound.lockOrderEditsAfter,
            } as DropdownListItem)
          : null,
      }));

      // RULES MANAGEMENT ------------------------------------
      const resultCustomer = await getCustomerRulesManagementByCustomerId(
        currentUser.Claim_CustomerId,
      );

      setFormSetting((prev) => ({
        ...prev,
        customerRuleManagementId: resultCustomer?.customerRuleManagementId,
        //  Signature Option ----------------------------------------------
        signatureOptionUseDefault: resultCustomer?.signatureOptionUseDefault,
        signatureOption: resultCustomer?.signatureOption,
        signatureOptionOrderValue: resultCustomer?.signatureOptionOrderValue,
        signatureOptionOrderWeight: resultCustomer?.signatureOptionOrderWeight,
        signatureType: resultCustomer?.signatureType
          ? signatureTypes.find(
              (v) => v.value === resultCustomer?.signatureType,
            )
          : null,
        signatureTypeWeight: resultCustomer?.signatureTypeWeight
          ? signatureTypes.find(
              (v) => v.value === resultCustomer?.signatureTypeWeight,
            )
          : null,

        // Carrier Option ----------------------------------------------
        carrierSelectionUseDefault: resultCustomer?.carrierSelectionUseDefault,
        carrierSelection: resultCustomer?.carrierSelection,
        carrierSelectionOrderValue: resultCustomer?.carrierSelectionOrderValue,
        carrierSelectionOrderWeight:
          resultCustomer?.carrierSelectionOrderWeight,

        // Insurance Option ----------------------------------------------
        insuranceOptionUseDefault: resultCustomer?.insuranceOptionUseDefault,
        insuranceOption: resultCustomer?.insuranceOption,
        insuranceOptionOrderValue: resultCustomer?.insuranceOptionOrderValue,
        insuranceOptionOrderWeight: resultCustomer?.insuranceOptionOrderWeight,

        // Print Carton Content Label -------------------------------------
        printCartonContentLabelUseDefault:
          resultCustomer?.printCartonContentLabelUseDefault,
        printCartonContentLabel: resultCustomer?.printCartonContentLabel,

        // Shipping Document Settings ------------------------------------
        shippingDocumentSettingsUseDefault:
          resultCustomer?.shippingDocumentSettingsUseDefault,
        shippingDocumentSettings: resultCustomer?.shippingDocumentSettings,
        printCartonContentLabelImage:
          resultCustomer?.printCartonContentLabelImage,
        overrideShipFromAddress: resultCustomer?.overrideShipFromAddress,
        shipFromName: resultCustomer?.shipFromName,
        shipFromAddress: resultCustomer?.shipFromAddress,
        shipFromCity: resultCustomer?.shipFromCity,
        shipFromState: resultCustomer?.shipFromState,
        shipFromZip: resultCustomer?.shipFromZip,
        shipFromCountry: resultCustomer?.shipFromCountry
          ? {
              value: resultCustomer?.shipFromCountry,
              label: resultCustomer?.shipFromCountryStr,
            }
          : null,
        overrideReturnFromAddress: resultCustomer?.overrideReturnFromAddress,
        returnName: resultCustomer?.returnName,
        returnAddress: resultCustomer?.returnAddress,
        returnCity: resultCustomer?.returnCity,
        returnState: resultCustomer?.returnState,
        returnZip: resultCustomer?.returnZip,
        returnCountry: resultCustomer?.returnCountry
          ? {
              value: resultCustomer?.returnCountry,
              label: resultCustomer?.returnCountryStr,
            }
          : null,

        // Default Package -----------------------------------------------
        defaultPackageTypeUseDefault:
          resultCustomer?.defaultPackageTypeUseDefault,
        defaultPackageType: resultCustomer?.defaultPackageType
          ? defaultPackgeTypeOptionSetting.find(
              (e) => e.value === resultCustomer?.defaultPackageType,
            )
          : null,

        // Orders -------------------------------------------------------
        ordersUseDefault: resultCustomer?.ordersUseDefault,
        internationalOrders: resultCustomer?.internationalOrders,
        internationalOrdersDollarValue:
          resultCustomer?.internationalOrdersDollarValue,
        autoholdAllOrders: resultCustomer?.autoholdAllOrders,
        defaultOrderType: resultCustomer?.defaultOrderType
          ? defaultOrderTypes.find(
              (e) => e.value === resultCustomer?.defaultOrderType,
            )
          : null,

        // Return Orders ------------------------------------------------
        returnOrdersUseDefault: resultCustomer?.returnOrdersUseDefault,
        acceptReturns: resultCustomer?.acceptReturns,
        maxAllowedReturnDays: resultCustomer?.maxAllowedReturnDays,
        overrideMaximumReturnDays: resultCustomer?.overrideMaximumReturnDays,
        acceptReceiveInWhole: resultCustomer?.acceptReceiveInWhole,

        itemMappingUseDefault: resultCustomer?.itemMappingUseDefault,
        itemMappingSkus: resultCustomer?.itemMappingSkus
          ? resultCustomer?.itemMappingSkus.map((i) => ({
              dataGridId: Math.random(),
              customerRuleManagementSkuMappingId:
                i?.customerRuleManagementSkuMappingId,
              itemId: i?.itemId,
              sku: i?.name,
              skuMapping: i?.skuMapping,
            }))
          : [],

        shippingDocuments: resultCustomer?.shippingDocuments
          ? resultCustomer?.shippingDocuments.map((i) => ({
              customerRuleManagementShippingDocumentId:
                i?.customerRuleManagementShippingDocumentId,
              value: i?.shippingDocumentId,
              label: i?.name,
            }))
          : [],

        carrierSelections: resultCustomer?.carrierSelections
          ? resultCustomer?.carrierSelections.map((i) => ({
              dataGridId: Math.random(),
              customerRuleManagementCarrierId:
                i?.customerRuleManagementCarrierId,
              carrierSelection: {
                value: i?.carrierId,
                label: i?.carrierName,
              },
              carrierSelectionId: i?.carrierId,
              carrierSelectionName: i?.carrierName,
              carrierSelectionService: {
                value: i?.carrierServiceId,
                label: i?.carrierServiceName,
              },
              carrierSelectionServiceId: i?.carrierServiceId,
              carrierSelectionServiceName: i?.carrierServiceName,
              carrierZipcode: i?.zipcode,
              carrierAccountName: i?.accountName,
              carrierthirdPartyAccount: i?.thirdPartyAccount,
            }))
          : [],

        carrierSelectionServices: resultCustomer?.carrierSelectionServices
          ? resultCustomer?.carrierSelectionServices.map((i) => ({
              customerRuleManagementCarrierServiceId:
                i?.customerRuleManagementCarrierServiceId,
              value: i?.carrierServiceId,
              label: i?.carrierServiceName,
            }))
          : [],

        isSendAlertLowInventory: resultCustomer?.isSendAlertLowInventory,
        alertPrimaryEmail: resultCustomer?.alertPrimaryEmail,
        alertEmailsCC: resultCustomer?.alertEmailsCC,
        isAutoCreatePurchaseOrderLowInventory:
          resultCustomer?.isAutoCreatePurchaseOrderLowInventory,
      }));

      // ORDER SOURCE ------------------------------------
      const resultOrderSource = await getCustomerOrderSourceRulesByCustomerId(
        currentUser.Claim_CustomerId,
      );
      setFormSetting((prev) => ({
        ...prev,
        orderSourceRulesDataRow: resultOrderSource
          ? resultOrderSource.map((v) => ({
              customerOrderSourceRuleId: v.customerOrderSourceRuleId,
              orderSource: v.orderSourceName,
              orderSourceDescription: v.description,
              orderSourceLongDescription: v.longDescription,
              customerRuleManagementId:
                v?.rulesManagement?.customerRuleManagementId,

              //  Signature Option ----------------------------------------------
              signatureOptionUseDefault:
                v?.rulesManagement?.signatureOptionUseDefault,
              signatureOption: v?.rulesManagement?.signatureOption,
              signatureOptionOrderValue:
                v?.rulesManagement?.signatureOptionOrderValue,
              signatureType: v.rulesManagement?.signatureType
                ? signatureTypes.find(
                    (e) => e.value === v.rulesManagement?.signatureType,
                  )
                : null,
              signatureTypeWeight: v.rulesManagement?.signatureTypeWeight
                ? signatureTypes.find(
                    (e) => e.value === v.rulesManagement?.signatureTypeWeight,
                  )
                : null,
              signatureOptionOrderWeight:
                v?.rulesManagement?.signatureOptionOrderWeight,

              // Carrier Option ----------------------------------------------
              carrierSelectionUseDefault:
                v?.rulesManagement?.carrierSelectionUseDefault,
              carrierSelection: v?.rulesManagement?.carrierSelection,
              carrierSelectionOrderValue:
                v?.rulesManagement?.carrierSelectionOrderValue,
              carrierSelectionOrderWeight:
                v?.rulesManagement?.carrierSelectionOrderWeight,

              // Insurance Option ----------------------------------------------
              insuranceOptionUseDefault:
                v?.rulesManagement?.insuranceOptionUseDefault,
              insuranceOption: v?.rulesManagement?.insuranceOption,
              insuranceOptionOrderValue:
                v?.rulesManagement?.insuranceOptionOrderValue,
              insuranceOptionOrderWeight:
                v?.rulesManagement?.insuranceOptionOrderWeight,

              // Print Carton Content Label -------------------------------------
              printCartonContentLabelUseDefault:
                v?.rulesManagement?.printCartonContentLabelUseDefault,
              printCartonContentLabel:
                v?.rulesManagement?.printCartonContentLabel,

              // Shipping Document Settings ------------------------------------
              shippingDocumentSettingsUseDefault:
                v?.rulesManagement?.shippingDocumentSettingsUseDefault,
              shippingDocumentSettings:
                v?.rulesManagement?.shippingDocumentSettings,
              printCartonContentLabelImage:
                v.rulesManagement?.printCartonContentLabelImage,
              overrideShipFromAddress:
                v?.rulesManagement?.overrideShipFromAddress,
              shipFromName: v?.rulesManagement?.shipFromName,
              shipFromAddress: v?.rulesManagement?.shipFromAddress,
              shipFromCity: v?.rulesManagement?.shipFromCity,
              shipFromState: v?.rulesManagement?.shipFromState,
              shipFromZip: v?.rulesManagement?.shipFromZip,
              shipFromCountry: v?.rulesManagement?.shipFromCountry
                ? {
                    value: v?.rulesManagement.shipFromCountry,
                    label: v?.rulesManagement.shipFromCountryStr,
                  }
                : null,
              overrideReturnFromAddress:
                v?.rulesManagement?.overrideReturnFromAddress,
              returnName: v?.rulesManagement?.returnName,
              returnAddress: v?.rulesManagement?.returnAddress,
              returnCity: v?.rulesManagement?.returnCity,
              returnState: v?.rulesManagement?.returnState,
              returnZip: v?.rulesManagement?.returnZip,
              returnCountry: v?.rulesManagement?.returnCountry
                ? {
                    value: v?.rulesManagement.returnCountry,
                    label: v?.rulesManagement.returnCountryStr,
                  }
                : null,

              // Default Package -----------------------------------------------
              defaultPackageTypeUseDefault:
                v?.rulesManagement?.defaultPackageTypeUseDefault,
              defaultPackageType: v?.rulesManagement?.defaultPackageType
                ? defaultPackgeTypeOptionSetting.find(
                    (e) => e.value === v.rulesManagement.defaultPackageType,
                  )
                : null,

              // Orders -------------------------------------------------------
              ordersUseDefault: v?.rulesManagement?.ordersUseDefault,
              internationalOrders: v?.rulesManagement?.internationalOrders,
              internationalOrdersDollarValue:
                v?.rulesManagement?.internationalOrdersDollarValue,
              autoholdAllOrders: v?.rulesManagement?.autoholdAllOrders,
              defaultOrderType: v?.rulesManagement?.defaultOrderType
                ? defaultOrderTypes.find(
                    (e) => e.value === v.rulesManagement?.defaultOrderType,
                  )
                : null,

              // Return Orders ------------------------------------------------
              returnOrdersUseDefault:
                v?.rulesManagement?.returnOrdersUseDefault,
              acceptReturns: v?.rulesManagement?.acceptReturns,
              maxAllowedReturnDays: v?.rulesManagement?.maxAllowedReturnDays,
              overrideMaximumReturnDays:
                v?.rulesManagement?.overrideMaximumReturnDays,
              acceptReceiveInWhole: v?.rulesManagement?.acceptReceiveInWhole,

              itemMappingUseDefault: v?.rulesManagement?.itemMappingUseDefault,
              itemMappingSkus: v?.rulesManagement?.itemMappingSkus
                ? v.rulesManagement?.itemMappingSkus.map((i) => ({
                    dataGridId: Math.random(),
                    customerRuleManagementSkuMappingId:
                      i?.customerRuleManagementSkuMappingId,
                    itemId: i?.itemId,
                    sku: i?.name,
                    skuMapping: i?.skuMapping,
                  }))
                : [],

              shippingDocuments: v?.rulesManagement?.shippingDocuments
                ? v.rulesManagement?.shippingDocuments.map((i) => ({
                    customerRuleManagementShippingDocumentId:
                      i?.customerRuleManagementShippingDocumentId,
                    value: i?.shippingDocumentId,
                    label: i?.name,
                  }))
                : [],

              carrierSelections: v?.rulesManagement?.carrierSelections
                ? v.rulesManagement?.carrierSelections.map((i) => ({
                    dataGridId: Math.random(),
                    customerRuleManagementCarrierId:
                      i?.customerRuleManagementCarrierId,
                    carrierSelection: {
                      value: i?.carrierId,
                      label: i?.carrierName,
                    },
                    carrierSelectionId: i?.carrierId,
                    carrierSelectionName: i?.carrierName,
                    carrierSelectionService: {
                      value: i?.carrierServiceId,
                      label: i?.carrierServiceName,
                    },
                    carrierSelectionServiceId: i?.carrierServiceId,
                    carrierSelectionServiceName: i?.carrierServiceName,
                    carrierZipcode: i?.zipcode,
                    carrierAccountName: i?.accountName,
                    carrierthirdPartyAccount: i?.thirdPartyAccount,
                  }))
                : [],

              carrierSelectionServices: v?.rulesManagement
                ?.carrierSelectionServices
                ? v.rulesManagement?.carrierSelectionServices.map((i) => ({
                    customerRuleManagementCarrierServiceId:
                      i?.customerRuleManagementCarrierServiceId,
                    value: i?.carrierServiceId,
                    label: i?.carrierServiceName,
                  }))
                : [],
            }))
          : [],
      }));

      return true;
    } catch (err) {
      return err;
    }
  };

  const validateOnlick = (fieldValues = formSetting) => {
    const temp: any = {};

    if (fieldValues.isSendAlertLowInventory) {
      if (fieldValues.alertPrimaryEmail) {
        temp.alertPrimaryEmail = /$^|.+@.+..+/.test(
          fieldValues.alertPrimaryEmail,
        )
          ? ''
          : 'Email is not valid';
      } else {
        temp.alertPrimaryEmail = fieldValues.alertPrimaryEmail
          ? ''
          : 'This field is required';
      }
    }

    if (isShowRulesFormSettings) {
      temp.orderSource = orderAIRuleDetailSetting?.orderSource
        ? ''
        : 'This field is required';
      temp.orderSourceDescription =
        orderAIRuleDetailSetting?.orderSourceDescription
          ? ''
          : 'This field is required';
    }

    setFormErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  const handleSaveUpdateCustomerRulesManagement = async () => {
    try {
      if (!validateOnlick()) {
        return;
      }

      const payload: any = {
        customerId: parseInt(currentUser.Claim_CustomerId, 10),
        rulesManagement: {
          customerRuleManagementId: formSetting?.customerRuleManagementId,
          customerId: parseInt(currentUser.Claim_CustomerId, 10),

          //  Signature Option ----------------------------------------------
          signatureOptionUseDefault: false,
          signatureOption: formSetting?.signatureOption,
          signatureOptionOrderValue: formSetting?.signatureOptionOrderValue,
          signatureType: formSetting?.signatureType?.value,
          signatureOptionOrderWeight: formSetting?.signatureOptionOrderWeight,
          signatureTypeWeight: formSetting?.signatureTypeWeight?.value,

          // Carrier Option ----------------------------------------------
          carrierSelectionUseDefault: false,
          carrierSelection: formSetting?.carrierSelection,
          carrierSelectionOrderValue: formSetting?.carrierSelectionOrderValue,
          carrierSelectionOrderWeight: formSetting?.carrierSelectionOrderWeight,

          // Insurance Option ----------------------------------------------
          insuranceOptionUseDefault: false,
          insuranceOption: formSetting?.insuranceOption,
          insuranceOptionOrderValue: formSetting?.insuranceOptionOrderValue,
          insuranceOptionOrderWeight: formSetting?.insuranceOptionOrderWeight,

          // Print Carton Content Label -------------------------------------
          printCartonContentLabelUseDefault:
            formSetting?.printCartonContentLabelUseDefault,
          printCartonContentLabel: formSetting?.printCartonContentLabel,

          // Shipping Document Settings ------------------------------------
          shippingDocumentSettingsUseDefault: false,
          shippingDocumentSettings: formSetting?.shippingDocumentSettings,
          printCartonContentLabelImage:
            formSetting?.printCartonContentLabelImage,
          overrideShipFromAddress: formSetting?.overrideShipFromAddress,
          shipFromName: formSetting?.shipFromName,
          shipFromAddress: formSetting?.shipFromAddress,
          shipFromCity: formSetting?.shipFromCity,
          shipFromState: formSetting?.shipFromState,
          shipFromZip: formSetting?.shipFromZip,
          shipFromCountry: formSetting?.shipFromCountry?.value,
          overrideReturnFromAddress: formSetting?.overrideReturnFromAddress,
          returnName: formSetting?.returnName,
          returnAddress: formSetting?.returnAddress,
          returnCity: formSetting?.returnCity,
          returnState: formSetting?.returnState,
          returnZip: formSetting?.returnZip,
          returnCountry: formSetting?.returnCountry?.value,

          // Default Package -----------------------------------------------
          defaultPackageTypeUseDefault: false,
          defaultPackageType: formSetting?.defaultPackageType?.value,
          defaultPackageTypeName: formSetting?.defaultPackageType?.label,

          // Orders -------------------------------------------------------
          ordersUseDefault: false,
          autoholdAllOrders: formSetting?.autoholdAllOrders,
          internationalOrders: formSetting?.internationalOrders,
          internationalOrdersDollarValue:
            formSetting?.internationalOrdersDollarValue,
          defaultOrderType: formSetting?.defaultOrderType?.value,

          // Return Orders ------------------------------------------------
          acceptReturns: formSetting?.acceptReturns,
          maxAllowedReturnDays: formSetting?.maxAllowedReturnDays,
          overrideMaximumReturnDays: formSetting?.overrideMaximumReturnDays,
          acceptReceiveInWhole: formSetting?.acceptReceiveInWhole,

          itemMappingUseDefault: formSetting?.itemMappingUseDefault,
          itemMappingSkus: formSetting?.itemMappingSkus
            ? formSetting.itemMappingSkus.map((i) => ({
                customerRuleManagementSkuMappingId:
                  i?.customerRuleManagementSkuMappingId,
                itemId: i?.itemId,
                name: i?.skuMapping,
              }))
            : [],

          // Carrier Shipment
          shippingDocuments: formSetting?.shippingDocuments
            ? formSetting.shippingDocuments.map((i) => ({
                customerRuleManagementShippingDocumentId:
                  i?.customerRuleManagementShippingDocumentId,
                shippingDocumentId: i?.value,
                name: i?.label,
              }))
            : [],
          // CARRIER LIST
          carrierSelections: formSetting?.carrierSelections
            ? formSetting.carrierSelections.map((i) => ({
                customerRuleManagementCarrierId:
                  i?.customerRuleManagementCarrierId,
                carrierId: i?.carrierSelectionId,
                carrierServiceId: i?.carrierSelectionServiceId,
                zipcode: i?.carrierZipcode,
                accountName: i?.carrierAccountName,
                thirdPartyAccount: i?.carrierthirdPartyAccount,
              }))
            : [],

          // CARRIER SERVICES LIST
          carrierSelectionServices: formSetting?.carrierSelectionServices
            ? formSetting.carrierSelectionServices.map((i) => ({
                customerRuleManagementCarrierServiceId:
                  i?.customerRuleManagementCarrierServiceId,
                carrierServiceId: i?.value,
                carrierServiceName: i?.label,
              }))
            : [],

          isSendAlertLowInventory: formSetting?.isSendAlertLowInventory,
          alertPrimaryEmail: formSetting?.alertPrimaryEmail,
          alertEmailsCC: formSetting?.alertEmailsCC,
          isAutoCreatePurchaseOrderLowInventory:
            formSetting?.isAutoCreatePurchaseOrderLowInventory,
        },
        inboundSettings: {
          inboundSectionSettingId: formSetting?.inboundSectionSettingId,
          binId: formSetting?.defaultReceivedToLocation?.value,
          isConfirmReceiptLineItem: formSetting?.isConfirmReceiptLineItem,
          isAllowMultiplePalletsInOneLocation:
            formSetting?.isAllowMultiplePalletsInOneLocation,
          isQualityControl: formSetting?.requireQualityControl,
          itemModels: formSetting?.qualityControlOnItems
            ? formSetting.qualityControlOnItems.map((v) => ({
                itemId: v.value,
              }))
            : null,
        },
        outboundSettings: {
          outboundSectionSettingId: formSetting?.outboundSectionSettingId,
          isRequiredApprovalBeforePicking:
            formSetting?.requireApprovalBeforePicking,
          isPackAndShip: formSetting?.packAndShipRequirePackingSlip,
          isCompleteOrderAfterPickApproval:
            formSetting?.completeOrdersAfterPickApproval,
          isUsingTote: formSetting?.isUsingTote,
          noOfTotes: formSetting?.noOfTotes,
          shippingIntegrationProvider: formSetting?.shippingIntegration?.value,
          lockOrderEditsAfter: formSetting?.lockOrderEditsAfter?.value,
        },
      };

      if (isShowRulesFormSettings) {
        // for order AI rules
        const datas = [...formSetting.orderSourceRulesDataRow];
        const newValue = datas?.find(
          (row) =>
            row.customerOrderSourceRuleId ===
            orderAIRuleDetailSetting?.customerOrderSourceRuleId,
        );
        const newState: IOrderAIRuleDetail[] = datas.map((obj) => {
          if (
            obj.customerOrderSourceRuleId ===
            orderAIRuleDetailSetting.customerOrderSourceRuleId
          ) {
            return {
              ...obj,
              customerOrderSourceRuleId:
                orderAIRuleDetailSetting?.customerOrderSourceRuleId,
              orderSource: orderAIRuleDetailSetting?.orderSource,
              orderSourceDescription:
                orderAIRuleDetailSetting?.orderSourceDescription,
              orderSourceLongDescription:
                orderAIRuleDetailSetting?.orderSourceLongDescription,

              customerRuleManagementId:
                orderAIRuleDetailSetting?.customerRuleManagementId,
              //  Signature Option ----------------------------------------------
              signatureOptionUseDefault:
                orderAIRuleDetailSetting?.signatureOptionUseDefault,
              signatureOption: orderAIRuleDetailSetting?.signatureOption,
              signatureOptionOrderValue:
                orderAIRuleDetailSetting?.signatureOptionOrderValue,
              signatureType: orderAIRuleDetailSetting?.signatureType,
              signatureOptionOrderWeight:
                orderAIRuleDetailSetting?.signatureOptionOrderWeight,
              signatureTypeWeight:
                orderAIRuleDetailSetting?.signatureTypeWeight,

              // Carrier Option ----------------------------------------------
              carrierSelectionUseDefault:
                orderAIRuleDetailSetting?.carrierSelectionUseDefault,
              carrierSelection: orderAIRuleDetailSetting?.carrierSelection,
              carrierSelectionOrderValue:
                orderAIRuleDetailSetting?.carrierSelectionOrderValue,
              carrierSelectionOrderWeight:
                orderAIRuleDetailSetting?.carrierSelectionOrderWeight,

              // Insurance Option ----------------------------------------------
              insuranceOptionUseDefault:
                orderAIRuleDetailSetting?.insuranceOptionUseDefault,
              insuranceOption: orderAIRuleDetailSetting?.insuranceOption,
              insuranceOptionOrderValue:
                orderAIRuleDetailSetting?.insuranceOptionOrderValue,

              // Print Carton Content Label -------------------------------------
              printCartonContentLabelUseDefault:
                orderAIRuleDetailSetting?.printCartonContentLabelUseDefault,
              printCartonContentLabel:
                orderAIRuleDetailSetting?.printCartonContentLabel,

              // Shipping Document Settings ------------------------------------
              shippingDocumentSettingsUseDefault:
                orderAIRuleDetailSetting?.shippingDocumentSettingsUseDefault,
              shippingDocumentSettings:
                orderAIRuleDetailSetting?.shippingDocumentSettings,
              printCartonContentLabelImage:
                orderAIRuleDetailSetting?.printCartonContentLabelImage,
              overrideShipFromAddress:
                orderAIRuleDetailSetting?.overrideShipFromAddress,
              shipFromName: orderAIRuleDetailSetting?.shipFromName,
              shipFromAddress: orderAIRuleDetailSetting?.shipFromAddress,
              shipFromCity: orderAIRuleDetailSetting?.shipFromCity,
              shipFromState: orderAIRuleDetailSetting?.shipFromState,
              shipFromZip: orderAIRuleDetailSetting?.shipFromZip,
              shipFromCountry: orderAIRuleDetailSetting?.shipFromCountry,
              overrideReturnFromAddress:
                orderAIRuleDetailSetting?.overrideReturnFromAddress,
              returnName: orderAIRuleDetailSetting?.returnName,
              returnAddress: orderAIRuleDetailSetting?.returnAddress,
              returnCity: orderAIRuleDetailSetting?.returnCity,
              returnState: orderAIRuleDetailSetting?.returnState,
              returnZip: orderAIRuleDetailSetting?.returnZip,
              returnCountry: orderAIRuleDetailSetting?.returnCountry,

              // Default Package -----------------------------------------------
              defaultPackageTypeUseDefault:
                orderAIRuleDetailSetting?.defaultPackageTypeUseDefault,
              defaultPackageType: orderAIRuleDetailSetting?.defaultPackageType,

              // Orders -------------------------------------------------------
              ordersUseDefault: orderAIRuleDetailSetting?.ordersUseDefault,
              internationalOrders:
                orderAIRuleDetailSetting?.internationalOrders,
              internationalOrdersDollarValue:
                orderAIRuleDetailSetting?.internationalOrdersDollarValue,
              autoholdAllOrders: orderAIRuleDetailSetting?.autoholdAllOrders,
              defaultOrderType: orderAIRuleDetailSetting?.defaultOrderType,
              // Return Orders ------------------------------------------------
              returnOrdersUseDefault:
                orderAIRuleDetailSetting?.returnOrdersUseDefault,
              acceptReturns: orderAIRuleDetailSetting?.acceptReturns,
              maxAllowedReturnDays:
                orderAIRuleDetailSetting?.maxAllowedReturnDays,
              overrideMaximumReturnDays:
                orderAIRuleDetailSetting?.overrideMaximumReturnDays,
              acceptReceiveInWhole:
                orderAIRuleDetailSetting?.acceptReceiveInWhole,

              itemMappingUseDefault:
                orderAIRuleDetailSetting?.itemMappingUseDefault,
              itemMappingSkus: orderAIRuleDetailSetting?.itemMappingSkus
                ? orderAIRuleDetailSetting.itemMappingSkus.map((i) => ({
                    customerRuleManagementSkuMappingId:
                      i?.customerRuleManagementSkuMappingId,
                    itemId: i?.itemId,
                    skuMapping: i?.skuMapping,
                  }))
                : [],

              // Carrier Shipment
              shippingDocuments: orderAIRuleDetailSetting?.shippingDocuments
                ? orderAIRuleDetailSetting.shippingDocuments.map((i) => ({
                    carrierId: i?.carrierId,
                    value: i?.value,
                    label: i?.label,
                  }))
                : [],
              // CARRIER LIST
              carrierSelections: orderAIRuleDetailSetting?.carrierSelections
                ? orderAIRuleDetailSetting.carrierSelections.map((i) => ({
                    customerRuleManagementCarrierId:
                      i?.customerRuleManagementCarrierId,
                    carrierSelection: i?.carrierSelection,
                    carrierSelectionId: i?.carrierSelectionId,
                    carrierSelectionName: i?.carrierSelectionName,
                    carrierSelectionService: i?.carrierSelectionService,
                    carrierSelectionServiceId: i?.carrierSelectionServiceId,
                    carrierSelectionServiceName: i?.carrierSelectionServiceName,
                    carrierZipcode: i?.carrierZipcode,
                    carrierAccountName: i?.carrierAccountName,
                    carrierthirdPartyAccount: i?.carrierthirdPartyAccount,
                  }))
                : [],

              // CARRIER SERVICES LIST
              carrierSelectionServices:
                orderAIRuleDetailSetting?.carrierSelectionServices
                  ? orderAIRuleDetailSetting.carrierSelectionServices.map(
                      (i) => ({
                        carrierServiceId: i?.carrierServiceId,
                        value: i?.value,
                        label: i?.label,
                      }),
                    )
                  : [],
            };
          }
          return obj;
        });

        const mapToListPayload = newValue
          ? newState
          : [...formSetting.orderSourceRulesDataRow, orderAIRuleDetailSetting];

        payload.customerOrderSourceRules = mapToListPayload.map((v) => ({
          customerOrderSourceRuleId: v?.customerOrderSourceRuleId,
          orderSourceName: v?.orderSource,
          Description: v?.orderSourceDescription,
          LongDescription: v?.orderSourceLongDescription,
          rulesManagement: {
            customerRuleManagementId: v?.customerRuleManagementId,
            //  Signature Option ----------------------------------------------
            signatureOptionUseDefault: v?.signatureOptionUseDefault,
            signatureOption: v?.signatureOption,
            signatureOptionOrderValue: v?.signatureOptionOrderValue,
            signatureType: v?.signatureType?.value,
            signatureOptionOrderWeight: v?.signatureOptionOrderWeight,
            signatureTypeWeight: v?.signatureTypeWeight?.value,
            // Carrier Option ----------------------------------------------
            carrierSelectionUseDefault: v?.carrierSelectionUseDefault,
            carrierSelection: v?.carrierSelection,
            useAllClientCarriersZipcode: v?.useAllClientCarriersZipcode,
            useAllClientCarriersAccountName: v?.useAllClientCarriersAccountName,
            thirdPartyAccount: v?.thirdPartyAccount,

            carrierSelectionOrderValue: v?.carrierSelectionOrderValue,
            carrierSelectionOrderWeight: v?.carrierSelectionOrderWeight,

            // Insurance Option ----------------------------------------------
            insuranceOptionUseDefault: v?.insuranceOptionUseDefault,
            insuranceOption: v?.insuranceOption,
            insuranceOptionOrderValue: v?.insuranceOptionOrderValue,
            insuranceOptionOrderWeight: v?.insuranceOptionOrderWeight,

            // Print Carton Content Label -------------------------------------
            printCartonContentLabelUseDefault:
              v?.printCartonContentLabelUseDefault,
            printCartonContentLabel: v?.printCartonContentLabel,

            // Shipping Document Settings ------------------------------------
            shippingDocumentSettingsUseDefault:
              v?.shippingDocumentSettingsUseDefault,
            shippingDocumentSettings: v?.shippingDocumentSettings,
            printCartonContentLabelImage: v?.printCartonContentLabelImage,
            overrideShipFromAddress: v?.overrideShipFromAddress,
            shipFromName: v?.shipFromName,
            shipFromAddress: v?.shipFromAddress,
            shipFromCity: v?.shipFromCity,
            shipFromState: v?.shipFromState,
            shipFromZip: v?.shipFromZip,
            shipFromCountry: v?.shipFromCountry?.value,
            overrideReturnFromAddress: v?.overrideReturnFromAddress,
            returnName: v?.returnName,
            returnAddress: v?.returnAddress,
            returnCity: v?.returnCity,
            returnState: v?.returnState,
            returnZip: v?.returnZip,
            returnCountry: v?.returnCountry?.value,

            // Default Package -----------------------------------------------
            defaultPackageTypeUseDefault: v?.defaultPackageTypeUseDefault,
            defaultPackageType: v?.defaultPackageType?.value,
            defaultPackageTypeName: v?.defaultPackageType?.label,

            // Orders -------------------------------------------------------
            ordersUseDefault: v?.ordersUseDefault,
            internationalOrders: v?.internationalOrders,
            internationalOrdersDollarValue: v?.internationalOrdersDollarValue,
            autoholdAllOrders: v?.autoholdAllOrders,
            defaultOrderType: v?.defaultOrderType?.value,

            // Return Orders ------------------------------------------------
            returnOrdersUseDefault: v?.returnOrdersUseDefault,
            acceptReturns: v?.acceptReturns,
            maxAllowedReturnDays: v?.maxAllowedReturnDays,
            overrideMaximumReturnDays: v?.overrideMaximumReturnDays,
            acceptReceiveInWhole: v?.acceptReceiveInWhole,

            itemMappingUseDefault: v?.itemMappingUseDefault,
            itemMappingSkus: v?.itemMappingSkus
              ? v.itemMappingSkus.map((i) => ({
                  customerRuleManagementSkuMappingId:
                    i?.customerRuleManagementSkuMappingId,
                  itemId: i?.itemId,
                  name: i?.skuMapping,
                }))
              : [],

            // Carrier Shipment
            shippingDocuments: v?.shippingDocuments
              ? v.shippingDocuments.map((i) => ({
                  customerRuleManagementShippingDocumentId: i?.carrierId,
                  shippingDocumentId: i?.value,
                  name: i?.label,
                }))
              : [],
            // CARRIER LIST
            carrierSelections: v?.carrierSelections
              ? v.carrierSelections.map((i) => ({
                  customerRuleManagementCarrierId:
                    i?.customerRuleManagementCarrierId,
                  carrierId: i?.carrierSelection?.value,
                  carrierServiceId: i?.carrierSelectionService?.value,
                  zipcode: i?.carrierZipcode,
                  accountName: i?.carrierAccountName,
                  thirdPartyAccount: i?.carrierthirdPartyAccount,
                }))
              : [],

            // CARRIER SERVICES LIST
            carrierSelectionServices: v?.carrierSelectionServices
              ? v.carrierSelectionServices.map((i) => ({
                  customerRuleManagementCarrierServiceId: i?.carrierServiceId,
                  carrierServiceId: i?.value,
                  carrierServiceName: i?.label,
                  ruleManagementCarrierType: i?.ruleManagementCarrierType,
                }))
              : [],
          },
        }));
      }

      await updateCustomerRulesManagement(payload);
      snackActions.success(`Rules Management has been successfully saved.`);

      if (isShowRulesFormSettings) {
        setIsShowRulesFormSettings(false);
        onLoadCustomerRulesManagement();
      }
      setIsManageClientDefaultSetting(false);
    } catch (err: any) {
      snackActions.error(`${err}`);
    }
  };

  const handleAddOrderSourceOnlickSetting = async () => {
    setOrderAIRuleDetailSetting(orderAIRuleDetailForm);
    setIsShowRulesFormSettings(true);
    setisRulesFormEditModeSettings(false);
    setFormErrors({});
  };

  const handleEditOrderSourceOnlickSetting = (params) => {
    setOrderAIRuleDetailSetting((e) => ({
      ...e,
      customerOrderSourceRuleId: params?.customerOrderSourceRuleId,
      orderSource: params?.orderSource,
      orderSourceDescription: params?.orderSourceDescription,
      orderSourceLongDescription: params?.orderSourceLongDescription,
      customerRuleManagementId: params?.customerRuleManagementId,

      //  Signature Option ----------------------------------------------
      signatureOptionUseDefault: params?.signatureOptionUseDefault,
      signatureOption: params?.signatureOption,
      signatureOptionOrderValue: params?.signatureOptionOrderValue,
      signatureType: params?.signatureType,
      signatureOptionOrderWeight: params?.signatureOptionOrderWeight,
      signatureTypeWeight: params?.signatureTypeWeight,

      // Carrier Option ----------------------------------------------
      carrierSelectionUseDefault: params?.carrierSelectionUseDefault,
      carrierSelection: params?.carrierSelection,

      carrierSelectionOrderValue: params?.carrierSelectionOrderValue,
      carrierSelectionOrderWeight: params?.carrierSelectionOrderWeight,

      // Insurance Option ----------------------------------------------
      insuranceOptionUseDefault: params?.insuranceOptionUseDefault,
      insuranceOption: params?.insuranceOption,
      insuranceOptionOrderValue: params?.insuranceOptionOrderValue,
      insuranceOptionOrderWeight: params?.insuranceOptionOrderWeight,

      // Print Carton Content Label -------------------------------------
      printCartonContentLabelUseDefault:
        params?.printCartonContentLabelUseDefault,
      printCartonContentLabel: params?.printCartonContentLabel,

      // Shipping Document Settings ------------------------------------
      shippingDocumentSettingsUseDefault:
        params?.shippingDocumentSettingsUseDefault,
      shippingDocumentSettings: params?.shippingDocumentSettings,
      printCartonContentLabelImage: params?.printCartonContentLabelImage,
      overrideShipFromAddress: params?.overrideShipFromAddress,
      shipFromName: params?.shipFromName,
      shipFromAddress: params?.shipFromAddress,
      shipFromCity: params?.shipFromCity,
      shipFromState: params?.shipFromState,
      shipFromZip: params?.shipFromZip,
      shipFromCountry: params?.shipFromCountry,
      overrideReturnFromAddress: params?.overrideReturnFromAddress,
      returnName: params?.returnName,
      returnAddress: params?.returnAddress,
      returnCity: params?.returnCity,
      returnState: params?.returnState,
      returnZip: params?.returnZip,
      returnCountry: params?.returnCountry,
      // Default Package -----------------------------------------------
      defaultPackageTypeUseDefault: params?.defaultPackageTypeUseDefault,
      defaultPackageType: params?.defaultPackageType,

      // Orders -------------------------------------------------------
      ordersUseDefault: params?.ordersUseDefault,
      internationalOrders: params?.internationalOrders,
      internationalOrdersDollarValue: params?.internationalOrdersDollarValue,
      autoholdAllOrders: params?.autoholdAllOrders,
      defaultOrderType: params?.defaultOrderType,

      // Return Orders ------------------------------------------------
      returnOrdersUseDefault: params?.returnOrdersUseDefault,
      acceptReturns: params?.acceptReturns,
      maxAllowedReturnDays: params?.maxAllowedReturnDays,
      overrideMaximumReturnDays: params?.overrideMaximumReturnDays,
      acceptReceiveInWhole: params?.acceptReceiveInWhole,

      itemMappingUseDefault: params?.itemMappingUseDefault,
      itemMappingSkus: params?.itemMappingSkus
        ? params.itemMappingSkus.map((i) => ({
            dataGridId: i?.dataGridId,
            customerRuleManagementSkuMappingId:
              i?.customerRuleManagementSkuMappingId,
            itemId: i?.itemId,
            sku: i?.sku,
            skuMapping: i?.skuMapping,
          }))
        : [],

      //  Shipment document
      shippingDocuments: params?.shippingDocuments
        ? params.shippingDocuments.map((i) => ({
            carrierId: i?.carrierId,
            value: i?.value,
            label: i?.label,
          }))
        : [],

      // CARRIER LIST
      carrierSelections: params?.carrierSelections
        ? params.carrierSelections.map((i) => ({
            carrierId: i?.carrierId,
            value: i?.value,
            label: i?.label,

            customerRuleManagementCarrierId: i?.customerRuleManagementCarrierId,
            carrierSelection: i?.carrierSelection,
            carrierSelectionId: i?.carrierSelectionId,
            carrierSelectionName: i?.carrierSelectionName,
            carrierSelectionService: i?.carrierSelectionService,
            carrierSelectionServiceId: i?.carrierSelectionServiceId,
            carrierSelectionServiceName: i?.carrierSelectionServiceName,
            carrierZipcode: i?.carrierZipcode,
            carrierAccountName: i?.carrierAccountName,
            carrierthirdPartyAccount: i?.carrierthirdPartyAccount,
          }))
        : [],

      carrierSelectionServices: params?.carrierSelectionServices
        ? params?.carrierSelectionServices.map((i) => ({
            carrierServiceId: i?.carrierServiceId,
            value: i?.value,
            label: i?.label,
          }))
        : [],
    }));
    setIsShowRulesFormSettings(true);
    setisRulesFormEditModeSettings(true);
    setFormErrors({});
  };

  const handleOnCarrierChangeArrayListSetting = async (
    inputCarriers?: any[],
  ) => {
    if (inputCarriers && inputCarriers.length > 0) {
      let setArray = [];

      inputCarriers.map(async (e) => {
        const result = await getOrderCarrierservices(e?.carrierSelectionId);

        const toCombine = result.map((c: any) => ({
          value: c.value,
          label: c.text,
          isShippingRate: c.isShippingRate,
          serviceCode: c.serviceCode,
        }));
        setArray = [...setArray, ...toCombine];

        setCarrierTypeServiceOptionCarrierTypeSetting([...setArray]);
      });
    } else {
      setCarrierTypeServiceOptionCarrierTypeSetting([]);
    }
    return null;
  };

  useEffect(() => {
    loadCountries();
    onLoadSkuLookupWithwarehouseCustomer();

    const optionsCarrier = getCarrierAccountByCustomer();
    optionsCarrier
      .then((opt) => {
        const settings = opt.map((c: any) => ({
          value: c.carrierId,
          label: c.carrierName,
        }));

        setCarrierTypesOptionSettings(settings);
        if (settings && settings.length > 0) {
          handleOnDefaultPackageTypeSetting(settings);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    onLoadCustomerRulesManagement();
  }, [defaultPackgeTypeOptionSetting, currentUser.Claim_CustomerId]);

  const value = useMemo(
    () => ({
      formErrors,
      formSetting,
      isShowRulesFormSettings,
      orderAIRuleDetailSetting,
      countriesSettings,
      skuMappingOptionSettings,
      carrierTypesOptionSettings,
      isRulesFormEditModeSettings,
      isManageClientDefaultSetting,
      defaultPackgeTypeOptionSetting,
      carrierTypeServiceOptionCarrierTypeSetting,
      setFormSetting,
      setIsShowRulesFormSettings,
      setOrderAIRuleDetailSetting,
      setIsManageClientDefaultSetting,
      handleAddOrderSourceOnlickSetting,
      handleOnDefaultPackageTypeSetting,
      handleEditOrderSourceOnlickSetting,
      handleSaveUpdateCustomerRulesManagement,
      handleOnCarrierChangeArrayListSetting,
      handleOnCarrierChangeSetting,
    }),
    [
      formErrors,
      formSetting,
      countriesSettings,
      isShowRulesFormSettings,
      orderAIRuleDetailSetting,
      skuMappingOptionSettings,
      carrierTypesOptionSettings,
      isRulesFormEditModeSettings,
      isManageClientDefaultSetting,
      defaultPackgeTypeOptionSetting,
      carrierTypeServiceOptionCarrierTypeSetting,
    ],
  );

  return (
    <OrderSourceRuleSettingsContext.Provider value={value}>
      {children}
    </OrderSourceRuleSettingsContext.Provider>
  );
};
