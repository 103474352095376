import React, { useContext, useState, useEffect } from 'react';

import Button from 'components/button';
import DatePickerInput from 'components/datepicker';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import moment from 'moment';
import { type } from 'os';
import {
  decimalValidationHelper,
  useInventoryItemDetailsContext,
} from 'pages/inventory/item/details';
import { getSupplierLookupByWarehouseCustomerSearchByName } from 'services/api/suppliers/suppliers.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Divider,
  TextField,
  Grid as MUIGrid,
  Autocomplete,
} from '@mui/material';

import { MUIContainer, MUIContent } from '../productionreportmodal';

type AutocompleteBase = {
  label?: string;
  value?: number;
};
type SupplierType = AutocompleteBase;

export default React.memo(() => {
  const { isItemCostSheetModalOpen, onCloseItemCostSheetModal } =
    useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);

  const {
    OnClickAddRecordItemCostSheet,
    formValues,
    setItemCostSheetSingleData,
    itemCostSheetSingleData,
    queryStringItemDetails,
    itemCostSheetItemRows,
    setItemCostSheetItemRows,
    pageViewer,
    setSaveCostSheetTrigger,
  } = useInventoryItemDetailsContext();

  const date = new Date();

  const initialFormItemCostSheet = {
    rowId: '',
    itemCostSheetId: '',
    sellPrice: 0,
    cost: 0,
    shippingCost: 0,
    date: '',
    partNumber: '',
    supplier: { value: -1, label: '' },
  };

  const initialFormItemCostSheetError = {
    sellPrice: '',
    cost: '',
    shippingCost: '',
    date: '',
    supplier: '',
  };

  const [itemCostSheetDataFormErrors, setItemCostSheetDataFormErrors] =
    useState<any>(initialFormItemCostSheetError);
  const [itemCostSheetData, setItemCostSheetData] = useState<any>(
    initialFormItemCostSheet,
  );

  const [optionSuppliers, setOptionSuppliers] = useState<SupplierType[]>([]);

  const onLoadSuppliers = async () => {
    try {
      return await getSupplierLookupByWarehouseCustomerSearchByName(
        currentUser.Claim_CustomerId,
        0,
        '',
      );
    } catch (err) {
      return err;
    }
  };

  const OnClickAddRecordItemCostSheetOnModal = async (model) => {
    // if form == view
    // need an identifier per row
    let tempId = null;
    if (model.itemCostSheetId === undefined || model.itemCostSheetId === null) {
      tempId = Math.random();
    }

    const toAddItem = {
      itemCostSheetId: model.itemCostSheetId,
      sellPrice: Number(model.sellPrice),
      cost: Number(model.cost),
      shippingCost: Number(model.shippingCost),
      date: model.date,
      supplierId: model?.supplier?.value,
      supplierName: model?.supplier?.label,
      partNumber: model.partNumber ? model.partNumber : null,
      itemId: queryStringItemDetails.id,
      tempId,
    };

    if (toAddItem.supplierId === -1) {
      toAddItem.supplierId = null;
    }

    if (model.itemCostSheetId !== undefined) {
      const newArray = [...itemCostSheetItemRows];
      const index = itemCostSheetItemRows.findIndex(
        (v) => v.itemCostSheetId === toAddItem.itemCostSheetId,
      );
      newArray[index] = toAddItem;
      setItemCostSheetItemRows(newArray);
    } else {
      console.log('--continue--');

      if (model.rowId !== null) {
        const newArray = [...itemCostSheetItemRows];
        const index = itemCostSheetItemRows.findIndex(
          (v) => v.rowId === model.rowId,
        );
        newArray[index] = toAddItem;
        setItemCostSheetItemRows(newArray);
      } else {
        setItemCostSheetItemRows([...itemCostSheetItemRows, toAddItem]);
      }
    }
    if (pageViewer.isViewOrEdit()) {
      setItemCostSheetSingleData((prev) => ({
        ...prev,
        supplierId: model?.supplier?.value,
      }));

      setSaveCostSheetTrigger(true);
    } else {
      setItemCostSheetSingleData({});
      onCloseItemCostSheetModal();
    }
  };

  useEffect(() => {
    const optionsSupplier = onLoadSuppliers();
    optionsSupplier
      .then((opt) => {
        setOptionSuppliers(
          opt.map((c: any) => ({
            value: c.supplierId,
            label: c.name,
          })),
        );
      })
      .catch((err) => console.log(err));

    setItemCostSheetData((prev) => ({
      ...prev,
      rowId: itemCostSheetSingleData.rowId
        ? itemCostSheetSingleData.rowId
        : null,
      itemCostSheetId: itemCostSheetSingleData.itemCostSheetId
        ? itemCostSheetSingleData.itemCostSheetId
        : undefined,
      sellPrice: itemCostSheetSingleData.sellPrice
        ? itemCostSheetSingleData.sellPrice
        : 0,
      cost: itemCostSheetSingleData.cost ? itemCostSheetSingleData.cost : 0,
      shippingCost: itemCostSheetSingleData.shippingCost
        ? itemCostSheetSingleData.shippingCost
        : 0,
      partNumber: itemCostSheetSingleData.partNumber
        ? itemCostSheetSingleData.partNumber
        : '',
      date: itemCostSheetSingleData.date
        ? moment(itemCostSheetSingleData.date).format('MM/DD/yyyy')
        : moment(date).format('MM/DD/yyyy'),
      supplier: {
        value: itemCostSheetSingleData.supplierId
          ? itemCostSheetSingleData.supplierId
          : -1,
        label: itemCostSheetSingleData.supplierName
          ? itemCostSheetSingleData.supplierName
          : '',
      },
    }));

    setItemCostSheetSingleData({});
  }, [isItemCostSheetModalOpen]);

  const handleModalClose = () => {
    onCloseItemCostSheetModal();
    setItemCostSheetSingleData({});
  };
  return (
    <Modal open={isItemCostSheetModalOpen}>
      <MUIContainer>
        <MUIContent>
          <Typography
            sx={{ marginBotton: '16px' }}
            variant="h6"
            fontWeight="bold"
          >
            Cost Sheet
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12}>
                <DatePickerInput
                  label="Date"
                  inputFormat="MM/dd/yyyy"
                  value={itemCostSheetData.date}
                  onChange={(e) =>
                    setItemCostSheetData((prev) => ({
                      ...prev,
                      date: e,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled
                      {...params}
                      error={itemCostSheetDataFormErrors.date}
                      helperText={itemCostSheetDataFormErrors.date}
                      size="small"
                    />
                  )}
                />
              </MUIGrid>

              <MUIGrid item xs={12} sm={12}>
                <TextField
                  inputProps={{
                    'data-state': 'sellPrice',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  id="outlined"
                  label="Sell Price"
                  size="small"
                  value={itemCostSheetData.sellPrice}
                  onChange={(e) => {
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setItemCostSheetData((prev) => ({
                      ...prev,
                      sellPrice: validateDecimal,
                    }));
                  }}
                  onKeyPress={(e) =>
                    !/[0-9.]/.test(e.key) && e.preventDefault()
                  }
                  error={itemCostSheetDataFormErrors.sellPrice}
                  helperText={itemCostSheetDataFormErrors.sellPrice}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={6} sm={6}>
                <TextField
                  inputProps={{
                    'data-state': 'cost',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Cost"
                  size="small"
                  name="desc"
                  value={itemCostSheetData.cost}
                  onChange={(e) => {
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setItemCostSheetData((prev) => ({
                      ...prev,
                      cost: validateDecimal,
                    }));
                  }}
                  onKeyPress={(e) =>
                    !/[0-9.]/.test(e.key) && e.preventDefault()
                  }
                  error={itemCostSheetDataFormErrors.cost}
                  helperText={itemCostSheetDataFormErrors.cost}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={6} sm={6}>
                <TextField
                  inputProps={{
                    'data-state': 'partNumber',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Part Number"
                  size="small"
                  name="desc"
                  value={itemCostSheetData.partNumber}
                  onChange={(e) => {
                    setItemCostSheetData((prev) => ({
                      ...prev,
                      partNumber: e.target.value,
                    }));
                  }}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <TextField
                  inputProps={{
                    'data-state': 'shippingCost',
                    autoComplete: autoCompleteTimeStamp().toString(),
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Shipping Cost"
                  size="small"
                  name="desc"
                  value={itemCostSheetData.shippingCost}
                  onChange={(e) => {
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setItemCostSheetData((prev) => ({
                      ...prev,
                      shippingCost: validateDecimal,
                    }));
                  }}
                  onKeyPress={(e) =>
                    !/[0-9.]/.test(e.key) && e.preventDefault()
                  }
                  error={itemCostSheetDataFormErrors.shippingCost}
                  helperText={itemCostSheetDataFormErrors.shippingCost}
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Autocomplete
                  id="controllable-states"
                  size="small"
                  options={optionSuppliers}
                  value={itemCostSheetData.supplier}
                  sx={{ width: '100%' }}
                  onChange={(event: any, values) => {
                    setItemCostSheetData((prevState) => ({
                      ...prevState,
                      supplier: values,
                    }));
                  }}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  getOptionLabel={(option: SupplierType) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      id="outlined"
                      size="small"
                      label="Supplier"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <MUIGrid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    boxShadow: 'none',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleModalClose();
                    }}
                    variant="outlined"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ padding: 1, width: '100%' }}
                    onClick={() => {
                      OnClickAddRecordItemCostSheetOnModal(itemCostSheetData);
                    }}
                  >
                    CONFIRM
                  </Button>
                </Box>
              </MUIGrid>
            </MUIGrid>
          </Box>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
