import React, { useContext, useEffect, useState, useRef } from 'react';

// import Button from 'components/button';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input/Input';
import { snackActions } from 'config/snackbar.js';
import { autoCompleteTimeStamp } from 'helpers/autocompletetimestamp';
import moment from 'moment';
import {
  itemPalletTypeOption,
  useSubpageReceiptsContext,
} from 'pages/receiving/receipts/context';
import {
  ReceiptLineItemImageType,
  FileType,
  FormQueryStringStatus,
  ModalTitleStatus,
  numberWithCommas,
  LicensePlateTypeStatus,
  LotNoType,
  ItemUnitOfMeasureType,
} from 'pages/receiving/receipts/subpage';
import { AddLotNoModal } from 'pages/shared/addlotno';
import ImageFullScreenPortal from 'pages/shared/imagefullscreenportal';
import {
  GetReasonTransferData,
  getItemById,
  getItemUnitOfMeasureDDL,
} from 'services/api/item/item.api';
import {
  createLotNo,
  getSkuItemDetail,
  getReceiptLineItem,
  getLotNo,
} from 'services/api/receipts/receipts-new.api';
import { getSkuLookupWithwarehouseCustomer } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { AutoCompleteTableHeader, AutoCompleteTablePaper } from 'styles/mui';

import { ConnectingAirportsOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import InfoIcon from '@mui/icons-material/Info';
import {
  Modal,
  Box,
  IconButton,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  Button,
  InputAdornment,
  Tooltip,
  Popper,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled as MuiStyled } from '@mui/material/styles';

export const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 1000px;
    border-radius: 20px;
    max-height: 80%;
  }
`;

export const AutoCompletePopper = (props) => (
  <Popper
    {...props}
    sx={{
      minWidth: '352px',
      width: 'max-content !important',
      height: '250px',
    }}
    placement="bottom-start"
  />
);

export const AutoCompletePaper = ({ children }) => (
  <AutoCompleteTablePaper sx={{ maxHeight: '250px' }}>
    <AutoCompleteTableHeader>
      <div className="th tw-140">SKU</div>
      <div className="th">Description</div>
    </AutoCompleteTableHeader>
    {children}
  </AutoCompleteTablePaper>
);

type AutocompleteBase = {
  label?: string;
  value?: number;
};

type LicensePlateType = AutocompleteBase;
type StatusOnArrivalType = AutocompleteBase;

export const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 40px;
    gap: 16px;
    overflow-y: auto;
    height: 100%;
  }
`;

// //Utilities
export function isNumberKey(evt) {
  const a = /^\d*\.?\d*$/;
  const regEx = new RegExp(a);
  if (regEx.test(evt.target.value)) {
    return true;
  }
  return false;
}

function calculateTotalQty(eaCase, totalCases) {
  let total = 0;
  if (eaCase !== undefined && totalCases !== undefined) {
    total = Math.round(eaCase * totalCases);
  }
  return total;
}

export default React.memo(() => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const {
    addLotNoModalData,
    isAddLotNoModalOpen,
    setAddLotNoModalData,
    setIsAddLotNoModalOpen,
    isAddLotNoUpdateOptions,
    setIsAddLotNoUpdateOptions,
    onCloseReceiptLineItemModal,
    isEditReceiptLineItemModalOpen,
  } = useContext(GlobalContext);

  const {
    singleLineItemRow,
    formValues,
    queryStringItemReceipts,
    modalTitle,
    setModalTitle,
    optionsSku,
    setOptionsSku,
    skuValue,
    setSkuValue,
    lotNoValue,
    setLotNoValue,
    setFormLineItemValues,
    formLineItemValues,
    formLineItemErrors,
    handleOnClickAddLineItem,
    optionLicensePlateType,
    optionLicensePlateValue,
    setOptionLicensePlateValue,
    optionStatusOnArrivalType,
    optionStatusOnArrivalValue,
    setOptionStatusOnArrivalValue,
    optionLotNo,
    setOptionLotNo,
    setFormLineItemErrors,
    optionLocationCrossDock,
    optionLocationCrossDockValue,
    setOptionLocationCrossDockValue,
    optionsUOM,
    uomValue,
    pageViewerReceipt,
    reasonOnStatusTypeValue,
    optionsReasonOnStatusType,
    setReasonOnStatusTypeValue,
    setOptionsReasonOnStatusType,
    setUomValue,
    setOptionsUOM,
    setOptionStatusOnArrivalType,
  } = useSubpageReceiptsContext();

  const [_oldQty, setOldQty] = useState('');
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
  const [base64DataFullScreen, setBase64DataFullScreen] =
    useState<string>(null);
  const [itemIsTrackableByLot, setItemIsTrackableByLot] = useState(false);
  const [itemIsSerialNo, setItemIsSerialNo] = useState(false);
  const [receiptPalletTypeValue, setReceiptPalletTypeValue] =
    useState<AutocompleteBase>(null);

  const onLoadSkuLookupWithwarehouseCustomer = async (warehouseCustomerId) => {
    try {
      const skuLookupFromApi = await getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        warehouseCustomerId,
      );

      return skuLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadGetSkuItemDetailOnChange = async (itemId) => {
    try {
      return await getSkuItemDetail(
        currentUser.Claim_CustomerId,
        currentLocationAndFacility.customerFacilityId,
        itemId,
      );
    } catch (err) {
      return err;
    }
  };

  const onLoadGetReceiptLineItemOnChange = async (itemId) => {
    try {
      return await getReceiptLineItem(itemId);
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLotNoOnChange = async (itemId) => {
    try {
      return await getLotNo(itemId);
    } catch (err) {
      return err;
    }
  };

  const onLoadGetItemUnitOfMeasureDDLOnChange = async (itemId) => {
    try {
      return await getItemUnitOfMeasureDDL(itemId);
    } catch (err) {
      return err;
    }
  };

  const onLoadReasonsDetails = async () => {
    try {
      const transferReasonsFromApi = await GetReasonTransferData();

      return transferReasonsFromApi;
    } catch (err) {
      return err;
    }
  };

  // https://timetoprogram.com/validate-file-size-in-react/
  const MAX_FILE_SIZE = 10240; // 10MB

  const uploadImage = (event) => {
    try {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];

        const fileSizeKiloBytes = file.size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          // remove file upload value
          fileUploadRef.current.value = '';

          snackActions.error(
            'Image size is greater than maximum limit (10MB).',
          );
          return;
        }

        if (file !== null) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const data = {} as ReceiptLineItemImageType;
            data.base64Data = reader.result.toString();

            const base64 = `${reader.result}`.split(';base64,').pop();
            const File = {
              name: file.name,
              content: base64,
              size: file.size,
            } as FileType;
            data.file = File;
            // const file = {
            //   name: file.name,
            //   size: file.size,
            //   type: file.type,
            //   content: base64,
            // } as FileType;

            const sameImages = formLineItemValues.images.filter(
              (x) => x.Base64Data === data.base64Data,
            );

            // add only if not exist
            if (sameImages.length === 0) {
              setFormLineItemValues((prevState) => ({
                ...prevState,
                images: [...prevState.images, data],
              }));
            }
          };
        }
      }

      // remove file upload value
      fileUploadRef.current.value = '';
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (
      modalTitle === ModalTitleStatus.Add ||
      modalTitle === ModalTitleStatus.Edit
    ) {
      if (optionsUOM.length > 0) {
        const uom = optionsUOM.find((x) =>
          formLineItemValues.itemUnitOfMeasureId
            ? x.value === formLineItemValues.itemUnitOfMeasureId
            : x.isPrimaryUOM,
        );

        if (uom !== undefined) {
          setUomValue((prev) => ({
            ...prev,
            value: uom.value,
            label: uom.label,
            uomAbbr: uom.uomAbbr,
            isPrimaryUOM: uom.isPrimaryUOM,
            qty: uom.qty,
            primaryUOM: uom.primaryUOM,
            primaryUOMAbbr: uom.primaryUOMAbbr,
            textInTotal: uom.textInTotal,
            primaryTextInPerCase: uom.primaryTextInPerCase,
            textInPerCase: uom.textInPerCase,
            primaryLength: uom.primaryLength,
            primaryWidth: uom.primaryWidth,
            primaryHeight: uom.primaryHeight,
            primaryWeight: uom.primaryWeight,
          }));
        }
      }
    }
  }, [optionsUOM]);

  useEffect(() => {
    if (
      (queryStringItemReceipts.form === FormQueryStringStatus.New &&
        modalTitle === ModalTitleStatus.Edit) ||
      (queryStringItemReceipts.form === FormQueryStringStatus.Edit &&
        (modalTitle === ModalTitleStatus.Edit ||
          modalTitle === ModalTitleStatus.AddHold))
    ) {
      if (formLineItemValues.itemUnitOfMeasureId === undefined) {
        formLineItemValues.itemUnitOfMeasureId = '';
      }

      if (formLineItemValues !== null) {
        // primary uom
        if (
          formLineItemValues.primaryUOM !== '' &&
          formLineItemValues.itemUnitOfMeasureId === ''
        ) {
          // set primaryUom Id to 0 at the backend
          const uom = optionsUOM.find((u) => u.value === 0);
          if (uom !== undefined) {
            setUomValue((prev) => ({
              ...prev,
              value: uom.value,
              label: uom.label,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              textInPerCase: uom.textInPerCase,
              textInTotal: uom.textInTotal,
              primaryTextInPerCase: uom.primaryTextInPerCase,
              primaryLength: uom.primaryLength,
              primaryWidth: uom.primaryWidth,
              primaryHeight: uom.primaryHeight,
              primaryWeight: uom.primaryWeight,
            }));
          }
          // other uom
        } else if (
          formLineItemValues.primaryUOM !== '' &&
          formLineItemValues.itemUnitOfMeasureId !== ''
        ) {
          const uom = optionsUOM.find(
            (u) => u.value === formLineItemValues.itemUnitOfMeasureId,
          );

          if (uom !== undefined) {
            setUomValue((prev) => ({
              ...prev,
              value: uom.value,
              label: uom.label,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              textInPerCase: uom.textInPerCase,
              textInTotal: uom.textInTotal,
              primaryTextInPerCase: uom.primaryTextInPerCase,
              primaryLength: uom.primaryLength,
              primaryWidth: uom.primaryWidth,
              primaryHeight: uom.primaryHeight,
              primaryWeight: uom.primaryWeight,
            }));
          }
        }
      }

      if (modalTitle === ModalTitleStatus.AddHold) {
        setUomValue(null);
      }
    }
  }, [optionsUOM, formLineItemValues]);

  useEffect(() => {
    if (
      (formValues.customerId !== undefined &&
        formValues.customerId !== null &&
        formValues.customerId !== '') ||
      (formValues.warehouseCustomerId !== undefined &&
        formValues.warehouseCustomerId !== null &&
        formValues.warehouseCustomerId !== '')
    ) {
      const optSkus = onLoadSkuLookupWithwarehouseCustomer(
        formValues.customerId,
      );

      optSkus
        .then((opt) => {
          setOptionsSku(
            opt.map((c: any) => ({
              sku: c.sku,
              itemId: c.itemId,
              description: c.description,
            })),
          );
        })
        .catch((err) => console.log(err));
    } else {
      setOptionsSku([]);
    }

    onLoadReasonsDetails()
      .then((x) => {
        const { reasonDDL } = x;
        setOptionsReasonOnStatusType(
          reasonDDL?.map((dropdown) => ({
            value: dropdown.value,
            label: dropdown.text,
          })),
        );
      })
      .catch((err) => snackActions.error(err));
  }, [isEditReceiptLineItemModalOpen]);

  useEffect(() => {
    if (pageViewerReceipt.isNew() && modalTitle === ModalTitleStatus.Add) {
      if (skuValue !== null) {
        const sku = optionsSku.find((o) => o.sku === skuValue?.sku);
        const res = onLoadGetSkuItemDetailOnChange(sku?.itemId);
        res.then((x) => {
          setFormLineItemValues((prevState) => ({
            ...prevState,
            // weight: x.packagingNetWeightPound,
            lineId: `NEW_${formValues.lineItems.length}`,
            sku: skuValue?.sku,
            itemId: skuValue?.itemId,
            description: x?.description,
            isSerialNo: x?.isSerialNo ?? false,
            serialNo: x?.serialNo ?? '',
            licensePlateType: x.licensePlateType === undefined ? 1 : 2,
            qtyOfPrimaryOUMPerLP: x.qtyOfPrimaryOUMPerLP,
            length: x.licensePlateLengthInch,
            width: x.licensePlateWidthInch,
            height: x.licensePlateHeightInch,
            palletWeight: x.licensePlateWeightLbs,
            receiptLineItemPalletType: x.licensePlatePalletType,
            eaCase: x.eaCase,
            totalCases: x.totalCases,
            eachesPerCase: x.eachesPerCase,
            primaryUOM: x.primaryUOM,
            primaryLength: x.primaryUnitsLength,
            primaryWidth: x.primaryUnitsWidth,
            primaryHeight: x.primaryUnitsHeight,
            primaryWeight: x.primaryUnitsWeight,
            images: [] as ReceiptLineItemImageType[],
          }));

          // if Pallet is Yes => pallet type
          setReceiptPalletTypeValue(
            x.licensePlatePalletType
              ? itemPalletTypeOption.find(
                  (v) => v.value === x.licensePlatePalletType,
                )
              : null,
          );

          const lpCheckCallback = (callback) => callback();

          if (optionLicensePlateType.length !== 0) {
            if (x.licensePlateType !== undefined) {
              const result = optionLicensePlateType.find(
                (o) =>
                  o.value ===
                  lpCheckCallback(() => {
                    let value = 1;
                    if (x.licensePlateType === 1) {
                      value = 2;
                    }
                    return value;
                  }),
              );
              if (result !== undefined) {
                setOptionLicensePlateValue(result);
              }
            } else {
              const result = optionLicensePlateType.find(
                (o) => o.label === LicensePlateTypeStatus.No,
              );
              if (result !== undefined) {
                setOptionLicensePlateValue(result);
                setFormLineItemValues((prevState) => ({
                  ...prevState,
                  licensePlateType: result.value,
                }));
              }
            }
          }

          if (optionStatusOnArrivalType.length !== 0) {
            const result = optionStatusOnArrivalType.find(
              (o) => o.label === x.sku,
            );
            if (result !== undefined) {
              setOptionStatusOnArrivalValue(result);
            }
          }
        });

        onLoadGetItemUnitOfMeasureDDLOnChange(sku?.itemId).then((x) => {
          setOptionsUOM([]);
          x.map((uom) =>
            setOptionsUOM((prev) => [
              ...prev,
              {
                value: uom.value,
                label: uom.text,
                uomAbbr: uom.uomAbbr,
                isPrimaryUOM: uom.isPrimaryUOM,
                qty: uom.qty,
                primaryUOM: uom.primaryUOM,
                primaryUOMStr: uom.primaryUOMStr,
                primaryUOMAbbr: uom.primaryUOMAbbr,
                uomType: uom.uomType,
                textInPerCase: uom.textInPerCase,
                primaryTextInPerCase: uom.primaryTextInPerCase,
                textInTotal: uom.textInTotal,
                primaryLength: uom.primaryLength,
                primaryWidth: uom.primaryWidth,
                primaryHeight: uom.primaryHeight,
                primaryWeight: uom.primaryWeight,
              },
            ]),
          );
        });

        onLoadGetLotNoOnChange(sku?.itemId).then((x) => {
          setOptionLotNo([]);
          x.map((lot) =>
            setOptionLotNo((prev) => [
              ...prev,
              {
                value: lot.lotId,
                label: lot.lotNo,
                lotCreationDate: lot.creationDate,
                lotExpirationDate: lot.expirationDate,
              },
            ]),
          );
        });
      } else {
        if (optionLicensePlateType.length !== 0) {
          const result = optionLicensePlateType.find(
            (o) => o.label === LicensePlateTypeStatus.No,
          );
          if (result !== undefined) {
            setOptionLicensePlateValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              licensePlateType: result.value,
            }));
          }
        }

        if (optionStatusOnArrivalType.length !== 0) {
          const result = optionStatusOnArrivalType.find(
            (o) => o.label === 'Available',
          );
          if (result !== undefined) {
            setOptionStatusOnArrivalValue(result);
            setFormLineItemValues((prevState) => ({
              ...prevState,
              statusOnArrival: result.value,
            }));
          }
        }
      }
    } else if (
      pageViewerReceipt.isNew() &&
      modalTitle === ModalTitleStatus.Edit
    ) {
      const lineItem = formValues.lineItems.find(
        (row) => row.lineId === singleLineItemRow.lineId,
      );
      const sku = optionsSku.find((o) => o.itemId === lineItem?.itemId);

      onLoadGetItemUnitOfMeasureDDLOnChange(lineItem?.itemId).then((x) => {
        setOptionsUOM([]);
        x.map((uom) =>
          setOptionsUOM((prev) => [
            ...prev,
            {
              value: uom.value,
              label: uom.text,
              uomAbbr: uom.uomAbbr,
              isPrimaryUOM: uom.isPrimaryUOM,
              qty: uom.qty,
              primaryUOM: uom.primaryUOM,
              primaryUOMStr: uom.primaryUOMStr,
              primaryUOMAbbr: uom.primaryUOMAbbr,
              uomType: uom.uomType,
              textInPerCase: uom.textInPerCase,
              primaryTextInPerCase: uom.primaryTextInPerCase,
              textInTotal: uom.textInTotal,
              primaryLength: uom.primaryLength,
              primaryWidth: uom.primaryWidth,
              primaryHeight: uom.primaryHeight,
              primaryWeight: uom.primaryWeight,
            },
          ]),
        );
      });

      onLoadGetLotNoOnChange(sku?.itemId).then((x) => {
        const options = [];

        x.map((lot) =>
          options.push({
            value: lot.lotId,
            label: lot.lotNo,
            lotCreationDate: lot.creationDate,
            lotExpirationDate: lot.expirationDate,
          }),
        );

        setOptionLotNo(options);

        const result = options.find(
          (o: LotNoType) => o.value === lineItem?.lotId,
        );

        if (result !== undefined) {
          setLotNoValue(result);

          setFormLineItemValues((prevState) => ({
            ...prevState,
            lotId: result.value,
            lotNo: result.label,
            lotCreationDate: result?.lotCreationDate,
            lotExpirationDate: result?.lotExpirationDate,
          }));
        }
      });

      if (optionsSku.length !== 0) {
        const result = optionsSku.find((o) => o.sku === lineItem.sku);
        if (result !== undefined) {
          setSkuValue(result);

          setFormLineItemValues((prevState) => ({
            ...prevState,
            sku: result.sku,
            itemId: result.itemId,
            primaryLength: result.primaryUnitsLength,
            primaryWidth: result.primaryUnitsWidth,
            primaryHeight: result.primaryUnitsHeight,
            primaryWeight: result.primaryUnitsWeight,
            // description: result.description,
            // isSerialNo: result.isSerialNo,
            // serialNo: '',
          }));
        }
      }

      if (optionLicensePlateType.length !== 0) {
        const result = optionLicensePlateType.find(
          (o) => o.value === lineItem.licensePlateType,
        );
        if (result !== undefined) {
          setOptionLicensePlateValue(result);

          setFormLineItemValues((prevState) => ({
            ...prevState,
            licensePlateType: result.value,
          }));
        } else {
          const licensePlateDefault = optionLicensePlateType.find(
            (o) => o.label === 'No',
          );
          if (licensePlateDefault !== undefined) {
            setOptionLicensePlateValue(licensePlateDefault);
            setFormLineItemValues((prevState) => ({
              ...prevState,
              licensePlateType: licensePlateDefault.value,
            }));
          }
        }
      }

      if (optionStatusOnArrivalType.length !== 0) {
        const result = optionStatusOnArrivalType.find(
          (o) => o.value === lineItem.statusOnArrival,
        );
        if (result !== undefined) {
          setOptionStatusOnArrivalValue(result);
          setFormLineItemValues((prevState) => ({
            ...prevState,
            statusOnArrival: result.value,
          }));
        }
      }
      setFormLineItemValues((prevState) => ({
        ...prevState,
        description: lineItem.description,
        isSerialNo: lineItem.isSerialNo,
        height: lineItem.height,
        length: lineItem.length,
        qty: lineItem.qty,
        weight: lineItem.weight,
        palletWeight: lineItem.palletWeight,
        width: lineItem.width,
        qtyOfPrimaryOUMPerLP: lineItem.qtyOfPrimaryOUMPerLP,
        eaCase: lineItem.eaCase,
        totalCases: lineItem.totalCases,
        eachesPerCase: lineItem.eachesPerCase,
        lotCreationDate: lineItem.lotCreationDate,
        lotExpirationDate: lineItem.lotExpirationDate,
        serialNo: lineItem?.serialNo ?? '',
        crossDockBinId: lineItem.crossDockBinId,
        reasonStatus: lineItem.reasonStatus,
        primaryUOM: lineItem.primaryUOM,
        primaryUOMAbbr: lineItem.primaryUOMAbbr,
        itemUnitOfMeasureId: lineItem.itemUnitOfMeasureId,
        otherUOMStr: lineItem.otherUOMStr,
        otherUOMAbbr: lineItem.otherUOMAbbr,
        otherUOMCount: lineItem.otherUOMCount,
        receiptLineItemPalletType: lineItem.receiptLineItemPalletType,
        primaryLength: lineItem.primaryLength,
        primaryWidth: lineItem.primaryWidth,
        primaryHeight: lineItem.primaryHeight,
        primaryWeight: lineItem.primaryWeight
          ? lineItem.primaryWeight
          : lineItem?.weight,
        images: lineItem.images,
        notes: lineItem.notes,
      }));

      // if Pallet is Yes => pallet type
      setReceiptPalletTypeValue(
        lineItem?.receiptLineItemPalletType
          ? itemPalletTypeOption.find(
              (v) => v.value === lineItem?.receiptLineItemPalletType,
            )
          : null,
      );

      getItemById(lineItem?.itemId).then((res) => {
        setItemIsTrackableByLot(res.isTrackableByLot);
        setItemIsSerialNo(res.isSerialNo);
      });
    }

    if (
      pageViewerReceipt.isViewOrEdit() &&
      modalTitle === ModalTitleStatus.Add
    ) {
      if (skuValue !== null) {
        const sku = optionsSku.find((o) => o.sku === skuValue?.sku);

        const res = onLoadGetSkuItemDetailOnChange(sku.itemId);

        res.then((x) => {
          setFormLineItemValues((prevState) => ({
            ...prevState,
            // weight: x.packagingNetWeightPound,
            sku: x?.sku,
            itemId: x?.itemId,
            description: x?.description,
            isSerialNo: x?.isSerialNo ?? false,
            serialNo: x?.serialNo ?? '',
            licensePlateType: x.licensePlateType === undefined ? 1 : 2,
            qtyOfPrimaryOUMPerLP: x.qtyOfPrimaryOUMPerLP,
            length: x.licensePlateLengthInch,
            width: x.licensePlateWidthInch,
            height: x.licensePlateHeightInch,
            palletWeight: x.licensePlateWeightLbs,
            receiptLineItemPalletType: x.licensePlatePalletType,
            eaCase: x.eaCase,
            totalCases: x.totalCases,
            eachesPerCase: x.eachesPerCase,
            lotCreationDate: x.lotId === undefined ? x.creationDate : null,
            lotExpirationDate: x.lotId === undefined ? x.expirationDate : null,
            primaryUOM: x.primaryUOM,
            primaryLength: x.primaryUnitsLength,
            primaryWidth: x.primaryUnitsWidth,
            primaryHeight: x.primaryUnitsHeight,
            primaryWeight: x.primaryUnitsWeight,
            images: [] as ReceiptLineItemImageType[],
          }));

          const lpCheckCallback = (callback) => callback();

          if (optionLicensePlateType.length !== 0) {
            if (x.licensePlateType !== undefined) {
              const result = optionLicensePlateType.find(
                (o) =>
                  o.value ===
                  lpCheckCallback(() => {
                    let value = 1;
                    if (x.licensePlateType === 1) {
                      value = 2;
                    }
                    return value;
                  }),
              );
              if (result !== undefined) {
                setOptionLicensePlateValue(result);
              }
            } else {
              const result = optionLicensePlateType.find(
                (o) => o.label === LicensePlateTypeStatus.No,
              );
              if (result !== undefined) {
                setOptionLicensePlateValue(result);
                setFormLineItemValues((prevState) => ({
                  ...prevState,
                  licensePlateType: result.value,
                }));
              }
            }
          }

          if (optionStatusOnArrivalType.length !== 0) {
            const result = optionStatusOnArrivalType.find(
              (o) => o.label === x.sku,
            );
            if (result !== undefined) {
              setOptionStatusOnArrivalValue(result);
            }
          }
        });

        onLoadGetItemUnitOfMeasureDDLOnChange(sku?.itemId).then((x) => {
          setOptionsUOM([]);
          x.map((uom) =>
            setOptionsUOM((prev) => [
              ...prev,
              {
                value: uom.value,
                label: uom.text,
                uomAbbr: uom.uomAbbr,
                isPrimaryUOM: uom.isPrimaryUOM,
                qty: uom.qty,
                primaryUOM: uom.primaryUOM,
                primaryUOMStr: uom.primaryUOMStr,
                primaryUOMAbbr: uom.primaryUOMAbbr,
                uomType: uom.uomType,
                textInPerCase: uom.textInPerCase,
                primaryTextInPerCase: uom.primaryTextInPerCase,
                textInTotal: uom.textInTotal,
                primaryLength: uom.primaryLength,
                primaryWidth: uom.primaryWidth,
                primaryHeight: uom.primaryHeight,
                primaryWeight: uom.primaryWeight,
              },
            ]),
          );
        });

        onLoadGetLotNoOnChange(sku?.itemId).then((x) => {
          setOptionLotNo([]);
          x.map((lot) =>
            setOptionLotNo((prev) => [
              ...prev,
              {
                value: lot.lotId,
                label: lot.lotNo,
                lotCreationDate: lot.creationDate,
                lotExpirationDate: lot.expirationDate,
              },
            ]),
          );
        });
      } else {
        if (optionLicensePlateType.length !== 0) {
          const result = optionLicensePlateType.find(
            (o) => o.label === LicensePlateTypeStatus.No,
          );
          if (result !== undefined) {
            setOptionLicensePlateValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              licensePlateType: result.value,
            }));
          }
        }

        if (optionStatusOnArrivalType.length !== 0) {
          const result = optionStatusOnArrivalType.find(
            (o) => o.label === 'Available',
          );
          if (result !== undefined) {
            setOptionStatusOnArrivalValue(result);
            setFormLineItemValues((prevState) => ({
              ...prevState,
              statusOnArrival: result.value,
            }));
          }
        }
      }
    } else if (
      pageViewerReceipt.isViewOrEdit() &&
      modalTitle === ModalTitleStatus.Edit
    ) {
      const res = onLoadGetReceiptLineItemOnChange(singleLineItemRow.lineId);
      res.then((x) => {
        setFormLineItemValues((prevState) => ({
          ...prevState,
          lineId: x.receiptLineItemId,
          sku: x.sku,
          qty: numberWithCommas(x.qty),
          description: x.description,
          isSerialNo: x?.isSerialNo ?? false,
          serialNo: x?.serialNo ?? '',
          weight: x.weight,
          licensePlateType: x.licensePlateType,
          qtyOfPrimaryOUMPerLP: x.qtyOfPrimaryOUMPerLP,
          length: x.length,
          width: x.width,
          height: x.height,
          palletWeight: x.palletWeight,
          eaCase: x.eaCase,
          totalCases: x.totalCases,
          eachesPerCase: x.eachesPerCase,
          lotCreationDate: x.lotId === undefined ? x.creationDate : null,
          lotExpirationDate: x.lotId === undefined ? x.expirationDate : null,
          crossDockBinId: x.crossDockBinId,
          reasonStatus: x.reasonStatus,
          primaryUOM: x.primaryUOM,
          primaryUOMAbbr: x.primaryUOMAbbr,
          itemUnitOfMeasureId: x.itemUnitOfMeasureId,
          otherUOMStr: x.otherUOMStr,
          otherUOMAbbr: x.otherUOMAbbr,
          otherUOMCount: x.otherUOMCount,
          receiptLineItemPalletType: x.receiptLineItemPalletType,
          primaryLength: x.primaryLength,
          primaryWidth: x.primaryWidth,
          primaryHeight: x.primaryHeight,
          primaryWeight: x.primaryWeight,
          images: x.images,
          notes: x.notes,
        }));

        // if Pallet is Yes => pallet type
        setReceiptPalletTypeValue({
          value: x.receiptLineItemPalletType,
          label: x.receiptLineItemPalletTypeStr,
        });

        setOldQty(singleLineItemRow.qty);

        if (optionsSku.length !== 0) {
          const result = optionsSku.find((o) => o.sku === x.sku);

          if (result !== undefined) {
            setSkuValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              sku: result.sku,
              itemId: result.itemId,
              description: result.description,
            }));

            getItemById(result?.itemId).then((res1) => {
              setItemIsTrackableByLot(res1.isTrackableByLot);
              setItemIsSerialNo(res1.isSerialNo);
            });
          }

          onLoadGetItemUnitOfMeasureDDLOnChange(result?.itemId).then((u) => {
            setOptionsUOM([]);
            u?.map((uom) =>
              setOptionsUOM((prev) => [
                ...prev,
                {
                  value: uom.value,
                  label: uom.text,
                  uomAbbr: uom.uomAbbr,
                  isPrimaryUOM: uom.isPrimaryUOM,
                  qty: uom.qty,
                  primaryUOM: uom.primaryUOM,
                  primaryUOMStr: uom.primaryUOMStr,
                  primaryUOMAbbr: uom.primaryUOMAbbr,
                  uomType: uom.uomType,
                  textInPerCase: uom.textInPerCase,
                  primaryTextInPerCase: uom.primaryTextInPerCase,
                  textInTotal: uom.textInTotal,
                  primaryLength: uom.primaryLength,
                  primaryWidth: uom.primaryWidth,
                  primaryHeight: uom.primaryHeight,
                  primaryWeight: uom.primaryWeight,
                },
              ]),
            );
          });

          onLoadGetLotNoOnChange(result?.itemId).then((l) => {
            const options = [];
            l?.map((lot) =>
              options.push({
                value: lot.lotId,
                label: lot.lotNo,
                lotCreationDate: lot.creationDate,
                lotExpirationDate: lot.expirationDate,
              }),
            );

            setOptionLotNo(options);

            const resultOptionLotNo = options.find(
              (o: LotNoType) => o.value === singleLineItemRow?.lotId,
            );

            if (resultOptionLotNo !== undefined) {
              setLotNoValue(resultOptionLotNo);

              const lotCreationDate =
                resultOptionLotNo.lotCreationDate === '0001-01-01T00:00:00'
                  ? null
                  : resultOptionLotNo.lotCreationDate;

              const lotExpirationDate =
                resultOptionLotNo.lotExpirationDate === '0001-01-01T00:00:00'
                  ? null
                  : resultOptionLotNo.lotExpirationDate;

              setFormLineItemValues((prevState) => ({
                ...prevState,
                lotId: resultOptionLotNo.value,
                lotNo: resultOptionLotNo.label,
                lotCreationDate,
                lotExpirationDate,
              }));
            }
          });
        }

        if (optionLicensePlateType.length !== 0) {
          const result = optionLicensePlateType.find(
            (o) => o.value === x.licensePlateType,
          );
          if (result !== undefined) {
            setOptionLicensePlateValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              licensePlateType: x.licensePlateType,
            }));
          }
        }

        if (optionStatusOnArrivalType.length !== 0) {
          const result = optionStatusOnArrivalType.find(
            (o) => o.value === x.statusOnArrival,
          );
          if (result !== undefined) {
            setOptionStatusOnArrivalValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              statusOnArrival: x.statusOnArrival,
            }));
          }
        }

        if (optionLocationCrossDock.length !== 0) {
          const result = optionLocationCrossDock.find(
            (o) => o.value === x.crossDockBinId,
          );
          if (result !== undefined) {
            setOptionLocationCrossDockValue(result);
          }
        }
        if (optionsReasonOnStatusType.length !== 0) {
          const result = optionsReasonOnStatusType.find(
            (o) => o.value === x.reasonStatus,
          );
          if (result !== undefined) {
            setReasonOnStatusTypeValue(result);
          }
        }
      });
    } else if (
      pageViewerReceipt.isViewOrEdit() &&
      modalTitle === ModalTitleStatus.AddHold
    ) {
      const res = onLoadGetReceiptLineItemOnChange(
        singleLineItemRow.lineIdToUpdateQty,
      );

      res.then((x) => {
        setFormLineItemValues((prevState) => ({
          ...prevState,
          sku: x.sku,
          qty: numberWithCommas(x.qty),
          description: x.description,
          isSerialNo: x?.isSerialNo ?? false,
          serialNo: x?.serialNo ?? '',
          weight: x.weight,
          licensePlateType: x.licensePlateType,
          qtyOfPrimaryOUMPerLP: x.qtyOfPrimaryOUMPerLP,
          length: x.length,
          width: x.width,
          height: x.height,
          palletWeight: x.palletWeight,
          eaCase: x.eaCase,
          totalCases: x.totalCases,
          eachesPerCase: x.eachesPerCase,
          lotCreationDate: x.lotId === undefined ? x.creationDate : null,
          lotExpirationDate: x.lotId === undefined ? x.expirationDate : null,
          crossDockBinId: x.crossDockBinId,
          reasonStatus: x.reasonStatus,
          primaryUOM: x.primaryUOM,
          primaryUOMAbbr: x.primaryUOMAbbr,
          itemUnitOfMeasureId: x.itemUnitOfMeasureId,
          otherUOMStr: x.otherUOMStr,
          otherUOMAbbr: x.otherUOMAbbr,
          otherUOMCount: x.otherUOMCount,
          onHoldOldQty: singleLineItemRow.qty,
          onHoldOldTotalCases: singleLineItemRow.totalCases,
          receiptLineItemPalletType:
            singleLineItemRow.receiptLineItemPalletType,
          primaryLength: singleLineItemRow.primaryLength,
          primaryWidth: singleLineItemRow.primaryWidth,
          primaryHeight: singleLineItemRow.primaryHeight,
          primaryWeight: singleLineItemRow.primaryWeight,
          images: x.images,
        }));

        setOldQty(singleLineItemRow.qty);

        if (optionsSku.length !== 0) {
          const result = optionsSku.find((o) => o.sku === x.sku);

          if (result !== undefined) {
            setSkuValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              sku: result.sku,
              itemId: result.itemId,
              description: result.description,
            }));
          }

          onLoadGetItemUnitOfMeasureDDLOnChange(result?.itemId).then((u) => {
            setOptionsUOM([]);

            // when hold Fitler optionUom to primary and selected uom
            const filtered = u.filter(
              (e) =>
                e.uomAbbr === formLineItemValues.primaryUOMAbbr ||
                e.uomAbbr === formLineItemValues?.otherUOMAbbr,
            );
            // setOptionsUOM(u);
            filtered.map((uom) =>
              setOptionsUOM((prev) => [
                ...prev,
                {
                  value: uom.value,
                  label: uom.text,
                  uomAbbr: uom.uomAbbr,
                  isPrimaryUOM: uom.isPrimaryUOM,
                  qty: uom.qty,
                  primaryUOM: uom.primaryUOM,
                  primaryUOMStr: uom.primaryUOMStr,
                  primaryUOMAbbr: uom.primaryUOMAbbr,
                  uomType: uom.uomType,
                  textInPerCase: uom.textInPerCase,
                  primaryTextInPerCase: uom.primaryTextInPerCase,
                  textInTotal: uom.textInTotal,
                  primaryLength: uom.primaryLength,
                  primaryWidth: uom.primaryWidth,
                  primaryHeight: uom.primaryHeight,
                  primaryWeight: uom.primaryWeight,
                },
              ]),
            );
          });

          onLoadGetLotNoOnChange(result?.itemId).then((l) => {
            const options = [];
            l.map((lot) =>
              options.push({
                value: lot.lotId,
                label: lot.lotNo,
                lotCreationDate: lot.creationDate,
                lotExpirationDate: lot.expirationDate,
              }),
            );

            setOptionLotNo(options);

            const resultOptionLotNo = options.find(
              (o: LotNoType) => o.value === singleLineItemRow?.lotId,
            );

            if (resultOptionLotNo !== undefined) {
              setLotNoValue(resultOptionLotNo);

              const lotCreationDate =
                resultOptionLotNo.lotCreationDate === '0001-01-01T00:00:00'
                  ? null
                  : resultOptionLotNo.lotCreationDate;

              const lotExpirationDate =
                resultOptionLotNo.lotExpirationDate === '0001-01-01T00:00:00'
                  ? null
                  : resultOptionLotNo.lotExpirationDate;

              setFormLineItemValues((prevState) => ({
                ...prevState,
                lotId: resultOptionLotNo.value,
                lotNo: resultOptionLotNo.label,
                lotCreationDate,
                lotExpirationDate,
              }));
            }
          });
        }

        if (optionLicensePlateType.length !== 0) {
          const result = optionLicensePlateType.find(
            (o) => o.value === x.licensePlateType,
          );
          if (result !== undefined) {
            setOptionLicensePlateValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              licensePlateType: x.licensePlateType,
            }));
          }
        }

        // statusOnArrival is from setSingleLineItemRow
        if (optionStatusOnArrivalType.length !== 0) {
          const result = optionStatusOnArrivalType.find(
            (o) => o.value === singleLineItemRow.statusOnArrival,
          );
          const filter = optionStatusOnArrivalType.filter(
            (e) => e.value !== 1 && e.value !== 5,
          );
          setOptionStatusOnArrivalType(filter);
          if (result !== undefined) {
            setOptionStatusOnArrivalValue(result);

            setFormLineItemValues((prevState) => ({
              ...prevState,
              statusOnArrival: singleLineItemRow.statusOnArrival,
            }));
          }
        }

        if (optionLocationCrossDock.length !== 0) {
          const result = optionLocationCrossDock.find(
            (o) => o.value === x.crossDockBinId,
          );
          if (result !== undefined) {
            setOptionLocationCrossDockValue(result);
          }
        }
        if (optionsReasonOnStatusType.length !== 0) {
          const result = optionsReasonOnStatusType.find(
            (o) => o.value === x.reasonStatus,
          );
          if (result !== undefined) {
            setReasonOnStatusTypeValue(result);
          }
        }
      });

      if (modalTitle === ModalTitleStatus.AddHold) {
        setUomValue(null);
      }
    }
  }, [modalTitle, skuValue]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  const inputRef = React.useRef<HTMLInputElement>();
  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const handleFilterOption = (options: any[], filter) => {
    const result = options.filter(
      (r) =>
        r.sku.toLowerCase().includes(filter.inputValue.toLowerCase()) ||
        r.description.toLowerCase().includes(filter.inputValue.toLowerCase()),
    );
    return result;
  };

  const imageFullScreenCloseHandler = () => {
    setShowFullScreen(false);
    setBase64DataFullScreen(null);
  };

  const handleSkuChange = (itemId) => {
    getItemById(itemId).then((res) => {
      setItemIsTrackableByLot(res.isTrackableByLot);
    });
  };

  useEffect(() => {
    if (isAddLotNoUpdateOptions) {
      onLoadGetLotNoOnChange(skuValue?.itemId).then((x) => {
        setOptionLotNo([]);
        x.map((lot) =>
          setOptionLotNo((prev) => [
            ...prev,
            {
              value: lot.lotId,
              label: lot.lotNo,
              lotCreationDate: lot.creationDate,
              lotExpirationDate: lot.expirationDate,
            },
          ]),
        );
      });

      setLotNoValue((prev) => ({
        ...prev,
        lotId: addLotNoModalData?.value,
        label: addLotNoModalData?.label,
        value: addLotNoModalData?.value,
        lotCreationDate: addLotNoModalData?.lotCreationDate,
        lotExpirationDate: addLotNoModalData?.lotExpirationDate,
      }));

      setFormLineItemValues((prevState) => ({
        ...prevState,
        lotId: addLotNoModalData?.value,
        lotNo: addLotNoModalData?.label,
        lotCreationDate: addLotNoModalData?.lotCreationDate,
        lotExpirationDate: addLotNoModalData?.lotExpirationDate,
      }));
      setIsAddLotNoUpdateOptions(false);
    }
  }, [isAddLotNoModalOpen]);

  useEffect(() => {
    let autoPalletWeight = null;
    if (!uomValue?.isPrimaryUOM) {
      autoPalletWeight =
        (formLineItemValues?.totalCases ?? 0) *
        (formLineItemValues?.primaryWeight ?? 0);
    } else {
      autoPalletWeight =
        (formLineItemValues?.qty ?? 0) *
        (formLineItemValues?.primaryWeight ?? 0);
    }
    setFormLineItemValues((prevState) => ({
      ...prevState,
      palletWeight: autoPalletWeight === 0 ? null : autoPalletWeight,
    }));
  }, [formLineItemValues?.qty, formLineItemValues?.primaryWeight]);

  return (
    <Modal open={isEditReceiptLineItemModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          {modalTitle} Receipt Line Item
        </Typography>
        <MUIContent>
          <Box autoComplete="off" component="form" noValidate>
            <Box className="modalLabelHeader">
              <Typography variant="subtitle2" fontWeight="bold">
                Search or Scan Item:
              </Typography>
              <Typography variant="body1" className="modalLabelSubText">
                Search and select SKU or scan the barcode
              </Typography>
            </Box>
            <MUIGrid
              container
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Autocomplete
                  value={skuValue}
                  disabled={modalTitle === ModalTitleStatus.AddHold}
                  onChange={(event: any, newValue: string | null | any) => {
                    if (newValue !== null) {
                      setSkuValue(newValue);
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        lineId: `NEW_${formValues.lineItems.length}`,
                        itemId: newValue?.itemId,
                      }));
                      handleSkuChange(newValue?.itemId);
                    } else {
                      setSkuValue(null);
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        sku: undefined,
                      }));
                      setItemIsTrackableByLot(false);
                    }
                    setLotNoValue(null);
                    setFormLineItemValues((prevState) => ({
                      ...prevState,
                      lotId: undefined,
                      lotNo: undefined,
                      lotCreationDate: null,
                      lotExpirationDate: null,
                      primaryUOM: '',
                      primaryUOMAbbr: '',
                      itemUnitOfMeasureId: '',
                      otherUOMStr: '',
                      otherUOMAbbr: '',
                      otherUOMCount: null,
                      totalCases: null,
                      qty: null,
                    }));
                    setOptionsUOM([]);
                    setUomValue(null);
                  }}
                  id="controllable-states"
                  options={optionsSku}
                  getOptionLabel={(option) => option.sku}
                  defaultValue={optionsSku.find((o) => o.sku === skuValue?.sku)}
                  {...(queryStringItemReceipts.form ===
                    FormQueryStringStatus.New &&
                    modalTitle === ModalTitleStatus.Edit && {
                      disabled: true,
                    })}
                  {...(queryStringItemReceipts.form ===
                    FormQueryStringStatus.Edit &&
                    modalTitle === ModalTitleStatus.Edit && {
                      disabled: true,
                    })}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  filterOptions={handleFilterOption}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option?.itemId}
                      data-description={option?.description}
                      data-itemid={option?.itemId}
                      data-isserialno={option?.isSerialNo}
                    >
                      <div className="td tw-140">{option?.sku}</div>
                      <div className="td">{option?.description}</div>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      inputRef={setTextInputRef}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...(formLineItemErrors.sku && {
                        error: true,
                        helperText: formLineItemErrors.sku,
                      })}
                      id="outlined-required"
                      label="SKU"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  )}
                  PopperComponent={AutoCompletePopper}
                  PaperComponent={AutoCompletePaper}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <TextField
                  disabled
                  value={formLineItemValues?.description || ''}
                  id="outlined"
                  label="Description"
                  size="small"
                  sx={{ width: '100%' }}
                />
              </MUIGrid>
              <MUIGrid item xs={4}>
                <Autocomplete
                  disableClearable
                  // disabled={modalTitle === ModalTitleStatus.AddHold}
                  onChange={(event: any, newValue: ItemUnitOfMeasureType) => {
                    if (newValue !== null) {
                      setUomValue(newValue);
                      if (newValue.isPrimaryUOM) {
                        setFormLineItemValues((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.value,
                          primaryUOMAbbr: newValue.uomAbbr,
                          itemUnitOfMeasureId: null,
                          otherUOMStr: '',
                          otherUOMAbbr: '',
                          otherUOMCount: null,
                          totalCases: null,
                          eachesPerCase: null,
                          qty: null,
                          qtyOfPrimaryOUMPerLP: null,
                          primaryLength: newValue.primaryLength,
                          primaryWidth: newValue.primaryWidth,
                          primaryHeight: newValue.primaryHeight,
                          primaryWeight: newValue.primaryWeight,
                        }));
                      } else {
                        setFormLineItemValues((prevState) => ({
                          ...prevState,
                          primaryUOM: newValue.primaryUOM,
                          primaryUOMAbbr: newValue.primaryUOMAbbr,
                          primaryTextInPerCase: newValue.primaryTextInPerCase,
                          itemUnitOfMeasureId: newValue.value,
                          otherUOMStr: newValue.label,
                          otherUOMAbbr: newValue.uomAbbr,
                          otherUOMCount: newValue.qty,
                          eachesPerCase: newValue.qty
                            ? newValue.qty.toString()
                            : '0',
                          totalCases: null,
                          qty: null,
                          qtyOfPrimaryOUMPerLP: null,
                          primaryLength: newValue.primaryLength,
                          primaryWidth: newValue.primaryWidth,
                          primaryHeight: newValue.primaryHeight,
                          primaryWeight: newValue.primaryWeight,
                        }));
                      }
                    } else {
                      setUomValue(null);
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        primaryUOM: '',
                        primaryUOMAbbr: '',
                        itemUnitOfMeasureId: '',
                        otherUOMStr: '',
                        otherUOMAbbr: '',
                        otherUOMCount: null,
                        totalCases: null,
                        qty: null,
                        qtyOfPrimaryOUMPerLP: null,
                      }));
                    }
                  }}
                  id="controllable-states"
                  options={optionsUOM}
                  getOptionLabel={(option: ItemUnitOfMeasureType) =>
                    option.label
                  }
                  value={uomValue}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option: ItemUnitOfMeasureType) => (
                    <li
                      {...props}
                      key={option?.value}
                      data-uomabbr={option?.uomAbbr}
                      data-isprimaryuom={option?.isPrimaryUOM}
                      data-qty={option?.qty}
                      data-primaryuom={option?.primaryUOM}
                      data-primaryuomstr={option?.primaryUOMStr}
                      data-primaryuomabbr={option?.primaryUOMAbbr}
                    >
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      autoComplete="off"
                      required
                      id="outlined-required"
                      label="Units Of Measure"
                      size="small"
                      sx={{ width: '100%' }}
                      {...(formLineItemErrors.uomValue && {
                        error: true,
                        helperText: formLineItemErrors.uomValue,
                      })}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>

            {skuValue !== null && (
              <>
                <Box className="modalLabelHeader" sx={{ marginTop: '20px' }}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Provide quantity received:
                  </Typography>
                  <Typography variant="body1" className="modalLabelSubText">
                    Enter the quantity of items based on the selected unit of
                    measure.
                  </Typography>
                </Box>
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {uomValue && !uomValue?.isPrimaryUOM && (
                    <>
                      <MUIGrid item xs={4}>
                        <TextField
                          disabled={uomValue?.isPrimaryUOM}
                          required={uomValue?.isPrimaryUOM === false}
                          onChange={(event: any) => {
                            if (isNumberKey(event)) {
                              setFormLineItemValues((prevState) => ({
                                ...prevState,
                                totalCases: event.target.value,
                                qty: (
                                  event.target.value *
                                  (prevState.eachesPerCase
                                    ? Number(prevState.eachesPerCase)
                                    : 0)
                                ).toString(),
                              }));
                            }
                          }}
                          value={formLineItemValues?.totalCases || ''}
                          {...(formLineItemErrors?.totalCases && {
                            error: true,
                            helperText: formLineItemErrors?.totalCases,
                          })}
                          id="outlined"
                          label={`Total ${uomValue?.textInTotal}`}
                          size="small"
                          sx={{ width: '100%' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    <h5>
                                      Total quantity (i.e. qty of boxes, bags,
                                      cases)
                                    </h5>
                                  }
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        color: 'white ',
                                        backgroundColor: 'rgb(46, 105, 255)',
                                      },
                                    },
                                  }}
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <TextField
                          disabled={
                            uomValue?.isPrimaryUOM ||
                            modalTitle === ModalTitleStatus.AddHold
                          }
                          required={uomValue?.isPrimaryUOM === false}
                          onChange={(event: any) => {
                            if (isNumberKey(event)) {
                              setFormLineItemValues((prevState) => ({
                                ...prevState,
                                eachesPerCase: event.target.value,
                                qty: (
                                  event.target.value *
                                  (prevState.totalCases
                                    ? Number(prevState.totalCases)
                                    : 0)
                                ).toString(),
                              }));
                            }
                          }}
                          value={formLineItemValues?.eachesPerCase || ''}
                          id="outlined"
                          label={`${uomValue?.primaryTextInPerCase} Per ${uomValue?.textInPerCase}`}
                          size="small"
                          sx={{ width: '100%' }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={<h5>Number of pieces per case</h5>}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        color: 'white ',
                                        backgroundColor: 'rgb(46, 105, 255)',
                                      },
                                    },
                                  }}
                                >
                                  <InfoIcon />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MUIGrid>
                    </>
                  )}
                  {uomValue && (
                    <MUIGrid item xs={4}>
                      <TextField
                        disabled={uomValue?.isPrimaryUOM === false}
                        required
                        onChange={(event: any) => {
                          setFormLineItemValues((prevState) => ({
                            ...prevState,
                            qty: event.target.value,
                          }));
                        }}
                        {...(formLineItemErrors.qty && {
                          error: true,
                          helperText: formLineItemErrors.qty,
                        })}
                        value={formLineItemValues?.qty || ''}
                        id="outlined-required"
                        label={`Total Qty (${
                          uomValue?.primaryUOMAbbr
                            ? uomValue?.primaryUOMAbbr
                            : uomValue?.uomAbbr
                        })`}
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    </MUIGrid>
                  )}
                </MUIGrid>
                {itemIsTrackableByLot && (
                  <MUIGrid
                    container
                    direction="row"
                    justifyContent="left"
                    alignItems="center"
                    spacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ marginTop: '0px' }}
                  >
                    <MUIGrid item md={2.3}>
                      <Autocomplete
                        filterOptions={filterOptions}
                        value={lotNoValue}
                        onChange={(event: any, newValue: LotNoType) => {
                          if (newValue !== null) {
                            setLotNoValue(newValue);
                            setFormLineItemValues((prevState) => ({
                              ...prevState,
                              lotId: newValue.value,
                              lotNo: newValue.label,
                              lotCreationDate: newValue?.lotCreationDate,
                              lotExpirationDate: newValue?.lotExpirationDate,
                            }));
                            setFormLineItemErrors({});
                          } else {
                            setLotNoValue(null);
                            setFormLineItemValues((prevState) => ({
                              ...prevState,
                              lotId: null,
                              lotNo: '',
                              lotCreationDate: null,
                              lotExpirationDate: null,
                            }));
                          }
                        }}
                        id="controllable-states"
                        options={optionLotNo}
                        getOptionLabel={(option: LotNoType) => option.label}
                        defaultValue={optionLotNo?.find(
                          (o: LotNoType) => o.label === lotNoValue?.label,
                        )}
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        {...(skuValue === null && {
                          disabled: true,
                        })}
                        renderOption={(props, option: LotNoType) => (
                          <li {...props} key={option.value}>
                            <Box display="flex">
                              <Box display="flex" flexDirection="column">
                                <Typography color="text.primary">
                                  {option.label}
                                </Typography>
                                <Typography
                                  color="text.secondary"
                                  variant="body2"
                                >
                                  {option.lotExpirationDate &&
                                    moment(option.lotExpirationDate).format(
                                      'MM/DD/YY',
                                    )}
                                </Typography>
                              </Box>
                            </Box>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            onChange={(e) => {
                              setFormLineItemValues((prevState) => ({
                                ...prevState,
                                lotNo: e.target.value,
                              }));
                            }}
                            id="outlined"
                            label="Lot No."
                            size="small"
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    </MUIGrid>
                    <MUIGrid
                      item
                      md={1.7}
                      sx={{ paddingLeft: '10px !important' }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setAddLotNoModalData((prevState) => ({
                            ...prevState,
                            value: null,
                            label: '',
                            lotId: null,
                            lotCreationDate: null,
                            lotExpirationDate: null,
                          }));
                          setIsAddLotNoModalOpen(true);
                        }}
                        sx={{
                          padding: '8px !important',
                          width: '100%',
                          textTransform: 'capitalize',
                        }}
                      >
                        + New LOT NO
                      </Button>
                    </MUIGrid>
                  </MUIGrid>
                )}

                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginTop: '0px' }}
                >
                  <MUIGrid item xs={3}>
                    <TextField
                      onChange={(event: any) => {
                        setFormLineItemValues((prevState) => ({
                          ...prevState,
                          primaryLength: event.target.value,
                        }));
                      }}
                      value={formLineItemValues?.primaryLength || ''}
                      id="outlined"
                      label="Length"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      onChange={(event: any) => {
                        setFormLineItemValues((prevState) => ({
                          ...prevState,
                          primaryWidth: event.target.value,
                        }));
                      }}
                      value={formLineItemValues?.primaryWidth || ''}
                      id="outlined"
                      label="Width"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      onChange={(event: any) => {
                        setFormLineItemValues((prevState) => ({
                          ...prevState,
                          primaryHeight: event.target.value,
                        }));
                      }}
                      value={formLineItemValues?.primaryHeight || ''}
                      id="outlined"
                      label="Height"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  </MUIGrid>
                  <MUIGrid item xs={3}>
                    <TextField
                      onChange={(event: any) => {
                        setFormLineItemValues((prevState) => ({
                          ...prevState,
                          primaryWeight: event.target.value,
                        }));
                      }}
                      value={formLineItemValues?.primaryWeight || ''}
                      id="outlined"
                      label="Weight (lbs)"
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  </MUIGrid>
                </MUIGrid>
              </>
            )}
            {skuValue !== null && formLineItemValues.isSerialNo && (
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ marginTop: 0.5 }}
              >
                <MUIGrid item xs={12} sm={12} md={4}>
                  <TextField
                    {...(skuValue === null && {
                      disabled: true,
                    })}
                    onChange={(event: any) => {
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        serialNo: event.target.value,
                      }));
                    }}
                    value={formLineItemValues?.serialNo || ''}
                    {...(formLineItemErrors.serialNo && {
                      error: true,
                      helperText: formLineItemErrors.serialNo,
                    })}
                    id="outlined"
                    label="Serial No. (List multiple separated by comma)"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
              </MUIGrid>
            )}
            {skuValue !== null && (
              <>
                <Box className="modalLabelHeader" sx={{ marginTop: '20px' }}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Provide Pallet Information
                  </Typography>
                  <Typography variant="body1" className="modalLabelSubText">
                    If you are receiving a pallet, then select Yes and specify
                    the number of pallets received containing items above.
                  </Typography>
                </Box>
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={12} sm={12} md={4}>
                    <Autocomplete
                      disableClearable
                      filterOptions={filterOptions}
                      onChange={(event: any, newValue: LicensePlateType) => {
                        if (newValue !== null) {
                          setOptionLicensePlateValue(newValue);

                          setFormLineItemValues((prevState) => ({
                            ...prevState,
                            licensePlateType: newValue.value,
                          }));
                        } else {
                          setOptionLicensePlateValue(null);
                        }
                      }}
                      id="controllable-states"
                      options={optionLicensePlateType}
                      value={optionLicensePlateValue}
                      getOptionLabel={(option: LicensePlateType) =>
                        option.label
                      }
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                          id="outlined"
                          label="Pallet"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </MUIGrid>
                  {optionLicensePlateValue &&
                  optionLicensePlateValue.label === 'No' ? null : (
                    <>
                      <MUIGrid item xs={12} sm={12} md={4}>
                        <TextField
                          required
                          onChange={(event: any) => {
                            setFormLineItemValues((prevState) => ({
                              ...prevState,
                              qtyOfPrimaryOUMPerLP: event.target.value,
                            }));
                          }}
                          value={formLineItemValues?.qtyOfPrimaryOUMPerLP || ''}
                          {...(formLineItemErrors?.qtyOfPrimaryOUMPerLP && {
                            error: true,
                            helperText:
                              formLineItemErrors?.qtyOfPrimaryOUMPerLP,
                          })}
                          id="outlined"
                          label="Total Number of Pallets"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={12} sm={12} md={4}>
                        <Autocomplete
                          onChange={(
                            event: any,
                            newValue: AutocompleteBase,
                          ) => {
                            if (newValue !== null) {
                              setReceiptPalletTypeValue(newValue);

                              setFormLineItemValues((prevState) => ({
                                ...prevState,
                                receiptLineItemPalletType: newValue.value,
                              }));
                            } else {
                              setReceiptPalletTypeValue(null);
                            }
                          }}
                          id="controllable-states"
                          options={itemPalletTypeOption}
                          value={receiptPalletTypeValue}
                          getOptionLabel={(option: AutocompleteBase) =>
                            option.label
                          }
                          isOptionEqualToValue={(option, selected) =>
                            option.value === selected.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                              }}
                              id="outlined"
                              label="Pallet Type"
                              size="small"
                              sx={{ width: '100%' }}
                            />
                          )}
                        />
                      </MUIGrid>
                    </>
                  )}
                </MUIGrid>
              </>
            )}
            {optionLicensePlateValue &&
            optionLicensePlateValue.label === 'No' ? null : (
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                sx={{ mt: 2, mb: 2 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={3}>
                  <TextField
                    onChange={(event: any) => {
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        length: event.target.value,
                      }));
                    }}
                    value={formLineItemValues?.length}
                    id="outlined"
                    label="Length (in)"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={3}>
                  <TextField
                    onChange={(event: any) => {
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        width: event.target.value,
                      }));
                    }}
                    value={formLineItemValues?.width}
                    id="outlined"
                    label="Width (in)"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={3}>
                  <TextField
                    onChange={(event: any) => {
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        height: event.target.value,
                      }));
                    }}
                    value={formLineItemValues?.height}
                    id="outlined"
                    label="Height (in)"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={3}>
                  <TextField
                    onChange={(event: any) => {
                      setFormLineItemValues((prevState) => ({
                        ...prevState,
                        palletWeight: event.target.value,
                      }));
                    }}
                    value={formLineItemValues?.palletWeight || ''}
                    id="outlined"
                    label="Weight (lbs)"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
              </MUIGrid>
            )}
            {skuValue !== null && (
              <>
                <Box className="modalLabelHeader" sx={{ marginTop: '20px' }}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Status
                  </Typography>
                </Box>
                <MUIGrid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={12} sm={12} md={4}>
                    <Autocomplete
                      disableClearable
                      filterOptions={filterOptions}
                      onChange={(event: any, newValue: StatusOnArrivalType) => {
                        if (newValue !== null) {
                          setOptionStatusOnArrivalValue(newValue);
                          setOptionLocationCrossDockValue(null);
                          setFormLineItemValues((prevState) => ({
                            ...prevState,
                            statusOnArrival: newValue.value,
                            crossDockBinId: '',
                            reasonStatus: '',
                          }));
                        } else {
                          setOptionStatusOnArrivalValue(null);
                        }
                      }}
                      id="controllable-states"
                      options={optionStatusOnArrivalType}
                      value={optionStatusOnArrivalValue}
                      getOptionLabel={(option: StatusOnArrivalType) =>
                        option.label
                      }
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                          id="outlined"
                          label="Status on Arrival"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </MUIGrid>
                  {optionStatusOnArrivalValue?.value > 1 &&
                    optionStatusOnArrivalValue?.value < 5 && (
                      <MUIGrid item xs={12} sm={12} md={4}>
                        <Autocomplete
                          onChange={(event: any, newValue: any) => {
                            if (newValue !== null) {
                              setReasonOnStatusTypeValue(newValue);
                              setFormLineItemValues((prevState) => ({
                                ...prevState,
                                reasonStatus: newValue.value,
                              }));
                            } else {
                              setReasonOnStatusTypeValue(null);
                              setFormLineItemValues((prevState) => ({
                                ...prevState,
                                reasonStatus: '',
                              }));
                            }
                          }}
                          id="controllable-states"
                          options={optionsReasonOnStatusType}
                          getOptionLabel={(option: any) => option.label}
                          value={reasonOnStatusTypeValue}
                          isOptionEqualToValue={(option, selected) =>
                            option.value === selected.value
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                              id="outlined-required"
                              label="Reason"
                              size="small"
                              sx={{ width: '100%' }}
                              {...(formLineItemErrors.reasonStatus && {
                                error: true,
                                helperText: formLineItemErrors.reasonStatus,
                              })}
                            />
                          )}
                        />
                      </MUIGrid>
                    )}
                  {optionStatusOnArrivalValue?.value === 5 && (
                    <MUIGrid item xs={12} sm={12} md={4}>
                      <Autocomplete
                        sx={{ width: '100%' }}
                        options={optionLocationCrossDock}
                        value={optionLocationCrossDockValue}
                        size="small"
                        onChange={(event: any, newValue) => {
                          if (newValue === null || newValue === undefined) {
                            setOptionLocationCrossDockValue(null);
                            setFormLineItemValues((prevState) => ({
                              ...prevState,
                              crossDockBinId: '',
                            }));
                          } else {
                            setOptionLocationCrossDockValue(newValue);
                            setFormLineItemValues((prevState) => ({
                              ...prevState,
                              crossDockBinId: newValue.value,
                            }));
                          }
                        }}
                        renderInput={(params) =>
                          formLineItemErrors.locationCrossDock ? (
                            <TextField
                              sx={{
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              {...params}
                              label="Cross Dock Location*"
                              error
                              helperText={formLineItemErrors.locationCrossDock}
                            />
                          ) : (
                            <TextField
                              sx={{
                                '& .MuiInputBase-root': {
                                  background: 'white',
                                },
                              }}
                              {...params}
                              label="Cross Dock Location*"
                            />
                          )
                        }
                      />
                    </MUIGrid>
                  )}
                </MUIGrid>
              </>
            )}

            {skuValue !== null && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1 }}>
                  <Box className="modalLabelHeader" sx={{ marginTop: '20px' }}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Upload Image
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    {formLineItemValues?.images?.map(
                      (row: ReceiptLineItemImageType) => (
                        <Box key={row.base64Data} margin={1}>
                          <Box
                            component="img"
                            src={`${row.base64Data}`}
                            sx={{
                              height: '125px',
                              width: '125px',
                              border: '2px dashed #e6e6e6',
                              borderRadius: '5px',
                              textAlign: 'center',
                              backgroundColor: '#f5f5f5',
                            }}
                            onMouseOver={() => {
                              const newState = formLineItemValues.images.map(
                                (prevState: ReceiptLineItemImageType) => ({
                                  ...prevState,
                                  showRemove:
                                    prevState.base64Data === row.base64Data,
                                }),
                              );

                              setFormLineItemValues((prevState) => ({
                                ...prevState,
                                images: newState,
                              }));
                            }}
                          />

                          {row.showRemove && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '-132px',
                                height: '125px',
                                width: '125px',
                                opacity: '.40',
                                border: '2px dashed #e6e6e6',
                                borderRadius: '5px',
                                textAlign: 'center',
                                backgroundColor: '#000',
                                cursor: 'pointer',
                              }}
                              onMouseOut={() => {
                                const newState = formLineItemValues.images.map(
                                  (prevState: ReceiptLineItemImageType) => ({
                                    ...prevState,
                                    showRemove: false,
                                  }),
                                );

                                setFormLineItemValues((prevState) => ({
                                  ...prevState,
                                  images: newState,
                                }));
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                              >
                                <IconButton
                                  color="primary"
                                  aria-label="Minus Quantity"
                                  component="label"
                                  onClick={() => {
                                    setShowFullScreen(true);
                                    setBase64DataFullScreen(row.base64Data);
                                  }}
                                >
                                  <FullscreenIcon
                                    fontSize="large"
                                    color="primary"
                                  />
                                </IconButton>

                                <IconButton
                                  color="primary"
                                  aria-label="Minus Quantity"
                                  component="label"
                                  onClick={() => {
                                    if (
                                      formLineItemValues.images.length === 1
                                    ) {
                                      setFormLineItemValues((prevState) => ({
                                        ...prevState,
                                        images:
                                          [] as ReceiptLineItemImageType[],
                                      }));
                                    } else {
                                      setFormLineItemValues((prevState) => ({
                                        ...prevState,
                                        images:
                                          formLineItemValues.images.filter(
                                            (x) =>
                                              x.base64Data !== row.base64Data,
                                          ),
                                      }));
                                    }
                                  }}
                                >
                                  <DeleteOutlineIcon
                                    fontSize="large"
                                    color="primary"
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ),
                    )}

                    <Box
                      margin={1}
                      onClick={() => {
                        fileUploadRef.current.click();
                      }}
                      sx={{
                        height: '125px',
                        width: '125px',
                        border: '2px dashed #e6e6e6',
                        borderRadius: '5px',
                        marginBottom: '3px',
                        textAlign: 'center',
                        backgroundColor: '#f5f5f5',
                        cursor: 'pointer',
                      }}
                    >
                      <AddIcon
                        sx={{
                          color: '#aeaeae',
                          fontSize: '80px',
                          marginTop: '25px',
                        }}
                      />
                    </Box>
                  </Box>
                  <input
                    ref={fileUploadRef}
                    type="file"
                    name="file"
                    accept=".jpg, .jpeg, .png"
                    multiple
                    onChange={uploadImage}
                    hidden
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box className="modalLabelHeader" sx={{ marginTop: '20px' }}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Add Note
                    </Typography>
                  </Box>
                  <TextField
                    id="outlined-multiline-notes"
                    label="Notes"
                    multiline
                    rows={4}
                    defaultValue=""
                    sx={{ width: '100%' }}
                    onChange={(event: any) => {
                      setFormLineItemValues((prev: any) => ({
                        ...prev,
                        notes: event.target.value,
                      }));
                    }}
                    value={formLineItemValues?.notes}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
            alignItems: 'center',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {formLineItemValues.qty ? (
            <MUIGrid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
              <ArchiveRoundedIcon
                sx={{
                  width: '1.5em !important',
                  height: '1.5em !important',
                  color: 'rgb(75, 126, 254) !important',
                  margin: '10px',
                }}
              />
              {formLineItemValues?.licensePlateType === 2 &&
              formLineItemValues?.qtyOfPrimaryOUMPerLP ? (
                <Typography
                  variant="h6"
                  sx={{
                    color: 'rgb(75, 126, 254) !important',
                    fontWeight: '700',
                  }}
                >
                  {formLineItemValues?.itemUnitOfMeasureId
                    ? `Receiving ${
                        formLineItemValues?.qtyOfPrimaryOUMPerLP
                      } Pallets of 
                    ${formLineItemValues.totalCases}${
                        formLineItemValues?.otherUOMAbbr
                      }(${formLineItemValues?.eachesPerCase}${
                        formLineItemValues?.primaryUOMAbbr
                      }) 
                    = Total ${
                      (formLineItemValues?.qtyOfPrimaryOUMPerLP ?? 0) *
                      formLineItemValues.qty
                    }(${
                        uomValue?.primaryUOMAbbr
                          ? uomValue?.primaryUOMAbbr
                          : uomValue?.uomAbbr
                      })  `
                    : `Receiving ${
                        formLineItemValues?.qtyOfPrimaryOUMPerLP
                      } Pallets of ${formLineItemValues.qty}(${
                        uomValue?.primaryUOMAbbr
                          ? uomValue?.primaryUOMAbbr
                          : uomValue?.uomAbbr
                      }) = Total ${
                        (formLineItemValues?.qtyOfPrimaryOUMPerLP ?? 0) *
                        formLineItemValues.qty
                      }(${
                        uomValue?.primaryUOMAbbr
                          ? uomValue?.primaryUOMAbbr
                          : uomValue?.uomAbbr
                      })
                      `}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  sx={{
                    color: 'rgb(75, 126, 254) !important',
                    fontWeight: '700',
                  }}
                >
                  {formLineItemValues?.itemUnitOfMeasureId
                    ? `Receiving ${formLineItemValues.totalCases}${
                        formLineItemValues?.otherUOMAbbr
                      }(${formLineItemValues?.eachesPerCase}${
                        formLineItemValues?.primaryUOMAbbr
                      }) = Total ${formLineItemValues.qty}(${
                        uomValue?.primaryUOMAbbr
                          ? uomValue?.primaryUOMAbbr
                          : uomValue?.uomAbbr
                      })`
                    : `Receiving ${formLineItemValues.qty}(${
                        uomValue?.primaryUOMAbbr
                          ? uomValue?.primaryUOMAbbr
                          : uomValue?.uomAbbr
                      })`}
                </Typography>
              )}
            </MUIGrid>
          ) : (
            <MUIGrid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography> </Typography>
            </MUIGrid>
          )}

          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => {
                  onCloseReceiptLineItemModal();
                  setModalTitle(null);
                  setSkuValue(null);
                  setLotNoValue(null);
                  setOptionLotNo([]);
                }}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  handleOnClickAddLineItem(e);
                }}
              >
                SUBMIT
              </Button>

              {showFullScreen && (
                <ImageFullScreenPortal
                  isOpen={showFullScreen}
                  imageSrc={base64DataFullScreen.toString()}
                  onImageFullScreenClose={imageFullScreenCloseHandler}
                />
              )}
            </Box>
          </MUIGrid>
          <AddLotNoModal paramId={skuValue?.itemId} />
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
