/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';

import {
  WarehouseCustomerChargeMethodRateEnum,
  WarehouseCustomerHandlingChargeMethodEnum,
  WarehouseCustomerHandlingChargeTypeEnum,
  WarehouseCustomerStorageChargeMethodEnum,
} from 'common/enums';
import { DropdownListItem } from 'common/models';
import { USDollar } from 'common/utils';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { itemPalletTypeOption } from 'pages/receiving/receipts/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  createAdhocChargeBilling,
  createHandlingChargeBilling,
  createStorageChargeBilling,
  editAdhocChargeBilling,
  editHandlingChargeBilling,
  editStorageChargeBilling,
} from 'services/api/warehousecustomer/warehouseCustomer.api';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridStandard } from 'styles';

import {
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Button,
  Grid as MUIGrid,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';

const UOMChargeRates = () => {
  const {
    uomChargeRates,
    setUOMChargeRates,
    setSelectedUOMChargeRate,
    setIsUOMChargeRateModal,
  } = useWarehouseCustomerContext();

  type ItemRow = typeof uomChargeRates[number];
  const itemSupplierColumns: GridColumns<ItemRow> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setSelectedUOMChargeRate({ ...params.row });
            setIsUOMChargeRateModal(true);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            if (params.row.rowId !== undefined) {
              setUOMChargeRates([
                ...uomChargeRates.filter((x) => x.rowId !== params.row.rowId),
              ]);
            }
            // exist in db
            else {
              setUOMChargeRates([
                ...uomChargeRates.filter(
                  (x) =>
                    x.warehouseCustomerHandlingChargeUOMChargeRateId !==
                    params.row.warehouseCustomerHandlingChargeUOMChargeRateId,
                ),
              ]);
            }
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'itemType',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Item Type</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.itemType.text}</Typography>
      ),
    },
    {
      field: 'sku',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
      renderCell: (params) => <Typography>{params.row.sku.text}</Typography>,
    },
    {
      field: 'itemFee',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">First Item Fee</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{USDollar.format(params.row.itemFee)}</Typography>
      ),
    },
    {
      field: 'itemAdditionalFee',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold"> Additional Item Fee</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{USDollar.format(params.row.itemAdditionalFee)}</Typography>
      ),
    },
  ];

  return (
    <DatagridStandard
      autoHeight
      rows={uomChargeRates}
      columns={itemSupplierColumns}
      pageSize={15}
      density="compact"
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      hideFooterSelectedRowCount
      rowsPerPageOptions={[15]}
      getRowId={() => Math.random()}
    />
  );
};

export default React.memo(() => {
  const {
    isBillingStorageModalOpen,
    isBillingHandlingModalOpen,
    isBillingAdhocModalOpen,
    onCloseBillingStorageModal,
    onCloseBillingHandlingModal,
    onCloseBillingAdhocModal,
  } = useContext(GlobalContext);

  const {
    billingData,
    dataModalAdhocCharge,
    dataModalHandlingCharge,
    dataModalStorageCharge,
    isAdhocChargeEdit,
    isHandlingChargeEdit,
    isStorageChargeEdit,
    setDataModalAdhocCharge,
    setDataModalHandlingCharge,
    setDataModalStorageCharge,
    storageChargeSlidingRate,
    handlingChargeSlidingRate,
    setStorageChargeSlidingRate,
    setHandlingChargeSlidingRate,
    queryString,
    viewLoadData,
    setTabValue,
    uomChargeRates,
    setUOMChargeRates,
    setSelectedUOMChargeRate,
    setIsUOMChargeRateModal,
  } = useWarehouseCustomerContext();

  type AutocompleteBase = {
    label?: string;
    value?: number;
  };

  const initialFormStateBillingAdhoc: any = {
    warehouseCustomerAdhocChargeId: 0,
    chargeName: '',
    rate: '',
    chargeType: {
      text: '',
      value: -1,
    },
  };
  const initialFormStateBillingHandling: any = {
    warehouseCustomerHandlingChargeId: 0,
    chargeType: {
      text: '',
      value: -1,
    },
    chargeMethod: {
      text: '',
      value: -1,
    },
    uom: null,
    rateOption: {
      text: '',
      value: -1,
    },
    rate: '',
    chargeName: '',
  };

  const initialFormStateBillingStorageSlidingRate: any = {
    fromQty: 0,
    toQty: 0,
    rate: '',
  };

  const initialFormStateBillingStorage: any = {
    warehouseCustomerStorageChargeId: 0,
    chargeMethod: {
      text: '',
      value: -1,
    },
    uom: null,
    rateOption: {
      text: '',
      value: -1,
    },
    rate: '',
    orderFee: null,
    itemQty: null,
    itemFee: null,
    itemAdditionalFee: null,
    uniqueItemQty: null,
    uniqueItemFee: null,
    uniqueItemAdditionalFee: null,
    binSize: null,
    slidingRate: [initialFormStateBillingStorageSlidingRate],
  };

  const initialFormStateBillingStorageFormError: any = {
    warehouseCustomerStorageChargeId: 0,
    chargeMethod: {
      text: '',
      value: -1,
    },
    uom: null,
    rateOption: {
      text: '',
      value: -1,
    },
    rate: '',
    chargeName: '',
    palletType: '',
    startDay: '',
    discountPercentage: '',
    binSize: '',
  };

  const initialFormStateBillingStorageSlidingRateFormError: any = {
    slidingRate: [initialFormStateBillingStorageSlidingRate],
  };

  const [formErrorsStorageSlidingRate, setFormErrorsStorageSlidingRate] =
    useState<any>([initialFormStateBillingStorageSlidingRateFormError]);

  const [formErrorsHandlingSlidingRate, setFormErrorsHandlingSlidingRate] =
    useState<any>([initialFormStateBillingStorageSlidingRateFormError]);

  const [formErrorsStorage, setFormErrorsStorage] = useState<any>([
    initialFormStateBillingStorageFormError,
  ]);
  const [formErrorsHandling, setFormErrorsHandling] = useState<any>([
    initialFormStateBillingHandling,
  ]);
  const [formErrorsAdhoc, setFormErrorsAdhoc] = useState<any>([
    initialFormStateBillingAdhoc,
  ]);

  const [handlingChargeMethodOption, setHandlingChargeMethodOption] = useState(
    [],
  );

  const [receiptPalletTypeValue, setReceiptPalletTypeValue] =
    useState<AutocompleteBase>(null);

  const handlingChargeMethodOnChange = (
    chargeType: WarehouseCustomerHandlingChargeTypeEnum,
  ) => {
    if (chargeType === WarehouseCustomerHandlingChargeTypeEnum.ReceivingFee) {
      setHandlingChargeMethodOption(
        billingData.handlingCharges_AddEditCharge_ChargeTypeInbound_ChargeMethodDDL,
      );
    } else if (
      chargeType === WarehouseCustomerHandlingChargeTypeEnum.PackingFee
    ) {
      setHandlingChargeMethodOption(
        billingData.handlingCharges_AddEditCharge_ChargeTypeOutbound_ChargeMethodDDL,
      );
    } else if (
      chargeType === WarehouseCustomerHandlingChargeTypeEnum.PickingCharge
    ) {
      setHandlingChargeMethodOption(
        billingData.handlingCharges_AddEditCharge_ChargeTypePickingCharge_ChargeMethodDDL,
      );
    } else if (
      chargeType === WarehouseCustomerHandlingChargeTypeEnum.PutAwayCharge
    ) {
      setHandlingChargeMethodOption(
        billingData.handlingCharges_AddEditCharge_ChargeTypePutAwayCharge_ChargeMethodDDL,
      );
    } else {
      setHandlingChargeMethodOption([]);
    }
    setDataModalHandlingCharge((e) => ({
      ...e,
      chargeMethod: {
        text: '',
        value: -1,
      },
    }));
  };

  const StorageChargeValidation = () => {
    const fieldValues = dataModalStorageCharge;
    const temp: any = {};

    if ('chargeMethod' in fieldValues) {
      temp.chargeMethod =
        fieldValues.chargeMethod.value !== -1 ? '' : 'This field is required';
    }

    if ('chargeName' in fieldValues) {
      temp.chargeName = fieldValues.chargeName ? '' : 'This field is required';
    } else {
      temp.chargeName = 'This field is required';
    }

    if (
      dataModalStorageCharge.chargeMethod &&
      dataModalStorageCharge.chargeMethod.value ===
        WarehouseCustomerStorageChargeMethodEnum.ByItemType
    ) {
      if (
        dataModalStorageCharge.uom === undefined ||
        dataModalStorageCharge.uom === null
      ) {
        temp.uom = 'This field is required';
      } else {
        temp.uom = '';
      }
    }

    if ('rateOption' in fieldValues) {
      if (
        dataModalStorageCharge.rateOption.value ===
        WarehouseCustomerChargeMethodRateEnum.FixedRate
      ) {
        temp.rate = fieldValues.rate.trim() ? '' : 'This field is required';
      }
    }

    if (
      dataModalStorageCharge.chargeMethod &&
      dataModalStorageCharge.chargeMethod.value ===
        WarehouseCustomerStorageChargeMethodEnum.ByPalletType
    ) {
      if (
        !dataModalStorageCharge.palletType ||
        !dataModalStorageCharge.palletType.value
      ) {
        temp.palletType = 'This field is required';
      }
    }

    if (
      dataModalStorageCharge.chargeMethod &&
      dataModalStorageCharge.chargeMethod.value ===
        WarehouseCustomerStorageChargeMethodEnum.BinLocationBySize
    ) {
      if (
        !dataModalStorageCharge.binSize ||
        !dataModalStorageCharge.binSize.value
      ) {
        temp.binSize = 'This field is required';
      }
    }

    if (
      dataModalStorageCharge.chargeMethod &&
      dataModalStorageCharge.chargeMethod.value ===
        WarehouseCustomerStorageChargeMethodEnum.PartialStorage
    ) {
      temp.rate = '';
      if (!dataModalStorageCharge.startDay) {
        temp.startDay = 'This field is required';
      }

      if (!dataModalStorageCharge.discountPercentage) {
        temp.discountPercentage = 'This field is required';
      }
    }

    setFormErrorsStorage({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const SlidingRateValidationSlidingRate = () => {
    const fieldValues = dataModalStorageCharge;
    const temp: any = {};

    if ('rateOption' in fieldValues) {
      if (
        dataModalStorageCharge.rateOption.value ===
        WarehouseCustomerChargeMethodRateEnum.SlidingRate
      ) {
        let results = [];
        let result = [];
        const datas = [...storageChargeSlidingRate];
        datas.forEach((value) => {
          const from = value.fromQty ? value.fromQty : 0;
          const to = value.toQty ? value.toQty : 0;
          if (value.toQty !== '' && value.fromQty !== '') {
            if (Number(from) > Number(to)) {
              result.push(
                `From is more than 'to' quantity`,
                value.toQty ? null : 'This field is required',
                value.rate ? null : 'This field is required',
              );
            } else if (
              Number(from) === Number(to) &&
              Number(from) > 0 &&
              Number(to) > 0
            ) {
              result.push(
                'Must not be equal',
                'Must not be equal',
                value.rate ? null : 'This field is required',
              );
            } else {
              result.push(
                value.fromQty ? null : 'This field is required',
                value.toQty ? null : 'This field is required',
                value.rate ? null : 'This field is required',
              );
            }
          } else {
            result.push(
              value.fromQty ? null : 'This field is required',
              value.toQty ? null : 'This field is required',
              value.rate ? null : 'This field is required',
            );
          }
          results = [...results, result];
          result = [];
        });
        temp.slidingRate = results;
      }
    }
    setFormErrorsStorageSlidingRate({
      ...temp,
    });
    let IsReturnTrue = true;
    temp.slidingRate.forEach((element) => {
      if (!Object.values(element).every((x) => x === null))
        IsReturnTrue = false;
    });

    return IsReturnTrue;
  };

  const SlidingRateValidationSlidingRateHandling = () => {
    const fieldValues = dataModalHandlingCharge;
    const temp: any = {};

    if ('rateOption' in fieldValues) {
      if (
        dataModalHandlingCharge.rateOption.value ===
        WarehouseCustomerChargeMethodRateEnum.SlidingRate
      ) {
        let results = [];
        let result = [];
        const datas = [...handlingChargeSlidingRate];
        datas.forEach((value) => {
          const from = value.fromQty ? value.fromQty : 0;
          const to = value.toQty ? value.toQty : 0;
          if (value.toQty !== '' && value.fromQty !== '') {
            if (Number(from) > Number(to)) {
              result.push(
                `From is more than 'to' quantity`,
                value.toQty ? null : 'This field is required',
                value.rate ? null : 'This field is required',
              );
            } else if (
              Number(from) === Number(to) &&
              Number(from) > 0 &&
              Number(to) > 0
            ) {
              result.push(
                'Must not be equal',
                'Must not be equal',
                value.rate ? null : 'This field is required',
              );
            } else {
              result.push(
                value.fromQty ? null : 'This field is required',
                value.toQty ? null : 'This field is required',
                value.rate ? null : 'This field is required',
              );
            }
          } else {
            result.push(
              value.fromQty ? null : 'This field is required',
              value.toQty ? null : 'This field is required',
              value.rate ? null : 'This field is required',
            );
          }
          results = [...results, result];
          result = [];
        });
        temp.slidingRate = results;
      }
    }
    setFormErrorsHandlingSlidingRate({
      ...temp,
    });
    let IsReturnTrue = true;
    temp.slidingRate.forEach((element) => {
      if (!Object.values(element).every((x) => x === null))
        IsReturnTrue = false;
    });

    return IsReturnTrue;
  };

  const handleOnCreateStorageCharge = async (e: any) => {
    e.preventDefault();

    if (!StorageChargeValidation()) {
      return;
    }

    if (
      dataModalStorageCharge.rateOption &&
      dataModalStorageCharge.rateOption.value ===
        WarehouseCustomerChargeMethodRateEnum.SlidingRate
    ) {
      if (!SlidingRateValidationSlidingRate()) {
        return;
      }
    }

    try {
      const payload = {
        ChargeName: dataModalStorageCharge.chargeName,
        AccountingCodeName: dataModalStorageCharge.accountingCodeName,
        WarehouseCustomerId: queryString.id,
        ChargeMethod: dataModalStorageCharge.chargeMethod.value,
        UOM: dataModalStorageCharge.uom
          ? dataModalStorageCharge.uom.value
          : null,
        RateOption: dataModalStorageCharge.rateOption.value,
        Rate: dataModalStorageCharge.rate ? dataModalStorageCharge.rate : 0,
        WarehouseCustomerStorageChargeId: '',
        SlidingRates: storageChargeSlidingRate,
        PalletType: dataModalStorageCharge?.palletType?.value,
        StartDay: dataModalStorageCharge?.startDay,
        PercentageDiscount: dataModalStorageCharge?.discountPercentage,
        BinSize: dataModalStorageCharge?.binSize?.value,
      };

      if (
        payload.ChargeMethod ===
        WarehouseCustomerStorageChargeMethodEnum.ByPalletType
      ) {
        if (payload.PalletType === null) {
          snackActions.error('Please select a Pallet Type');
          return;
        }
      }

      if (isStorageChargeEdit) {
        payload.WarehouseCustomerStorageChargeId =
          dataModalStorageCharge.warehouseCustomerStorageChargeId;
        await editStorageChargeBilling(payload);
        snackActions.success(`Storage charges have been updated successfully.`);
      } else {
        await createStorageChargeBilling(payload);
        snackActions.success(`Storage charge have been added successfully.`);
      }

      setTabValue('2');
      viewLoadData();
      setDataModalStorageCharge(initialFormStateBillingStorage);
      onCloseBillingStorageModal();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleOnCreateHandlingCharge = async (e: any) => {
    e.preventDefault();
    const fieldValues = dataModalHandlingCharge;
    const temp: any = {};

    if ('chargeMethod' in fieldValues) {
      temp.chargeMethod =
        fieldValues.chargeMethod.value !== -1 ? '' : 'This field is required';
    }
    if ('chargeType' in fieldValues) {
      temp.chargeType =
        fieldValues.chargeType.value !== -1 ? '' : 'This field is required';
    }

    if ('chargeName' in fieldValues) {
      temp.chargeName = fieldValues.chargeName ? '' : 'This field is required';
    } else {
      temp.chargeName = 'This field is required';
    }

    if (
      dataModalHandlingCharge.chargeType &&
      dataModalHandlingCharge.chargeMethod &&
      ((dataModalHandlingCharge.chargeType.value ===
        WarehouseCustomerHandlingChargeTypeEnum.ReceivingFee &&
        dataModalHandlingCharge.chargeMethod.value ===
          WarehouseCustomerHandlingChargeMethodEnum.ByItemType) ||
        (dataModalHandlingCharge.chargeType.value ===
          WarehouseCustomerHandlingChargeTypeEnum.PutAwayCharge &&
          dataModalHandlingCharge.chargeMethod.value ===
            WarehouseCustomerHandlingChargeMethodEnum.ByItemType))
    ) {
      if (
        dataModalHandlingCharge.uom === undefined ||
        dataModalHandlingCharge.uom === null
      ) {
        temp.uom = 'This field is required';
      } else {
        temp.uom = '';
      }
    }

    if ('rateOption' in fieldValues) {
      if (dataModalHandlingCharge.rateOption.value === 0) {
        temp.rate = fieldValues.rate.trim() ? '' : 'This field is required';
      }
    }
    // if ('rate' in fieldValues) {
    //   temp.rate = fieldValues.rate.trim() ? '' : 'This field is required';
    // }
    setFormErrorsHandling({
      ...temp,
    });
    const bool = Object.values(temp).every((x) => x === '');

    if (bool) {
      if (
        dataModalHandlingCharge.rateOption &&
        dataModalHandlingCharge.rateOption.value ===
          WarehouseCustomerChargeMethodRateEnum.SlidingRate
      ) {
        if (!SlidingRateValidationSlidingRateHandling()) {
          return;
        }
      }

      try {
        const payload = {
          ChargeName: dataModalHandlingCharge.chargeName,
          AccountingCodeName: dataModalHandlingCharge.accountingCodeName,
          WarehouseCustomerId: queryString.id,
          ChargeMethod: dataModalHandlingCharge.chargeMethod.value,
          ChargeType: dataModalHandlingCharge.chargeType.value,
          UOM: dataModalHandlingCharge.uom
            ? dataModalHandlingCharge.uom.value
            : null,
          RateOption: dataModalHandlingCharge.rateOption.value,
          Rate: dataModalHandlingCharge.rate ? dataModalHandlingCharge.rate : 0,
          orderFee: dataModalHandlingCharge.orderFee
            ? dataModalHandlingCharge.orderFee
            : null,
          // need to accept zero value
          itemQty:
            dataModalHandlingCharge.itemQty !== undefined ||
            dataModalHandlingCharge.itemQty !== null
              ? dataModalHandlingCharge.itemQty
              : null,
          // need to accept zero value
          itemFee:
            dataModalHandlingCharge.itemFee !== undefined ||
            dataModalHandlingCharge.itemFee !== null
              ? dataModalHandlingCharge.itemFee
              : null,
          // need to accept zero value
          itemAdditionalFee:
            dataModalHandlingCharge.itemAdditionalFee !== undefined ||
            dataModalHandlingCharge.itemAdditionalFee !== null
              ? dataModalHandlingCharge.itemAdditionalFee
              : null,
          // need to accept zero value
          uniqueItemQty:
            dataModalHandlingCharge.uniqueItemQty !== undefined ||
            dataModalHandlingCharge.uniqueItemQty !== null
              ? dataModalHandlingCharge.uniqueItemQty
              : null,
          // need to accept zero value
          uniqueItemFee:
            dataModalHandlingCharge.uniqueItemFee !== undefined ||
            dataModalHandlingCharge.uniqueItemFee !== null
              ? dataModalHandlingCharge.uniqueItemFee
              : null,
          // need to accept zero value
          uniqueItemAdditionalFee:
            dataModalHandlingCharge.uniqueItemAdditionalFee !== undefined ||
            dataModalHandlingCharge.uniqueItemAdditionalFee !== null
              ? dataModalHandlingCharge.uniqueItemAdditionalFee
              : null,
          WarehouseCustomerHandlingChargeId: '',
          SlidingRates: handlingChargeSlidingRate,
          uomChargeRates: uomChargeRates.map((x) => ({
            itemType: x.itemType.value,
            itemFee: x.itemFee,
            itemAdditionalFee: x.itemAdditionalFee,
            itemId: x.sku.value,
          })),
        };

        if (isHandlingChargeEdit) {
          payload.WarehouseCustomerHandlingChargeId =
            dataModalHandlingCharge.warehouseCustomerHandlingChargeId;
          await editHandlingChargeBilling(payload);
          snackActions.success(
            `Handling charges have been updated successfully.`,
          );
        } else {
          await createHandlingChargeBilling(payload);
          snackActions.success(`Handling charge have been added successfully.`);
        }

        setTabValue('2');
        viewLoadData();
        setDataModalHandlingCharge(initialFormStateBillingHandling);
        onCloseBillingHandlingModal();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const handleOnCreateAdhocCharge = async (e: any) => {
    e.preventDefault();
    const fieldValues = dataModalAdhocCharge;
    const temp: any = {};

    if ('chargeType' in fieldValues) {
      temp.chargeType =
        fieldValues.chargeType.value !== -1 ? '' : 'This field is required';
    }
    if ('rate' in fieldValues) {
      temp.rate = fieldValues.rate.trim() ? '' : 'This field is required';
    }
    if ('chargeName' in fieldValues) {
      temp.chargeName = fieldValues.chargeName.trim()
        ? ''
        : 'This field is required';
    }

    setFormErrorsAdhoc({
      ...temp,
    });

    const bool = Object.values(temp).every((x) => x === '');

    if (bool) {
      try {
        const payload = {
          WarehouseCustomerId: queryString.id,
          ChargeType: dataModalAdhocCharge.chargeType.value,
          ChargeName: dataModalAdhocCharge.chargeName,
          AccountingCodeName: dataModalAdhocCharge.accountingCodeName,
          Rate: dataModalAdhocCharge.rate,
          WarehouseCustomerAdhocChargeId: '',
          Required: dataModalAdhocCharge.required,
        };

        if (isAdhocChargeEdit) {
          payload.WarehouseCustomerAdhocChargeId =
            dataModalAdhocCharge.warehouseCustomerAdhocChargeId;
          await editAdhocChargeBilling(payload);
          snackActions.success(`Adhoc charges have been updated successfully.`);
        } else {
          await createAdhocChargeBilling(payload);
          snackActions.success(`Adhoc charge have been added successfully.`);
        }

        setTabValue('2');
        viewLoadData();
        setDataModalAdhocCharge(initialFormStateBillingAdhoc);
        onCloseBillingAdhocModal();
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const removeFieldSlidingRate = (index) => {
    const data = [...storageChargeSlidingRate];
    data.splice(index, 1);
    setStorageChargeSlidingRate(data);
  };

  const removeFieldSlidingRateHandling = (index) => {
    const data = [...handlingChargeSlidingRate];
    data.splice(index, 1);
    setHandlingChargeSlidingRate(data);
  };

  const addFieldSlidingRate = () => {
    const newfield = { slidingRateId: '', fromQty: 0, toQty: 0, rate: '' };
    setStorageChargeSlidingRate([...storageChargeSlidingRate, newfield]);

    setFormErrorsStorageSlidingRate([...storageChargeSlidingRate, newfield]);
  };

  const addFieldSlidingRateHandling = () => {
    const newfield = { slidingRateId: '', fromQty: 0, toQty: 0, rate: '' };
    setHandlingChargeSlidingRate([...handlingChargeSlidingRate, newfield]);

    setFormErrorsHandlingSlidingRate([...handlingChargeSlidingRate, newfield]);
  };

  const handleFormChangeSlidingRate = (arrayInput, index, event) => {
    event.preventDefault();

    const datas = [...arrayInput];
    const data = { ...datas[index] };

    if (event.target.name === 'rate') {
      if (event.target.value < 0) {
        const validateDecimal = decimalValidationHelper('0');

        data[event.target.name] = validateDecimal;
      } else {
        const validateDecimal = decimalValidationHelper(event.target.value);

        data[event.target.name] = validateDecimal;
      }
    } else {
      data[event.target.name] = event.target.value;
    }

    datas[index] = data;
    setStorageChargeSlidingRate(datas);
  };

  const handleFormChangeSlidingRateHandling = (arrayInput, index, event) => {
    event.preventDefault();

    const datas = [...arrayInput];
    const data = { ...datas[index] };

    if (event.target.name === 'rate') {
      if (event.target.value < 0) {
        const validateDecimal = decimalValidationHelper('0');

        data[event.target.name] = validateDecimal;
      } else {
        const validateDecimal = decimalValidationHelper(event.target.value);

        data[event.target.name] = validateDecimal;
      }
    } else {
      data[event.target.name] = event.target.value;
    }

    datas[index] = data;
    setHandlingChargeSlidingRate(datas);
  };

  return (
    <>
      <Modal open={isBillingStorageModalOpen}>
        <MUIContainer sx={{ maxWidth: '600px !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            {isStorageChargeEdit ? 'Edit ' : 'New '}
            Storage Charge
          </Typography>

          <MUIContent>
            <Input
              required
              placeholder="Charge Name"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={dataModalStorageCharge.chargeName}
              onChange={(e) => {
                setDataModalStorageCharge((prev) => ({
                  ...prev,
                  chargeName: e.target.value,
                }));
              }}
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
              error={formErrorsStorage.chargeName}
            />
            <Input
              placeholder="Sub Account Name (optional)"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={dataModalStorageCharge.accountingCodeName}
              onChange={(e) => {
                setDataModalStorageCharge((prev) => ({
                  ...prev,
                  accountingCodeName: e.target.value,
                }));
              }}
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
            />
            <Autocomplete
              disableClearable
              sx={{ width: '100%' }}
              options={billingData.storageCharges_AddEditCharge_ChargeMethodDDL}
              getOptionLabel={(option) => (option.text ? option.text : '')}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              size="small"
              value={dataModalStorageCharge.chargeMethod}
              onChange={(event: any, value: DropdownListItem | null) => {
                setDataModalStorageCharge((e) => ({
                  ...e,
                  chargeMethod: value,
                  uom: null,
                }));
              }}
              renderOption={(props, option: DropdownListItem) => (
                <li {...props} key={option.value}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  autoComplete="off"
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  {...(formErrorsStorage.chargeMethod && {
                    error: true,
                    helperText: formErrorsStorage.chargeMethod,
                  })}
                  label="Charge Method"
                />
              )}
            />

            {dataModalStorageCharge.chargeMethod &&
              dataModalStorageCharge.chargeMethod.value !==
                WarehouseCustomerStorageChargeMethodEnum.PartialStorage && (
                <>
                  {/* ByPalletType */}
                  {dataModalStorageCharge.chargeMethod &&
                    dataModalStorageCharge.chargeMethod.value ===
                      WarehouseCustomerStorageChargeMethodEnum.ByPalletType && (
                      <Autocomplete
                        onChange={(
                          event: any,
                          newValue: AutocompleteBase | null,
                        ) => {
                          if (newValue !== null) {
                            setReceiptPalletTypeValue(newValue);

                            setDataModalStorageCharge((e) => ({
                              ...e,
                              palletType: newValue,
                            }));
                          } else {
                            setReceiptPalletTypeValue(null);
                          }
                        }}
                        id="controllable-states"
                        options={itemPalletTypeOption}
                        value={dataModalStorageCharge.palletType}
                        getOptionLabel={(option: AutocompleteBase) =>
                          option.label
                        }
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            {...(formErrorsStorage.palletType && {
                              error: true,
                              helperText: formErrorsStorage.palletType,
                            })}
                            id="outlined"
                            label="Pallet Type"
                            size="small"
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                    )}

                  {/* ByItemType */}
                  {dataModalStorageCharge.chargeMethod &&
                    dataModalStorageCharge.chargeMethod.value ===
                      WarehouseCustomerStorageChargeMethodEnum.ByItemType && (
                      <Autocomplete
                        disableClearable
                        sx={{ width: '100%' }}
                        options={
                          billingData.storageCharges_AddEditCharge_UOMDDL
                        }
                        getOptionLabel={(option) => option.text}
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        size="small"
                        value={dataModalStorageCharge.uom}
                        onChange={(event: any, value: DropdownListItem) => {
                          setDataModalStorageCharge((e) => ({
                            ...e,
                            uom: value,
                          }));
                        }}
                        renderOption={(props, option: DropdownListItem) => (
                          <li {...props} key={option.value}>
                            {option.text}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off',
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                background: 'white',
                              },
                            }}
                            label="UOM"
                            {...(formErrorsStorage.uom && {
                              error: true,
                              helperText: formErrorsStorage.uom,
                            })}
                          />
                        )}
                      />
                    )}

                  {/* BinLocationBySize */}
                  {dataModalStorageCharge.chargeMethod &&
                    dataModalStorageCharge.chargeMethod.value ===
                      WarehouseCustomerStorageChargeMethodEnum.BinLocationBySize && (
                      <Autocomplete
                        sx={{ width: '100%' }}
                        options={
                          billingData.storageCharges_AddEditCharge_BinSizeDDL
                        }
                        getOptionLabel={(option) => option.text}
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        size="small"
                        value={dataModalStorageCharge.binSize}
                        onChange={(event: any, value: DropdownListItem) => {
                          setDataModalStorageCharge((e) => ({
                            ...e,
                            binSize: value,
                          }));
                        }}
                        renderOption={(props, option: DropdownListItem) => (
                          <li {...props} key={option.value}>
                            {option.text}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off',
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                background: 'white',
                              },
                            }}
                            label="Bin Size"
                            {...(formErrorsStorage.binSize && {
                              error: true,
                              helperText: formErrorsStorage.binSize,
                            })}
                          />
                        )}
                      />
                    )}

                  {/* Rate */}
                  <Autocomplete
                    disableClearable
                    sx={{ width: '100%' }}
                    options={
                      billingData.storageCharges_AddEditCharge_Rate_ChargeMethodDDL
                    }
                    getOptionLabel={(option) =>
                      option.text ? option.text : ''
                    }
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    size="small"
                    value={dataModalStorageCharge.rateOption}
                    onChange={(event: any, value: DropdownListItem | null) => {
                      setDataModalStorageCharge((e) => ({
                        ...e,
                        rateOption: value,
                      }));
                      if (
                        (value.value ===
                          WarehouseCustomerChargeMethodRateEnum.SlidingRate &&
                          storageChargeSlidingRate === null) ||
                        storageChargeSlidingRate === undefined
                      ) {
                        addFieldSlidingRate();
                      }
                    }}
                    renderOption={(props, option: DropdownListItem) => (
                      <li {...props} key={option.value}>
                        {option.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                        autoComplete="off"
                        sx={{
                          '& .MuiInputBase-root': {
                            background: 'white',
                          },
                        }}
                        {...(formErrorsStorage.rateOption && {
                          error: true,
                          helperText: formErrorsStorage.rateOption,
                        })}
                        label="Rate Option"
                      />
                    )}
                  />

                  {/* FixedRate */}
                  {dataModalStorageCharge.rateOption &&
                    dataModalStorageCharge.rateOption.value ===
                      WarehouseCustomerChargeMethodRateEnum.FixedRate && (
                      <Input
                        required
                        placeholder="Rate"
                        size="small"
                        sx={{
                          width: '100%',
                          backgroundColor: '#ffffff !important',
                        }}
                        value={dataModalStorageCharge.rate}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }

                          const validateDecimal = decimalValidationHelper(
                            e.target.value,
                          );
                          setDataModalStorageCharge((prev) => ({
                            ...prev,
                            rate: validateDecimal,
                          }));
                        }}
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">$</InputAdornment>
                        //   ),
                        // }}
                        error={formErrorsStorage.rate}
                      />
                    )}

                  {/* SlidingRate */}
                  {dataModalStorageCharge.rateOption &&
                    dataModalStorageCharge.rateOption.value ===
                      WarehouseCustomerChargeMethodRateEnum.SlidingRate && (
                      <MUIGrid
                        container
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ marginBottom: 1 }}
                      >
                        {storageChargeSlidingRate &&
                          storageChargeSlidingRate.map((input, index) => (
                            <>
                              <MUIGrid item xs={3}>
                                <TextField
                                  label="From"
                                  size="small"
                                  type="number"
                                  sx={{
                                    width: '100%',
                                    backgroundColor: '#ffffff !important',
                                  }}
                                  name="fromQty"
                                  value={
                                    input.fromQty
                                    // ? input.fromQty.replace(/[^0-9]/g, '')
                                    // : ''
                                  }
                                  onChange={(event) =>
                                    handleFormChangeSlidingRate(
                                      storageChargeSlidingRate,
                                      index,
                                      event,
                                    )
                                  }
                                  required
                                  {...(formErrorsStorageSlidingRate.slidingRate !==
                                    undefined && {
                                    error:
                                      formErrorsStorageSlidingRate.slidingRate[
                                        index
                                      ][0],
                                    helperText:
                                      formErrorsStorageSlidingRate.slidingRate[
                                        index
                                      ][0],
                                  })}

                                  // error={
                                  //   formErrorsStorage.slidingRate
                                  //     ? formErrorsStorage.slidingRate[index][0]
                                  //     : null
                                  // }
                                  // helperText={
                                  //   formErrorsStorage.slidingRate[index]
                                  //     ? formErrorsStorage.slidingRate[index][0]
                                  //     : null
                                  // }
                                />
                              </MUIGrid>
                              <MUIGrid item xs={3}>
                                <TextField
                                  label="To"
                                  size="small"
                                  type="number"
                                  sx={{
                                    width: '100%',
                                    backgroundColor: '#ffffff !important',
                                  }}
                                  name="toQty"
                                  value={
                                    input.toQty
                                    // ? input.toQty.replace(/[^0-9]/g, '')
                                    // : ''
                                  }
                                  onChange={(event) =>
                                    handleFormChangeSlidingRate(
                                      storageChargeSlidingRate,
                                      index,
                                      event,
                                    )
                                  }
                                  required
                                  {...(formErrorsStorageSlidingRate.slidingRate !==
                                    undefined && {
                                    error:
                                      formErrorsStorageSlidingRate.slidingRate[
                                        index
                                      ][1],
                                    helperText:
                                      formErrorsStorageSlidingRate.slidingRate[
                                        index
                                      ][1],
                                  })}
                                />
                              </MUIGrid>
                              <MUIGrid item xs={4}>
                                <TextField
                                  label="Rate"
                                  size="small"
                                  sx={{
                                    width: '100%',
                                    backgroundColor: '#ffffff !important',
                                  }}
                                  type="number"
                                  name="rate"
                                  value={input.rate}
                                  onChange={(event) =>
                                    handleFormChangeSlidingRate(
                                      storageChargeSlidingRate,
                                      index,
                                      event,
                                    )
                                  }
                                  {...(formErrorsStorageSlidingRate.slidingRate !==
                                    undefined && {
                                    error:
                                      formErrorsStorageSlidingRate.slidingRate[
                                        index
                                      ][2],
                                    helperText:
                                      formErrorsStorageSlidingRate.slidingRate[
                                        index
                                      ][2],
                                  })}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                  required
                                />
                              </MUIGrid>
                              <MUIGrid item xs={1}>
                                <Button
                                  onClick={() => removeFieldSlidingRate(index)}
                                >
                                  X
                                </Button>
                              </MUIGrid>
                            </>
                          ))}
                        <MUIGrid item xs={6}>
                          <Button onClick={() => addFieldSlidingRate()}>
                            Add Rate
                          </Button>
                        </MUIGrid>
                      </MUIGrid>
                    )}
                </>
              )}

            {/* Partial storage */}
            {dataModalStorageCharge.chargeMethod &&
              dataModalStorageCharge.chargeMethod.value ===
                WarehouseCustomerStorageChargeMethodEnum.PartialStorage && (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginBottom: 1 }}
                >
                  <MUIGrid item xs={6}>
                    <TextField
                      label="Start Day"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      type="number"
                      name="startDay"
                      value={dataModalStorageCharge.startDay}
                      onChange={(event) => {
                        const intValue = parseInt(event.target.value, 10);
                        if (intValue > 31 || intValue < 1) return;

                        setDataModalStorageCharge((e) => ({
                          ...e,
                          startDay: event.target.value,
                        }));
                      }}
                      {...(formErrorsStorage.startDay && {
                        error: true,
                        helperText: formErrorsStorage.startDay,
                      })}
                      required
                    />
                  </MUIGrid>

                  <MUIGrid item xs={6}>
                    <TextField
                      label="Discount Percentage"
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      type="number"
                      name="discountPercentage"
                      value={dataModalStorageCharge.discountPercentage}
                      onChange={(event) => {
                        const value = parseFloat(event.target.value);
                        if (value < 0 || value > 100) return;
                        setDataModalStorageCharge((e) => ({
                          ...e,
                          discountPercentage: event.target.value,
                        }));
                      }}
                      {...(formErrorsStorage.discountPercentage && {
                        error: true,
                        helperText: formErrorsStorage.discountPercentage,
                      })}
                      required
                    />
                  </MUIGrid>
                </MUIGrid>
              )}
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={() => {
                    setDataModalStorageCharge(initialFormStateBillingStorage);
                    setFormErrorsStorage('');
                    setFormErrorsStorageSlidingRate('');
                    onCloseBillingStorageModal();
                  }}
                >
                  CANCEL
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleOnCreateStorageCharge(e)}
                >
                  SAVE
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>

      {/* -------------------------------------------------- Handling ------------------------------------------- */}

      <Modal open={isBillingHandlingModalOpen}>
        <MUIContainer
          sx={{
            maxWidth:
              dataModalHandlingCharge.chargeType &&
              dataModalHandlingCharge.chargeType.value ===
                WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
              dataModalHandlingCharge.chargeMethod &&
              dataModalHandlingCharge.chargeMethod.value ===
                WarehouseCustomerHandlingChargeMethodEnum.ByItemType
                ? '800px !important'
                : '600px !important',
          }}
        >
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            {isHandlingChargeEdit ? 'Edit ' : 'New '}
            Handling Charge
          </Typography>

          <MUIContent>
            <Input
              required
              placeholder="Charge Name"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={dataModalHandlingCharge.chargeName}
              onChange={(e) => {
                setDataModalHandlingCharge((prev) => ({
                  ...prev,
                  chargeName: e.target.value,
                }));
              }}
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
              error={formErrorsHandling.chargeName}
            />
            <Input
              placeholder="Sub Account Name (optional)"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={dataModalHandlingCharge.accountingCodeName}
              onChange={(e) => {
                setDataModalHandlingCharge((prev) => ({
                  ...prev,
                  accountingCodeName: e.target.value,
                }));
              }}
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
            />
            <Autocomplete
              disableClearable
              sx={{ width: '100%' }}
              // options={statusOptions}
              options={billingData.handlingCharges_AddEditCharge_ChargeTypeDDL}
              getOptionLabel={(option) => (option.text ? option.text : '')}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              size="small"
              value={dataModalHandlingCharge.chargeType}
              onChange={(event: any, value: DropdownListItem | null) => {
                setDataModalHandlingCharge((e) => ({
                  ...e,
                  chargeType: value,
                  rateOption: {
                    text: '',
                    value: -1,
                  },
                  rate: '',
                  slidingRate: [initialFormStateBillingStorageSlidingRate],
                  uom: null,
                  orderFee: null,
                  itemQty: null,
                  itemFee: null,
                  itemAdditionalFee: null,
                  uniqueItemQty: null,
                  uniqueItemFee: null,
                  uniqueItemAdditionalFee: null,
                }));
                setHandlingChargeSlidingRate([]);
                setUOMChargeRates([]);
                setSelectedUOMChargeRate(null);
                handlingChargeMethodOnChange(
                  value.value as WarehouseCustomerHandlingChargeTypeEnum,
                );
              }}
              renderOption={(props, option: DropdownListItem) => (
                <li {...props} key={option.value}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  label="Charge Type"
                  {...(formErrorsHandling.chargeType && {
                    error: true,
                    helperText: formErrorsHandling.chargeType,
                  })}
                />
              )}
            />

            <Autocomplete
              disableClearable
              sx={{ width: '100%' }}
              // options={statusOptions}
              options={handlingChargeMethodOption}
              getOptionLabel={(option) => (option.text ? option.text : '')}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              size="small"
              value={dataModalHandlingCharge.chargeMethod}
              onChange={(event: any, value: DropdownListItem | null) => {
                setDataModalHandlingCharge((e) => ({
                  ...e,
                  chargeMethod: value,
                  rateOption: {
                    text: '',
                    value: -1,
                  },
                  rate: '',
                  slidingRate: [initialFormStateBillingStorageSlidingRate],
                  uom: null,
                  orderFee: null,
                  itemQty: null,
                  itemFee: null,
                  itemAdditionalFee: null,
                  uniqueItemQty: null,
                  uniqueItemFee: null,
                  uniqueItemAdditionalFee: null,
                }));
                setHandlingChargeSlidingRate([]);
                setUOMChargeRates([]);
                setSelectedUOMChargeRate(null);
              }}
              renderOption={(props, option: DropdownListItem) => (
                <li {...props} key={option.value}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  label="Charge Method"
                  {...(formErrorsHandling.chargeMethod && {
                    error: true,
                    helperText: formErrorsHandling.chargeMethod,
                  })}
                />
              )}
            />

            {/* no uom for ChargeType = PackingFee and ChargeMethod = ByItemType */}
            {/* because it going to use the order fee and uom charge */}
            {dataModalHandlingCharge.chargeType &&
              dataModalHandlingCharge.chargeType.value !==
                WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
              dataModalHandlingCharge.chargeMethod &&
              dataModalHandlingCharge.chargeMethod.value ===
                WarehouseCustomerHandlingChargeMethodEnum.ByItemType && (
                <Autocomplete
                  disableClearable
                  sx={{ width: '100%' }}
                  options={billingData.storageCharges_AddEditCharge_UOMDDL}
                  getOptionLabel={(option) => option.text}
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  size="small"
                  value={dataModalHandlingCharge.uom}
                  onChange={(event: any, value: DropdownListItem) => {
                    setDataModalHandlingCharge((e) => ({
                      ...e,
                      uom: value,
                    }));
                  }}
                  renderOption={(props, option: DropdownListItem) => (
                    <li {...props} key={option.value}>
                      {option.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          background: 'white',
                        },
                      }}
                      label="UOM"
                      {...(formErrorsHandling.uom && {
                        error: true,
                        helperText: formErrorsHandling.uom,
                      })}
                    />
                  )}
                />
              )}

            {/* no rate option for ChargeType = PackingFee and ChargeMethod = ByItemType */}
            {((dataModalHandlingCharge.chargeType &&
              dataModalHandlingCharge.chargeType.value !==
                WarehouseCustomerHandlingChargeTypeEnum.PackingFee) ||
              (dataModalHandlingCharge.chargeType &&
                dataModalHandlingCharge.chargeType.value ===
                  WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
                dataModalHandlingCharge.chargeMethod &&
                (dataModalHandlingCharge.chargeMethod.value ===
                  WarehouseCustomerHandlingChargeMethodEnum.BasedOnPalletPerLocation ||
                  dataModalHandlingCharge.chargeMethod.value ===
                    WarehouseCustomerHandlingChargeMethodEnum.ByWeight ||
                  dataModalHandlingCharge.chargeMethod.value ===
                    WarehouseCustomerHandlingChargeMethodEnum.ByPackageWeight))) && (
              <Autocomplete
                disableClearable
                sx={{ width: '100%' }}
                options={
                  billingData.storageCharges_AddEditCharge_Rate_ChargeMethodDDL
                }
                getOptionLabel={(option) => (option.text ? option.text : '')}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                size="small"
                value={dataModalHandlingCharge.rateOption}
                onChange={(event: any, value: DropdownListItem | null) => {
                  setDataModalHandlingCharge((e) => ({
                    ...e,
                    rateOption: value,
                  }));

                  setHandlingChargeSlidingRate([]);

                  if (
                    value.value ===
                      WarehouseCustomerChargeMethodRateEnum.SlidingRate &&
                    (handlingChargeSlidingRate === undefined ||
                      handlingChargeSlidingRate === null ||
                      handlingChargeSlidingRate.length === 0)
                  ) {
                    addFieldSlidingRateHandling();
                  }
                }}
                renderOption={(props, option: DropdownListItem) => (
                  <li {...props} key={option.value}>
                    {option.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off',
                    }}
                    autoComplete="off"
                    sx={{
                      '& .MuiInputBase-root': {
                        background: 'white',
                      },
                    }}
                    {...(formErrorsHandling.rateOption && {
                      error: true,
                      helperText: formErrorsHandling.rateOption,
                    })}
                    label="Rate Option"
                  />
                )}
              />
            )}

            {/* no rate for ChargeType = PackingFee and ChargeMethod = ByItemType */}
            {/* because it going to use the order fee and uom charge */}
            {((dataModalHandlingCharge.chargeType &&
              dataModalHandlingCharge.chargeType.value !==
                WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
              dataModalHandlingCharge.rateOption &&
              dataModalHandlingCharge.rateOption.value ===
                WarehouseCustomerChargeMethodRateEnum.FixedRate) ||
              (dataModalHandlingCharge.chargeType &&
                dataModalHandlingCharge.chargeType.value ===
                  WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
                ((dataModalHandlingCharge.chargeMethod &&
                  dataModalHandlingCharge.chargeMethod.value ===
                    WarehouseCustomerHandlingChargeMethodEnum.BasedOnPalletPerLocation) ||
                  dataModalHandlingCharge.chargeMethod.value ===
                    WarehouseCustomerHandlingChargeMethodEnum.ByWeight ||
                  dataModalHandlingCharge.chargeMethod.value ===
                    WarehouseCustomerHandlingChargeMethodEnum.ByPackageWeight) &&
                dataModalHandlingCharge.rateOption &&
                dataModalHandlingCharge.rateOption.value ===
                  WarehouseCustomerChargeMethodRateEnum.FixedRate)) && (
              <Input
                placeholder="Rate"
                required
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff !important',
                }}
                value={dataModalHandlingCharge.rate}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    e.target.value = 0;
                  }
                  const validateDecimal = decimalValidationHelper(
                    e.target.value,
                  );
                  setDataModalHandlingCharge((prev) => ({
                    ...prev,
                    rate: validateDecimal,
                  }));
                }}
                InputProps={{
                  autoComplete: 'off',
                }}
                error={formErrorsHandling.rate}
              />
            )}

            {/* sliding rate is only for RateOption = SlidingRate */}
            {dataModalHandlingCharge.rateOption &&
              dataModalHandlingCharge.rateOption.value ===
                WarehouseCustomerChargeMethodRateEnum.SlidingRate && (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ marginBottom: 1 }}
                >
                  {handlingChargeSlidingRate &&
                    handlingChargeSlidingRate.map((input, index) => (
                      <>
                        <MUIGrid item xs={3}>
                          <TextField
                            label="From"
                            size="small"
                            type="number"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            name="fromQty"
                            value={input.fromQty}
                            onChange={(event) =>
                              handleFormChangeSlidingRateHandling(
                                handlingChargeSlidingRate,
                                index,
                                event,
                              )
                            }
                            required
                            {...(formErrorsHandlingSlidingRate.slidingRate !==
                              undefined && {
                              error:
                                formErrorsHandlingSlidingRate.slidingRate[
                                  index
                                ][0],
                              helperText:
                                formErrorsHandlingSlidingRate.slidingRate[
                                  index
                                ][0],
                            })}
                          />
                        </MUIGrid>
                        <MUIGrid item xs={3}>
                          <TextField
                            label="To"
                            size="small"
                            type="number"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            name="toQty"
                            value={input.toQty}
                            onChange={(event) =>
                              handleFormChangeSlidingRateHandling(
                                handlingChargeSlidingRate,
                                index,
                                event,
                              )
                            }
                            required
                            {...(formErrorsHandlingSlidingRate.slidingRate !==
                              undefined && {
                              error:
                                formErrorsHandlingSlidingRate.slidingRate[
                                  index
                                ][1],
                              helperText:
                                formErrorsHandlingSlidingRate.slidingRate[
                                  index
                                ][1],
                            })}
                          />
                        </MUIGrid>
                        <MUIGrid item xs={4}>
                          <TextField
                            label="Rate"
                            size="small"
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                            type="number"
                            name="rate"
                            value={input.rate}
                            onChange={(event) =>
                              handleFormChangeSlidingRateHandling(
                                handlingChargeSlidingRate,
                                index,
                                event,
                              )
                            }
                            {...(formErrorsHandlingSlidingRate.slidingRate !==
                              undefined && {
                              error:
                                formErrorsHandlingSlidingRate.slidingRate[
                                  index
                                ][2],
                              helperText:
                                formErrorsHandlingSlidingRate.slidingRate[
                                  index
                                ][2],
                            })}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            required
                          />
                        </MUIGrid>
                        <MUIGrid item xs={1}>
                          <Button
                            onClick={() =>
                              removeFieldSlidingRateHandling(index)
                            }
                          >
                            X
                          </Button>
                        </MUIGrid>
                      </>
                    ))}
                  <MUIGrid item xs={6}>
                    <Button onClick={() => addFieldSlidingRateHandling()}>
                      Add Rate
                    </Button>
                  </MUIGrid>
                </MUIGrid>
              )}

            {/* order fee and uom charge is only for ChargeType = PackingFee and ChargeMethod = ByItemType */}
            {dataModalHandlingCharge.chargeType &&
              dataModalHandlingCharge.chargeType.value ===
                WarehouseCustomerHandlingChargeTypeEnum.PackingFee &&
              dataModalHandlingCharge.chargeMethod &&
              dataModalHandlingCharge.chargeMethod.value ===
                WarehouseCustomerHandlingChargeMethodEnum.ByItemType && (
                <>
                  <Input
                    type="number"
                    placeholder="Order Fee"
                    size="small"
                    sx={{
                      width: '100%',
                      backgroundColor: '#ffffff !important',
                    }}
                    value={dataModalHandlingCharge.orderFee}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = 0;
                      }
                      const validateDecimal = decimalValidationHelper(
                        e.target.value,
                      );
                      setDataModalHandlingCharge((prev) => ({
                        ...prev,
                        orderFee: validateDecimal,
                      }));
                    }}
                    InputProps={{
                      autoComplete: 'off',
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={formErrorsHandling.orderFee}
                  />
                  <MUIGrid
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flowDirection: 'row',
                    }}
                  >
                    <Typography variant="body2">First</Typography>
                    <Input
                      type="number"
                      placeholder=""
                      size="small"
                      sx={{
                        backgroundColor: '#ffffff !important',
                        width: '100px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                      value={dataModalHandlingCharge.itemQty}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );
                        setDataModalHandlingCharge((prev) => ({
                          ...prev,
                          itemQty: validateDecimal,
                        }));
                      }}
                    />
                    <Typography variant="body2">item</Typography>
                    <Input
                      type="number"
                      placeholder=""
                      size="small"
                      sx={{
                        backgroundColor: '#ffffff !important',
                        width: '130px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                      value={dataModalHandlingCharge.itemFee}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );
                        setDataModalHandlingCharge((prev) => ({
                          ...prev,
                          itemFee: validateDecimal,
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="body2">
                      and any additional items
                    </Typography>
                    <Input
                      type="number"
                      placeholder=""
                      size="small"
                      sx={{
                        backgroundColor: '#ffffff !important',
                        width: '130px',
                        paddingLeft: '10px',
                      }}
                      value={dataModalHandlingCharge.itemAdditionalFee}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );
                        setDataModalHandlingCharge((prev) => ({
                          ...prev,
                          itemAdditionalFee: validateDecimal,
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flowDirection: 'row',
                    }}
                  >
                    <Typography variant="body2">First</Typography>
                    <Input
                      type="number"
                      placeholder=""
                      size="small"
                      sx={{
                        backgroundColor: '#ffffff !important',
                        width: '100px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                      value={dataModalHandlingCharge.uniqueItemQty}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );
                        setDataModalHandlingCharge((prev) => ({
                          ...prev,
                          uniqueItemQty: validateDecimal,
                        }));
                      }}
                    />
                    <Typography variant="body2">unique item</Typography>
                    <Input
                      type="number"
                      placeholder=""
                      size="small"
                      sx={{
                        backgroundColor: '#ffffff !important',
                        width: '130px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                      value={dataModalHandlingCharge.uniqueItemFee}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );
                        setDataModalHandlingCharge((prev) => ({
                          ...prev,
                          uniqueItemFee: validateDecimal,
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="body2">
                      and any additional unique items
                    </Typography>
                    <Input
                      type="number"
                      placeholder=""
                      size="small"
                      sx={{
                        backgroundColor: '#ffffff !important',
                        width: '130px',
                        paddingLeft: '10px',
                      }}
                      value={dataModalHandlingCharge.uniqueItemAdditionalFee}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );
                        setDataModalHandlingCharge((prev) => ({
                          ...prev,
                          uniqueItemAdditionalFee: validateDecimal,
                        }));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </MUIGrid>
                  <MUIGrid
                    container
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flowDirection: 'row',
                    }}
                  >
                    <MUIGrid item xs={6}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Customize Charge Rate by Item Type
                      </Typography>
                    </MUIGrid>
                    <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setSelectedUOMChargeRate({
                            rowId: Math.random(),
                            warehouseCustomerHandlingChargeUOMChargeRateId:
                              null,
                            itemType: null,
                            itemFee: null,
                            itemAdditionalFee: null,
                          });
                          setIsUOMChargeRateModal(true);
                        }}
                      >
                        Add Charge
                      </Button>
                    </MUIGrid>
                  </MUIGrid>
                  <UOMChargeRates />
                </>
              )}
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={() => {
                    setDataModalHandlingCharge(initialFormStateBillingHandling);
                    setFormErrorsHandling('');
                    setHandlingChargeMethodOption([]);
                    onCloseBillingHandlingModal();
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleOnCreateHandlingCharge(e)}
                >
                  SAVE
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>

      {/* ----------------------------------------------- ADHOC ----------------------------------------------------------- */}

      <Modal open={isBillingAdhocModalOpen}>
        <MUIContainer sx={{ maxWidth: '600px !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            {isAdhocChargeEdit ? 'Edit ' : 'New '}
            Adhoc Charge
          </Typography>

          <MUIContent>
            <Input
              required
              placeholder="Charge Name"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={dataModalAdhocCharge.chargeName}
              onChange={(e) => {
                setDataModalAdhocCharge((prev) => ({
                  ...prev,
                  chargeName: e.target.value,
                }));
              }}
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
              error={formErrorsAdhoc.chargeName}
            />
            <Input
              placeholder="Sub Account Name (optional)"
              size="small"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff !important',
              }}
              value={dataModalAdhocCharge.accountingCodeName}
              onChange={(e) => {
                setDataModalAdhocCharge((prev) => ({
                  ...prev,
                  accountingCodeName: e.target.value,
                }));
              }}
              type="text"
              InputProps={{
                autoComplete: 'off',
              }}
            />
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={9}>
                <Input
                  placeholder="Rate"
                  required
                  size="small"
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  value={dataModalAdhocCharge.rate}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }

                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setDataModalAdhocCharge((prev) => ({
                      ...prev,
                      rate: validateDecimal,
                    }));
                  }}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                  error={formErrorsAdhoc.rate}
                />
              </MUIGrid>
              <MUIGrid item xs={3}>
                <FormControlLabel
                  sx={{
                    backgroundColor: '#ffffff !important',
                  }}
                  control={
                    <Checkbox
                      checked={dataModalAdhocCharge.required}
                      onChange={(e) => {
                        setDataModalAdhocCharge((prev) => ({
                          ...prev,
                          required: e.target.checked,
                        }));
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" variant="subtitle2">
                      Required
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </MUIGrid>
            </MUIGrid>
            <Autocomplete
              disableClearable
              sx={{ width: '100%' }}
              // options={statusOptions}
              options={billingData.adhocCharges_AddEditCharge_ChargeTypeDDL}
              getOptionLabel={(option) => (option.text ? option.text : '')}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              size="small"
              value={dataModalAdhocCharge.chargeType}
              onChange={(event: any, value: any | null) => {
                setDataModalAdhocCharge((e) => ({
                  ...e,
                  chargeType: value,
                }));
              }}
              renderOption={(props, option: DropdownListItem) => (
                <li {...props} key={option.value}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      background: 'white',
                    },
                  }}
                  required
                  label="Charge Type"
                  {...(formErrorsAdhoc.chargeType && {
                    error: true,
                    helperText: formErrorsAdhoc.chargeType,
                  })}
                />
              )}
            />
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={4}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: 1, width: '100%' }}
                  onClick={() => {
                    setDataModalAdhocCharge(initialFormStateBillingAdhoc);
                    setFormErrorsAdhoc('');
                    onCloseBillingAdhocModal();
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => handleOnCreateAdhocCharge(e)}
                >
                  SAVE
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>
    </>
  );
});
