import { ModelParameter } from 'common/models/ModelParameter';
import { USDollar } from 'common/utils';
import DatePickerInput from 'components/datepicker';
import Input from 'components/input';
import { decimalValidationHelper } from 'pages/inventory/item/details';

import {
  Box,
  Typography,
  TextField,
  Divider,
  Grid as MUIGrid,
  InputAdornment,
  Button,
} from '@mui/material';

import { useInvoiceDetailFormContext } from './context';
import { InvoiceLineItemModel } from './models';

function InvoiceDetailForm() {
  const {
    state,

    // FORM
    setFormInvoiceNo,
    setFormInvoiceDate,
    setFormAdjustmentAmount,
    setFormComment,

    // LINE ITEM
    addLineItem,
    removeLineItem,
    setLineItemDescription,
    setLineItemQuantity,
    setLineItemAmount,
  } = useInvoiceDetailFormContext();

  return (
    <MUIGrid
      sx={{
        paddingTop: '20px !impoortant',
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography fontSize={20} color="#0052CC">
          {state.vm.form?.invoiceId && 'Edit'} Invoice Details:
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {/* HEADER ------------------------------------------------------------------------- */}
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: '30px !important',
            alignItems: 'center',
          }}
        >
          <MUIGrid item xs={7}>
            <Typography
              variant="h2"
              fontWeight="bold"
              sx={{ marginBottom: '10px', float: 'left', color: '#0052CC' }}
            >
              INVOICE
            </Typography>
          </MUIGrid>

          <MUIGrid item xs={5}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={3} sx={{ alignSelf: 'center' }}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ color: 'gray' }}
                >
                  Invoice no.:
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={7}>
                <TextField
                  placeholder="Invoice No"
                  value={state.vm.form?.invoiceNo}
                  name="invoiceNo"
                  disabled={state.isBusy}
                  onChange={(e) => {
                    setFormInvoiceNo(e.target.value);
                  }}
                  sx={{ width: '100%' }}
                  size="small"
                  required
                  {...(state.vm.formError?.invoiceNo && {
                    error: true,
                    helperText: state.vm.formError?.invoiceNo,
                  })}
                />
              </MUIGrid>
              <MUIGrid item xs={3} sx={{ alignSelf: 'center' }}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ color: 'gray' }}
                >
                  Invoice date:
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={7}>
                <DatePickerInput
                  label="Invoice Date"
                  inputFormat="MM/dd/yyyy"
                  value={state.vm.form?.invoiceDate}
                  disabled={state.isBusy}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      setFormInvoiceDate(e);
                    } else {
                      setFormInvoiceDate(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      size="small"
                      required
                      {...(state.vm.formError?.invoiceDate && {
                        error: true,
                        helperText: state.vm.formError?.invoiceDate,
                      })}
                    />
                  )}
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        {/* DESCRIPTION FOREACH ------------------------------------------------------------ */}
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: '20px !important',
            alignItems: 'center',
            marginBottom: 1,
          }}
        >
          {/* header ------------------------------------------------------------ */}
          <MUIGrid item xs={12}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={0.5}>
                <p style={{ tabSize: '2' }}> </p>
              </MUIGrid>
              <MUIGrid item xs={6.5}>
                <Typography variant="subtitle2" fontWeight="bold">
                  Description
                </Typography>
              </MUIGrid>
              {/* <MUIGrid item xs={1.5}>
                <Typography variant="subtitle2" fontWeight="bold">
                  Quantity
                </Typography>
              </MUIGrid> */}
              <MUIGrid item xs={4}>
                <Typography variant="subtitle2" fontWeight="bold">
                  Amount
                </Typography>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          {/* lineitem ------------------------------------------------------------ */}
          <MUIGrid item xs={12}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ marginBottom: 1 }}
            >
              {state.vm.form?.lineItems.map(
                (lineItem: InvoiceLineItemModel, index: number) => (
                  <>
                    <MUIGrid item xs={0.5}>
                      <Typography variant="subtitle1">{index + 1}</Typography>
                    </MUIGrid>
                    <MUIGrid item xs={6.5}>
                      <TextField
                        size="small"
                        sx={{
                          width: '100%',
                          backgroundColor: '#ffffff !important',
                        }}
                        name="description"
                        value={lineItem.description}
                        disabled={state.isBusy}
                        onChange={(e) => {
                          const model: ModelParameter<
                            InvoiceLineItemModel,
                            string,
                            string
                          > = {
                            model: lineItem,
                            key: 'description',
                            value: e.target.value,
                          };

                          setLineItemDescription(model);
                        }}
                        {...(lineItem.error?.description && {
                          error: true,
                          helperText: lineItem.error?.description,
                        })}
                        required
                      />
                    </MUIGrid>
                    {/* <MUIGrid item xs={1.5}>
                      <Input
                        size="small"
                        type="number"
                        sx={{
                          width: '100%',
                          backgroundColor: '#ffffff !important',
                        }}
                        placeholder=""
                        value={lineItem.quantity}
                        disabled={state.isBusy}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                          const validateDecimal = decimalValidationHelper(
                            e.target.value,
                          );

                          const model: ModelParameter<
                            InvoiceLineItemModel,
                            string,
                            number
                          > = {
                            model: lineItem,
                            key: 'quantity',
                            value: Number(validateDecimal),
                          };

                          setLineItemQuantity(model);
                        }}
                        {...(lineItem.error?.quantity && {
                          error: true,
                          helperText: lineItem.error?.quantity,
                        })}
                        required
                      />
                    </MUIGrid> */}
                    <MUIGrid item xs={4}>
                      <Input
                        size="small"
                        sx={{
                          width: '100%',
                          backgroundColor: '#ffffff !important',
                        }}
                        placeholder=""
                        type="number"
                        value={lineItem.amount}
                        disabled={state.isBusy}
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                          const validateDecimal = decimalValidationHelper(
                            e.target.value,
                          );

                          const model: ModelParameter<
                            InvoiceLineItemModel,
                            string,
                            number
                          > = {
                            model: lineItem,
                            key: 'amount',
                            value: Number(validateDecimal),
                          };

                          setLineItemAmount(model);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        {...(lineItem.error?.amount && {
                          error: true,
                          helperText: lineItem.error?.amount,
                        })}
                        required
                      />
                    </MUIGrid>
                    <MUIGrid item xs={1}>
                      <Button
                        disabled={state.isBusy}
                        onClick={() => removeLineItem(lineItem)}
                      >
                        X
                      </Button>
                    </MUIGrid>
                  </>
                ),
              )}
            </MUIGrid>
            <MUIGrid item xs={12} sx={{ padding: '20px 0 0 50px' }}>
              <Button disabled={state.isBusy} onClick={() => addLineItem()}>
                Add Item
              </Button>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        {/* SUBTOTALS FOREACH ------------------------------------------------------------ */}
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: '0 60px!important',
            justifyContent: 'right',
          }}
        >
          <MUIGrid item xs={6}>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ alignItems: 'center' }}
            >
              <MUIGrid item xs={6}>
                <Typography variant="body2" fontWeight="bold">
                  Subtotal ({state.vm.form?.totalQuantity} Item/s):
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Typography
                  sx={{ float: 'right' }}
                  variant="body2"
                  fontWeight="bold"
                >
                  {USDollar.format(state.vm.form?.subTotal ?? 0)}
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Typography variant="body2" fontWeight="bold">
                  Adjustment Amount:
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Input
                  size="small"
                  placeholder=""
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff !important',
                  }}
                  type="number"
                  value={state.vm.form?.adjustmentAmount}
                  disabled={state.isBusy}
                  onChange={(e) => {
                    const validateDecimal = decimalValidationHelper(
                      e.target.value,
                    );
                    setFormAdjustmentAmount(Number(validateDecimal));
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        {/* TOTAL FOREACH ------------------------------------------------------------ */}
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: '0 60px !important',
            justifyContent: 'right',
          }}
        >
          <MUIGrid item xs={6}>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ alignItems: 'center' }}
            >
              <MUIGrid item xs={6}>
                <Typography variant="body2" fontWeight="bold">
                  Total:
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={6}>
                <Typography
                  sx={{ float: 'right' }}
                  variant="body2"
                  fontWeight="bold"
                >
                  {USDollar.format(state.vm.form?.total ?? 0)}
                </Typography>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: '30px !important',
            alignItems: 'center',
          }}
        >
          <MUIGrid item xs={12}>
            <Typography variant="body2" fontWeight="bold">
              Comment
            </Typography>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <TextField
              autoComplete="off"
              value={state.vm.form?.comment}
              disabled={state.isBusy}
              onChange={(e) => {
                setFormComment(e.target.value);
              }}
              multiline
              minRows={2}
              sx={{ width: '100%' }}
              size="small"
            />
          </MUIGrid>
          <MUIGrid item xs={12}>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{ color: '#0052CC', paddingTop: '3%' }}
            >
              Thank You For Your Business!
            </Typography>
          </MUIGrid>
        </MUIGrid>
      </Box>
    </MUIGrid>
  );
}

export default InvoiceDetailForm;
