import {
  GenerateInvoiceModel,
  InvoiceCreateUpdateModel,
  InvoiceDeleteModel,
} from 'common/models/invoices';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

// POST /api/invoice/createupdate
export const CreateUpdateInvoice = async (model: InvoiceCreateUpdateModel) => {
  try {
    const response = await httpClient.post(`invoice/createupdate`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/invoice/delete
export const DeleteInvoice = async (model: InvoiceDeleteModel) => {
  try {
    const response = await httpClient.post(`invoice/delete`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/invoice/detail/{invoiceId}
export const GetInvoiceDetail = async (invoiceId: number) => {
  try {
    const response = await httpClient.get(`invoice/detail/${invoiceId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/invoice/detail/order/{orderId}
export const GetOrderDetailForNewInvoice = async (orderId: number) => {
  try {
    const response = await httpClient.get(`invoice/detail/order/${orderId}`);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/invoice/detail/billing
export const GenerateInvoiceForBilling = async (
  model: GenerateInvoiceModel,
) => {
  try {
    const response = await httpClient.post(`invoice/detail/billing`, model);

    return response;
  } catch (error) {
    throw errorCatch(error);
  }
};

// GET /api/invoice/list/warehousecustomer/{warehouseCustomerId}
export const GetWarehouseCustomerInvoiceList = async (
  warehouseCustomerId: number,
) => {
  try {
    const response = await httpClient.get(
      `invoice/list/warehousecustomer/${warehouseCustomerId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const updateInvoiceStatus = async (
  invoiceId: number,
  status: number,
) => {
  try {
    const response = await httpClient.put(
      `invoice/updateInvoiceStatus/${invoiceId}/${status}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
