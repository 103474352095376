/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import {
  getCustomerInventoryByCustomerId,
  getCustomerInventoryFiltered,
} from 'services/api/item/item.api';
import { getSupplierLookupByWarehouseCustomerSearchByName } from 'services/api/suppliers/suppliers.api';
import {
  CreateUserFilterSearchValue,
  deleteOrderFilter,
  getUserFilterSearchValue,
} from 'services/api/user/users.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import useLocalStorage from 'store/localStorage/hooks/useLocalStorage';

const InventoryListPageContext = createContext<IInventoryListPage>(
  {} as IInventoryListPage,
);
export const useInventoryListPageContext = () =>
  useContext(InventoryListPageContext);

type InventoryListPageProviderProps = {
  children: React.ReactNode;
};

type AutocompleteBase = {
  label?: string;
  value?: number;
};

export type SupplierType = AutocompleteBase;

interface ITilesInventory {
  totalPallets?: number;
  totalUniqueSkus?: number;
  totalCustomers?: number;
  totalKits?: number;
}

interface IInventoryUserFilterSearch {
  filterSearchParameter?: {
    value: number;
    label: string;
  };
  userFilterSearchValueId?: number;
  setFilterName?: string;
  skuOrDescription?: string;
  customerName?: string;
  pointOfContactName?: string;
  receiptDate?: string;
  lotNo?: string;
  location?: string;
  isKit?: boolean;
  isComponent?: boolean;
  isShowZeroInventory?: boolean;
  supplierName?: {
    value: number;
    label: string;
  };
}
interface IInventoryListPage {
  setAdvanceSearchValueInventory: React.Dispatch<
    React.SetStateAction<IInventoryUserFilterSearch>
  >;
  advanceSearchValueInventory: IInventoryUserFilterSearch;
  initialFormFilterSearchValue: IInventoryUserFilterSearch;
  OnClickCreateUserFilterSearchInventory: (e?: any) => Promise<void>;
  setCustomerInventory: React.Dispatch<React.SetStateAction<any[]>>;
  customerInventory: any[];
  setFilteredInventory: React.Dispatch<React.SetStateAction<any[]>>;
  filteredInventory: any[];
  setTilesInventory: React.Dispatch<React.SetStateAction<ITilesInventory>>;
  tilesInventory: ITilesInventory;
  onLoadCustomerInventory: (modelSearch?: any) => Promise<unknown>;
  viewByItemChecked: boolean;
  setViewByItemChecked: React.Dispatch<React.SetStateAction<boolean>>;
  filterSearchList: any;
  filterSearchOptions: any[];
  OnClickUserFilterSearchApply: (
    e?: any,
    forceApply?: boolean,
  ) => Promise<void>;
  optionCustomerSupplier: [] | AutocompleteBase[];
  setInventoryFilterListLocal: any;
  inventoryFilterListLocal: any;
  OnClickDeleteFilterSearchInventory: (e?: any) => Promise<void>;
}

export const InventoryListPageContextProvider = ({
  children,
}: InventoryListPageProviderProps) => {
  const {
    currentUser,
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
  } = useContext(AuthContext);

  const { isLocationAndFacilityModalOpen } = useContext(GlobalContext);

  const initialFormFilterSearchValue: IInventoryUserFilterSearch = {
    filterSearchParameter: null,
    userFilterSearchValueId: 0,
    setFilterName: '',
    skuOrDescription: '',
    customerName: '',
    pointOfContactName: '',
    receiptDate: null,
    lotNo: '',
    location: '',
    isKit: false,
    isComponent: false,
    isShowZeroInventory: false,
    supplierName: null,
  };

  const [inventoryFilterListLocal, setInventoryFilterListLocal] =
    useLocalStorage('inventoryFilterListLocal', null);

  const [advanceSearchValueInventory, setAdvanceSearchValueInventory] =
    useState(initialFormFilterSearchValue);
  const [filterSearchList, setFilterSearchList] = useState<any>(null);
  const [filterSearchOptions, setFilterSearchOptions] = useState([]);
  const [optionCustomerSupplier, setOptionCustomerSupplier] = useState<
    SupplierType[] | null | []
  >([]);

  const [customerInventory, setCustomerInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const initialStateTilesInventory: ITilesInventory = {
    totalPallets: 0,
    totalUniqueSkus: 0,
    totalCustomers: 0,
    totalKits: 0,
  };

  const [tilesInventory, setTilesInventory] = useState(
    initialStateTilesInventory,
  );

  const onLoadCustomerSuppliers = async () => {
    try {
      const suppliersFromApi =
        await getSupplierLookupByWarehouseCustomerSearchByName(
          currentUser.Claim_CustomerId,
          0,
          '',
        );
      return suppliersFromApi;
    } catch (err) {
      return err;
    }
  };

  const [viewByItemChecked, setViewByItemChecked] = useState<boolean | null>(
    null,
  );

  const loadFilterList = async () => {
    const result = await getUserFilterSearchValue('Inventory');
    setFilterSearchList(result);
    setFilterSearchOptions(
      result.map((v) => ({
        value: v.userFilterSearchValueId,
        label: v.setFilterName,
      })),
    );
  };

  const onLoadCustomerInventory = async (modelSearch?: any) => {
    try {
      let customerInventoryFromApi;
      const getStoredWarehouseValue = JSON.parse(
        localStorage.getItem('storeWarehouseCustomer'),
      );

      if (modelSearch !== undefined && modelSearch) {
        customerInventoryFromApi = await getCustomerInventoryFiltered(
          modelSearch,
        );
      } else {
        customerInventoryFromApi = await getCustomerInventoryByCustomerId({
          customerId: currentUser.Claim_CustomerId,
          customerFacilityId: !isWarehouseCustomerAccount
            ? currentLocationAndFacility.customerFacilityId
            : 0,
          showZeroInventory: false,
          showItemView: viewByItemChecked === null ? true : viewByItemChecked,
          warehouseCustomerId: getStoredWarehouseValue
            ? getStoredWarehouseValue.id
            : null,
        });
      }

      setCustomerInventory(customerInventoryFromApi.itemV2GetModel_Lists);
      setFilteredInventory(customerInventoryFromApi.itemV2GetModel_Lists);
      setTilesInventory((e) => ({
        ...e,
        totalPallets: customerInventoryFromApi.totalPallets,
        totalUniqueSkus: customerInventoryFromApi.totalUniqueSkus,
        totalCustomers: customerInventoryFromApi.totalCustomers,
        totalKits: customerInventoryFromApi.totalKits,
      }));
      return true;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (viewByItemChecked !== null) {
      onLoadCustomerInventory();
    }
  }, [viewByItemChecked]);

  const OnClickCreateUserFilterSearchInventory = async (e?: any) => {
    e?.preventDefault();
    const getStoredWarehouseValue = JSON.parse(
      localStorage.getItem('storeWarehouseCustomer'),
    );

    const payload = {
      CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
      CustomerLocationId: currentLocationAndFacility.customerLocationId,
      CustomerId: currentUser.Claim_CustomerId,
      WarehouseCustomerId: getStoredWarehouseValue
        ? getStoredWarehouseValue?.id
        : null,
      UserFilterSearchValueId: advanceSearchValueInventory.filterSearchParameter
        ? advanceSearchValueInventory.filterSearchParameter.value
        : null,
      SetFilterName: advanceSearchValueInventory.setFilterName,
      UserFilterValueTypeEnum: 'Inventory',
      ShowItemView: viewByItemChecked === null ? true : viewByItemChecked,
      UserFilterSearhValueInventoryModelData: {
        SkuOrDescription: advanceSearchValueInventory.skuOrDescription,
        CustomerName: advanceSearchValueInventory.customerName,
        PointOfContactName: advanceSearchValueInventory.pointOfContactName,
        ReceiptDate: advanceSearchValueInventory.receiptDate
          ? moment(advanceSearchValueInventory.receiptDate).format('MM/DD/yyyy')
          : null,
        LotNo: advanceSearchValueInventory.lotNo,
        Location: advanceSearchValueInventory.location,
        IsKit: advanceSearchValueInventory.isKit,
        IsComponent: advanceSearchValueInventory.isComponent,
        IsShowZeroInventory: advanceSearchValueInventory.isShowZeroInventory,
        SupplierId: advanceSearchValueInventory.supplierName
          ? advanceSearchValueInventory.supplierName.value
          : null,
        SupplierName: advanceSearchValueInventory.supplierName
          ? advanceSearchValueInventory.supplierName.label
          : null,
      },
    };

    try {
      await CreateUserFilterSearchValue(payload).then((result) => {
        loadFilterList().then(() => {
          setAdvanceSearchValueInventory((prev) => ({
            ...prev,
            filterSearchParameter: {
              value: result?.userFilterSearchValueId,
              label: result?.setFilterName,
            },
          }));
        });
      });
      await onLoadCustomerInventory(payload);
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  const OnClickUserFilterSearchApply = async (
    e?: any,
    forceApply?: boolean,
  ) => {
    e?.preventDefault();
    const getStoredWarehouseValue = JSON.parse(
      localStorage.getItem('storeWarehouseCustomer'),
    );

    const payload = {
      CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
      CustomerLocationId: currentLocationAndFacility.customerLocationId,
      CustomerId: currentUser.Claim_CustomerId,
      WarehouseCustomerId: getStoredWarehouseValue
        ? getStoredWarehouseValue?.id
        : null,
      UserFilterSearchValueId: advanceSearchValueInventory.filterSearchParameter
        ? advanceSearchValueInventory.filterSearchParameter.value
        : null,
      SetFilterName: advanceSearchValueInventory.setFilterName,
      UserFilterValueTypeEnum: 'Inventory',
      ShowItemView: viewByItemChecked === null ? true : viewByItemChecked,
      UserFilterSearhValueInventoryModelData: {
        SkuOrDescription: advanceSearchValueInventory.skuOrDescription,
        CustomerName: advanceSearchValueInventory.customerName,
        PointOfContactName: advanceSearchValueInventory.pointOfContactName,
        ReceiptDate: advanceSearchValueInventory.receiptDate
          ? moment(advanceSearchValueInventory.receiptDate).format('MM/DD/yyyy')
          : null,
        LotNo: advanceSearchValueInventory.lotNo,
        Location: advanceSearchValueInventory.location,
        IsKit: advanceSearchValueInventory.isKit,
        IsComponent: advanceSearchValueInventory.isComponent,
        IsShowZeroInventory: advanceSearchValueInventory.isShowZeroInventory
          ? advanceSearchValueInventory.isShowZeroInventory
          : false,
        SupplierId: advanceSearchValueInventory.supplierName
          ? advanceSearchValueInventory.supplierName.value
          : null,
        SupplierName: advanceSearchValueInventory.supplierName
          ? advanceSearchValueInventory.supplierName.label
          : null,
      },
    };

    try {
      if (forceApply) {
        onLoadCustomerInventory(payload);
      } else {
        onLoadCustomerInventory(null);
      }
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  const OnClickDeleteFilterSearchInventory = async (e?: any) => {
    e?.preventDefault();

    const temp: any = {};

    temp.filterSearchParameter =
      advanceSearchValueInventory.filterSearchParameter
        ? ''
        : 'This field is required';

    if (!Object.values(temp).every((x) => x === '')) {
      snackActions.error('No filter found');
      return;
    }
    try {
      const payload = {
        UserFilterSearchValueId:
          advanceSearchValueInventory.filterSearchParameter.value,
      };
      await deleteOrderFilter(payload);

      const clearItem = initialFormFilterSearchValue;
      setAdvanceSearchValueInventory(clearItem);
      setInventoryFilterListLocal(null);
      OnClickUserFilterSearchApply(e);
      snackActions.success(`Filter has been successfully delete`);
    } catch (err) {
      snackActions.error(`${err}`);
    }
  };

  useEffect(() => {
    const optCustomerSupplier = onLoadCustomerSuppliers();
    optCustomerSupplier
      .then((ddl) => {
        setOptionCustomerSupplier(
          ddl.map((c: any) => ({
            label: c.name,
            value: c.supplierId,
          })),
        );
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
    loadFilterList();
  }, [currentUser.Claim_CustomerId]);

  useEffect(() => {
    if (
      inventoryFilterListLocal !== null &&
      filterSearchList &&
      customerInventory
    ) {
      const getItem = [...filterSearchList];
      const newValue = getItem.find(
        (r) => r.userFilterSearchValueId === inventoryFilterListLocal,
      );
      setAdvanceSearchValueInventory((prev) => ({
        ...prev,
        filterSearchParameter: {
          value: newValue.userFilterSearchValueId,
          label: newValue.setFilterName,
        },
        setFilterName: newValue.setFilterName,
        userFilterSearchValueId: newValue.userFilterSearchValueId,
        skuOrDescription:
          newValue.userFilterSearhValueInventoryModelData.skuOrDescription,
        customerName:
          newValue.userFilterSearhValueInventoryModelData.customerName,
        pointOfContactName:
          newValue.userFilterSearhValueInventoryModelData.pointOfContactName,
        receiptDate: newValue.userFilterSearhValueInventoryModelData.receiptDate
          ? moment(
              newValue.userFilterSearhValueInventoryModelData.receiptDate,
            ).format('MM/DD/YY')
          : null,
        lotNo: newValue.userFilterSearhValueInventoryModelData.lotNo,
        location: newValue.userFilterSearhValueInventoryModelData.location,
        isKit: newValue.userFilterSearhValueInventoryModelData.isKit,
        isComponent:
          newValue.userFilterSearhValueInventoryModelData.isComponent,
        isShowZeroInventory:
          newValue.userFilterSearhValueInventoryModelData.isShowZeroInventory,
        supplierId: newValue.supplierName ? newValue.supplierName.value : null,
        supplierName: newValue.supplierName
          ? newValue.supplierName.label
          : null,
      }));
    }
  }, [filterSearchList, inventoryFilterListLocal, customerInventory]);

  useEffect(() => {
    if (!isLocationAndFacilityModalOpen) {
      if (advanceSearchValueInventory.filterSearchParameter) {
        const getStoredWarehouseValue = JSON.parse(
          localStorage.getItem('storeWarehouseCustomer'),
        );
        const payload = {
          CustomerFacilityId: currentLocationAndFacility.customerFacilityId,
          CustomerLocationId: currentLocationAndFacility.customerLocationId,
          CustomerId: currentUser.Claim_CustomerId,
          WarehouseCustomerId: getStoredWarehouseValue
            ? getStoredWarehouseValue?.id
            : null,
          UserFilterSearchValueId:
            advanceSearchValueInventory.filterSearchParameter
              ? advanceSearchValueInventory.filterSearchParameter.value
              : null,
          SetFilterName: advanceSearchValueInventory.setFilterName,
          UserFilterValueTypeEnum: 'Inventory',
          ShowItemView: viewByItemChecked === null ? true : viewByItemChecked,
          UserFilterSearhValueInventoryModelData: {
            SkuOrDescription: advanceSearchValueInventory.skuOrDescription,
            CustomerName: advanceSearchValueInventory.customerName,
            PointOfContactName: advanceSearchValueInventory.pointOfContactName,
            ReceiptDate: advanceSearchValueInventory.receiptDate
              ? moment(advanceSearchValueInventory.receiptDate).format(
                  'MM/DD/yyyy',
                )
              : null,
            LotNo: advanceSearchValueInventory.lotNo,
            Location: advanceSearchValueInventory.location,
            IsKit: advanceSearchValueInventory.isKit,
            IsComponent: advanceSearchValueInventory.isComponent,
            IsShowZeroInventory:
              advanceSearchValueInventory.isShowZeroInventory,
            SupplierId: advanceSearchValueInventory.supplierName
              ? advanceSearchValueInventory.supplierName.value
              : null,
            SupplierName: advanceSearchValueInventory.supplierName
              ? advanceSearchValueInventory.supplierName.label
              : null,
          },
        };
        onLoadCustomerInventory(payload);
      } else {
        onLoadCustomerInventory();
      }
    }
  }, [isLocationAndFacilityModalOpen]);

  const value: IInventoryListPage = useMemo(
    () => ({
      advanceSearchValueInventory,
      initialFormFilterSearchValue,
      customerInventory,
      filteredInventory,
      tilesInventory,
      filterSearchList,
      filterSearchOptions,
      optionCustomerSupplier,
      inventoryFilterListLocal,
      setInventoryFilterListLocal,
      onLoadCustomerInventory,
      OnClickCreateUserFilterSearchInventory,
      OnClickUserFilterSearchApply,
      setAdvanceSearchValueInventory,
      setCustomerInventory,
      setFilteredInventory,
      setTilesInventory,
      viewByItemChecked,
      setViewByItemChecked,
      OnClickDeleteFilterSearchInventory,
    }),
    [
      advanceSearchValueInventory,
      inventoryFilterListLocal,
      filteredInventory,
      tilesInventory,
      filterSearchList,
      filterSearchOptions,
      optionCustomerSupplier,
    ],
  );

  return (
    <InventoryListPageContext.Provider value={value}>
      {children}
    </InventoryListPageContext.Provider>
  );
};
