/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { IReportInfo } from 'common/interfaces';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import { REPORT_URL } from 'config/constants';
import moment from 'moment';
import { getCustomerSuppliers } from 'services/api/customer/customer.api';
import { getWarehouseCustomerLookup } from 'services/api/inventory/inventory-new.api';
import { getItemLookup } from 'services/api/item/item.api';
import { getLotNoByCustomerId } from 'services/api/receipts/receipts-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { reportUrlVariable } from 'store/reporturl';

import {
  Checkbox,
  Autocomplete,
  TextField,
  Button,
  Box,
  Paper,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
} from '@mui/material';

import { MainContainer, ContentContainer } from '../styles';

type AutocompleteBase = {
  label?: string;
  value?: string;
};
type CustomerType = AutocompleteBase;
type ReportsContextProviderProps = {
  children: React.ReactNode;
};

const ReportsContext = createContext(
  {} as {
    currentUser: any;
    customerValueSummary: any;
    isNoQtySummary: any;
    selectedDate: any;
    optionWarehouseCustomers: any;
    optionsSupplierCustomers: any;
    optionsProductCustomers: any;
    setCustomerValueSummary: any;
    setIsNoQtySummary: any;
    setSelectedDate: any;
    OnClickReportViewer: any;
    isWarehouseCustomerAccount: any;
    reportTypeValue: any;
    setReportTypeValue: any;
    outboundFrom: any;
    outboundTo: any;
    setOutboundFrom: any;
    setOutboundTo: any;
    paramErrors: any;
    inventoryDetailFrom: any;
    setInventoryDetailFrom: any;
    inventoryDetailTo: any;
    setInventoryDetailTo: any;
    paramInventoryDetailErrors: any;
    setParamInventoryDetailErrors: any;
    setAvgDailyOrderPeriod: any;
    avgDailyOrderPeriod: any;
    setsupplierValue: any;
    supplierValue: any;
    setproductValue: any;
    productValue: any;
    expiresIn?: any;
    setExpiresIn?: any;
    lotNoValue?: any;
    setLotNoValue?: any;
    optionsLotNo: any;
    setOptionsLotNo: any;
  },
);

export const useReportsContext = () => useContext(ReportsContext);

const ReportsContextProvider = ({ children }: ReportsContextProviderProps) => {
  const {
    currentUser,
    isWarehouseCustomerAccount,
    currentLocationAndFacility,
  } = useContext(AuthContext);
  const [optionWarehouseCustomers, setOptionsWarehouseCustomers] = useState<
    CustomerType[]
  >([]);
  const [optionsSupplierCustomers, setOptionsSupplierCustomers] = useState<
    CustomerType[]
  >([]);
  const [optionsProductCustomers, setOptionsProductCustomers] = useState<
    CustomerType[]
  >([]);

  const [optionsLotNo, setOptionsLotNo] = useState<CustomerType[]>([]);

  const [reportTypeValue, setReportTypeValue] = useState<IReportInfo>({
    name: 'inventory-summary',
    description: 'Inventory Summary Report',
    allowed: ['Customer'],
  });

  const [customerValueSummary, setCustomerValueSummary] =
    useState<CustomerType>(null);
  const [supplierValue, setsupplierValue] = useState<CustomerType>(null);
  const [productValue, setproductValue] = useState<CustomerType>(null);
  const [isNoQtySummary, setIsNoQtySummary] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState('');
  const [outboundFrom, setOutboundFrom] = useState('');
  const [outboundTo, setOutboundTo] = useState('');
  const [paramErrors, setParamErrors] = useState({});
  const [lotNoValue, setLotNoValue] = useState<CustomerType>(null);

  const [inventoryDetailFrom, setInventoryDetailFrom] = useState('');
  const [inventoryDetailTo, setInventoryDetailTo] = useState('');
  const [paramInventoryDetailErrors, setParamInventoryDetailErrors] = useState(
    {},
  );
  // replenishment report
  const [avgDailyOrderPeriod, setAvgDailyOrderPeriod] = useState<number>(90);
  const [expiresIn, setExpiresIn] = useState('');

  const onLoadWarehouseCustomerLookup = async () => {
    try {
      return await getWarehouseCustomerLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };
  const onLoadSuppliersCustomerLookup = async () => {
    try {
      return await getCustomerSuppliers(currentUser.Claim_CustomerId);
    } catch (err) {
      return err;
    }
  };
  const onLoadItemLookup = async () => {
    try {
      return await getItemLookup(currentUser.Claim_CustomerId, '');
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLotNoByCustomerId = async () => {
    try {
      return await getLotNoByCustomerId();
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    const optionsCustomer = onLoadWarehouseCustomerLookup();
    optionsCustomer
      .then((opt) => {
        setOptionsWarehouseCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch((err) => console.log(err));

    const optionsSupplier = onLoadSuppliersCustomerLookup();
    optionsSupplier
      .then((opt) => {
        setOptionsSupplierCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.supplierId,
          })),
        );
      })
      .catch((err) => console.log(err));

    const optionsProduct = onLoadItemLookup();
    optionsProduct
      .then((opt) => {
        setOptionsProductCustomers(
          opt.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
          })),
        );
      })
      .catch((err) => console.log(err));

    const optionsLotNoResult = onLoadGetLotNoByCustomerId();
    optionsLotNoResult
      .then((opt) => {
        setOptionsLotNo(
          opt.map((c: any) => ({
            label: c.lotNo,
            value: c.lotId,
          })),
        );
      })
      .catch((err) => console.log(err));
  }, [currentUser.Claim_CustomerId]);

  const OutboundValidation = () => {
    const temp: any = {};

    if (outboundTo) {
      if (!outboundFrom) {
        temp.outboundFrom = 'Please input Outbound From.';
      }
    }

    if (outboundFrom) {
      if (!outboundTo) {
        temp.outboundTo = 'Please input Outbound To.';
      }
    }

    if (outboundFrom && outboundTo) {
      if (
        moment(outboundFrom).format('MM-DD-yyyy') >
        moment(outboundTo).format('MM-DD-yyyy')
      ) {
        temp.outboundTo = 'Outbound From date is greater than Outbound To.';
        setOutboundTo('');
      } else {
        temp.outboundTo = '';
      }
    }

    setParamErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const InventoryDetailReportValidation = () => {
    const temp: any = {};

    if (selectedDate === null || selectedDate === '') {
      temp.selectedDate = 'Please input date.';
    }

    setParamErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const InventoryDetailValidation = () => {
    const temp: any = {};

    if (inventoryDetailTo) {
      if (!inventoryDetailFrom) {
        temp.inventoryDetailFrom = 'Please input Date From.';
      }
    }

    if (inventoryDetailFrom) {
      if (!inventoryDetailTo) {
        temp.inventoryDetailTo = 'Please input Date To.';
      }
    }

    if (inventoryDetailFrom && inventoryDetailTo) {
      if (
        moment(inventoryDetailFrom).format('MM-DD-yyyy') >
        moment(inventoryDetailTo).format('MM-DD-yyyy')
      ) {
        temp.inventoryDetailTo = 'Date From is greater than Date To.';
        setInventoryDetailTo('');
      } else {
        temp.inventoryDetailTo = '';
      }
    }
    if (reportTypeValue.name === 'billing-report') {
      if (customerValueSummary === null) {
        temp.customer = 'Please select customer!';
      } else {
        temp.customer = '';
      }
    }

    setParamInventoryDetailErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const OnClickReportViewer = async () => {
    let UrlStringSummary = '';
    if (!InventoryDetailValidation()) {
      return;
    }

    if (customerValueSummary === null) {
      if (reportTypeValue.name === 'inventory-outbound') {
        if (!OutboundValidation()) {
          return;
        }

        if (outboundFrom && outboundTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }/${btoa(currentUser.Claim_CustomerId)}/${moment(outboundFrom).format(
            'MM-DD-yyyy',
          )}/${moment(outboundTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }/${btoa(currentUser.Claim_CustomerId)}`;
        }
      }
      if (reportTypeValue.name === 'inventory-detail') {
        if (!InventoryDetailReportValidation()) {
          return;
        }

        if (selectedDate) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&facility=${btoa(
            currentLocationAndFacility.customerFacilityId,
          )}&dateTo=${moment(selectedDate).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&facility=${btoa(currentLocationAndFacility.customerFacilityId)}`;
        }
      }
      if (reportTypeValue.name === 'receipt-summary') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;
        }
      }

      if (reportTypeValue.name === 'inventory-summary') {
        UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
          reportTypeValue.name
        }?isNoQty=${isNoQtySummary}&customerIdString=${btoa(
          currentUser.Claim_CustomerId,
        )}`;
      }

      if (reportTypeValue.name === 'activity-report') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;
        }
      }
    } else {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }/${isNoQtySummary}/${btoa(currentUser.Claim_CustomerId)}/${btoa(
        customerValueSummary.value,
      )}`;

      if (reportTypeValue.name === 'billing-report') {
        if (!InventoryDetailValidation()) {
          return;
        }

        UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
          reportTypeValue.name
        }?customerIdString=${btoa(
          currentUser.Claim_CustomerId,
        )}&warehouseCustomeridString=${btoa(customerValueSummary.value)}`;

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&from=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&to=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        }
      }

      if (reportTypeValue.name === 'inventory-outbound') {
        if (!OutboundValidation()) {
          return;
        }

        if (outboundFrom && outboundTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }/${btoa(currentUser.Claim_CustomerId)}/${btoa(
            customerValueSummary.value,
          )}/${moment(outboundFrom).format('MM-DD-yyyy')}/${moment(
            outboundTo,
          ).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }/${btoa(currentUser.Claim_CustomerId)}/${btoa(
            customerValueSummary.value,
          )}`;
        }
      }

      if (reportTypeValue.name === 'inventory-detail') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (selectedDate) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&facility=${btoa(
            currentLocationAndFacility.customerFacilityId,
          )}&dateTo=${moment(selectedDate).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&facility=${btoa(currentLocationAndFacility.customerFacilityId)}`;
        }
      }

      if (reportTypeValue.name === 'receipt-summary') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(customerValueSummary.value)}`;
        }
      }

      if (reportTypeValue.name === 'inventory-summary') {
        UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
          reportTypeValue.name
        }?isNoQty=${isNoQtySummary}&customerIdString=${btoa(
          currentUser.Claim_CustomerId,
        )}&warehouseCustomeridString=${btoa(customerValueSummary.value)}`;
      }

      if (reportTypeValue.name === 'activity-report') {
        if (!InventoryDetailValidation()) {
          return;
        }

        if (inventoryDetailFrom && inventoryDetailTo) {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(
            customerValueSummary.value,
          )}&dateFrom=${moment(inventoryDetailFrom).format(
            'MM-DD-yyyy',
          )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
        } else {
          UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
            reportTypeValue.name
          }?customerIdString=${btoa(
            currentUser.Claim_CustomerId,
          )}&warehouseCustomeridString=${btoa(customerValueSummary.value)}`;
        }
      }

      if (
        isWarehouseCustomerAccount &&
        reportTypeValue.name === 'inventory-summary'
      ) {
        UrlStringSummary = `${UrlStringSummary}&isWarehouseCustomer=true`;
      }

      if (
        isWarehouseCustomerAccount &&
        reportTypeValue.name === 'receipt-summary'
      ) {
        UrlStringSummary = `${UrlStringSummary}&isWarehouseCustomer=true`;
      }

      if (
        isWarehouseCustomerAccount &&
        reportTypeValue.name === 'inventory-detail'
      ) {
        UrlStringSummary = `${UrlStringSummary}&isWarehouseCustomer=true`;
      }
    }

    if (reportTypeValue.name === 'locations-by-customer') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (!InventoryDetailValidation()) {
        return;
      }

      if (isWarehouseCustomerAccount) {
        UrlStringSummary = `${UrlStringSummary}&warehouseCustomeridString=${btoa(
          currentUser.Claim_WarehouseCustomerId,
        )}`;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary = `${UrlStringSummary}&dateFrom=${moment(
          inventoryDetailFrom,
        ).format('MM-DD-yyyy')}&dateTo=${moment(inventoryDetailTo).format(
          'MM-DD-yyyy',
        )}`;
      }
    }

    if (reportTypeValue.name === 'replenishmment-report') {
      const temp: any = {};

      temp.avgDailyOrderPeriod =
        avgDailyOrderPeriod > 0 ? '' : 'This field is required';

      setParamErrors({ ...temp });

      if (!Object.values(temp).every((x) => x === '')) {
        return;
      }
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(
        currentUser.Claim_CustomerId,
      )}&avgDailyOrderPeriod=${avgDailyOrderPeriod}`;

      if (isWarehouseCustomerAccount) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          currentUser.Claim_WarehouseCustomerId,
        )}`;
      }
    }

    if (reportTypeValue.name === 'purchase-order-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }
      if (supplierValue) {
        UrlStringSummary += `&supplierId=${btoa(supplierValue.value)}`;
      }

      if (productValue) {
        UrlStringSummary += `&product=${btoa(productValue.value)}`;
      }

      if (!InventoryDetailValidation()) {
        return;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    if (reportTypeValue.name === 'cycle-count-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }

      if (!InventoryDetailValidation()) {
        return;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    if (reportTypeValue.name === 'product-expiry-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }
      if (lotNoValue && lotNoValue.value !== null) {
        UrlStringSummary += `&lotNo=${lotNoValue.value}`;
      }
      if (expiresIn) {
        UrlStringSummary += `&expiresIn=${expiresIn}`;
      }
    }

    if (reportTypeValue.name === 'performance-tracking-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (!InventoryDetailValidation()) {
        return;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    if (reportTypeValue.name === 'shipment-cost-report') {
      UrlStringSummary = `${reportUrlVariable(REPORT_URL)}/reports/${
        reportTypeValue.name
      }?customerIdString=${btoa(currentUser.Claim_CustomerId)}`;

      if (!InventoryDetailValidation()) {
        return;
      }

      if (customerValueSummary) {
        UrlStringSummary += `&warehouseCustomeridString=${btoa(
          customerValueSummary.value,
        )}`;
      }

      if (inventoryDetailFrom && inventoryDetailTo) {
        UrlStringSummary += `&dateFrom=${moment(inventoryDetailFrom).format(
          'MM-DD-yyyy',
        )}&dateTo=${moment(inventoryDetailTo).format('MM-DD-yyyy')}`;
      }
    }

    window.open(UrlStringSummary, '_blank', 'noreferrer');
  };

  const value = useMemo(
    () => ({
      currentUser,
      customerValueSummary,
      isNoQtySummary,
      selectedDate,
      optionWarehouseCustomers,
      optionsSupplierCustomers,
      optionsProductCustomers,
      inventoryDetailFrom,
      inventoryDetailTo,
      isWarehouseCustomerAccount,
      reportTypeValue,
      outboundFrom,
      outboundTo,
      paramErrors,
      paramInventoryDetailErrors,
      avgDailyOrderPeriod,
      supplierValue,
      productValue,
      expiresIn,
      lotNoValue,
      optionsLotNo,
      setOptionsLotNo,
      setLotNoValue,
      setExpiresIn,
      setReportTypeValue,
      setOutboundFrom,
      setOutboundTo,
      setCustomerValueSummary,
      setIsNoQtySummary,
      setSelectedDate,
      OnClickReportViewer,
      setInventoryDetailFrom,
      setInventoryDetailTo,
      setParamInventoryDetailErrors,
      setAvgDailyOrderPeriod,
      setsupplierValue,
      setproductValue,
    }),
    [
      avgDailyOrderPeriod,
      customerValueSummary,
      optionWarehouseCustomers,
      inventoryDetailFrom,
      inventoryDetailTo,
      isNoQtySummary,
      selectedDate,
      isWarehouseCustomerAccount,
      reportTypeValue,
      outboundFrom,
      outboundTo,
      paramErrors,
      paramInventoryDetailErrors,
      supplierValue,
      productValue,
      expiresIn,
      lotNoValue,
      optionsLotNo,
      setReportTypeValue,
    ],
  );
  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};

const ListReports: IReportInfo[] = [
  {
    name: 'activity-report',
    description: 'Activity Report',
    allowed: [],
  },
  { name: 'billing-report', description: 'Billing Report', allowed: [] },
  {
    name: 'inventory-summary',
    description: 'Inventory Summary Report',
    allowed: ['Customer'],
  },
  {
    name: 'inventory-detail',
    description: 'Inventory Detail Report',
    allowed: ['Customer'],
  },
  {
    name: 'inventory-outbound',
    description: 'Inventory Outbound Report',
    allowed: ['Customer'],
  },
  {
    name: 'locations-by-customer',
    description: 'Locations By Client Report',
    allowed: ['Customer'],
  },
  {
    name: 'performance-tracking-report',
    description: 'Performance Tracking Report',
    allowed: [],
  },
  {
    name: 'purchase-order-report',
    description: 'Purchase Order Report',
    allowed: ['Customer'],
  },
  {
    name: 'receipt-summary',
    description: 'Receipt Summary Report',
    allowed: ['Customer'],
  },
  {
    name: 'replenishmment-report',
    description: 'Replenishmment Report',
    allowed: ['Customer'],
  },
  {
    name: 'cycle-count-report',
    description: 'Cycle Count Report',
    allowed: [],
  },
  {
    name: 'product-expiry-report',
    description: 'Product Expiry Report',
    allowed: ['Customer'],
  },
  {
    name: 'order-status-report',
    description: 'Order Status Report',
    allowed: [],
  },
  {
    name: 'shipment-cost-report',
    description: 'Shipment Cost Report',
    allowed: [],
  },
];

interface IReportListProps {
  activeReport: IReportInfo;
  onSelect: (name: string) => void;
  reports: IReportInfo[];
}

const ReportsList = (props: IReportListProps) => {
  const { activeReport, onSelect, reports } = props;
  const { setReportTypeValue, setCustomerValueSummary } = useReportsContext();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reportName = (event.target as HTMLInputElement).value;
    const currentReport = reports.find((r) => r.name === reportName);
    setReportTypeValue(currentReport);

    if (reportName === 'billing-report') {
      setCustomerValueSummary(null);
    }

    onSelect(reportName);
  };
  return (
    <Paper sx={{ flex: 'auto', width: '50%', background: '#fff', padding: 2 }}>
      <Box>
        <strong>Select Report</strong>
      </Box>
      <FormControl>
        <RadioGroup
          sx={{ display: 'inline-flex', flexFlow: 'wrap', padding: '10px 0' }}
          value={activeReport.name}
          onChange={handleRadioChange}
        >
          {reports.map((report: IReportInfo) => (
            <FormControlLabel
              value={report.name}
              control={<Radio size="small" color="info" />}
              label={report.description}
              key={`ky-${report.name}`}
              sx={{
                width: { sm: '100%', md: '32%' },
                marginRight: 0,
                marginLeft: 0,
                minHeight: '35px',
                '& .MuiFormControlLabel-label': {
                  fontSize: '13px',
                },
                '& .MuiButtonBase-root': {
                  padding: '0 5px 0 0;',
                  color: '#d6d9dd',
                },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

interface IReportRunnerProps {
  activeReport: IReportInfo;
}

const ReportsRunner = (props: IReportRunnerProps) => {
  const { activeReport } = props;
  const navigate = useNavigate();

  const {
    currentUser,
    customerValueSummary,
    optionWarehouseCustomers,
    setCustomerValueSummary,
    setIsNoQtySummary,
    setSelectedDate,
    inventoryDetailFrom,
    inventoryDetailTo,
    isNoQtySummary,
    selectedDate,
    OnClickReportViewer,
    isWarehouseCustomerAccount,
    reportTypeValue,
    setReportTypeValue,
    outboundFrom,
    outboundTo,
    setInventoryDetailFrom,
    setInventoryDetailTo,
    setOutboundFrom,
    setOutboundTo,
    paramErrors,
    paramInventoryDetailErrors,
    setAvgDailyOrderPeriod,
    avgDailyOrderPeriod,
    optionsSupplierCustomers,
    optionsProductCustomers,
    setsupplierValue,
    supplierValue,
    setproductValue,
    productValue,
    expiresIn,
    setExpiresIn,
    lotNoValue,
    setLotNoValue,
    optionsLotNo,
  } = useReportsContext();

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }
  function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
  }
  const date = new Date();
  const firstofWeek = date.getDate() - date.getDay();
  const lastofWeek = firstofWeek + 6;

  const firstDay = getFirstDayOfMonth(date.getFullYear(), date.getMonth());
  const lastDayCurrentMonth = getLastDayOfMonth(
    date.getFullYear(),
    date.getMonth(),
  );

  const [customerList, setCustomerList] = useState<CustomerType[]>([]);

  const handleRun = (name: string) => {
    if (name === 'order-status-report') {
      navigate('/reports/order-status-report');
      return;
    }
    OnClickReportViewer();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNoQtySummary(event.target.checked);
  };

  useEffect(() => {
    setReportTypeValue(activeReport);
    setInventoryDetailFrom(firstDay.toLocaleString());
    setInventoryDetailTo(new Date().toLocaleString());
    setOutboundFrom(firstDay.toLocaleString());
    setOutboundTo(new Date().toLocaleString());
    if (isWarehouseCustomerAccount) {
      if (optionWarehouseCustomers.length !== 0) {
        if (optionWarehouseCustomers.length === 1) {
          const result = optionWarehouseCustomers.find(
            (o: any) =>
              o.value === Number(currentUser.Claim_WarehouseCustomerId),
          );
          setCustomerValueSummary(result);
        }
      }
    }
    if (
      activeReport.name === 'billing-report' ||
      activeReport.name === 'cycle-count-report'
    ) {
      const newOpt = optionWarehouseCustomers.filter((e) => e.value !== 0);
      setCustomerList(newOpt);
      setInventoryDetailFrom(moment(firstDay).format('MM/DD/yyyy'));
      setInventoryDetailTo(moment(lastDayCurrentMonth).format('MM/DD/yyyy'));
    } else {
      setCustomerList(optionWarehouseCustomers);
    }
  }, [
    currentUser.Claim_CustomerId,
    optionWarehouseCustomers,
    activeReport.name,
  ]);

  return (
    <Paper
      sx={{
        flex: 'auto',
        width: '50%',
        background: '#fff',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <strong>{activeReport.description}</strong>
      </Box>
      <Box sx={{ flex: 'auto', padding: '10px 0' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {(activeReport.name === 'activity-report' ||
            activeReport.name === 'billing-report' ||
            activeReport.name === 'inventory-summary' ||
            activeReport.name === 'inventory-detail' ||
            activeReport.name === 'inventory-outbound' ||
            activeReport.name === 'receipt-summary' ||
            activeReport.name === 'cycle-count-report' ||
            activeReport.name === 'shipment-cost-report' ||
            activeReport.name === 'order-status-report') && (
            <Autocomplete
              onChange={(event: any, newValue: CustomerType) => {
                if (newValue !== null) {
                  setCustomerValueSummary(newValue);
                } else {
                  setCustomerValueSummary(null);
                }
              }}
              id="controllable-states"
              options={customerList}
              getOptionLabel={(option: CustomerType) => option.label}
              value={customerValueSummary}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  {...(activeReport.name === 'billing-report' && require)}
                  autoComplete="off"
                  id="outlined-required"
                  label="All Clients"
                  size="small"
                  type="text"
                  sx={{ width: '100%' }}
                  error={
                    activeReport.name === 'billing-report' &&
                    paramInventoryDetailErrors.customer
                  }
                  helperText={
                    activeReport.name === 'billing-report' &&
                    paramInventoryDetailErrors.customer
                  }
                />
              )}
              sx={{ width: '48.5%' }}
            />
          )}
          {activeReport.name === 'purchase-order-report' && (
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Autocomplete
                onChange={(event: any, newValue: CustomerType) => {
                  if (newValue !== null) {
                    setsupplierValue(newValue);
                  } else {
                    setsupplierValue(null);
                  }
                }}
                id="controllable-states"
                options={optionsSupplierCustomers}
                getOptionLabel={(option: CustomerType) => option.label}
                value={supplierValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Supplier"
                    size="small"
                    type="text"
                    sx={{ width: '100%' }}
                  />
                )}
                sx={{ flex: 'auto' }}
              />
              <Autocomplete
                onChange={(event: any, newValue: CustomerType) => {
                  if (newValue !== null) {
                    setproductValue(newValue);
                  } else {
                    setproductValue(null);
                  }
                }}
                id="controllable-states"
                options={optionsProductCustomers}
                getOptionLabel={(option: CustomerType) => option.label}
                value={productValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Product"
                    size="small"
                    type="text"
                    sx={{ width: '100%' }}
                  />
                )}
                sx={{ flex: 'auto' }}
              />
            </Box>
          )}
          {activeReport.name === 'inventory-summary' && (
            <Box>
              <Checkbox
                color="primary"
                size="medium"
                checked={isNoQtySummary}
                onChange={(e) => handleChange(e)}
              />
              Exclude SKUs with zero inventory
            </Box>
          )}
          {activeReport.name === 'replenishmment-report' && (
            <Box>
              <TextField
                id="outlined-required"
                label="Daily Order Period"
                onChange={(e) => {
                  e.preventDefault();
                  setAvgDailyOrderPeriod(e.target.value);
                }}
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                type="number"
                value={avgDailyOrderPeriod}
                size="small"
                sx={{ width: '100%' }}
                {...(paramErrors.avgDailyOrderPeriod && {
                  error: true,
                  helperText: paramErrors.avgDailyOrderPeriod,
                })}
              />
            </Box>
          )}
          {activeReport.name === 'product-expiry-report' && (
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Autocomplete
                onChange={(event: any, newValue: CustomerType) => {
                  if (newValue !== null) {
                    setLotNoValue(newValue);
                  } else {
                    setLotNoValue(null);
                  }
                }}
                id="controllable-states"
                options={optionsLotNo}
                getOptionLabel={(option: CustomerType) => option.label}
                value={lotNoValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    autoComplete="off"
                    id="outlined-required"
                    label="Lot #"
                    size="small"
                    type="text"
                    sx={{ width: '100%' }}
                  />
                )}
                sx={{ flex: 'auto' }}
              />
              <TextField
                id="outlined-required"
                label="Expires In:"
                onChange={(e) => {
                  e.preventDefault();
                  setExpiresIn(e.target.value);
                }}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                type="number"
                value={expiresIn}
                size="small"
                sx={{ flex: 'auto' }}
              />
            </Box>
          )}
          {activeReport.name === 'inventory-detail' && (
            <Box>
              <DatePickerInput
                label="Date*"
                inputFormat="MM/dd/yyyy"
                value={selectedDate || null}
                onChange={(e: any) => setSelectedDate(e)}
                renderInput={(params: any) => (
                  <TextField
                    disabled
                    {...params}
                    size="small"
                    error={paramErrors.selectedDate}
                    helperText={paramErrors.selectedDate}
                  />
                )}
              />
            </Box>
          )}
          {(activeReport.name === 'activity-report' ||
            activeReport.name === 'billing-report' ||
            activeReport.name === 'locations-by-customer' ||
            activeReport.name === 'performance-tracking-report' ||
            activeReport.name === 'purchase-order-report' ||
            activeReport.name === 'receipt-summary' ||
            activeReport.name === 'cycle-count-report' ||
            activeReport.name === 'shipment-cost-report' ||
            activeReport.name === 'order-status-report') && (
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <DatePickerInput
                label="Date From"
                inputFormat="MM/dd/yyyy"
                value={inventoryDetailFrom || null}
                onChange={(e: any) => setInventoryDetailFrom(e)}
                renderInput={(params: any) => (
                  <TextField
                    disabled
                    {...params}
                    size="small"
                    error={paramInventoryDetailErrors.inventoryDetailFrom}
                    helperText={paramInventoryDetailErrors.inventoryDetailFrom}
                  />
                )}
              />
              <DatePickerInput
                label="Date To"
                inputFormat="MM/dd/yyyy"
                value={inventoryDetailTo || null}
                onChange={(e: any) => setInventoryDetailTo(e)}
                renderInput={(params: any) => (
                  <TextField
                    disabled
                    {...params}
                    size="small"
                    error={paramInventoryDetailErrors.inventoryDetailTo}
                    helperText={paramInventoryDetailErrors.inventoryDetailTo}
                  />
                )}
              />
            </Box>
          )}
          {activeReport.name === 'inventory-outbound' && (
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <DatePickerInput
                label="Outbound From"
                inputFormat="MM/dd/yyyy"
                value={outboundFrom || null}
                onChange={(e: any) => setOutboundFrom(e)}
                renderInput={(params: any) => (
                  <TextField
                    disabled
                    {...params}
                    size="small"
                    error={paramErrors.outboundFrom}
                    helperText={paramErrors.outboundFrom}
                  />
                )}
              />
              <DatePickerInput
                label="Outbound To"
                inputFormat="MM/dd/yyyy"
                value={outboundTo || null}
                onChange={(e: any) => setOutboundTo(e)}
                renderInput={(params: any) => (
                  <TextField
                    disabled
                    {...params}
                    size="small"
                    error={paramErrors.outboundTo}
                    helperText={paramErrors.outboundTo}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          sx={{ float: 'right', textTransform: 'capitalize' }}
          color="info"
          onClick={() => handleRun(activeReport.name)}
        >
          Run Report
        </Button>
      </Box>
    </Paper>
  );
};

const defReport: IReportInfo = {
  name: '',
  description: '',
  allowed: [],
};

const ReportsBox = () => {
  const { isWarehouseCustomerAccount } = useContext(AuthContext);

  const [reports, setReports] = useState<IReportInfo[]>([]);
  const [reportSelected, setReportSelected] = useState(defReport);

  const handleSelectReport = (name: string) => {
    const report = ListReports.find((r) => r.name === name);
    setReportSelected(report);
  };

  useEffect(() => {
    if (isWarehouseCustomerAccount) {
      const customerReports = ListReports.filter((r) =>
        r.allowed.includes('Customer'),
      );
      setReports(customerReports);
      if (customerReports.length > 0) {
        setReportSelected(customerReports[0]);
      }
    } else {
      setReports(ListReports);
      setReportSelected(ListReports[0]);
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', gap: '15px', width: '100%' }}>
      <ReportsList
        activeReport={reportSelected}
        onSelect={handleSelectReport}
        reports={reports}
      />
      <ReportsRunner activeReport={reportSelected} />
    </Box>
  );
};

const Reports = () => (
  <MainContainer>
    <ReportsContextProvider>
      <Header />
      <ContentContainer>
        <ReportsBox />
      </ContentContainer>
    </ReportsContextProvider>
  </MainContainer>
);

export default React.memo(Reports);
