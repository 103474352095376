import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import Card from 'components/card';
import Header from 'components/header';
import { snackActions } from 'config/snackbar.js';
import AssignedToUserModal from 'pages/picking/assigned-to-user-modal';
import { StyledMenu } from 'pages/sales/salesorder/orderstatusdropdown';
import Printformtemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import { MainContainer, ContentContainer } from 'pages/styles';
import {
  getAndScanPickTicketV2,
  updatePickTicketAssignedTo,
} from 'services/api/packandship/packandship.api';
import { getSaleOrderPickQueue } from 'services/api/salesorders/salesorders.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Typography,
  Box,
  Link,
  Switch,
  Autocomplete,
  Button,
  TextField,
  Grid as MUIGrid,
  MenuItem,
  Card as MUICard,
} from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import {
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid-premium';

export function isNumber(value) {
  return typeof value === 'number';
}

type AutocompleteBase = {
  value?: number;
  text?: string;
};
type AssignedToType = AutocompleteBase;

interface IPickingQueue {
  pickTicketId?: number;
  batchNo?: number;
  orderNo?: string;
  pickingStatus?: string;
  pickingProgress?: string;
  sku?: string;
  locations: [];
  pickTickets: [];
  searchJobNumbers: [];
  assignedTo?: string;
}
interface ISearchState {
  searchOrderNo?: string;
  searchStatus?: string;
  searchProgress?: string;
  searchSku?: string;
  searchLocations?: string;
  searchPickTickets?: string;
  searchJobNumbers?: string;
  searchAssignedTo?: string;
  searchBatchNo?: string;
}

const PickQueueDataTableViewContext = createContext(
  {} as {
    setPickingQueue: React.Dispatch<React.SetStateAction<IPickingQueue[]>>;
    pickingQueue: IPickingQueue[];
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    checked: boolean;
    setSearchParams: React.Dispatch<React.SetStateAction<ISearchState>>;
    searchParams: ISearchState;
    HandleUpdatePickTicketAssignedTo: (
      e?: any,
      pickTicketIdParam?: number,
      assignedToParam?: number,
      pickTicketIdsParam?: any,
    ) => Promise<void>;
    setFilteredPickingQueue: React.Dispatch<React.SetStateAction<any[]>>;
    filteredPickingQueue: any[];
    setMainfilteredPickingQueue: React.Dispatch<React.SetStateAction<any[]>>;
    mainPickingQueue: any[];
    completedToday: number;
    unitsPending: number;
    itemCount: number;
    setIsEditAssignedTo: React.Dispatch<React.SetStateAction<boolean>>;
    isEditAssignedTo: boolean;
    setIsAssignedToUserModal: React.Dispatch<React.SetStateAction<boolean>>;
    isAssignedToUserModal: boolean;
    setRowSelectionModel: React.Dispatch<
      React.SetStateAction<GridSelectionModel>
    >;
    rowSelectionModel: GridSelectionModel;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>;
    anchorEl: HTMLElement;
  },
);

export const usePickQueueDataTableViewContext = () =>
  useContext(PickQueueDataTableViewContext);

export const PickQueueDataTableViewContextProvider = ({ children }: any) => {
  const { currentUser, currentLocationAndFacility } = useContext(AuthContext);
  const { isLocationAndFacilityModalOpen } = useContext(GlobalContext);

  const initialFormPickingQueueValue: IPickingQueue[] = [
    {
      pickTicketId: null,
      batchNo: null,
      orderNo: '',
      pickingStatus: '',
      pickingProgress: '',
      sku: '',
      locations: [],
      pickTickets: [],
      searchJobNumbers: [],
      assignedTo: '',
    },
  ];

  const [pickingQueue, setPickingQueue] = useState(
    initialFormPickingQueueValue,
  );

  const [checked, setChecked] = React.useState(false);
  const [mainPickingQueue, setMainfilteredPickingQueue] = useState([]);
  const [filteredPickingQueue, setFilteredPickingQueue] = useState([]);
  const [completedToday, setCompletedToday] = useState(0);
  const [unitsPending, setUnitsPending] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [isEditAssignedTo, setIsEditAssignedTo] = React.useState(false);
  const [isAssignedToUserModal, setIsAssignedToUserModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridSelectionModel>([]);

  const initialSearchState: ISearchState = {
    searchOrderNo: '',
    searchStatus: '',
    searchProgress: '',
    searchSku: '',
    searchLocations: '',
    searchPickTickets: '',
    searchJobNumbers: '',
    searchAssignedTo: '',
    searchBatchNo: '',
  };
  const [searchParams, setSearchParams] = useState(initialSearchState);

  const onLoadPickingQueue = async () => {
    try {
      const getStoredWarehouseValue = JSON.parse(
        localStorage.getItem('storeWarehouseCustomer'),
      );

      const pickingQueueFromApi = await getSaleOrderPickQueue(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
        getStoredWarehouseValue ? getStoredWarehouseValue.id : null,
      );

      setPickingQueue(pickingQueueFromApi.items);
      if (!checked) {
        const result = pickingQueueFromApi.items.filter(
          (u) => u.pickingStatus !== 'Complete',
        );
        setFilteredPickingQueue(result);
      } else {
        setFilteredPickingQueue(pickingQueueFromApi.items);
      }
      setMainfilteredPickingQueue(pickingQueueFromApi.items);
      setItemCount(pickingQueueFromApi.itemCount);
      setUnitsPending(pickingQueueFromApi.unitsPending);
      setCompletedToday(pickingQueueFromApi.completedToday);

      return true;
    } catch (error) {
      return error;
    }
  };

  const HandleUpdatePickTicketAssignedTo = async (
    e?: any,
    pickTicketIdParam?: number,
    assignedToParam?: number,
    pickTicketIdsParam?: any,
  ) => {
    e?.preventDefault();

    const payload = {
      pickTicketId: pickTicketIdParam,
      assignedTo: assignedToParam,
      pickTicketIds: pickTicketIdsParam
        ? pickTicketIdsParam.filter((x) => isNumber(x))
        : [],
    };

    try {
      await updatePickTicketAssignedTo(payload);
      snackActions.success('User has been successfully updated');
      onLoadPickingQueue();
      setIsEditAssignedTo(false);
    } catch (err) {
      snackActions.error(err);
    }
  };

  const onLoadScanPickTicketLookupV2 = async (
    customerLocationId: number,
    customerFacilityId: number,
    searchText: string,
  ) => {
    try {
      const response = await getAndScanPickTicketV2(
        customerLocationId,
        customerFacilityId,
        searchText,
      );

      return response;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    onLoadPickingQueue();
  }, [currentUser.Claim_CustomerId, isLocationAndFacilityModalOpen]);

  const value = useMemo(
    () => ({
      filteredPickingQueue,
      setFilteredPickingQueue,
      pickingQueue,
      setPickingQueue,
      checked,
      setChecked,
      searchParams,
      setSearchParams,
      HandleUpdatePickTicketAssignedTo,
      mainPickingQueue,
      setMainfilteredPickingQueue,
      completedToday,
      unitsPending,
      itemCount,
      isEditAssignedTo,
      setIsEditAssignedTo,
      isAssignedToUserModal,
      setIsAssignedToUserModal,
      rowSelectionModel,
      setRowSelectionModel,
      anchorEl,
      setAnchorEl,
    }),
    [
      anchorEl,
      checked,
      itemCount,
      pickingQueue,
      searchParams,
      unitsPending,
      completedToday,
      mainPickingQueue,
      isEditAssignedTo,
      rowSelectionModel,
      filteredPickingQueue,
      isAssignedToUserModal,
    ],
  );

  return (
    <PickQueueDataTableViewContext.Provider value={value}>
      {children}
    </PickQueueDataTableViewContext.Provider>
  );
};

const PickingDataTable = () => {
  const theme = useTheme();
  const { isWarehouseCustomerAccount, handleUserPermissionAllow } =
    useContext(AuthContext);
  const {
    onOpenPrintFormTemplateModal,
    idsToBulkPrintPickTicketFormTemplate,
    setIdsToBulkPrintPickTicketFormTemplate,
  } = useContext(GlobalContext);

  const {
    checked,
    anchorEl,
    itemCount,
    pickingQueue,
    searchParams,
    unitsPending,
    completedToday,
    mainPickingQueue,
    isEditAssignedTo,
    rowSelectionModel,
    filteredPickingQueue,
    setChecked,
    setAnchorEl,
    setPickingQueue,
    setIsEditAssignedTo,
    setRowSelectionModel,
    setFilteredPickingQueue,
    setIsAssignedToUserModal,
    HandleUpdatePickTicketAssignedTo,
  } = usePickQueueDataTableViewContext();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterArray = (gridValue, filterValue) => {
    const search = gridValue !== undefined ? gridValue : '';

    return search.toLowerCase().includes(filterValue.toLowerCase());
  };

  const filterUsers = () => {
    const saleOrderNoSearchResults = mainPickingQueue.filter((c) =>
      filterArray(c.orderNo, searchParams.searchOrderNo),
    );
    const batchNoSearchResults = saleOrderNoSearchResults.filter((c) =>
      filterArray(
        c.batchNo !== undefined ? c.batchNo.toString() : undefined,
        searchParams.searchBatchNo ? searchParams.searchBatchNo.toString() : '',
      ),
    );

    const pickingStatusSearchResults = batchNoSearchResults.filter((c) =>
      filterArray(c.pickingStatus, searchParams.searchStatus),
    );

    const unitsSearchResults = pickingStatusSearchResults.filter((c) =>
      filterArray(c.sku, searchParams.searchSku),
    );

    const progressSearchResults = unitsSearchResults.filter((c) =>
      filterArray(c.pickingProgress, searchParams.searchProgress),
    );

    const finalResult = progressSearchResults.filter((c) =>
      filterArray(c.assignedToName, searchParams.searchAssignedTo),
    );

    setFilteredPickingQueue(finalResult);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (!checked) {
      const result = pickingQueue.filter((u) => u.pickingStatus !== 'Complete');
      setFilteredPickingQueue(result);
    } else {
      setPickingQueue(mainPickingQueue);
      setFilteredPickingQueue(mainPickingQueue);
    }
  }, [checked]);

  useEffect(() => {
    filterUsers();
  }, [searchParams]);

  const [pickTicketIdPrint, setPickTicketIdPrint] = useState(0);

  const printPickTicketItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: any): Promise<void> => {
      e.preventDefault();
      setPickTicketIdPrint(id);
      setIdsToBulkPrintPickTicketFormTemplate(null);
      onOpenPrintFormTemplateModal();
    },
    [pickTicketIdPrint],
  );

  const HandleBulkPrintOnClick = async (e: any) => {
    e.preventDefault();
    try {
      setIdsToBulkPrintPickTicketFormTemplate(
        rowSelectionModel?.length > 1
          ? rowSelectionModel.filter((x) => isNumber(x))
          : filteredPickingQueue.map((v) => v.pickTicketId),
      );
      setPickTicketIdPrint(0);
      onOpenPrintFormTemplateModal();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const pickingColumns: GridColDef[] = [
    {
      field: 'batchNo',
      width: 200,
      headerName: 'Batch No',
    },
    {
      field: 'orderNo',
      minWidth: 200,
      headerName: 'Order',
      renderCell: (params: any) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={
            isWarehouseCustomerAccount
              ? `warehouse-customer-orders/sales-orders?form=view&id=${params.row.orderId}`
              : `orders/sales-orders?form=view&id=${params.row.orderId}`
          }
          target=""
        >
          <Typography
            className="cellDatagridWrapper"
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.orderNo}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'pickingStatus',
      width: 200,
      headerName: 'Status',
    },
    {
      field: 'pickingProgress',
      width: 150,
      headerName: 'Progress',
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            {params.row.pickingProgressReceivedQty}/
            {params.row.pickingProgressTotalQty}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={params.row.pickingProgress}
          />
        </Box>
      ),
    },
    {
      field: 'sku',
      width: 200,
      headerName: 'SKUs',
    },
    {
      field: 'pickTickets',
      width: 200,
      headerName: 'Pick Ticket',
      renderCell: (params) => (
        <ul className="flex">
          {params?.value?.map((row, index) => (
            <li key={Math.random()}>
              {params.row.pickingStatus === 'Complete' ? (
                <Link
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#303538',
                    textDecoration: 'none',
                  }}
                  href={`/pack-and-ship/pick-ticket?form=view&id=${params.row.pickTicketId}&pickId=${row.pickId}`}
                >
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: [theme.palette.primary.main],
                    }}
                  >
                    {row.pickId}
                  </Typography>
                </Link>
              ) : (
                <Button
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#303538',
                    textDecoration: 'none',
                  }}
                  onClick={(e) =>
                    printPickTicketItemOnClick(e, row.pickTicketId)
                  }
                >
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: [theme.palette.primary.main],
                    }}
                  >
                    {row.pickId}
                  </Typography>
                </Button>
              )}
            </li>
          ))}
        </ul>
      ),
      type: 'string',
    },
    {
      field: 'jobNumbers',
      width: 200,
      headerName: 'Job No',
      type: 'string',
    },
    {
      field: 'assignedTo',
      width: 250,
      headerName: 'Assigned To',
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
          {isEditAssignedTo && params.row.pickTicketId !== undefined ? (
            <Autocomplete
              onChange={(event: any, newValue: AssignedToType) => {
                if (newValue !== null) {
                  HandleUpdatePickTicketAssignedTo(
                    event,
                    params.row.pickTicketId,
                    newValue.value,
                  );
                }
              }}
              id="controllable-states"
              options={params.row.assignedByUsers}
              getOptionLabel={(option: AssignedToType) => option.text}
              value={
                {
                  value: params?.row?.assignedTo,
                  text: params?.row?.assignedToName,
                } || null
              }
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              renderInput={(paramsAuto) => (
                <TextField
                  {...paramsAuto}
                  inputProps={{
                    ...paramsAuto.inputProps,
                    autoComplete: 'off',
                  }}
                  autoComplete="off"
                  id="outlined"
                  label="Users"
                  size="small"
                  sx={{ width: '170%' }}
                />
              )}
            />
          ) : (
            <Typography sx={{ width: '100%' }}>
              {params.row.assignedToName
                ? params.row.assignedToName
                : 'Unassigned'}
            </Typography>
          )}

          {handleUserPermissionAllow(UserPermissionEnum.Picking_Edit) && (
            <Button
              sx={{ fontSize: '12px', position: 'relative' }}
              variant="text"
              size="small"
              onClick={() => {
                setIsEditAssignedTo(true);
              }}
            >
              {!isEditAssignedTo && 'Edit'}
            </Button>
          )}
        </div>
      ),
      type: 'string',
    },
  ];

  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['batchNo'],
      },
    },
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <MUICard className="cardLayoutPicking">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <HourglassTopIcon
                sx={{
                  backgroundColor: '#ffe2e7',
                  fontSize: 60,
                  color: 'red',
                }}
                className="hourGlassIcon"
              />
            </Box>
            <Box sx={{ width: '100%', padding: '0px 10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: '5px',
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Total Open Pick Tickets
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {unitsPending.toLocaleString()}
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={20}
                sx={{
                  height: '10px',
                  borderRadius: 2,
                  backgroundColor: '#ffe2e7',
                  [`& .${linearProgressClasses.bar1Determinate}`]: {
                    borderRadius: 2,
                    backgroundColor: 'red',
                  },
                }}
              />
            </Box>
          </Box>
        </MUICard>

        <MUICard className="cardLayoutPicking">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <CheckIcon
                sx={{
                  backgroundColor: '#d3ffe3',
                  fontSize: 60,
                  color: '#28d23f',
                }}
                className="hourGlassIcon"
              />
            </Box>
            <Box sx={{ width: '100%', padding: '0px 10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: '5px',
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Pick Tickets Completed Today
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {completedToday.toLocaleString()}
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={20}
                sx={{
                  height: '10px',
                  borderRadius: 2,
                  backgroundColor: '#d3ffe3',
                  [`& .${linearProgressClasses.bar1Determinate}`]: {
                    borderRadius: 2,
                    backgroundColor: '#28d23f',
                  },
                }}
              />
            </Box>
          </Box>
        </MUICard>
        <MUICard className="cardLayoutPicking">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <AccessTimeIcon
                sx={{
                  backgroundColor: '#ccf1ff',
                  fontSize: 60,
                  color: '#21b7ee',
                }}
                className="hourGlassIcon"
              />
            </Box>
            <Box sx={{ width: '100%', padding: '0px 10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: '5px',
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Total Items
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  {itemCount.toLocaleString()}
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={20}
                sx={{
                  height: '10px',
                  borderRadius: 2,
                  backgroundColor: '#ccf1ff',
                  [`& .${linearProgressClasses.bar1Determinate}`]: {
                    borderRadius: 2,
                    backgroundColor: '#21b7ee',
                  },
                }}
              />
            </Box>
          </Box>
        </MUICard>
      </Box>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={12} sx={{ textAlignLast: 'right' }}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Actions
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                disableRipple
                onClick={() => {
                  if (rowSelectionModel.length <= 1) {
                    snackActions.error(
                      'Please select at least two orders to assign user',
                    );
                    return;
                  }

                  setIsAssignedToUserModal(true);
                }}
              >
                Assign To User
              </MenuItem>
              <MenuItem disableRipple onClick={HandleBulkPrintOnClick}>
                Bulk Print Pick Ticket
              </MenuItem>
              <MenuItem disableRipple>
                Show Completed Orders
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </MenuItem>
            </StyledMenu>
          </MUIGrid>
          <MUIGrid item xs={12}>
            <DatagridPremium
              autoHeight
              rows={filteredPickingQueue}
              columns={pickingColumns}
              apiRef={apiRef}
              hideFooterSelectedRowCount
              pageSize={25}
              rowsPerPageOptions={[25]}
              getRowId={(row) => row.pickTicketId}
              rowGroupingColumnMode="single"
              initialState={initialState}
              pagination
              disableColumnMenu
              density="compact"
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              selectionModel={rowSelectionModel}
            />
          </MUIGrid>
        </MUIGrid>
      </Card>
      {pickTicketIdPrint !== 0 && (
        <Printformtemplate
          preview={false}
          dynamicId={pickTicketIdPrint}
          formtemplateTypeId={FormTemplateTypeEnum.PickTicket}
        />
      )}
      {idsToBulkPrintPickTicketFormTemplate !== null &&
        idsToBulkPrintPickTicketFormTemplate?.length > 0 && (
          <Printformtemplate
            preview={false}
            dynamicId={idsToBulkPrintPickTicketFormTemplate}
            formtemplateTypeId={FormTemplateTypeEnum.PickTicket}
            isBulkPrintPickTicket
          />
        )}
      <AssignedToUserModal />
    </>
  );
};

function Picking() {
  return (
    <MainContainer>
      <PickQueueDataTableViewContextProvider>
        <Header />
        <ContentContainer>
          <PickingDataTable />
        </ContentContainer>
      </PickQueueDataTableViewContextProvider>
    </MainContainer>
  );
}

export default React.memo(Picking);
