import { TriggerRuleModel } from 'common/models/orderprocessingrules';
import httpClient from 'infra/httpClient/index.js';
import { errorCatch } from 'services/api/serivceBase.js';

export const CreateOrderProcessingRule = async (model) => {
  try {
    const response = await httpClient.post(
      `/orderprocessingrule/createorderprocessingrule`,
      model,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetOrderProcessingRuleViewModel = async (
  customerType: number,
  warehouseCustomerId: number,
  customerLocationId: number,
) => {
  try {
    const url = `/orderprocessingrule/getorderprocessingruleviewmodel/${customerType}/${warehouseCustomerId}/${customerLocationId}`;

    const response = await httpClient.get(url);

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

export const GetDetailOrderProcessingRule = async (id) => {
  try {
    const response = await httpClient.get(
      `/orderprocessingrule/GetDetailOrderProcessingRule/${id}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const getOrderTagsLookup = async () => {
  try {
    const response = await httpClient.get(
      `/orderprocessingrule/lookup/ordertags`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};

export const updateRuleOrder = async (ruleId, direction) => {
  try {
    const response = await httpClient.get(
      `/orderprocessingrule/changeOrder/${ruleId}/${direction}`,
    );

    return response.data;
  } catch (error) {
    return errorCatch(error);
  }
};
export const deleteCustomRule = async (orderProcessingRuleId) => {
  try {
    const response = await httpClient.delete(
      `orderprocessingrule/DeleteCustomRule/${orderProcessingRuleId}`,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};

// POST /api/orderprocessingrule/triggerrule
export const TriggerRule = async (model: TriggerRuleModel) => {
  try {
    const response = await httpClient.post(
      `/OrderProcessingRule/TriggerRule`,
      model,
    );

    return response.data;
  } catch (error) {
    throw errorCatch(error);
  }
};
